import { FunctionComponent } from 'react'
import { OrderProductProps } from './OrderProduct'
import { OrderProductFeature } from './OrderProductFeature'
import styles from './OrderProductFeatures.module.sass'

export type OrderProductFeaturesProps = {
	features: OrderProductProps['features']
}

export const OrderProductFeatures: FunctionComponent<OrderProductFeaturesProps> = ({ features }) => {
	return (
		<div className={styles.items}>
			{features.map(
				feature =>
					feature.localesByLocale && (
						<div key={feature.id} className={styles.item}>
							<OrderProductFeature {...feature} />
						</div>
					),
			)}
		</div>
	)
}
