import clsx from 'clsx'
import { FunctionComponent, InputHTMLAttributes, useCallback, useState } from 'react'
import { FormSelect, FormSelectProps } from './FormSelect'
import { Input, InputProps, TextArea, TextAreaProps } from './Input'
import styles from './LabelWithField.module.sass'

export type LabelWithFieldProps = (LabelWithInputProps | LabelWithTextareaProps | LabelWithSelectProps) & {
	label?: string
}

export type LabelWithInputProps = {
	fieldType: 'default'
	autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete']
} & InputProps

export type LabelWithTextareaProps = {
	fieldType: 'textarea'
} & TextAreaProps

export type LabelWithSelectProps = {
	fieldType: 'select'
} & FormSelectProps

export const LabelWithField: FunctionComponent<LabelWithFieldProps> = props => {
	const [isFocused, setFocused] = useState(false)

	const onFocus = useCallback(() => {
		setFocused(true)
	}, [])

	const onBlur = useCallback(() => {
		setFocused(false)
	}, [])

	return (
		<div className={clsx(styles.wrapper, styles[`is_${props.fieldType}`], isFocused && styles.is_focused)}>
			<label className={styles.main}>
				{props.label && <div className={styles.label}>{props.label}</div>}
				{props.fieldType === 'default' ? (
					<Input {...props} onFocus={onFocus} onBlur={onBlur} />
				) : props.fieldType === 'textarea' ? (
					<TextArea {...props} onFocus={onFocus} onBlur={onBlur} />
				) : props.fieldType === 'select' ? (
					<FormSelect {...props} onFocus={onFocus} onBlur={onBlur} className={styles.select} />
				) : null}
			</label>
		</div>
	)
}
