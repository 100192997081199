import type { FunctionComponent } from 'react'
import { ButtonLinkResult } from '../data/ButtonLinkFragment'
import { FeatureListResult } from '../data/FeatureListFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Features } from './Features'
import styles from './FeaturesBlock.module.sass'

export type FeaturesBlockProps = FeatureListResult & {
	buttonLink?: ButtonLinkResult
}

export const FeaturesBlock: FunctionComponent<FeaturesBlockProps> = ({ items, buttonLink }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.features}>
				<Features items={items} />
			</div>
			{buttonLink?.link && (
				<div className={styles.button}>
					<Button type="link" href={contemberLinkToHref(buttonLink.link)} {...buttonLink}>
						{buttonLink.link.title}
					</Button>
				</div>
			)}
		</div>
	)
}
