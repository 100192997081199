import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { FeatureSectionAntimerResult } from '../data/FeatureSectionAntimerFragment'
import { FeatureSectionResult } from '../data/FeatureSectionFragment'
import { FeatureSectionHeatResult } from '../data/FeatureSectionHeatFragment'
import { FeatureSectionPumpResult } from '../data/FeatureSectionPumpFragment'
import { FeatureSectionTemperatureResult } from '../data/FeatureSectionTemperatureFragment'
import { FeatureSectionTubeResult } from '../data/FeatureSectionTubeFragment'
import { ImageResult } from '../data/ImageFragment'
import { BreakableContent } from './BreakableContent'
import { useTranslate } from './contexts/TranslationsContextProvider'
import styles from './FeatureSection.module.sass'

export type FeatureSectionProps = FeatureSectionResult

export const FeatureSection: FunctionComponent<FeatureSectionProps> = ({ antimer, heat, pump, temperature, tube }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.tiles}>
				<FeatureSectionAntimer {...antimer} />
				<FeatureSectionHeat {...heat} />
				<FeatureSectionPump {...pump} />
				<FeatureSectionTemperature {...temperature} />
				{(tube?.description || tube?.image || tube?.secondaryImage || tube?.title) && <FeatureSectionTube {...tube} />}
			</div>
		</div>
	)
}

export const FeatureSectionAntimer: FunctionComponent<FeatureSectionAntimerResult> = ({
	label1,
	label2,
	number,
	description,
	image,
}) => {
	return (
		<div className={clsx(styles.tile, styles.is_antimer)}>
			<div className={styles.header}>
				<div className={styles.label1}>{label1}</div>
				<div className={styles.number}>{number}</div>
				<div className={styles.label2}>{label2}</div>
			</div>
			<div className={styles.description}>{description && <BreakableContent isParagraph text={description} />}</div>
			{image && (
				<div className={styles.image}>
					<Image
						className={styles.imageIn}
						src={image.url}
						width={image.width}
						height={image.height}
						alt={image.alt ?? image.fileName ?? ''}
					/>
				</div>
			)}
		</div>
	)
}

export const FeatureSectionHeat: FunctionComponent<FeatureSectionHeatResult> = ({
	title1,
	titleHighlighted1,
	titleHighlighted2,
	image,
	description,
}) => {
	return (
		<div className={clsx(styles.tile, styles.is_heat)}>
			<h3 className={styles.title}>
				<span>{title1}</span>
				<span className={styles.highlighted}>{titleHighlighted1}</span>
				<span className={clsx(styles.shifted, styles.highlighted)}>{titleHighlighted2}</span>
			</h3>
			<div className={styles.description}>{description && <BreakableContent isParagraph text={description} />}</div>
			{image && (
				<div className={styles.image}>
					<Image
						className={styles.imageIn}
						src={image.url}
						width={image.width}
						height={image.height}
						alt={image.alt ?? image.fileName ?? ''}
					/>
				</div>
			)}
		</div>
	)
}

export const FeatureSectionPump: FunctionComponent<FeatureSectionPumpResult> = ({ title, sideTitle, description }) => {
	return (
		<div className={clsx(styles.tile, styles.is_pump)}>
			<div className={styles.header}>
				<div className={styles.column}>
					<h3 className={styles.title}>{title}</h3>
				</div>
				<div className={styles.sideTitles}>
					{[...Array(5)].map((_, index) => (
						<div key={index} className={styles.sideTitle}>
							{sideTitle}
						</div>
					))}
				</div>
			</div>
			<div className={styles.description}>{description && <BreakableContent isParagraph text={description} />}</div>
		</div>
	)
}

export const FeatureSectionTemperature: FunctionComponent<FeatureSectionTemperatureResult> = ({
	label,
	number,
	description,
}) => {
	const translations = useTranslate()
	return (
		<div className={clsx(styles.tile, styles.is_temperature)}>
			<div className={styles.header}>
				<span className={styles.label}>{label}</span>
				<div className={styles.number}>
					{number}
					<span className={styles.unit}>{translations('featureSection.temperature.unit')}</span>
				</div>
				<div className={styles.background}></div>
			</div>
			<div className={styles.description}>{description && <BreakableContent isParagraph text={description} />}</div>
		</div>
	)
}

export const FeatureSectionTube: FunctionComponent<FeatureSectionTubeResult> = ({
	title,
	image,
	secondaryImage,
	description,
}) => {
	return (
		<div className={clsx(styles.tile, styles.is_tube)}>
			<div className={styles.content}>
				<h3 className={styles.title}>{title}</h3>
				<div className={styles.description}>{description && <BreakableContent isParagraph text={description} />}</div>
			</div>
			{image && <FeatureImage {...image} deviceType="desktop" />}
			{secondaryImage && <FeatureImage {...secondaryImage} />}
		</div>
	)
}

type FeatureImageProps = ImageResult & {
	deviceType?: 'mobile' | 'desktop'
}

const FeatureImage: FunctionComponent<FeatureImageProps> = ({
	url,
	width,
	height,
	alt,
	fileName,
	deviceType = 'mobile',
}) => {
	return (
		<div className={clsx(styles.image, styles[`is_${deviceType}`])}>
			<Image className={styles.imageIn} src={url} width={width} height={height} alt={alt ?? fileName ?? ''} />
		</div>
	)
}
