import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { CaptionWithLine } from './CaptionWithLine'
import styles from './ImageBlock.module.sass'

export type ImageBlockProps = ImageResult & {
	description?: string
	isImageWithWhiteShadow?: boolean
}

export const ImageBlock: FunctionComponent<ImageBlockProps> = ({
	url,
	width,
	height,
	alt,
	fileName,
	description,
	isImageWithWhiteShadow,
}) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				description && styles.is_descriptionLayout,
				isImageWithWhiteShadow && styles.is_imageWithWhiteShadow,
			)}
			style={{
				'--ImageBlock-image-width': `${width}px`,
			}}
		>
			<div className={styles.side} />
			<div className={styles.image}>
				<Image
					src={url}
					width={width}
					height={height}
					className={styles.imageIn}
					alt={alt ?? fileName ?? description ?? ''}
				/>
			</div>
			<div className={styles.side}>{description && <CaptionWithLine text={description} />}</div>
		</div>
	)
}
