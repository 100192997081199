import clsx from 'clsx'
import { FunctionComponent, useCallback } from 'react'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import { useFlasMessage, useHandleFlashMessage } from './contexts/FlashMessageContext'
import { useTranslate } from './contexts/TranslationsContextProvider'
import styles from './FlashMessage.module.sass'
import { Icon } from './Icon'

// export type FlashMessageProps = {}

export const FlashMessage: FunctionComponent = () => {
	const { type, message } = useFlasMessage()
	const { handleVisibility, isVisible } = useHandleFlashMessage()
	const translations = useTranslate()
	const onClick = useCallback(() => {
		handleVisibility(false)
	}, [handleVisibility])
	const successStateText = translations('flashMessage.successStateText')
	const errorStateText = translations('flashMessage.errorStateText')
	return (
		<div className={clsx(styles.wrapper, isVisible && styles.is_visible)}>
			<div className={styles.main}>
				<div className={styles.state}>
					<div className={styles.icon}>
						{type === 'success' ? <Icon name="success" /> : type === 'error' ? <Icon name="error" /> : null}
					</div>
					<div>{type === 'success' ? successStateText : type === 'error' ? errorStateText : null}</div>
				</div>
				<div className={styles.message}>
					<BreakableContent text={message} />
				</div>
				<div className={styles.button}>
					<Button type="button" visualType="default" fillType="light" size="medium" onClick={onClick}>
						{translations('flashMessage.button.label')}
					</Button>
				</div>
			</div>
		</div>
	)
}
