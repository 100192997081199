import { FunctionComponent, useId } from 'react'
import Select, { ActionMeta, Props, SingleValue } from 'react-select'
import styles from './FormSelect.module.sass'
import { Icon } from './Icon'

export type SelectOptionProps = {
	value: string
	label: string | undefined
}

export interface FormSelectProps
	extends Pick<
		Props,
		'name' | 'onFocus' | 'onBlur' | 'menuIsOpen' | 'isSearchable' | 'className' | 'hideSelectedOptions'
	> {
	onChange: (newValue: SingleValue<SelectOptionProps>, actionMeta: ActionMeta<SelectOptionProps>) => void
	defaultValue?: SelectOptionProps
	options: SelectOptionProps[]
	required?: boolean
}

export const FormSelect: FunctionComponent<FormSelectProps> = ({
	name,
	onChange,
	onFocus,
	onBlur,
	menuIsOpen,
	isSearchable = false,
	hideSelectedOptions = false,
	options,
	defaultValue,
	className,
	required,
}) => {
	const instanceId = useId()

	return (
		<Select
			// need to pass id to instanceId to avoid warning (see: https://stackoverflow.com/questions/61290173/react-select-how-do-i-resolve-warning-prop-id-did-not-match)
			instanceId={instanceId}
			name={name}
			className={className}
			options={options}
			defaultValue={defaultValue ?? options[0]}
			classNamePrefix="select"
			onChange={onChange}
			isClearable
			onFocus={onFocus}
			onBlur={onBlur}
			menuIsOpen={menuIsOpen}
			isSearchable={isSearchable}
			required={required}
			hideSelectedOptions={hideSelectedOptions}
			components={{
				ClearIndicator: () => null,
				IndicatorSeparator: null,
				IndicatorsContainer: () => {
					return (
						<div className={styles.indicator}>
							<Icon name="chevron" />
						</div>
					)
				},
			}}
		/>
	)
}
