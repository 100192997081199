import clsx from 'clsx'
import { FunctionComponent, useRef } from 'react'
import { useInlineElementSize } from '../utilities/useInlineElementSize'
import { useIsInView } from '../utilities/useIsInView'
import { BreakableText } from './BreakableContent'
import styles from './CaptionWithLine.module.sass'

export type CaptionWithLineProps = {
	text: string
	isReverse?: boolean
	isVertical?: boolean
}

export const CaptionWithLine: FunctionComponent<CaptionWithLineProps> = ({
	text,
	isReverse = false,
	isVertical = false,
}) => {
	const descriptionRef = useRef<HTMLDivElement>(null)
	const wrapperRef = useRef<HTMLDivElement>(null)
	const [progressWidth, progressHeight] = useInlineElementSize(descriptionRef)
	const isInView = useIsInView(
		wrapperRef,
		{
			threshold: 0.9,
		},
		true,
	)
	return (
		<div
			className={clsx(
				styles.wrapper,
				isReverse && styles.is_reverse,
				isVertical && styles.is_vertical,
				isInView && styles.is_inView,
			)}
			style={{
				'--CaptionWithLine-progressWidth': `${progressWidth ? progressWidth + 'px' : '100%'}`,
				'--CaptionWithLine-progressHeight': `${progressHeight ? progressHeight + 'px' : '100%'}`,
			}}
			ref={wrapperRef}
		>
			{text && (
				<div className={styles.caption} ref={descriptionRef}>
					<div className={styles.captionLine}>
						<div className={styles.captionLineIn} />
					</div>
					<div className={styles.description}>
						<BreakableText text={text} />
					</div>
				</div>
			)}
		</div>
	)
}
