import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { CommonPageProps } from '../pages/[[...page]]'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { BreakableText } from './BreakableContent'
import { Container } from './Container'
import styles from './Footer.module.sass'

export type FooterProps = Pick<CommonPageProps, 'footer'>

export const Footer: FunctionComponent<FooterProps> = ({ footer }) => {
	const bitcoinPrice = footer?.bitcoinPrice
	return (
		<div className={styles.wrapper}>
			<Container size="wide">
				<div className={styles.main}>
					<div className={styles.content}>
						<div className={styles.logos}>
							<div className={styles.logo}>
								{footer?.localesByLocale?.root?.logo && (
									<Link title="logo" href="/" className={styles.logoLink}>
										<Image
											src={footer.localesByLocale.root.logo.url}
											width={footer.localesByLocale.root.logo.width}
											height={footer.localesByLocale.root.logo.height}
											alt={footer.localesByLocale.root.logo.alt ?? ''}
										/>
									</Link>
								)}
							</div>
							<div className={styles.logo}>
								{footer?.localesByLocale?.root?.logoCompany && (
									<Image
										src={footer?.localesByLocale?.root?.logoCompany.url}
										width={footer?.localesByLocale?.root?.logoCompany.width}
										height={footer?.localesByLocale?.root?.logoCompany.height}
										alt={footer?.localesByLocale?.root?.logoCompany.alt ?? ''}
									/>
								)}
							</div>
						</div>
						<div className={styles.columns}>
							{footer?.localesByLocale?.groupsLinkList.map(column => (
								<div key={column.id} className={styles.column}>
									{column.title && <h3 className={styles.columnTitle}>{column.title}</h3>}
									{column.linkList?.items && (
										<ul className={styles.linkList}>
											{column.linkList?.items.map(
												linkItem =>
													linkItem.link && (
														<li key={linkItem.id} className={styles.linkItem}>
															<Link href={contemberLinkToHref(linkItem.link)} className={styles.link}>
																{linkItem.link.title}
															</Link>
														</li>
													),
											)}
											{column.isAddress && column.addressLink && (
												<li className={styles.linkItem}>
													<Link href={contemberLinkToHref(column.addressLink)} className={styles.link}>
														<address className={styles.address}>
															{column.addressValue && <BreakableText text={column.addressValue} />}
														</address>
													</Link>
												</li>
											)}
										</ul>
									)}
								</div>
							))}
						</div>
					</div>
					<div className={styles.divider}></div>
					<div className={styles.bar}>
						<div className={styles.barColumn}>
							<div className={styles.copyright}>
								{footer?.localesByLocale?.copyright && <BreakableText text={footer.localesByLocale?.copyright} />}
							</div>
							<div className={styles.contact}>
								{footer?.localesByLocale?.contact && <BreakableText text={footer.localesByLocale?.contact} />}
							</div>
						</div>
						<div className={styles.bitcoinPrice}>
							<div className={styles.bitcoinPriceMain}>
								<span className={styles.bitcoinPriceLabel}>1 BTC</span>
								<span>—</span>
								<div className={styles.bitcoinPriceIn}>
									<div className={styles.bitcoinPriceValue}>{`€ ${bitcoinPrice?.eur}`}</div>
									<div className={styles.bitcoinPriceValue}>{`$ ${bitcoinPrice?.usd}`}</div>
								</div>
							</div>
							<div className={styles.bitcoinPriceSource}>
								source: <Link href="https://www.coindesk.com/">coindesk.com</Link>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
}
