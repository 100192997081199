export const scrollToTargetToTopElement = (target: string) => {
	const targetId = target.startsWith('#') ? target.substring(1) : target
	const scrollToTargetElement = document.getElementById(targetId)

	if (!scrollToTargetElement) {
		console.warn('Scroll to element is not found')
		return
	}

	return scrollToTargetElement.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	})
}
