import { HidingHeader } from 'hiding-header-react'
import { FunctionComponent } from 'react'
import { CommonPageProps } from '../pages/[[...page]]'
import { FlashMessage } from './FlashMessage'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './Layout.module.sass'

export type LayoutProps = Pick<CommonPageProps, 'header' | 'footer'> & {
	children?: React.ReactNode
	isHeaderVisible?: boolean
	isFooterVisible?: boolean
}

export const Layout: FunctionComponent<LayoutProps> = ({
	header,
	footer,
	isHeaderVisible = true,
	isFooterVisible = true,
	children,
}) => {
	return (
		<div className={styles.wrapper}>
			{header && isHeaderVisible && (
				<HidingHeader>
					<Header header={header} />
				</HidingHeader>
			)}
			<main>{children}</main>
			<FlashMessage />
			{footer && isFooterVisible && <Footer footer={footer} />}
		</div>
	)
}
