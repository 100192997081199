import type { FunctionComponent } from 'react'
import { ButtonLinkResult } from '../data/ButtonLinkFragment'
import { ButtonLinkVisualType } from '../generated/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import styles from './ButtonBlock.module.sass'
import { Container } from './Container'

export type ButtonBlockProps = {
	button: ButtonLinkResult
}

export const ButtonBlock: FunctionComponent<ButtonBlockProps> = ({ button }) => {
	return (
		<div className={styles.wrapper}>
			<Container size={button.widthType}>
				{button.link && (
					<div className={styles.main}>
						<Button
							type="link"
							href={contemberLinkToHref(button.link)}
							isFullSize
							isArrow={button.visualType === ButtonLinkVisualType.outline}
							{...button}
						>
							{button.link.title}
						</Button>
					</div>
				)}
			</Container>
		</div>
	)
}
