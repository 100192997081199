import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import React from 'react'
import styles from './BreakableContent.module.sass'

export type BreakableContentProps = {
	breakableFrom?: 'always' | '768' | '992'
	text: string
	isParagraph?: boolean
}

export const BreakableContent: FunctionComponent<BreakableContentProps> = ({
	text,
	breakableFrom = 'always',
	isParagraph = false,
}) => {
	return (
		<div className={clsx(styles.wrapper, breakableFrom && styles[`is_breakableFrom_${breakableFrom}`])}>
			{isParagraph ? (
				<p className={styles.paragraph}>
					<BreakableText text={text} />
				</p>
			) : (
				<BreakableText text={text} />
			)}
		</div>
	)
}

export type BreakableTextProps = {
	text: string
}

export const BreakableText: FunctionComponent<BreakableTextProps> = ({ text }) => {
	const lines = text.split('\n')
	return (
		<>
			{lines.map((line, i) => (
				<React.Fragment key={i}>
					{i != 0 && <br />}
					{line}
				</React.Fragment>
			))}
		</>
	)
}
