import clsx from 'clsx'
import { ButtonBase, DistinctBaseButtonProps, SharedBaseButtonProps } from 'nextjs-button-base'
import { FunctionComponent, MouseEventHandler, ReactNode, useCallback } from 'react'
import { onScrollToTargetClick } from '../utilities/onScrollToTargetClick'
import styles from './Button.module.sass'
import { Icon } from './Icon'

export type ButtonProps = (Omit<SharedBaseButtonProps, 'className'> & DistinctBaseButtonProps) & {
	isFullSize?: boolean
	size?: 'default' | 'medium'
} & (ButtonOutlineProps | ButtonDefaultProps)

type ButtonDefaultProps = {
	visualType?: 'default'
	fillType?: 'transparent' | 'light'
}

type ButtonOutlineProps = {
	visualType?: 'outline'
	isArrow?: boolean
}

export const Button: FunctionComponent<ButtonProps> = props => {
	const visualType: ButtonProps['visualType'] = props.visualType ?? 'default'
	const isFullSize: ButtonProps['isFullSize'] = props.isFullSize ?? false
	const size: ButtonProps['size'] = props.size ?? 'default'
	let fillType: ButtonDefaultProps['fillType']
	let isArrow: ButtonOutlineProps['isArrow']

	switch (visualType) {
		case 'default': {
			fillType = 'fillType' in props && props.fillType ? props.fillType : 'transparent'
			break
		}
		case 'outline': {
			isArrow = 'isArrow' in props && props.isArrow ? props.isArrow : false
		}
		default: {
			break
		}
	}

	const href = props.type === 'link' ? props.href.toString() : null

	const onClickHandler = useCallback<MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>>(
		event => {
			if (props.onClick) {
				props.onClick(event)
			}

			if (!href) {
				console.warn('URL is not provided')
				return null
			}

			if (href.startsWith('#')) {
				onScrollToTargetClick(event, href)
			}
		},
		[props, href],
	)

	return (
		<ButtonBase
			{...props}
			className={clsx(
				styles.wrapper,
				styles[`is_size_${size}`],
				styles[`is_visualType_${visualType}`],
				isFullSize && styles.is_fullSize,
				styles[`is_fill_${fillType}`], // visualType = 'default'
				isArrow && styles.is_arrow, // visualType = 'outline'
			)}
			onClick={onClickHandler}
		>
			<ButtonContent type={visualType} isArrow={isArrow}>
				{props.children}
			</ButtonContent>
		</ButtonBase>
	)
}

type ButtonContent = {
	type: NonNullable<ButtonProps['visualType']>
	isArrow?: ButtonOutlineProps['isArrow']
	children: ReactNode
}

export const ButtonContent: FunctionComponent<ButtonContent> = ({ type, isArrow = false, children }) => {
	if (type === 'outline' && isArrow) {
		return (
			<span className={styles.content}>
				<span className={styles.text}>{children}</span>
				<span className={styles.arrow}>
					<Icon name="arrowButton" />
				</span>
			</span>
		)
	}

	return <>{children}</>

	// return (
	// 	<span className={styles.content}>
	// 		<span className={styles.text}>{children}</span>
	// 		<span className={styles.arrow}>
	// 			<Icon name="arrowButton" />
	// 		</span>
	// 	</span>
	// )
}
