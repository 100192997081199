import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Button } from './Button'
import { useTermsAndConditions } from './contexts/OrderFormContext'
import { useTranslate } from './contexts/TranslationsContextProvider'
import styles from './OrderSubmit.module.sass'

export const OrderSubmit: FunctionComponent = () => {
	const translations = useTranslate()
	const { termsAndConditions } = useTermsAndConditions()
	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.submit, styles.is_mobile)}>
				<Button type="submit" visualType="default" fillType="light" isFullSize size="medium">
					{translations('orderForm.submit')}
				</Button>
			</div>
			<div className={clsx(styles.submit, styles.is_desktop)}>
				<Button type="submit" visualType="default" fillType="light">
					{translations('orderForm.submit')}
				</Button>
			</div>
			{termsAndConditions && (
				<div className={styles.terms}>
					<RichTextRenderer source={termsAndConditions} />
				</div>
			)}
		</div>
	)
}
