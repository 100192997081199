import type { FunctionComponent, ReactNode } from 'react'
import { HeatBoxRichTextRenderer } from './HeatBoxRichTextRenderer'
import style from './Wysiwyg.module.sass'

export type WysiwygProps = {
	source?: string
	children?: ReactNode
}

export const Wysiwyg: FunctionComponent<WysiwygProps> = ({ source, children }) => {
	return <div className={style.wrapper}>{source ? <HeatBoxRichTextRenderer source={source} /> : children}</div>
}
