import {
	createContext,
	FunctionComponent,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { FilledOrderFormResult } from '../../data/FilledOrderFormFragment'
import { OrderPageProps } from '../OrderPage'

type OrderFormContextProps = {
	productId: string
	price: number
	featureIds: string[]
	selectFeatureId: (id: string) => void
	unselectFeatureId: (id: string) => void
	data: FilledOrderFormResult
	setName: (name: FilledOrderFormResult['name']) => void
	setMobile: (mobile: FilledOrderFormResult['mobile']) => void
	setEmail: (email: FilledOrderFormResult['email']) => void
	setDeliveryAddress: (deliveryAddress: FilledOrderFormResult['deliveryAddress']) => void
	setCountry: (country: FilledOrderFormResult['country']) => void
	setProduct: (product: FilledOrderFormResult['product']) => void
	setNote: (note: FilledOrderFormResult['note']) => void
	termsAndConditions: OrderPageProps['termsAndConditions']
	setTermsAndConditions: (value: OrderPageProps['termsAndConditions']) => void
}

const defaultValues: OrderFormContextProps = {
	productId: '',
	price: 0,
	featureIds: [],
	selectFeatureId: () => {},
	unselectFeatureId: () => {},
	setName: () => {},
	setMobile: () => {},
	setEmail: () => {},
	setDeliveryAddress: () => {},
	setCountry: () => {},
	setNote: () => {},
	setProduct: () => {},
	data: {
		name: '',
		mobile: '',
		email: '',
		deliveryAddress: '',
		country: {
			id: '',
		},
		product: {
			id: '',
		},
		productFeatures: [],
	},
	termsAndConditions: '',
	setTermsAndConditions: () => {},
}

export const orderFormContext = createContext(defaultValues)
export const OrderFormProvider: FunctionComponent<
	PropsWithChildren<{ productId: string; basePrice: number; featurePricesById: Map<string, number> }>
> = ({ productId, basePrice, featurePricesById, children }) => {
	const [featureIds, setFeatureIds] = useState<Array<FilledOrderFormResult['productFeatures'][number]['id']>>([])
	const [name, setName] = useState<FilledOrderFormResult['name']>('')
	const [mobile, setMobile] = useState<FilledOrderFormResult['mobile']>('')
	const [email, setEmail] = useState<FilledOrderFormResult['email']>('')
	const [deliveryAddress, setDeliveryAddress] = useState<FilledOrderFormResult['deliveryAddress']>('')
	const [country, setCountry] = useState<FilledOrderFormResult['country']>({ id: '' })
	const [note, setNote] = useState<FilledOrderFormResult['note']>()
	const [product, setProduct] = useState<FilledOrderFormResult['product']>({ id: '' })
	const [termsAndConditions, setTermsAndConditions] = useState<OrderPageProps['termsAndConditions']>('')

	const data = useMemo(
		() => ({
			name,
			mobile,
			email,
			deliveryAddress,
			country,
			note,
			product,
			productFeatures: [...featureIds.map(id => ({ id }))],
		}),
		[country, deliveryAddress, email, featureIds, mobile, name, note, product],
	)

	const price = useMemo(
		() => basePrice + featureIds.reduce((sum, id) => sum + (featurePricesById.get(id) ?? 0), 0),
		[basePrice, featureIds, featurePricesById],
	)

	const selectFeatureId = useCallback((id: string) => {
		setFeatureIds(featureIds => [...featureIds, id])
	}, [])

	const unselectFeatureId = useCallback((id: string) => {
		setFeatureIds(featureIds => featureIds.filter(otherId => otherId !== id))
	}, [])

	useEffect(() => {
		setFeatureIds([])
	}, [productId])

	const value = useMemo(
		() => ({
			productId,
			price,
			featureIds,
			selectFeatureId,
			unselectFeatureId,
			data,
			setName,
			setMobile,
			setEmail,
			setDeliveryAddress,
			setCountry,
			setNote,
			setProduct,
			termsAndConditions,
			setTermsAndConditions,
		}),
		[data, featureIds, price, productId, selectFeatureId, termsAndConditions, unselectFeatureId],
	)

	return <orderFormContext.Provider value={value}>{children}</orderFormContext.Provider>
}

export const useFeature = () => {
	const { featureIds, selectFeatureId, unselectFeatureId } = useContext(orderFormContext)
	return { featureIds, selectFeatureId, unselectFeatureId }
}

export const useOrderPrice = () => useContext(orderFormContext).price

export const useOrderForm = () => {
	return useContext(orderFormContext)
}

export const useFormData = () => {
	const { data, setName, setMobile, setEmail, setDeliveryAddress, setCountry, setNote, setProduct } =
		useContext(orderFormContext)

	return {
		data,
		setName,
		setMobile,
		setEmail,
		setDeliveryAddress,
		setCountry,
		setNote,
		setProduct,
	}
}

export const useTermsAndConditions = () => {
	const { termsAndConditions, setTermsAndConditions } = useContext(orderFormContext)

	return { termsAndConditions, setTermsAndConditions }
}
