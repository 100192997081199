import { useLocales } from '../components/contexts/SwitcherLocalesContext'

export const useFormatPriceAndCurrency = (price: string) => {
	const { currentLocale } = useLocales()
	const currency = currentLocale?.locale?.currency
	const isCurrencyBeforeAmount = currentLocale?.locale?.code === 'en'
	const formatedPrice = price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	if (isCurrencyBeforeAmount) {
		return `${currency} ${formatedPrice}`
	} else {
		return `${formatedPrice} ${currency}`
	}
}
