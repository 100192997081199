import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormEventHandler, FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMirrorLoading } from 'shared-loading-indicator'
import { CommonPageProps } from '../pages/[[...page]]'
import { api } from '../utilities/api'
import { useHandleFlashMessage } from './contexts/FlashMessageContext'
import { OrderFormProvider, useFeature, useFormData, useTermsAndConditions } from './contexts/OrderFormContext'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { FormSelectProps } from './FormSelect'
import { OrderForm, OrderFormProps } from './OrderForm'
import styles from './OrderPage.module.sass'
import { OrderProducts, OrderProductsProps } from './OrderProducts'

export type OrderPageProps = NonNullable<CommonPageProps['page']['orderPage']>

export const OrderPage: FunctionComponent<OrderPageProps> = ({ products, termsAndConditions, countries, root }) => {
	const logo = root?.logo

	const options = useMemo(() => {
		return products.map(item => {
			return {
				value: item.id,
				label: item.localesByLocale?.name ?? '',
			}
		})
	}, [products])

	const [productId, setProductId] = useState<string>(options[0].value)

	const selectedProduct = useMemo(() => {
		return products.find(item => item.id === productId)
	}, [products, productId])

	const onProductChange = useCallback<FormSelectProps['onChange']>(selected => {
		if (!selected) {
			return
		}
		setProductId(selected.value)
	}, [])

	const { basePath } = useRouter()

	const featurePricesById = useMemo(() => {
		const features = new Map<string, number>()
		products.map(product => {
			product.features.map(feature => {
				features.set(feature.id, feature.localesByLocale?.price ?? 0)
			})
		})
		return features
	}, [products])

	const {
		query: { product: productSlugQuery },
	} = useRouter()

	useEffect(() => {
		if (typeof productSlugQuery !== 'string') {
			return
		}
		const id = products.find(item => item.slug === productSlugQuery)?.id
		if (!id) {
			return
		}
		setProductId(id)
	}, [productSlugQuery, products])

	return (
		<div className={styles.wrapper}>
			<div className={styles.closeWrapper}>
				<Link className={styles.link} href={basePath} aria-label="close">
					<div className={styles.close} />
				</Link>
			</div>
			<OrderFormProvider
				productId={productId}
				basePrice={
					selectedProduct?.localesByLocale?.discountedPrice && selectedProduct.localesByLocale.discountedPrice > 0
						? selectedProduct?.localesByLocale?.discountedPrice
						: selectedProduct?.localesByLocale?.price ?? 0
				}
				featurePricesById={featurePricesById}
			>
				<OrderPageForm
					products={products}
					options={options}
					selectedProductId={productId}
					onProductChange={onProductChange}
					tersmAndConditions={termsAndConditions}
					countries={countries}
					logo={logo}
				/>
			</OrderFormProvider>
		</div>
	)
}

type OrderPageFormProps = OrderProductsProps &
	OrderFormProps & {
		tersmAndConditions: OrderPageProps['termsAndConditions']
	}

const OrderPageForm: FunctionComponent<OrderPageFormProps> = ({
	products,
	onProductChange,
	options,
	selectedProductId,
	tersmAndConditions,
	countries,
	logo,
}) => {
	const { submit } = api.orderForm
	const mutation = submit.useMutation()
	const { featureIds, unselectFeatureId } = useFeature()
	const { data, setProduct } = useFormData()
	const translations = useTranslate()
	const [isResetable, setIsResetable] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const form = useRef<HTMLFormElement>(null)
	const { setTermsAndConditions } = useTermsAndConditions()
	const { handleFlashMessage, handleVisibility } = useHandleFlashMessage()

	const successMessage = translations('orderForm.successMessage')
	const errorMessage = translations('orderForm.errorMessage')

	const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		event => {
			event.preventDefault()

			if (!(data && data.product)) {
				throw new Error('OrderPageForm: product is not selected')
			}

			if (!(data && data.country)) {
				throw new Error('OrderPageForm: country is not selected')
			}

			mutation.mutate({
				name: data.name,
				mobile: data.mobile,
				email: data.email,
				deliveryAddress: data.deliveryAddress,
				countryId: data.country.id,
				note: data.note,
				productId: data.product.id,
				productFeatureIds: featureIds,
			})
		},
		[data, featureIds, mutation],
	)

	const onChangeProduct = useCallback<FormSelectProps['onChange']>(
		(selected, action) => {
			if (!selected) {
				return
			}
			setProduct({ id: selected.value })
			onProductChange(selected, action)
		},
		[onProductChange, setProduct],
	)

	useEffect(() => {
		setTermsAndConditions(tersmAndConditions)
	}, [setTermsAndConditions, tersmAndConditions])

	useEffect(() => {
		setProduct({ id: selectedProductId })
	}, [selectedProductId, setProduct])

	useEffect(() => {
		if (mutation.status === 'success' || mutation.status === 'error') {
			setIsSubmitted(true)
		}
	}, [mutation.status])

	useEffect(() => {
		if (isSubmitted && mutation.data?.mailStatus) {
			setIsResetable(true)
			handleVisibility(true)
			handleFlashMessage({
				type: 'success',
				message: successMessage,
			})
		} else if (isSubmitted && mutation.data?.mailStatus === false) {
			handleVisibility(true)
			handleFlashMessage({
				type: 'error',
				message: errorMessage,
			})
		}
	}, [errorMessage, handleFlashMessage, handleVisibility, isSubmitted, mutation.data?.mailStatus, successMessage])

	useEffect(() => {
		if (isResetable && mutation.data?.mailStatus) {
			form.current?.reset()
			featureIds.map(featureId => unselectFeatureId(featureId))
			setIsResetable(false)
		}
	}, [featureIds, isResetable, mutation.data?.mailStatus, unselectFeatureId])

	useMirrorLoading(mutation.status === 'loading')

	return (
		<form className={styles.main} onSubmit={onSubmit} ref={form}>
			<div className={styles.products}>
				<OrderProducts
					products={products}
					options={options}
					onProductChange={onChangeProduct}
					selectedProductId={selectedProductId}
					logo={logo}
				/>
			</div>
			<div className={styles.form}>
				<OrderForm countries={countries} />
			</div>
		</form>
	)
}
