import clsx from 'clsx'
import { ChangeEventHandler, FunctionComponent, useCallback, useMemo, useState } from 'react'
import { useFormatPriceAndCurrency } from '../utilities/useFormatPriceAndCurrency'
import { useFeature } from './contexts/OrderFormContext'
import { useTranslate } from './contexts/TranslationsContextProvider'
import styles from './OrderProductFeature.module.sass'
import { OrderProductFeaturesProps } from './OrderProductFeatures'

export type OrderProductFeatureProps = OrderProductFeaturesProps['features'][number]

export const OrderProductFeature: FunctionComponent<OrderProductFeatureProps> = ({ id, localesByLocale }) => {
	const translations = useTranslate()
	const { featureIds, selectFeatureId, unselectFeatureId } = useFeature()
	const isChecked = useMemo(() => featureIds.includes(id), [featureIds, id])
	const formattedPrice = useFormatPriceAndCurrency(
		localesByLocale?.price.toString() ? localesByLocale.price.toString() : '0',
	)

	const localOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
		event => {
			if (event.target.checked) {
				selectFeatureId(id)
			} else {
				unselectFeatureId(id)
			}
		},
		[id, selectFeatureId, unselectFeatureId],
	)

	if (!localesByLocale) {
		throw new Error(`OrderProductFeature: item (title or price) is not defined for feature ${id}`)
	}

	const [isFocused, setIsFocused] = useState(false)

	const onFocus = useCallback(() => {
		setIsFocused(true)
	}, [])

	const onBlur = useCallback(() => {
		setIsFocused(false)
	}, [])

	return (
		<label className={clsx(styles.wrapper, isChecked && styles.is_checked, isFocused && styles.is_focused)}>
			<input
				className={styles.checkbox}
				type="checkbox"
				name="checkbox"
				checked={isChecked}
				onChange={localOnChange}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			<div className={styles.checkboxCustom} />
			<div className={styles.content}>
				<div className={styles.title}>{localesByLocale.title}</div>
				<div className={styles.price}>
					{localesByLocale.price === 0 ? translations('orderForm.feature.price.free') : `+ ${formattedPrice}`}
				</div>
			</div>
		</label>
	)
}
