import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { FeatureListResult } from '../data/FeatureListFragment'
import styles from './Features.module.sass'

export type FeaturesProps = FeatureListResult

export const Features: FunctionComponent<FeaturesProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			<ul className={styles.list}>
				{items.map(item => (
					<li key={item.id} className={styles.item}>
						<div className={styles.icon}>
							{item.feature?.icon && (
								<Image
									src={item.feature.icon.url}
									width={item.feature.icon.width}
									height={item.feature.icon.height}
									alt={item.feature.icon.alt ?? ''}
								/>
							)}
						</div>
						<h3 className={styles.title}>{item.feature?.title}</h3>
					</li>
				))}
			</ul>
		</div>
	)
}
