import { ReferenceRendererProps } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import { ContentBlockResult } from '../data/ContentBlockFragment'
import { ContentResult } from '../data/ContentFragment'
import { ContentReferenceType } from '../generated/contember'
import { useContentRendererCopyPasteBugWorkaround } from '../utilities/useContentRendererCopyPasteBugWorkaround'
import { AsideContentMedia } from './AsideContentMedia'
import { ButtonBlock } from './ButtonBlock'
import { CallToAction } from './CallToAction'
import { Container } from './Container'
import { ContentImageWithFeatures } from './ContentImageWithFeatures'
import styles from './ContentRenderer.module.sass'
import { FeaturesBlock } from './FeaturesBlock'
import { FeatureSection } from './FeatureSection'
import { HeatBoxRichTextRenderer } from './HeatBoxRichTextRenderer'
import { ImageBlock } from './ImageBlock'
import { ImageTiles } from './ImageTiles'
import { ProductCategoryTabsTiles } from './ProductCategoryTabsTiles'
import { Projection } from './Projection'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	asideContentMedia: function asideContentMedia({ reference }) {
		return (
			reference.asideContentMedia && (
				<Container size="medium">
					<AsideContentMedia {...reference.asideContentMedia} />
				</Container>
			)
		)
	},
	button: function button({ reference }) {
		return reference.buttonLink?.link && <ButtonBlock button={reference.buttonLink} />
	},
	callToAction: function callToAction({ reference }) {
		return (
			<Container size="medium">
				<CallToAction
					type={reference.callToActionTypes}
					imageAboveContent={reference.image}
					title={reference.primaryText}
					text={reference.secondaryText}
					backgroundVideo={reference.video}
					backgroundVideoMobile={reference.secondaryVideo}
					backgroundImage={reference.secondaryImage}
					backgroundImageMobile={reference.terciaryImage}
					button={reference.buttonLink}
					buttonTwo={reference.buttonLinkTwo}
				/>
			</Container>
		)
	},
	contentImageWithFeatures: function contentImageWithFeatures({ reference }) {
		return (
			reference.contentImageWithFeatures && (
				<Container size="medium">
					<ContentImageWithFeatures {...reference.contentImageWithFeatures} />
				</Container>
			)
		)
	},
	features: function features({ reference }) {
		return (
			reference.featureList && (
				<Container>
					<FeaturesBlock items={reference.featureList.items} buttonLink={reference.buttonLink} />
				</Container>
			)
		)
	},
	featureSection: function featureSection({ reference }) {
		return (
			reference.featureSection && (
				<Container size="medium">
					<FeatureSection {...reference.featureSection} />
				</Container>
			)
		)
	},
	image: function image({ reference }) {
		return (
			reference.image && (
				<Container size="medium">
					<ImageBlock
						{...reference.image}
						description={reference.primaryText}
						isImageWithWhiteShadow={reference.isImageWithWhiteShadow}
					/>
				</Container>
			)
		)
	},
	imageTiles: function imageTiles({ reference }) {
		return (
			reference.imageList && (
				<Container size="medium">
					<ImageTiles items={reference.imageList.items} image={reference.image} />
				</Container>
			)
		)
	},
	productTabsTiles: function productTabsTiles({ reference }) {
		return (
			reference.productCategories && (
				<Container>
					<ProductCategoryTabsTiles items={reference.productCategories.items} />
				</Container>
			)
		)
	},
	projection: function projection({ reference }) {
		return (
			reference.projection && (
				<Container size="medium">
					<Projection items={reference.projection.items} />
				</Container>
			)
		)
	},
	// @TODO: video
	// video: function video({ reference }) {
	// 	return (
	// 		reference.video && (
	// 			<Container disableGutters>
	// 				<Video {...reference.video} />
	// 			</Container>
	// 		)
	// 	)
	// },
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({ content }) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return (
		<div className={styles.wrapper}>
			<HeatBoxRichTextRenderer
				blocks={blocks}
				sourceField="json"
				renderElement={element => {
					const { type } = element.element

					if (nestedTypes.includes(type)) {
						return element.fallback
					}

					if (standaloneTypes.includes(type)) {
						return (
							<div
								className={clsx(
									styles.section,
									element.referenceType && styles[`is_reference_${element.referenceType}`],
								)}
							>
								{type !== 'reference' || !element.referenceType || element.referenceType in referenceRenderers ? (
									element.fallback
								) : (
									<Container>
										<div className={styles.notImplemented}>
											<div className={styles.notImplemented_name}>{element.referenceType}</div>
											is not yet implemented
										</div>
									</Container>
								)}
							</div>
						)
					}
					return (
						<div className={clsx(styles.section, styles.is_wysiwyg, styles[`is_${type}`])}>
							<Container>
								<Wysiwyg>{element.fallback}</Wysiwyg>
							</Container>
						</div>
					)
				}}
				referenceRenderers={referenceRenderers}
			/>
		</div>
	)
}
