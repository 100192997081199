import type { FunctionComponent, ReactNode } from 'react'
import styles from './DescriptionText.module.sass'

export type DescriptionTextProps = {
	children: ReactNode
}

export const DescriptionText: FunctionComponent<DescriptionTextProps> = ({ children }) => {
	return <span className={styles.wrapper}>{children}</span>
}
