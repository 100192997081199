import { RefObject, useCallback, useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { plusJakartaSans } from '../pages/_app'

export function useInlineElementSize(element: RefObject<HTMLDivElement> | null) {
	const [isElementWidth, setElementWidth] = useState<number | null>(null)
	const [isElementHeight, setElementHeight] = useState<number | null>(null)
	const { width, height } = useWindowSize()

	const recalculate = useCallback(() => {
		if (element?.current) {
			const offsetWidth = element.current.offsetWidth
			const offsetHeight = element.current.offsetHeight

			setElementWidth(offsetWidth ?? null)
			setElementHeight(offsetHeight ?? null)
		}
	}, [element])

	useEffect(() => {
		recalculate()

		window.addEventListener('resize', recalculate)
		return () => {
			window.removeEventListener('resize', recalculate)
		}
	}, [width, height, recalculate])

	useEffect(() => {
		document.fonts.ready.then(function () {
			if ([plusJakartaSans.style.fontFamily].some(fontFamily => document.fonts.check(`1em ${fontFamily}`))) {
				recalculate()
			}
		})
	}, [recalculate])

	return [isElementWidth, isElementHeight]
}
