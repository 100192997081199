import clsx from 'clsx'
import Image from 'next/image'
import { Fragment, FunctionComponent, useRef } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { ImageListResult } from '../data/ImageListFragment'
import { useInlineElementSize } from '../utilities/useInlineElementSize'
import { BreakableText } from './BreakableContent'
import { CaptionWithLine } from './CaptionWithLine'
import styles from './ImageTiles.module.sass'

export type ImageTilesProps = {
	image?: ImageResult
} & ImageListResult

export const ImageTiles: FunctionComponent<ImageTilesProps> = ({ image, items }) => {
	return (
		<div className={styles.wrapper}>
			{image && (
				<Image
					className={clsx(styles.image, styles.is_nonTile)}
					src={image.url}
					width={image.width}
					height={image.height}
					alt={image.alt ?? ''}
				/>
			)}
			<div className={styles.items}>
				{items.map(
					(item, count) =>
						item && (
							<Fragment key={item.id}>
								{count === 0 ? (
									<ImageTile {...item} isDescriptionVertical />
								) : count === items.length - 1 ? (
									<ImageTile {...item} isDescriptionReverese />
								) : (
									<ImageTile {...item} />
								)}
								{count === 1 && <div className={clsx(styles.item, styles.is_empty)} />}
								{count === 2 && <div className={clsx(styles.item, styles.is_empty)} />}
							</Fragment>
						),
				)}
			</div>
		</div>
	)
}

type ImageTile = {
	isDescriptionReverese?: boolean
	isDescriptionVertical?: boolean
} & ImageTilesProps['items'][number]

const ImageTile: FunctionComponent<ImageTile> = ({
	image,
	imageMobile,
	description,
	isDescriptionReverese = false,
	isDescriptionVertical = false,
}) => {
	const descriptionRef = useRef<HTMLDivElement>(null)

	const [progressWidth, progressHeight] = useInlineElementSize(descriptionRef)

	return (
		<div
			className={styles.item}
			style={{
				'--ImageBlock-progressWidth': `${progressWidth ? progressWidth + 'px' : '100%'}`,
				'--ImageBlock-progressHeight': `${progressHeight ? progressHeight + 'px' : '100%'}`,
			}}
		>
			{imageMobile && (
				<Image
					className={clsx(styles.image, styles.is_mobile)}
					src={imageMobile.url}
					width={imageMobile.width}
					height={imageMobile.height}
					alt={imageMobile.alt ?? imageMobile.fileName ?? ''}
				/>
			)}
			{image && (
				<Image
					className={clsx(styles.image, styles.is_desktop)}
					src={image.url}
					width={image.width}
					height={image.height}
					alt={image.alt ?? image.fileName ?? ''}
				/>
			)}
			{description && (
				<>
					<div className={clsx(styles.caption, styles.is_mobile)}>
						<BreakableText text={description} />
					</div>
					<div className={clsx(styles.caption, styles.is_desktop)}>
						<CaptionWithLine text={description} isReverse={isDescriptionReverese} isVertical={isDescriptionVertical} />
					</div>
				</>
			)}
		</div>
	)
}
