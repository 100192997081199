import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { ImageResult } from '../data/ImageFragment'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { FormSelectProps } from './FormSelect'
import { LabelWithField } from './LabelWithField'
import { OrderProduct, OrderProductProps } from './OrderProduct'
import styles from './OrderProducts.module.sass'

export type OrderProductsProps = {
	products: Exclude<OrderProductProps, 'ProductOnChange'>[]
	options: ProductOptionProps[]
	onProductChange: FormSelectProps['onChange']
	selectedProductId: string
	logo?: ImageResult
}

type ProductOptionProps = {
	label: string
	value: string
	// @TODO: slug
	// slug: string
}

export const OrderProducts: FunctionComponent<OrderProductsProps> = ({
	products,
	options,
	onProductChange,
	selectedProductId,
	logo,
}) => {
	const [count, setCount] = useState(0)
	const translations = useTranslate()
	const selectedOption = useMemo(
		() => options.find(item => item.value === selectedProductId),
		[options, selectedProductId],
	)
	useEffect(() => {
		setCount(count => count + 1)
	}, [selectedOption])
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				{products.map(
					item =>
						item.tileImage && (
							<Image
								key={item.id}
								className={clsx(styles.image, item.id === selectedProductId && styles.is_active)}
								src={item.tileImage.url}
								width={item.tileImage.width}
								height={item.tileImage.height}
								alt={item.tileImage.alt ?? ''}
							/>
						),
				)}
				<div className={styles.header}>
					<div className={styles.headerLogo}>
						{logo ? (
							<Image
								className={styles.headerLogoIn}
								src={logo.url}
								width={logo.width}
								height={logo.height}
								alt={logo.alt ?? ''}
							/>
						) : (
							<h2 className={styles.title}>{translations('orderForm.products.title')}</h2>
						)}
					</div>
					{/* @TODO style select */}
					<div className={styles.select}>
						<LabelWithField
							key={count}
							className={styles.select}
							onChange={onProductChange}
							fieldType="select"
							name="product"
							options={options}
							required
							isSearchable={false}
							defaultValue={selectedOption}
						/>
					</div>
				</div>
				{products.map(item => (
					<div key={item.id} className={clsx(styles.item, item.id === selectedProductId && styles.is_active)}>
						<OrderProduct {...item} />
					</div>
				))}
			</div>
		</div>
	)
}
