import React from 'react'
import { CommonPageProps } from '../../pages/[[...page]]'

interface AppTranslationsStateProps {
	primaryValues: Values
	fallbackValues: Values
}
interface Values {
	[key: string]: string
}
const defaultValue: AppTranslationsStateProps = {
	primaryValues: {},
	fallbackValues: {},
}
export const TranslationsContext = React.createContext(defaultValue)
export const TranslationsContextProvider: React.FunctionComponent<{
	data: CommonPageProps['translations']
	children?: React.ReactNode
}> = ({ children, data }) => {
	const toKeyValue = (
		values: Array<{
			key?:
				| {
						identifier: string
				  }
				| undefined
			value: string
		}>,
	) => Object.fromEntries(values.map(value => [value.key?.identifier || '', value.value]))
	const primaryValues = toKeyValue(data?.cataloguesByIdentifier?.values || [])
	const fallbackValues = toKeyValue(data?.cataloguesByIdentifier?.fallback?.values || [])
	return (
		<TranslationsContext.Provider value={{ primaryValues, fallbackValues }}>{children}</TranslationsContext.Provider>
	)
}

export const useTranslate = () => {
	const appTranslations = React.useContext(TranslationsContext)
	return (key: string): string => {
		return appTranslations.primaryValues[key] || appTranslations.fallbackValues[key] || key
	}
}
