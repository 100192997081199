import { RichTextRenderer } from '@contember/react-client'
import { DescriptionText } from './DescriptionText'

export type DescriptionTextElement = {
	type: 'descriptionText'
}

type CustomElements = DescriptionTextElement

export const HeatBoxRichTextRenderer: typeof RichTextRenderer = props => {
	return (
		<RichTextRenderer
			{...props}
			renderElement={(...args) => {
				const [params] = args
				const element = params.element as typeof params['element'] | CustomElements
				switch (element.type) {
					case 'descriptionText':
						return <DescriptionText>{params.children}</DescriptionText>
				}
				const { renderElement } = props
				if (renderElement && typeof renderElement === 'function') {
					return (renderElement as any)(...args)
				}
				return params.fallback
			}}
		/>
	)
}
