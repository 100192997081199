import { createContext, FunctionComponent, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'

type FlashMessageStateContextProps = {
	type: 'neutral' | 'success' | 'error'
	message: string
}

export type FlashMessageContextProps = FlashMessageStateContextProps & {
	handleFlashMessage: (stateData: FlashMessageStateContextProps) => void
	handleVisibility: (isVisible: boolean) => void
	isVisible: boolean
}

const defaultFlashMessageValues: FlashMessageContextProps = {
	type: 'error',
	message: 'Error',
	handleFlashMessage: () => {},
	handleVisibility: () => {},
	isVisible: false,
}

export const flashMessageContext = createContext(defaultFlashMessageValues)
export const FlashMessageProvider: FunctionComponent<PropsWithChildren<FlashMessageContextProps>> = ({ children }) => {
	const [type, setType] = useState<FlashMessageContextProps['type']>('error')
	const [message, setMessage] = useState<FlashMessageContextProps['message']>('Error')
	const [isVisible, setIsVisible] = useState<FlashMessageContextProps['isVisible']>(false)
	const handleFlashMessage = useCallback((stateData: FlashMessageStateContextProps) => {
		setType(stateData.type)
		setMessage(stateData.message)
	}, [])
	const handleVisibility = useCallback((isVisible: boolean) => {
		setIsVisible(isVisible)
	}, [])
	const value = useMemo(
		() => ({ type, message, handleFlashMessage, handleVisibility, isVisible }),
		[handleFlashMessage, handleVisibility, isVisible, message, type],
	)
	return <flashMessageContext.Provider value={value}>{children}</flashMessageContext.Provider>
}

export const useHandleFlashMessage = () => {
	const context = useContext(flashMessageContext)
	return {
		handleFlashMessage: context.handleFlashMessage,
		handleVisibility: context.handleVisibility,
		isVisible: context.isVisible,
	}
}

export const useFlasMessage = () => {
	const context = useContext(flashMessageContext)
	return {
		type: context.type,
		message: context.message,
	}
}
