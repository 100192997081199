import { createContext, FunctionComponent, PropsWithChildren, useContext, useMemo } from 'react'
import { CommonPageProps } from '../../pages/[[...page]]'

type OrderPageLinkContextProps = CommonPageProps['orderPage']

const defaultOrderPageLinkValues = {
	localesByLocale: {
		link: {
			url: '#',
		},
	},
}

export const orderPageLinkContext = createContext<OrderPageLinkContextProps>(defaultOrderPageLinkValues)
export const OrderPageLinkProvider: FunctionComponent<PropsWithChildren<OrderPageLinkContextProps>> = ({
	localesByLocale,
	children,
}) => {
	const value = useMemo(() => ({ localesByLocale }), [localesByLocale])
	return <orderPageLinkContext.Provider value={value}>{children}</orderPageLinkContext.Provider>
}

export const useOrderPageLink = () => {
	const context = useContext(orderPageLinkContext)
	return context?.localesByLocale?.link?.url
}
