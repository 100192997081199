import clsx from 'clsx'
import Image from 'next/image'
import {
	Dispatch,
	FormEventHandler,
	FunctionComponent,
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import slugify from 'slugify'
import { FilledOrderFormResult } from '../data/FilledOrderFormFragment'
import { FilledProductWaitlistFormResult } from '../data/FilledProductWaitlistFormFragment'
import { ProductCategoryTileResult } from '../data/ProductCategoryTileFragment'
import { ProductCategoryTileListResult } from '../data/ProductCategoryTileListFragment'
import { api } from '../utilities/api'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import { useHandleFlashMessage } from './contexts/FlashMessageContext'
import { useOrderPageLink } from './contexts/OrderPageLinkContext'
import { useLocales } from './contexts/SwitcherLocalesContext'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { LabelWithField } from './LabelWithField'
import styles from './ProductCategoryTabsTiles.module.sass'

export type ProductCategoryTabsTilesProps = ProductCategoryTileListResult

export const ProductCategoryTabsTiles: FunctionComponent<ProductCategoryTabsTilesProps> = ({ items }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.tiles}>
				{items.map(item => item.item && <ProductTile key={item.id} item={item.item} />)}
			</div>
		</div>
	)
}

type ProductTileProps = {
	item: NonNullable<ProductCategoryTileListResult['items'][number]['item']>
}

const ProductTile: FunctionComponent<ProductTileProps> = ({ item }) => {
	const [activeProductId, setActiveProductId] = useState<string | null>(null)

	useEffect(() => {
		setActiveProductId(item.products[0].id ?? null)
	}, [item.products])

	return (
		<div className={styles.tile}>
			<div className={styles.tileIn}>
				<ProductTabs
					products={item.products}
					activeProductId={activeProductId}
					setActiveProductId={setActiveProductId}
				/>
				<ProductContents products={item.products} activeProductId={activeProductId} />
			</div>
		</div>
	)
}

type ProductTilesProps = {
	activeProductId: ProductCategoryTabsTilesProps['items'][number]['id'] | null
	products: ProductCategoryTileResult['products']
}

const ProductContents: FunctionComponent<ProductTilesProps> = ({ products, activeProductId }) => {
	const translations = useTranslate()
	const { currentLocale } = useLocales()
	const orderPageLink = useOrderPageLink()
	return (
		<div className={styles.products}>
			{products.map(product => (
				<div key={product.id} className={clsx(styles.product, activeProductId === product.id && styles.is_active)}>
					{product.tileImage && (
						<div className={styles.productImage}>
							<Image
								className={styles.productImageIn}
								src={product.tileImage.url}
								width={product.tileImage.width}
								height={product.tileImage.height}
								alt={product.tileImage.alt ?? product.tileImage.fileName ?? ''}
							/>
						</div>
					)}
					{product.localesByLocale && (
						<div className={styles.productContent}>
							<h3 className={styles.productName}>{product.localesByLocale.name}</h3>
							{!product.isForSale && (
								<span className={styles.productShortInfo}>{translations('productTabsTiles.tab.comingSoon')}</span>
							)}
							{product.localesByLocale.description && (
								<div className={styles.productDescription}>
									<BreakableContent text={product.localesByLocale.description} isParagraph />
								</div>
							)}
						</div>
					)}
					{product.isForSale && orderPageLink && product.localesByLocale?.buttonLabel && product.slug && (
						<div className={styles.productAction}>
							{/* The label/tag which displayed the text "-% Sale valid only during this weekend". Doesn't matter anymore, but the styles might be usable for something else. */}
							{/* {product.localesByLocale.discountedPrice > 0 && (
								<div className={styles.productSaleLabel}>
									{translations('productTabsTiles.forSale.discountedLabel')}
								</div>
							)} */}
							<Button
								type="link"
								href={{
									// @TODO: generic url for product
									pathname: orderPageLink,
									query: {
										product: slugify(product.slug),
									},
								}}
								size="medium"
								fillType="light"
							>
								{product.localesByLocale.isCustomButtonLabel ? (
									product.localesByLocale?.buttonLabel
								) : (
									<span>
										{translations('productTabsTiles.forSale.label')}{' '}
										{product.localesByLocale?.discountedPrice > 0 ? (
											<>
												{currentLocale?.locale?.currency} {product.localesByLocale?.discountedPrice}
												<span className={styles.strikethrough}>
													{' '}
													{currentLocale?.locale?.currency} {product.localesByLocale?.price}
												</span>
											</>
										) : (
											<>
												{currentLocale?.locale?.currency} {product.localesByLocale?.price}
											</>
										)}
									</span>
								)}
							</Button>
						</div>
					)}
					{!product.isForSale && <WaitlistForm productId={product.id} />}
				</div>
			))}
		</div>
	)
}

const ProductTabs: FunctionComponent<
	ProductTilesProps & {
		setActiveProductId: Dispatch<SetStateAction<ProductCategoryTabsTilesProps['items'][number]['id'] | null>>
	}
> = ({ products, activeProductId, setActiveProductId }) => {
	const translations = useTranslate()
	return (
		<>
			<h4 className={styles.tabsLabel}>{translations('productTabsTiles.tab.miningPower')}</h4>
			<div className={styles.tabs}>
				{products.map(product => (
					<button
						key={product.id}
						type="button"
						className={clsx(styles.tab, activeProductId === product.id && styles.is_active)}
						onClick={() => setActiveProductId(product.id)}
					>
						{product.miningPower}
					</button>
				))}
			</div>
		</>
	)
}

type WaitlistFormProps = {
	productId: NonNullable<FilledOrderFormResult['product']>['id']
}

const WaitlistForm: FunctionComponent<WaitlistFormProps> = ({ productId }) => {
	const [isButtonClicked, setIsButtonClicked] = useState(false)
	const { submit } = api.productWaitlistForm
	const mutation = submit.useMutation()
	const [email, setEmail] = useState<FilledProductWaitlistFormResult['email']>('')
	const translations = useTranslate()
	const form = useRef<HTMLFormElement>(null)
	const [isResetable, setIsResetable] = useState(false)
	const { handleFlashMessage, handleVisibility } = useHandleFlashMessage()

	const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		event => {
			event.preventDefault()

			mutation.mutate({
				email: email,
				productId: productId,
			})
		},
		[email, mutation, productId],
	)

	const successMessage = translations('productTabsTiles.successMessage')
	const errorMessage = translations('productTabsTiles.errorMessage')

	useEffect(() => {
		if (mutation.status === 'success') {
			setIsResetable(true)
			handleVisibility(true)
			handleFlashMessage({
				type: 'success',
				message: successMessage,
			})
		} else if (mutation.status === 'error') {
			handleVisibility(true)
			handleFlashMessage({
				type: 'error',
				message: errorMessage,
			})
		}
	}, [errorMessage, handleFlashMessage, handleVisibility, mutation.status, successMessage])

	useEffect(() => {
		if (isResetable && mutation.data?.contemberStatus.ok) {
			form.current?.reset()
			setIsResetable(false)
		}
	}, [isResetable, mutation.data?.contemberStatus.ok])

	return (
		<>
			<div className={styles.productAction}>
				{isButtonClicked ? (
					<form className={styles.waitlist} onSubmit={onSubmit} ref={form}>
						<input value={productId} name="productId" required disabled hidden />
						<LabelWithField
							fieldType="default"
							type="email"
							name="email"
							label={translations('productTabsTiles.label.email')}
							onChange={event => {
								setEmail(event.target.value)
							}}
							required
						/>
						<div className={styles.waitlistInfo}>{translations('productTabsTiles.waitlist.info')}</div>
						<div className={styles.waitlistSubmit}>
							<Button type="submit" visualType="default" fillType="light" size="medium">
								{translations('productTabsTiles.submit')}
							</Button>
						</div>
					</form>
				) : (
					<Button type="button" size="medium" fillType="light" onClick={() => setIsButtonClicked(true)}>
						{translations('productTabsTiles.button.waitingList')}
					</Button>
				)}
			</div>
		</>
	)
}
