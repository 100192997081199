import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { ContentImageWithFeaturesResult } from '../data/ContentImageWithFeaturesFragment'
import { FeatureListResult } from '../data/FeatureListFragment'
import { BreakableContent } from './BreakableContent'
import styles from './ContentImageWithFeatures.module.sass'

export type ContentImageWithFeaturesProps = ContentImageWithFeaturesResult

export const ContentImageWithFeatures: FunctionComponent<ContentImageWithFeaturesProps> = ({
	image,
	title,
	subtitle,
	text,
	features,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<div className={styles.column}>
					{subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
					{title && (
						<h2 className={styles.title}>
							<BreakableContent text={title}></BreakableContent>
						</h2>
					)}
				</div>
				<div className={styles.column}>
					{image && (
						<div className={styles.image}>
							<Image
								className={styles.imageIn}
								src={image.url}
								width={image.width}
								height={image.height}
								alt={image.alt ?? image.fileName ?? ''}
							/>
						</div>
					)}
				</div>
			</div>
			{text && <p className={styles.text}>{text}</p>}
			<div className={styles.features}>
				{features && features.items.map(item => item.feature && <FeatureItem key={item.id} {...item.feature} />)}
			</div>
		</div>
	)
}

type FeatureItemProps = NonNullable<FeatureListResult['items'][number]['feature']>

const FeatureItem: FunctionComponent<FeatureItemProps> = ({ icon, title, text }) => {
	return (
		<div className={styles.feature}>
			{icon && (
				<div className={styles.featureIcon}>
					<Image src={icon.url} width={icon.width} height={icon.height} alt={icon.alt ?? icon.fileName ?? ''} />
				</div>
			)}
			{title && <h3 className={styles.featureTitle}>{title}</h3>}
			{text && <p className={styles.featureText}>{text}</p>}
		</div>
	)
}
