import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useEffect, useRef } from 'react'
import { ButtonLinkResult } from '../data/ButtonLinkFragment'
import { ImageResult } from '../data/ImageFragment'
import { VideoResult } from '../data/VideoFragment'
import { CallToActionType } from '../generated/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { useReducedMotion } from '../utilities/useReducedMotion'
import { BreakableContent } from './BreakableContent'
import { Button } from './Button'
import styles from './CallToAction.module.sass'

export type CallToActionProps = {
	type: CallToActionType
	imageAboveContent?: ImageResult
	title?: string
	text?: string
	backgroundVideo?: VideoResult
	backgroundVideoMobile?: VideoResult
	backgroundImage?: ImageResult
	backgroundImageMobile?: ImageResult
	button?: ButtonLinkResult
	buttonTwo?: ButtonLinkResult
}

export const CallToAction: FunctionComponent<CallToActionProps> = ({
	type,
	imageAboveContent,
	title,
	text,
	backgroundVideo,
	backgroundVideoMobile,
	backgroundImage,
	backgroundImageMobile,
	button,
	buttonTwo,
}) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const reducedMotion = useReducedMotion()

	useEffect(() => {
		const video = videoRef.current
		if (video === null) {
			return
		}
		if (reducedMotion) {
			video.pause()
		} else {
			video.play()
		}
	}, [reducedMotion, title])

	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.main, styles[`is_${type}`])}>
				<BackgroundMedia
					video={backgroundVideo ?? backgroundVideoMobile}
					image={backgroundImage}
					deviceType="desktop"
				/>
				<BackgroundMedia video={backgroundVideoMobile ?? backgroundVideo} image={backgroundImageMobile} />

				{(title || text) && (
					<div className={styles.content}>
						{imageAboveContent && (
							<div
								className={styles.imageAboveContent}
								style={{
									'--CallToAction-imageAboveContent-width': `${imageAboveContent.width}px`,
								}}
							>
								<Image
									className={styles.imageAboveContentIn}
									src={imageAboveContent.url}
									width={imageAboveContent.width}
									height={imageAboveContent.height}
									alt={imageAboveContent.alt ?? imageAboveContent.fileName ?? ''}
								/>
							</div>
						)}
						{title && (
							<h2 className={styles.title}>
								<BreakableContent text={title} />
							</h2>
						)}
						{text && (
							<div className={styles.text}>
								<BreakableContent text={text} isParagraph />
							</div>
						)}
					</div>
				)}
				<div className={styles.actions}>
					{button?.link && (
						<div className={styles.button}>
							<Button
								type="link"
								href={contemberLinkToHref(button.link)}
								isFullSize={type === CallToActionType.option1}
								isArrow={false}
								{...button}
							>
								{button.link.title}
							</Button>
						</div>
					)}
					{buttonTwo?.link && (
						<div className={styles.button}>
							<Button
								type="link"
								href={contemberLinkToHref(buttonTwo.link)}
								isFullSize={type === CallToActionType.option1}
								isArrow={false}
								{...buttonTwo}
							>
								{buttonTwo.link.title}
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

type BackgroundMediaProps = {
	video?: VideoResult
	image?: ImageResult
	deviceType?: 'mobile' | 'desktop'
}

const BackgroundMedia: FunctionComponent<BackgroundMediaProps> = ({ video, image, deviceType = 'mobile' }) => {
	return (
		<div className={clsx(styles.background, styles[`is_${deviceType}`])}>
			{video ? (
				<div className={styles.backgroundVideo}>
					<video
						autoPlay
						muted
						loop
						playsInline
						className={styles.backgroundVideoIn}
						style={{
							'--CallToAction-video-aspectRatio': (video?.width ?? 16) / (video?.height ?? 9),
						}}
						poster={image && image.url}
					>
						<source src={video.src} type={video.type} />
					</video>
				</div>
			) : image ? (
				<Image
					className={styles.backgroundImage}
					src={image.url}
					width={image.width}
					height={image.height}
					alt={image.alt ?? image.fileName ?? ''}
				/>
			) : null}
		</div>
	)
}
