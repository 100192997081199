import { FunctionComponent, useEffect, useMemo } from 'react'
import { useFormData } from './contexts/OrderFormContext'
import { useTranslate } from './contexts/TranslationsContextProvider'
import { SelectOptionProps } from './FormSelect'
import { LabelWithField } from './LabelWithField'
import styles from './OrderForm.module.sass'
import { OrderPageProps } from './OrderPage'
import { OrderSubmit } from './OrderSubmit'

export type OrderFormProps = {
	countries: OrderPageProps['countries']
}

const DEFAULT_COUNTRY_CODE = 'CZ'

export const OrderForm: FunctionComponent<OrderFormProps> = ({ countries }) => {
	const translations = useTranslate()
	const countryOptions: SelectOptionProps[] = useMemo(
		() =>
			countries.map(item => ({
				value: item.id,
				label: item.label,
			})),
		[countries],
	)

	const defaultCoutryOption = useMemo<SelectOptionProps>(() => {
		const defaultCountryId = countries.find(item => item.code === DEFAULT_COUNTRY_CODE)?.id

		if (!defaultCountryId) {
			return countryOptions[0]
		}

		return countryOptions.find(item => item.value === defaultCountryId) ?? countryOptions[0]
	}, [countries, countryOptions])

	const { setName, setMobile, setEmail, setDeliveryAddress, setCountry, setNote } = useFormData()

	useEffect(() => {
		setCountry({
			id: defaultCoutryOption.value,
		})
	}, [defaultCoutryOption, setCountry])

	return (
		<div className={styles.wrapper}>
			<h3 className={styles.title}>{translations('orderForm.title')}</h3>
			<LabelWithField
				fieldType="default"
				type="text"
				name="name"
				label={translations('orderForm.label.name')}
				onChange={event => {
					setName(event.target.value)
				}}
				required
			/>
			<LabelWithField
				fieldType="default"
				type="tel"
				name="mobile"
				label={translations('orderForm.label.mobile')}
				onChange={event => {
					setMobile(event.target.value)
				}}
				required
			/>
			<LabelWithField
				fieldType="default"
				type="email"
				name="email"
				label={translations('orderForm.label.email')}
				onChange={event => {
					setEmail(event.target.value)
				}}
				required
			/>
			<LabelWithField
				fieldType="default"
				type="text"
				name="address"
				label={translations('orderForm.label.deliveryAddress')}
				onChange={event => {
					setDeliveryAddress(event.target.value)
				}}
				required
			/>
			<LabelWithField
				fieldType="select"
				name="country"
				label={translations('orderForm.label.country')}
				options={countryOptions}
				defaultValue={defaultCoutryOption}
				hideSelectedOptions={true}
				onChange={selected => {
					if (!selected) {
						console.error('Selected country is not defined')
						return
					}
					setCountry({ id: selected.value })
				}}
				isSearchable
			/>
			<LabelWithField
				fieldType="textarea"
				name="note"
				label={translations('orderForm.label.note')}
				onChange={event => {
					setNote(event.target.value)
				}}
			/>
			<div className={styles.actions}>
				<OrderSubmit />
			</div>
		</div>
	)
}
