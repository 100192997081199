import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useEffect, useState } from 'react'
import { ProjectionResult } from '../data/ProjectionFragment'
import { ProjectionItemResult } from '../data/ProjectionItemFragment'
import styles from './ProjectionMobile.module.sass'

export type ProjectionMobileProps = ProjectionResult

export const ProjectionMobile: FunctionComponent<ProjectionMobileProps> = ({ items }) => {
	const [isActiveItemId, setIsActiveItemId] = useState<ProjectionItemResult['id'] | null>(null)

	useEffect(() => {
		setIsActiveItemId(items[0].id ?? null)
	}, [items])

	return (
		<div className={styles.wrapper}>
			<div className={styles.images}>
				{items.map(
					item =>
						item.item?.image && (
							<div key={item.id} className={clsx(styles.image, isActiveItemId === item.id && styles.is_active)}>
								<Image
									className={styles.imageIn}
									src={item.item.image.url}
									width={item.item.image.width}
									height={item.item.image.height}
									alt={item.item.image.alt ?? item.item.image.fileName ?? ''}
								/>
							</div>
						),
				)}
			</div>
			<div
				className={styles.buttons}
				style={{
					'--ProjectionMobile-buttons-count': items.length <= 3 ? items.length : 4,
				}}
			>
				{items.map(item => (
					<button
						key={item.id}
						title={item.item?.title}
						aria-label={item.item?.title}
						type="button"
						className={clsx(styles.button, isActiveItemId === item.id && styles.is_active)}
						onClick={() => setIsActiveItemId(item.id)}
					/>
				))}
			</div>
			{items.map(item => (
				<div key={item.id} className={styles.content}>
					<div className={clsx(styles.contentIn, isActiveItemId === item.id && styles.is_active)}>
						{item.item?.title && <h3 className={styles.title}>{item.item.title}</h3>}
						{item.item?.text && <p className={styles.text}>{item.item.text}</p>}
					</div>
				</div>
			))}
		</div>
	)
}
