/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getGenericPage:{
			by:"GenericPageUniqueWhere",
			filter:"GenericPageWhere"
		},
		listGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		paginateGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getGenericPageLocale:{
			by:"GenericPageLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		listGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getHeader:{
			by:"HeaderUniqueWhere",
			filter:"HeaderWhere"
		},
		listHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		paginateHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		getHeaderLocale:{
			by:"HeaderLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		listHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getButtonLink:{
			by:"ButtonLinkUniqueWhere",
			filter:"ButtonLinkWhere"
		},
		listButtonLink:{
			filter:"ButtonLinkWhere",
			orderBy:"ButtonLinkOrderBy"
		},
		paginateButtonLink:{
			filter:"ButtonLinkWhere",
			orderBy:"ButtonLinkOrderBy"
		},
		getFeature:{
			by:"FeatureUniqueWhere",
			filter:"FeatureWhere"
		},
		listFeature:{
			filter:"FeatureWhere",
			orderBy:"FeatureOrderBy"
		},
		paginateFeature:{
			filter:"FeatureWhere",
			orderBy:"FeatureOrderBy"
		},
		getFeatureItem:{
			by:"FeatureItemUniqueWhere",
			filter:"FeatureItemWhere"
		},
		listFeatureItem:{
			filter:"FeatureItemWhere",
			orderBy:"FeatureItemOrderBy"
		},
		paginateFeatureItem:{
			filter:"FeatureItemWhere",
			orderBy:"FeatureItemOrderBy"
		},
		getFeatureList:{
			by:"FeatureListUniqueWhere",
			filter:"FeatureListWhere"
		},
		listFeatureList:{
			filter:"FeatureListWhere",
			orderBy:"FeatureListOrderBy"
		},
		paginateFeatureList:{
			filter:"FeatureListWhere",
			orderBy:"FeatureListOrderBy"
		},
		getLinkItem:{
			by:"LinkItemUniqueWhere",
			filter:"LinkItemWhere"
		},
		listLinkItem:{
			filter:"LinkItemWhere",
			orderBy:"LinkItemOrderBy"
		},
		paginateLinkItem:{
			filter:"LinkItemWhere",
			orderBy:"LinkItemOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getFooter:{
			by:"FooterUniqueWhere",
			filter:"FooterWhere"
		},
		listFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		paginateFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		getFooterGroupLinkList:{
			by:"FooterGroupLinkListUniqueWhere",
			filter:"FooterGroupLinkListWhere"
		},
		listFooterGroupLinkList:{
			filter:"FooterGroupLinkListWhere",
			orderBy:"FooterGroupLinkListOrderBy"
		},
		paginateFooterGroupLinkList:{
			filter:"FooterGroupLinkListWhere",
			orderBy:"FooterGroupLinkListOrderBy"
		},
		getFooterLocale:{
			by:"FooterLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		listFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		getGenericPageSideNavigation:{
			by:"GenericPageSideNavigationUniqueWhere",
			filter:"GenericPageSideNavigationWhere"
		},
		listGenericPageSideNavigation:{
			filter:"GenericPageSideNavigationWhere",
			orderBy:"GenericPageSideNavigationOrderBy"
		},
		paginateGenericPageSideNavigation:{
			filter:"GenericPageSideNavigationWhere",
			orderBy:"GenericPageSideNavigationOrderBy"
		},
		getProjection:{
			by:"ProjectionUniqueWhere",
			filter:"ProjectionWhere"
		},
		listProjection:{
			filter:"ProjectionWhere",
			orderBy:"ProjectionOrderBy"
		},
		paginateProjection:{
			filter:"ProjectionWhere",
			orderBy:"ProjectionOrderBy"
		},
		getProjectionContent:{
			by:"ProjectionContentUniqueWhere",
			filter:"ProjectionContentWhere"
		},
		listProjectionContent:{
			filter:"ProjectionContentWhere",
			orderBy:"ProjectionContentOrderBy"
		},
		paginateProjectionContent:{
			filter:"ProjectionContentWhere",
			orderBy:"ProjectionContentOrderBy"
		},
		getProjectionItem:{
			by:"ProjectionItemUniqueWhere",
			filter:"ProjectionItemWhere"
		},
		listProjectionItem:{
			filter:"ProjectionItemWhere",
			orderBy:"ProjectionItemOrderBy"
		},
		paginateProjectionItem:{
			filter:"ProjectionItemWhere",
			orderBy:"ProjectionItemOrderBy"
		},
		getContentImageWithFeatures:{
			by:"ContentImageWithFeaturesUniqueWhere",
			filter:"ContentImageWithFeaturesWhere"
		},
		listContentImageWithFeatures:{
			filter:"ContentImageWithFeaturesWhere",
			orderBy:"ContentImageWithFeaturesOrderBy"
		},
		paginateContentImageWithFeatures:{
			filter:"ContentImageWithFeaturesWhere",
			orderBy:"ContentImageWithFeaturesOrderBy"
		},
		getAsideContentMedia:{
			by:"AsideContentMediaUniqueWhere",
			filter:"AsideContentMediaWhere"
		},
		listAsideContentMedia:{
			filter:"AsideContentMediaWhere",
			orderBy:"AsideContentMediaOrderBy"
		},
		paginateAsideContentMedia:{
			filter:"AsideContentMediaWhere",
			orderBy:"AsideContentMediaOrderBy"
		},
		getAsideContentMediaSpecification:{
			by:"AsideContentMediaSpecificationUniqueWhere",
			filter:"AsideContentMediaSpecificationWhere"
		},
		listAsideContentMediaSpecification:{
			filter:"AsideContentMediaSpecificationWhere",
			orderBy:"AsideContentMediaSpecificationOrderBy"
		},
		paginateAsideContentMediaSpecification:{
			filter:"AsideContentMediaSpecificationWhere",
			orderBy:"AsideContentMediaSpecificationOrderBy"
		},
		getFile:{
			by:"FileUniqueWhere",
			filter:"FileWhere"
		},
		listFile:{
			filter:"FileWhere",
			orderBy:"FileOrderBy"
		},
		paginateFile:{
			filter:"FileWhere",
			orderBy:"FileOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getProduct:{
			by:"ProductUniqueWhere",
			filter:"ProductWhere"
		},
		listProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		getProductCategory:{
			by:"ProductCategoryUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		listProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		getProductCategoryLocale:{
			by:"ProductCategoryLocaleUniqueWhere",
			filter:"ProductCategoryLocaleWhere"
		},
		listProductCategoryLocale:{
			filter:"ProductCategoryLocaleWhere",
			orderBy:"ProductCategoryLocaleOrderBy"
		},
		paginateProductCategoryLocale:{
			filter:"ProductCategoryLocaleWhere",
			orderBy:"ProductCategoryLocaleOrderBy"
		},
		getProductLocale:{
			by:"ProductLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		listProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		getProductCategoryItem:{
			by:"ProductCategoryItemUniqueWhere",
			filter:"ProductCategoryItemWhere"
		},
		listProductCategoryItem:{
			filter:"ProductCategoryItemWhere",
			orderBy:"ProductCategoryItemOrderBy"
		},
		paginateProductCategoryItem:{
			filter:"ProductCategoryItemWhere",
			orderBy:"ProductCategoryItemOrderBy"
		},
		getProductCategoryList:{
			by:"ProductCategoryListUniqueWhere",
			filter:"ProductCategoryListWhere"
		},
		listProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		paginateProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		getFeatureSection:{
			by:"FeatureSectionUniqueWhere",
			filter:"FeatureSectionWhere"
		},
		listFeatureSection:{
			filter:"FeatureSectionWhere",
			orderBy:"FeatureSectionOrderBy"
		},
		paginateFeatureSection:{
			filter:"FeatureSectionWhere",
			orderBy:"FeatureSectionOrderBy"
		},
		getFeatureSectionHeat:{
			by:"FeatureSectionHeatUniqueWhere",
			filter:"FeatureSectionHeatWhere"
		},
		listFeatureSectionHeat:{
			filter:"FeatureSectionHeatWhere",
			orderBy:"FeatureSectionHeatOrderBy"
		},
		paginateFeatureSectionHeat:{
			filter:"FeatureSectionHeatWhere",
			orderBy:"FeatureSectionHeatOrderBy"
		},
		getFeatureSectionPump:{
			by:"FeatureSectionPumpUniqueWhere",
			filter:"FeatureSectionPumpWhere"
		},
		listFeatureSectionPump:{
			filter:"FeatureSectionPumpWhere",
			orderBy:"FeatureSectionPumpOrderBy"
		},
		paginateFeatureSectionPump:{
			filter:"FeatureSectionPumpWhere",
			orderBy:"FeatureSectionPumpOrderBy"
		},
		getFeatureSectionTemperature:{
			by:"FeatureSectionTemperatureUniqueWhere",
			filter:"FeatureSectionTemperatureWhere"
		},
		listFeatureSectionTemperature:{
			filter:"FeatureSectionTemperatureWhere",
			orderBy:"FeatureSectionTemperatureOrderBy"
		},
		paginateFeatureSectionTemperature:{
			filter:"FeatureSectionTemperatureWhere",
			orderBy:"FeatureSectionTemperatureOrderBy"
		},
		getFeatureSectionTube:{
			by:"FeatureSectionTubeUniqueWhere",
			filter:"FeatureSectionTubeWhere"
		},
		listFeatureSectionTube:{
			filter:"FeatureSectionTubeWhere",
			orderBy:"FeatureSectionTubeOrderBy"
		},
		paginateFeatureSectionTube:{
			filter:"FeatureSectionTubeWhere",
			orderBy:"FeatureSectionTubeOrderBy"
		},
		getFeatureSectionAntimer:{
			by:"FeatureSectionAntimerUniqueWhere",
			filter:"FeatureSectionAntimerWhere"
		},
		listFeatureSectionAntimer:{
			filter:"FeatureSectionAntimerWhere",
			orderBy:"FeatureSectionAntimerOrderBy"
		},
		paginateFeatureSectionAntimer:{
			filter:"FeatureSectionAntimerWhere",
			orderBy:"FeatureSectionAntimerOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getGeneral:{
			by:"GeneralUniqueWhere",
			filter:"GeneralWhere"
		},
		listGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		paginateGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		getGeneralLocale:{
			by:"GeneralLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		listGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		getProductFeature:{
			by:"ProductFeatureUniqueWhere",
			filter:"ProductFeatureWhere"
		},
		listProductFeature:{
			filter:"ProductFeatureWhere",
			orderBy:"ProductFeatureOrderBy"
		},
		paginateProductFeature:{
			filter:"ProductFeatureWhere",
			orderBy:"ProductFeatureOrderBy"
		},
		getProductFeatureLocale:{
			by:"ProductFeatureLocaleUniqueWhere",
			filter:"ProductFeatureLocaleWhere"
		},
		listProductFeatureLocale:{
			filter:"ProductFeatureLocaleWhere",
			orderBy:"ProductFeatureLocaleOrderBy"
		},
		paginateProductFeatureLocale:{
			filter:"ProductFeatureLocaleWhere",
			orderBy:"ProductFeatureLocaleOrderBy"
		},
		getFilledOrderForm:{
			by:"FilledOrderFormUniqueWhere",
			filter:"FilledOrderFormWhere"
		},
		listFilledOrderForm:{
			filter:"FilledOrderFormWhere",
			orderBy:"FilledOrderFormOrderBy"
		},
		paginateFilledOrderForm:{
			filter:"FilledOrderFormWhere",
			orderBy:"FilledOrderFormOrderBy"
		},
		validateCreateFilledOrderForm:{
			data:"FilledOrderFormCreateInput"
		},
		validateUpdateFilledOrderForm:{
			by:"FilledOrderFormUniqueWhere",
			data:"FilledOrderFormUpdateInput"
		},
		getOrderPage:{
			by:"OrderPageUniqueWhere",
			filter:"OrderPageWhere"
		},
		listOrderPage:{
			filter:"OrderPageWhere",
			orderBy:"OrderPageOrderBy"
		},
		paginateOrderPage:{
			filter:"OrderPageWhere",
			orderBy:"OrderPageOrderBy"
		},
		getOrderPageLocale:{
			by:"OrderPageLocaleUniqueWhere",
			filter:"OrderPageLocaleWhere"
		},
		listOrderPageLocale:{
			filter:"OrderPageLocaleWhere",
			orderBy:"OrderPageLocaleOrderBy"
		},
		paginateOrderPageLocale:{
			filter:"OrderPageLocaleWhere",
			orderBy:"OrderPageLocaleOrderBy"
		},
		getFilledOrderFormEmailAddress:{
			by:"FilledOrderFormEmailAddressUniqueWhere",
			filter:"FilledOrderFormEmailAddressWhere"
		},
		listFilledOrderFormEmailAddress:{
			filter:"FilledOrderFormEmailAddressWhere",
			orderBy:"FilledOrderFormEmailAddressOrderBy"
		},
		paginateFilledOrderFormEmailAddress:{
			filter:"FilledOrderFormEmailAddressWhere",
			orderBy:"FilledOrderFormEmailAddressOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		getFilledOrderFormCountry:{
			by:"FilledOrderFormCountryUniqueWhere",
			filter:"FilledOrderFormCountryWhere"
		},
		listFilledOrderFormCountry:{
			filter:"FilledOrderFormCountryWhere",
			orderBy:"FilledOrderFormCountryOrderBy"
		},
		paginateFilledOrderFormCountry:{
			filter:"FilledOrderFormCountryWhere",
			orderBy:"FilledOrderFormCountryOrderBy"
		},
		getFilledProductWaitlistForm:{
			by:"FilledProductWaitlistFormUniqueWhere",
			filter:"FilledProductWaitlistFormWhere"
		},
		listFilledProductWaitlistForm:{
			filter:"FilledProductWaitlistFormWhere",
			orderBy:"FilledProductWaitlistFormOrderBy"
		},
		paginateFilledProductWaitlistForm:{
			filter:"FilledProductWaitlistFormWhere",
			orderBy:"FilledProductWaitlistFormOrderBy"
		},
		validateCreateFilledProductWaitlistForm:{
			data:"FilledProductWaitlistFormCreateInput"
		},
		validateUpdateFilledProductWaitlistForm:{
			by:"FilledProductWaitlistFormUniqueWhere",
			data:"FilledProductWaitlistFormUpdateInput"
		}
	},
	UUID: `scalar.UUID` as const,
	ImageUniqueWhere:{
		id:"UUID"
	},
	ImageWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		alt:"StringCondition",
		fileName:"StringCondition",
		and:"ImageWhere",
		or:"ImageWhere",
		not:"ImageWhere"
	},
	UUIDCondition:{
		and:"UUIDCondition",
		or:"UUIDCondition",
		not:"UUIDCondition",
		eq:"UUID",
		notEq:"UUID",
		in:"UUID",
		notIn:"UUID",
		lt:"UUID",
		lte:"UUID",
		gt:"UUID",
		gte:"UUID"
	},
	StringCondition:{
		and:"StringCondition",
		or:"StringCondition",
		not:"StringCondition"
	},
	IntCondition:{
		and:"IntCondition",
		or:"IntCondition",
		not:"IntCondition"
	},
	ImageOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		alt:"OrderDirection",
		fileName:"OrderDirection"
	},
	OrderDirection: "enum" as const,
	GenericPage:{
		locales:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		localesByLocale:{
			by:"GenericPageLocalesByLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesBySeo:{
			by:"GenericPageLocalesBySeoUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesByLink:{
			by:"GenericPageLocalesByLinkUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesByContent:{
			by:"GenericPageLocalesByContentUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		localesBySideNavigationItems:{
			by:"GenericPageLocalesBySideNavigationItemsUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		paginateLocales:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		}
	},
	GenericPageLocale:{
		root:{
			filter:"GenericPageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		},
		link:{
			filter:"LinkableWhere"
		},
		content:{
			filter:"ContentWhere"
		},
		sideNavigationItems:{
			filter:"GenericPageSideNavigationWhere",
			orderBy:"GenericPageSideNavigationOrderBy"
		},
		paginateSideNavigationItems:{
			filter:"GenericPageSideNavigationWhere",
			orderBy:"GenericPageSideNavigationOrderBy"
		}
	},
	GenericPageWhere:{
		id:"UUIDCondition",
		locales:"GenericPageLocaleWhere",
		isSideNavigation:"BooleanCondition",
		and:"GenericPageWhere",
		or:"GenericPageWhere",
		not:"GenericPageWhere"
	},
	GenericPageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		root:"GenericPageWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		link:"LinkableWhere",
		content:"ContentWhere",
		sideNavigationItems:"GenericPageSideNavigationWhere",
		and:"GenericPageLocaleWhere",
		or:"GenericPageLocaleWhere",
		not:"GenericPageLocaleWhere"
	},
	LocaleWhere:{
		id:"UUIDCondition",
		code:"StringCondition",
		label:"StringCondition",
		genericPages:"GenericPageLocaleWhere",
		headers:"HeaderLocaleWhere",
		footers:"FooterLocaleWhere",
		productCategories:"ProductCategoryLocaleWhere",
		products:"ProductLocaleWhere",
		generals:"GeneralLocaleWhere",
		currency:"StringCondition",
		productFeatureItems:"ProductFeatureLocaleWhere",
		orderPages:"OrderPageLocaleWhere",
		and:"LocaleWhere",
		or:"LocaleWhere",
		not:"LocaleWhere"
	},
	HeaderLocaleWhere:{
		id:"UUIDCondition",
		root:"HeaderWhere",
		locale:"LocaleWhere",
		specialLink:"LinkWhere",
		and:"HeaderLocaleWhere",
		or:"HeaderLocaleWhere",
		not:"HeaderLocaleWhere"
	},
	HeaderWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		logo:"ImageWhere",
		locales:"HeaderLocaleWhere",
		and:"HeaderWhere",
		or:"HeaderWhere",
		not:"HeaderWhere"
	},
	OneCondition:{
		and:"OneCondition",
		or:"OneCondition",
		not:"OneCondition",
		eq:"One",
		notEq:"One",
		in:"One",
		notIn:"One",
		lt:"One",
		lte:"One",
		gt:"One",
		gte:"One"
	},
	One: "enum" as const,
	LinkWhere:{
		id:"UUIDCondition",
		type:"LinkTypeCondition",
		title:"StringCondition",
		externalLink:"StringCondition",
		internalLink:"LinkableWhere",
		scrollToTargetId:"StringCondition",
		file:"FileWhere",
		and:"LinkWhere",
		or:"LinkWhere",
		not:"LinkWhere"
	},
	LinkTypeCondition:{
		and:"LinkTypeCondition",
		or:"LinkTypeCondition",
		not:"LinkTypeCondition",
		eq:"LinkType",
		notEq:"LinkType",
		in:"LinkType",
		notIn:"LinkType",
		lt:"LinkType",
		lte:"LinkType",
		gt:"LinkType",
		gte:"LinkType"
	},
	LinkType: "enum" as const,
	LinkableWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		genericPage:"GenericPageLocaleWhere",
		redirect:"RedirectWhere",
		orderPage:"OrderPageLocaleWhere",
		and:"LinkableWhere",
		or:"LinkableWhere",
		not:"LinkableWhere"
	},
	RedirectWhere:{
		id:"UUIDCondition",
		link:"LinkableWhere",
		target:"LinkWhere",
		and:"RedirectWhere",
		or:"RedirectWhere",
		not:"RedirectWhere"
	},
	OrderPageLocaleWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		link:"LinkableWhere",
		root:"OrderPageWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		termsAndConditions:"StringCondition",
		and:"OrderPageLocaleWhere",
		or:"OrderPageLocaleWhere",
		not:"OrderPageLocaleWhere"
	},
	OrderPageWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"OrderPageLocaleWhere",
		logo:"ImageWhere",
		and:"OrderPageWhere",
		or:"OrderPageWhere",
		not:"OrderPageWhere"
	},
	SeoWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		description:"StringCondition",
		ogTitle:"StringCondition",
		ogDescription:"StringCondition",
		noIndex:"BooleanCondition",
		noFollow:"BooleanCondition",
		ogImage:"ImageWhere",
		and:"SeoWhere",
		or:"SeoWhere",
		not:"SeoWhere"
	},
	BooleanCondition:{
		and:"BooleanCondition",
		or:"BooleanCondition",
		not:"BooleanCondition"
	},
	FileWhere:{
		id:"UUIDCondition",
		url:"StringCondition",
		size:"IntCondition",
		type:"StringCondition",
		label:"StringCondition",
		fileName:"StringCondition",
		and:"FileWhere",
		or:"FileWhere",
		not:"FileWhere"
	},
	FooterLocaleWhere:{
		id:"UUIDCondition",
		copyright:"StringCondition",
		groupsLinkList:"FooterGroupLinkListWhere",
		root:"FooterWhere",
		locale:"LocaleWhere",
		contact:"StringCondition",
		and:"FooterLocaleWhere",
		or:"FooterLocaleWhere",
		not:"FooterLocaleWhere"
	},
	FooterGroupLinkListWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		title:"StringCondition",
		footer:"FooterLocaleWhere",
		linkList:"LinkListWhere",
		isAddress:"BooleanCondition",
		addressValue:"StringCondition",
		addressLink:"LinkWhere",
		and:"FooterGroupLinkListWhere",
		or:"FooterGroupLinkListWhere",
		not:"FooterGroupLinkListWhere"
	},
	LinkListWhere:{
		id:"UUIDCondition",
		items:"LinkItemWhere",
		and:"LinkListWhere",
		or:"LinkListWhere",
		not:"LinkListWhere"
	},
	LinkItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"LinkListWhere",
		link:"LinkWhere",
		and:"LinkItemWhere",
		or:"LinkItemWhere",
		not:"LinkItemWhere"
	},
	FooterWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		logo:"ImageWhere",
		locales:"FooterLocaleWhere",
		logoCompany:"ImageWhere",
		and:"FooterWhere",
		or:"FooterWhere",
		not:"FooterWhere"
	},
	ProductCategoryLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		root:"ProductCategoryWhere",
		locale:"LocaleWhere",
		and:"ProductCategoryLocaleWhere",
		or:"ProductCategoryLocaleWhere",
		not:"ProductCategoryLocaleWhere"
	},
	ProductCategoryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		products:"ProductWhere",
		locales:"ProductCategoryLocaleWhere",
		and:"ProductCategoryWhere",
		or:"ProductCategoryWhere",
		not:"ProductCategoryWhere"
	},
	ProductWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		miningPower:"StringCondition",
		image:"ImageWhere",
		category:"ProductCategoryWhere",
		tileImage:"ImageWhere",
		locales:"ProductLocaleWhere",
		isForSale:"BooleanCondition",
		features:"ProductFeatureWhere",
		slug:"StringCondition",
		and:"ProductWhere",
		or:"ProductWhere",
		not:"ProductWhere"
	},
	ProductLocaleWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		description:"StringCondition",
		root:"ProductWhere",
		locale:"LocaleWhere",
		price:"IntCondition",
		buttonLabel:"StringCondition",
		isCustomButtonLabel:"BooleanCondition",
		discountedPrice:"IntCondition",
		and:"ProductLocaleWhere",
		or:"ProductLocaleWhere",
		not:"ProductLocaleWhere"
	},
	ProductFeatureWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		locales:"ProductFeatureLocaleWhere",
		and:"ProductFeatureWhere",
		or:"ProductFeatureWhere",
		not:"ProductFeatureWhere"
	},
	ProductFeatureLocaleWhere:{
		id:"UUIDCondition",
		price:"IntCondition",
		root:"ProductFeatureWhere",
		locale:"LocaleWhere",
		title:"StringCondition",
		and:"ProductFeatureLocaleWhere",
		or:"ProductFeatureLocaleWhere",
		not:"ProductFeatureLocaleWhere"
	},
	GeneralLocaleWhere:{
		id:"UUIDCondition",
		root:"GeneralWhere",
		locale:"LocaleWhere",
		seo:"SeoWhere",
		and:"GeneralLocaleWhere",
		or:"GeneralLocaleWhere",
		not:"GeneralLocaleWhere"
	},
	GeneralWhere:{
		id:"UUIDCondition",
		unique:"OneCondition",
		locales:"GeneralLocaleWhere",
		and:"GeneralWhere",
		or:"GeneralWhere",
		not:"GeneralWhere"
	},
	ContentWhere:{
		id:"UUIDCondition",
		blocks:"ContentBlockWhere",
		and:"ContentWhere",
		or:"ContentWhere",
		not:"ContentWhere"
	},
	ContentBlockWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		json:"StringCondition",
		content:"ContentWhere",
		references:"ContentReferenceWhere",
		and:"ContentBlockWhere",
		or:"ContentBlockWhere",
		not:"ContentBlockWhere"
	},
	ContentReferenceWhere:{
		id:"UUIDCondition",
		type:"ContentReferenceTypeCondition",
		block:"ContentBlockWhere",
		primaryText:"StringCondition",
		link:"LinkWhere",
		linkTwo:"LinkWhere",
		buttonLink:"ButtonLinkWhere",
		buttonLinkTwo:"ButtonLinkWhere",
		featureList:"FeatureListWhere",
		projection:"ProjectionWhere",
		contentImageWithFeatures:"ContentImageWithFeaturesWhere",
		asideContentMedia:"AsideContentMediaWhere",
		imageList:"ImageListWhere",
		productCategories:"ProductCategoryListWhere",
		image:"ImageWhere",
		secondaryText:"StringCondition",
		callToActionTypes:"CallToActionTypeCondition",
		secondaryImage:"ImageWhere",
		featureSection:"FeatureSectionWhere",
		terciaryImage:"ImageWhere",
		video:"VideoWhere",
		isImageWithWhiteShadow:"BooleanCondition",
		secondaryVideo:"VideoWhere",
		and:"ContentReferenceWhere",
		or:"ContentReferenceWhere",
		not:"ContentReferenceWhere"
	},
	ContentReferenceTypeCondition:{
		and:"ContentReferenceTypeCondition",
		or:"ContentReferenceTypeCondition",
		not:"ContentReferenceTypeCondition",
		eq:"ContentReferenceType",
		notEq:"ContentReferenceType",
		in:"ContentReferenceType",
		notIn:"ContentReferenceType",
		lt:"ContentReferenceType",
		lte:"ContentReferenceType",
		gt:"ContentReferenceType",
		gte:"ContentReferenceType"
	},
	ContentReferenceType: "enum" as const,
	ButtonLinkWhere:{
		id:"UUIDCondition",
		visualType:"ButtonLinkVisualTypeCondition",
		link:"LinkWhere",
		fillType:"ButtonLinkFillTypeCondition",
		widthType:"ButtonLinkWidthTypeCondition",
		and:"ButtonLinkWhere",
		or:"ButtonLinkWhere",
		not:"ButtonLinkWhere"
	},
	ButtonLinkVisualTypeCondition:{
		and:"ButtonLinkVisualTypeCondition",
		or:"ButtonLinkVisualTypeCondition",
		not:"ButtonLinkVisualTypeCondition",
		eq:"ButtonLinkVisualType",
		notEq:"ButtonLinkVisualType",
		in:"ButtonLinkVisualType",
		notIn:"ButtonLinkVisualType",
		lt:"ButtonLinkVisualType",
		lte:"ButtonLinkVisualType",
		gt:"ButtonLinkVisualType",
		gte:"ButtonLinkVisualType"
	},
	ButtonLinkVisualType: "enum" as const,
	ButtonLinkFillTypeCondition:{
		and:"ButtonLinkFillTypeCondition",
		or:"ButtonLinkFillTypeCondition",
		not:"ButtonLinkFillTypeCondition",
		eq:"ButtonLinkFillType",
		notEq:"ButtonLinkFillType",
		in:"ButtonLinkFillType",
		notIn:"ButtonLinkFillType",
		lt:"ButtonLinkFillType",
		lte:"ButtonLinkFillType",
		gt:"ButtonLinkFillType",
		gte:"ButtonLinkFillType"
	},
	ButtonLinkFillType: "enum" as const,
	ButtonLinkWidthTypeCondition:{
		and:"ButtonLinkWidthTypeCondition",
		or:"ButtonLinkWidthTypeCondition",
		not:"ButtonLinkWidthTypeCondition",
		eq:"ButtonLinkWidthType",
		notEq:"ButtonLinkWidthType",
		in:"ButtonLinkWidthType",
		notIn:"ButtonLinkWidthType",
		lt:"ButtonLinkWidthType",
		lte:"ButtonLinkWidthType",
		gt:"ButtonLinkWidthType",
		gte:"ButtonLinkWidthType"
	},
	ButtonLinkWidthType: "enum" as const,
	FeatureListWhere:{
		id:"UUIDCondition",
		items:"FeatureItemWhere",
		and:"FeatureListWhere",
		or:"FeatureListWhere",
		not:"FeatureListWhere"
	},
	FeatureItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"FeatureListWhere",
		feature:"FeatureWhere",
		and:"FeatureItemWhere",
		or:"FeatureItemWhere",
		not:"FeatureItemWhere"
	},
	FeatureWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		icon:"ImageWhere",
		text:"StringCondition",
		and:"FeatureWhere",
		or:"FeatureWhere",
		not:"FeatureWhere"
	},
	ProjectionWhere:{
		id:"UUIDCondition",
		items:"ProjectionItemWhere",
		and:"ProjectionWhere",
		or:"ProjectionWhere",
		not:"ProjectionWhere"
	},
	ProjectionItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProjectionWhere",
		item:"ProjectionContentWhere",
		and:"ProjectionItemWhere",
		or:"ProjectionItemWhere",
		not:"ProjectionItemWhere"
	},
	ProjectionContentWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		text:"StringCondition",
		image:"ImageWhere",
		and:"ProjectionContentWhere",
		or:"ProjectionContentWhere",
		not:"ProjectionContentWhere"
	},
	ContentImageWithFeaturesWhere:{
		id:"UUIDCondition",
		subtitle:"StringCondition",
		title:"StringCondition",
		text:"StringCondition",
		image:"ImageWhere",
		features:"FeatureListWhere",
		and:"ContentImageWithFeaturesWhere",
		or:"ContentImageWithFeaturesWhere",
		not:"ContentImageWithFeaturesWhere"
	},
	AsideContentMediaWhere:{
		id:"UUIDCondition",
		subtitle:"StringCondition",
		title:"StringCondition",
		media:"ImageWhere",
		link:"LinkWhere",
		tableSpecifiation:"AsideContentMediaSpecificationWhere",
		and:"AsideContentMediaWhere",
		or:"AsideContentMediaWhere",
		not:"AsideContentMediaWhere"
	},
	AsideContentMediaSpecificationWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		label:"StringCondition",
		value:"StringCondition",
		specification:"AsideContentMediaWhere",
		and:"AsideContentMediaSpecificationWhere",
		or:"AsideContentMediaSpecificationWhere",
		not:"AsideContentMediaSpecificationWhere"
	},
	ImageListWhere:{
		id:"UUIDCondition",
		items:"ImageListItemWhere",
		and:"ImageListWhere",
		or:"ImageListWhere",
		not:"ImageListWhere"
	},
	ImageListItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		description:"StringCondition",
		list:"ImageListWhere",
		image:"ImageWhere",
		imageMobile:"ImageWhere",
		and:"ImageListItemWhere",
		or:"ImageListItemWhere",
		not:"ImageListItemWhere"
	},
	ProductCategoryListWhere:{
		id:"UUIDCondition",
		items:"ProductCategoryItemWhere",
		and:"ProductCategoryListWhere",
		or:"ProductCategoryListWhere",
		not:"ProductCategoryListWhere"
	},
	ProductCategoryItemWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"ProductCategoryListWhere",
		item:"ProductCategoryWhere",
		and:"ProductCategoryItemWhere",
		or:"ProductCategoryItemWhere",
		not:"ProductCategoryItemWhere"
	},
	CallToActionTypeCondition:{
		and:"CallToActionTypeCondition",
		or:"CallToActionTypeCondition",
		not:"CallToActionTypeCondition",
		eq:"CallToActionType",
		notEq:"CallToActionType",
		in:"CallToActionType",
		notIn:"CallToActionType",
		lt:"CallToActionType",
		lte:"CallToActionType",
		gt:"CallToActionType",
		gte:"CallToActionType"
	},
	CallToActionType: "enum" as const,
	FeatureSectionWhere:{
		id:"UUIDCondition",
		temperature:"FeatureSectionTemperatureWhere",
		heat:"FeatureSectionHeatWhere",
		pump:"FeatureSectionPumpWhere",
		tube:"FeatureSectionTubeWhere",
		antimer:"FeatureSectionAntimerWhere",
		and:"FeatureSectionWhere",
		or:"FeatureSectionWhere",
		not:"FeatureSectionWhere"
	},
	FeatureSectionTemperatureWhere:{
		id:"UUIDCondition",
		label:"StringCondition",
		description:"StringCondition",
		number:"IntCondition",
		and:"FeatureSectionTemperatureWhere",
		or:"FeatureSectionTemperatureWhere",
		not:"FeatureSectionTemperatureWhere"
	},
	FeatureSectionHeatWhere:{
		id:"UUIDCondition",
		title1:"StringCondition",
		titleHighlighted1:"StringCondition",
		titleHighlighted2:"StringCondition",
		description:"StringCondition",
		image:"ImageWhere",
		and:"FeatureSectionHeatWhere",
		or:"FeatureSectionHeatWhere",
		not:"FeatureSectionHeatWhere"
	},
	FeatureSectionPumpWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		sideTitle:"StringCondition",
		description:"StringCondition",
		and:"FeatureSectionPumpWhere",
		or:"FeatureSectionPumpWhere",
		not:"FeatureSectionPumpWhere"
	},
	FeatureSectionTubeWhere:{
		id:"UUIDCondition",
		title:"StringCondition",
		description:"StringCondition",
		image:"ImageWhere",
		secondaryImage:"ImageWhere",
		and:"FeatureSectionTubeWhere",
		or:"FeatureSectionTubeWhere",
		not:"FeatureSectionTubeWhere"
	},
	FeatureSectionAntimerWhere:{
		id:"UUIDCondition",
		label1:"StringCondition",
		label2:"StringCondition",
		description:"StringCondition",
		number:"IntCondition",
		image:"ImageWhere",
		and:"FeatureSectionAntimerWhere",
		or:"FeatureSectionAntimerWhere",
		not:"FeatureSectionAntimerWhere"
	},
	VideoWhere:{
		id:"UUIDCondition",
		src:"StringCondition",
		width:"IntCondition",
		height:"IntCondition",
		size:"IntCondition",
		type:"StringCondition",
		duration:"FloatCondition",
		poster:"ImageWhere",
		fileName:"StringCondition",
		and:"VideoWhere",
		or:"VideoWhere",
		not:"VideoWhere"
	},
	FloatCondition:{
		and:"FloatCondition",
		or:"FloatCondition",
		not:"FloatCondition"
	},
	GenericPageSideNavigationWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		list:"GenericPageLocaleWhere",
		link:"LinkWhere",
		and:"GenericPageSideNavigationWhere",
		or:"GenericPageSideNavigationWhere",
		not:"GenericPageSideNavigationWhere"
	},
	Locale:{
		genericPages:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		headers:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		footers:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		productCategories:{
			filter:"ProductCategoryLocaleWhere",
			orderBy:"ProductCategoryLocaleOrderBy"
		},
		products:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		generals:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		productFeatureItems:{
			filter:"ProductFeatureLocaleWhere",
			orderBy:"ProductFeatureLocaleOrderBy"
		},
		orderPages:{
			filter:"OrderPageLocaleWhere",
			orderBy:"OrderPageLocaleOrderBy"
		},
		genericPagesByRoot:{
			by:"LocaleGenericPagesByRootUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesBySeo:{
			by:"LocaleGenericPagesBySeoUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesByLink:{
			by:"LocaleGenericPagesByLinkUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesByContent:{
			by:"LocaleGenericPagesByContentUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		genericPagesBySideNavigationItems:{
			by:"LocaleGenericPagesBySideNavigationItemsUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		headersByRoot:{
			by:"LocaleHeadersByRootUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		footersByRoot:{
			by:"LocaleFootersByRootUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		footersByGroupsLinkList:{
			by:"LocaleFootersByGroupsLinkListUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		productCategoriesByRoot:{
			by:"LocaleProductCategoriesByRootUniqueWhere",
			filter:"ProductCategoryLocaleWhere"
		},
		productsByRoot:{
			by:"LocaleProductsByRootUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		generalsByRoot:{
			by:"LocaleGeneralsByRootUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		generalsBySeo:{
			by:"LocaleGeneralsBySeoUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		productFeatureItemsByRoot:{
			by:"LocaleProductFeatureItemsByRootUniqueWhere",
			filter:"ProductFeatureLocaleWhere"
		},
		orderPagesByRoot:{
			by:"LocaleOrderPagesByRootUniqueWhere",
			filter:"OrderPageLocaleWhere"
		},
		orderPagesByLink:{
			by:"LocaleOrderPagesByLinkUniqueWhere",
			filter:"OrderPageLocaleWhere"
		},
		orderPagesBySeo:{
			by:"LocaleOrderPagesBySeoUniqueWhere",
			filter:"OrderPageLocaleWhere"
		},
		paginateGenericPages:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateHeaders:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateFooters:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateProductCategories:{
			filter:"ProductCategoryLocaleWhere",
			orderBy:"ProductCategoryLocaleOrderBy"
		},
		paginateProducts:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateGenerals:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateProductFeatureItems:{
			filter:"ProductFeatureLocaleWhere",
			orderBy:"ProductFeatureLocaleOrderBy"
		},
		paginateOrderPages:{
			filter:"OrderPageLocaleWhere",
			orderBy:"OrderPageLocaleOrderBy"
		}
	},
	GenericPageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		root:"GenericPageOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy",
		link:"LinkableOrderBy",
		content:"ContentOrderBy"
	},
	GenericPageOrderBy:{
		id:"OrderDirection",
		isSideNavigation:"OrderDirection"
	},
	LocaleOrderBy:{
		id:"OrderDirection",
		code:"OrderDirection",
		label:"OrderDirection",
		currency:"OrderDirection"
	},
	SeoOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		description:"OrderDirection",
		ogTitle:"OrderDirection",
		ogDescription:"OrderDirection",
		noIndex:"OrderDirection",
		noFollow:"OrderDirection",
		ogImage:"ImageOrderBy"
	},
	LinkableOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		genericPage:"GenericPageLocaleOrderBy",
		redirect:"RedirectOrderBy",
		orderPage:"OrderPageLocaleOrderBy"
	},
	RedirectOrderBy:{
		id:"OrderDirection",
		link:"LinkableOrderBy",
		target:"LinkOrderBy"
	},
	LinkOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		title:"OrderDirection",
		externalLink:"OrderDirection",
		internalLink:"LinkableOrderBy",
		scrollToTargetId:"OrderDirection",
		file:"FileOrderBy"
	},
	FileOrderBy:{
		id:"OrderDirection",
		url:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		label:"OrderDirection",
		fileName:"OrderDirection"
	},
	OrderPageLocaleOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		link:"LinkableOrderBy",
		root:"OrderPageOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy",
		termsAndConditions:"OrderDirection"
	},
	OrderPageOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		logo:"ImageOrderBy"
	},
	ContentOrderBy:{
		id:"OrderDirection"
	},
	HeaderLocale:{
		root:{
			filter:"HeaderWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		specialLink:{
			filter:"LinkWhere"
		}
	},
	Header:{
		logo:{
			filter:"ImageWhere"
		},
		locales:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		localesByLocale:{
			by:"HeaderLocalesByLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		paginateLocales:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		}
	},
	HeaderLocaleOrderBy:{
		id:"OrderDirection",
		root:"HeaderOrderBy",
		locale:"LocaleOrderBy",
		specialLink:"LinkOrderBy"
	},
	HeaderOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		logo:"ImageOrderBy"
	},
	HeaderLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	LocaleUniqueWhere:{
		id:"UUID",
		genericPages:"GenericPageLocaleUniqueWhere",
		headers:"HeaderLocaleUniqueWhere",
		footers:"FooterLocaleUniqueWhere",
		productCategories:"ProductCategoryLocaleUniqueWhere",
		products:"ProductLocaleUniqueWhere",
		generals:"GeneralLocaleUniqueWhere",
		productFeatureItems:"ProductFeatureLocaleUniqueWhere",
		orderPages:"OrderPageLocaleUniqueWhere"
	},
	GenericPageLocaleUniqueWhere:{
		id:"UUID",
		root:"GenericPageUniqueWhere",
		locale:"LocaleUniqueWhere",
		seo:"SeoUniqueWhere",
		link:"LinkableUniqueWhere",
		content:"ContentUniqueWhere",
		sideNavigationItems:"GenericPageSideNavigationUniqueWhere"
	},
	GenericPageUniqueWhere:{
		id:"UUID",
		locales:"GenericPageLocaleUniqueWhere"
	},
	SeoUniqueWhere:{
		id:"UUID",
		ogImage:"ImageUniqueWhere"
	},
	LinkableUniqueWhere:{
		id:"UUID",
		genericPage:"GenericPageLocaleUniqueWhere",
		redirect:"RedirectUniqueWhere",
		orderPage:"OrderPageLocaleUniqueWhere"
	},
	RedirectUniqueWhere:{
		id:"UUID",
		link:"LinkableUniqueWhere",
		target:"LinkUniqueWhere"
	},
	LinkUniqueWhere:{
		id:"UUID"
	},
	OrderPageLocaleUniqueWhere:{
		id:"UUID",
		root:"OrderPageUniqueWhere",
		locale:"LocaleUniqueWhere",
		link:"LinkableUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	OrderPageUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"OrderPageLocaleUniqueWhere"
	},
	ContentUniqueWhere:{
		id:"UUID",
		blocks:"ContentBlockUniqueWhere"
	},
	ContentBlockUniqueWhere:{
		id:"UUID",
		references:"ContentReferenceUniqueWhere"
	},
	ContentReferenceUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere",
		linkTwo:"LinkUniqueWhere",
		buttonLink:"ButtonLinkUniqueWhere",
		buttonLinkTwo:"ButtonLinkUniqueWhere",
		featureList:"FeatureListUniqueWhere",
		projection:"ProjectionUniqueWhere",
		contentImageWithFeatures:"ContentImageWithFeaturesUniqueWhere",
		asideContentMedia:"AsideContentMediaUniqueWhere",
		imageList:"ImageListUniqueWhere",
		productCategories:"ProductCategoryListUniqueWhere",
		featureSection:"FeatureSectionUniqueWhere"
	},
	ButtonLinkUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere"
	},
	FeatureListUniqueWhere:{
		id:"UUID",
		items:"FeatureItemUniqueWhere"
	},
	FeatureItemUniqueWhere:{
		id:"UUID"
	},
	ProjectionUniqueWhere:{
		id:"UUID",
		items:"ProjectionItemUniqueWhere"
	},
	ProjectionItemUniqueWhere:{
		id:"UUID"
	},
	ContentImageWithFeaturesUniqueWhere:{
		id:"UUID",
		features:"FeatureListUniqueWhere"
	},
	AsideContentMediaUniqueWhere:{
		id:"UUID",
		tableSpecifiation:"AsideContentMediaSpecificationUniqueWhere"
	},
	AsideContentMediaSpecificationUniqueWhere:{
		id:"UUID"
	},
	ImageListUniqueWhere:{
		id:"UUID",
		items:"ImageListItemUniqueWhere"
	},
	ImageListItemUniqueWhere:{
		id:"UUID"
	},
	ProductCategoryListUniqueWhere:{
		id:"UUID",
		items:"ProductCategoryItemUniqueWhere"
	},
	ProductCategoryItemUniqueWhere:{
		id:"UUID"
	},
	FeatureSectionUniqueWhere:{
		id:"UUID",
		temperature:"FeatureSectionTemperatureUniqueWhere",
		heat:"FeatureSectionHeatUniqueWhere",
		pump:"FeatureSectionPumpUniqueWhere",
		tube:"FeatureSectionTubeUniqueWhere",
		antimer:"FeatureSectionAntimerUniqueWhere"
	},
	FeatureSectionTemperatureUniqueWhere:{
		id:"UUID"
	},
	FeatureSectionHeatUniqueWhere:{
		id:"UUID"
	},
	FeatureSectionPumpUniqueWhere:{
		id:"UUID"
	},
	FeatureSectionTubeUniqueWhere:{
		id:"UUID"
	},
	FeatureSectionAntimerUniqueWhere:{
		id:"UUID"
	},
	GenericPageSideNavigationUniqueWhere:{
		id:"UUID"
	},
	HeaderLocaleUniqueWhere:{
		id:"UUID",
		root:"HeaderUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	HeaderUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"HeaderLocaleUniqueWhere"
	},
	FooterLocaleUniqueWhere:{
		id:"UUID",
		root:"FooterUniqueWhere",
		locale:"LocaleUniqueWhere",
		groupsLinkList:"FooterGroupLinkListUniqueWhere"
	},
	FooterUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"FooterLocaleUniqueWhere"
	},
	FooterGroupLinkListUniqueWhere:{
		id:"UUID",
		linkList:"LinkListUniqueWhere"
	},
	LinkListUniqueWhere:{
		id:"UUID",
		items:"LinkItemUniqueWhere"
	},
	LinkItemUniqueWhere:{
		id:"UUID",
		link:"LinkUniqueWhere"
	},
	ProductCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductCategoryUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductCategoryUniqueWhere:{
		id:"UUID",
		products:"ProductUniqueWhere",
		locales:"ProductCategoryLocaleUniqueWhere"
	},
	ProductUniqueWhere:{
		id:"UUID",
		locales:"ProductLocaleUniqueWhere"
	},
	ProductLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	GeneralLocaleUniqueWhere:{
		id:"UUID",
		root:"GeneralUniqueWhere",
		locale:"LocaleUniqueWhere",
		seo:"SeoUniqueWhere"
	},
	GeneralUniqueWhere:{
		id:"UUID",
		unique:"One",
		locales:"GeneralLocaleUniqueWhere"
	},
	ProductFeatureLocaleUniqueWhere:{
		id:"UUID",
		root:"ProductFeatureUniqueWhere",
		locale:"LocaleUniqueWhere"
	},
	ProductFeatureUniqueWhere:{
		id:"UUID",
		locales:"ProductFeatureLocaleUniqueWhere"
	},
	Link:{
		internalLink:{
			filter:"LinkableWhere"
		},
		file:{
			filter:"FileWhere"
		}
	},
	Linkable:{
		genericPage:{
			filter:"GenericPageLocaleWhere"
		},
		redirect:{
			filter:"RedirectWhere"
		},
		orderPage:{
			filter:"OrderPageLocaleWhere"
		}
	},
	Redirect:{
		link:{
			filter:"LinkableWhere"
		},
		target:{
			filter:"LinkWhere"
		}
	},
	OrderPageLocale:{
		link:{
			filter:"LinkableWhere"
		},
		root:{
			filter:"OrderPageWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		}
	},
	OrderPage:{
		locales:{
			filter:"OrderPageLocaleWhere",
			orderBy:"OrderPageLocaleOrderBy"
		},
		logo:{
			filter:"ImageWhere"
		},
		localesByLocale:{
			by:"OrderPageLocalesByLocaleUniqueWhere",
			filter:"OrderPageLocaleWhere"
		},
		localesByLink:{
			by:"OrderPageLocalesByLinkUniqueWhere",
			filter:"OrderPageLocaleWhere"
		},
		localesBySeo:{
			by:"OrderPageLocalesBySeoUniqueWhere",
			filter:"OrderPageLocaleWhere"
		},
		paginateLocales:{
			filter:"OrderPageLocaleWhere",
			orderBy:"OrderPageLocaleOrderBy"
		}
	},
	OrderPageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	OrderPageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	OrderPageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	Seo:{
		ogImage:{
			filter:"ImageWhere"
		}
	},
	FooterLocale:{
		groupsLinkList:{
			filter:"FooterGroupLinkListWhere",
			orderBy:"FooterGroupLinkListOrderBy"
		},
		root:{
			filter:"FooterWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		groupsLinkListByLinkList:{
			by:"FooterLocaleGroupsLinkListByLinkListUniqueWhere",
			filter:"FooterGroupLinkListWhere"
		},
		paginateGroupsLinkList:{
			filter:"FooterGroupLinkListWhere",
			orderBy:"FooterGroupLinkListOrderBy"
		}
	},
	FooterGroupLinkList:{
		footer:{
			filter:"FooterLocaleWhere"
		},
		linkList:{
			filter:"LinkListWhere"
		},
		addressLink:{
			filter:"LinkWhere"
		}
	},
	LinkList:{
		items:{
			filter:"LinkItemWhere",
			orderBy:"LinkItemOrderBy"
		},
		itemsByLink:{
			by:"LinkListItemsByLinkUniqueWhere",
			filter:"LinkItemWhere"
		},
		paginateItems:{
			filter:"LinkItemWhere",
			orderBy:"LinkItemOrderBy"
		}
	},
	LinkItem:{
		list:{
			filter:"LinkListWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	LinkItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"LinkListOrderBy",
		link:"LinkOrderBy"
	},
	LinkListOrderBy:{
		id:"OrderDirection"
	},
	LinkListItemsByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	FooterGroupLinkListOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		title:"OrderDirection",
		footer:"FooterLocaleOrderBy",
		linkList:"LinkListOrderBy",
		isAddress:"OrderDirection",
		addressValue:"OrderDirection",
		addressLink:"LinkOrderBy"
	},
	FooterLocaleOrderBy:{
		id:"OrderDirection",
		copyright:"OrderDirection",
		root:"FooterOrderBy",
		locale:"LocaleOrderBy",
		contact:"OrderDirection"
	},
	FooterOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection",
		logo:"ImageOrderBy",
		logoCompany:"ImageOrderBy"
	},
	Footer:{
		logo:{
			filter:"ImageWhere"
		},
		locales:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		logoCompany:{
			filter:"ImageWhere"
		},
		localesByLocale:{
			by:"FooterLocalesByLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		localesByGroupsLinkList:{
			by:"FooterLocalesByGroupsLinkListUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		paginateLocales:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		}
	},
	FooterLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	FooterLocalesByGroupsLinkListUniqueWhere:{
		groupsLinkList:"FooterGroupLinkListUniqueWhere"
	},
	FooterLocaleGroupsLinkListByLinkListUniqueWhere:{
		linkList:"LinkListUniqueWhere"
	},
	ProductCategoryLocale:{
		root:{
			filter:"ProductCategoryWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductCategory:{
		products:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		locales:{
			filter:"ProductCategoryLocaleWhere",
			orderBy:"ProductCategoryLocaleOrderBy"
		},
		productsBySlug:{
			by:"ProductCategoryProductsBySlugUniqueWhere",
			filter:"ProductWhere"
		},
		productsByLocales:{
			by:"ProductCategoryProductsByLocalesUniqueWhere",
			filter:"ProductWhere"
		},
		localesByLocale:{
			by:"ProductCategoryLocalesByLocaleUniqueWhere",
			filter:"ProductCategoryLocaleWhere"
		},
		paginateProducts:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateLocales:{
			filter:"ProductCategoryLocaleWhere",
			orderBy:"ProductCategoryLocaleOrderBy"
		}
	},
	Product:{
		image:{
			filter:"ImageWhere"
		},
		category:{
			filter:"ProductCategoryWhere"
		},
		tileImage:{
			filter:"ImageWhere"
		},
		locales:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		features:{
			filter:"ProductFeatureWhere",
			orderBy:"ProductFeatureOrderBy"
		},
		localesByLocale:{
			by:"ProductLocalesByLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		paginateLocales:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateFeatures:{
			filter:"ProductFeatureWhere",
			orderBy:"ProductFeatureOrderBy"
		}
	},
	ProductLocale:{
		root:{
			filter:"ProductWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		description:"OrderDirection",
		root:"ProductOrderBy",
		locale:"LocaleOrderBy",
		price:"OrderDirection",
		buttonLabel:"OrderDirection",
		isCustomButtonLabel:"OrderDirection",
		discountedPrice:"OrderDirection"
	},
	ProductOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		miningPower:"OrderDirection",
		image:"ImageOrderBy",
		category:"ProductCategoryOrderBy",
		tileImage:"ImageOrderBy",
		isForSale:"OrderDirection",
		slug:"OrderDirection"
	},
	ProductCategoryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection"
	},
	ProductFeature:{
		locales:{
			filter:"ProductFeatureLocaleWhere",
			orderBy:"ProductFeatureLocaleOrderBy"
		},
		localesByLocale:{
			by:"ProductFeatureLocalesByLocaleUniqueWhere",
			filter:"ProductFeatureLocaleWhere"
		},
		paginateLocales:{
			filter:"ProductFeatureLocaleWhere",
			orderBy:"ProductFeatureLocaleOrderBy"
		}
	},
	ProductFeatureLocale:{
		root:{
			filter:"ProductFeatureWhere"
		},
		locale:{
			filter:"LocaleWhere"
		}
	},
	ProductFeatureLocaleOrderBy:{
		id:"OrderDirection",
		price:"OrderDirection",
		root:"ProductFeatureOrderBy",
		locale:"LocaleOrderBy",
		title:"OrderDirection"
	},
	ProductFeatureOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection"
	},
	ProductFeatureLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	ProductCategoryLocaleOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		root:"ProductCategoryOrderBy",
		locale:"LocaleOrderBy"
	},
	ProductCategoryProductsBySlugUniqueWhere:{

	},
	ProductCategoryProductsByLocalesUniqueWhere:{
		locales:"ProductLocaleUniqueWhere"
	},
	ProductCategoryLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	GeneralLocale:{
		root:{
			filter:"GeneralWhere"
		},
		locale:{
			filter:"LocaleWhere"
		},
		seo:{
			filter:"SeoWhere"
		}
	},
	General:{
		locales:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		localesByLocale:{
			by:"GeneralLocalesByLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		localesBySeo:{
			by:"GeneralLocalesBySeoUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		paginateLocales:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		}
	},
	GeneralLocaleOrderBy:{
		id:"OrderDirection",
		root:"GeneralOrderBy",
		locale:"LocaleOrderBy",
		seo:"SeoOrderBy"
	},
	GeneralOrderBy:{
		id:"OrderDirection",
		unique:"OrderDirection"
	},
	GeneralLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	GeneralLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleGenericPagesByRootUniqueWhere:{
		root:"GenericPageUniqueWhere"
	},
	LocaleGenericPagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleGenericPagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleGenericPagesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	LocaleGenericPagesBySideNavigationItemsUniqueWhere:{
		sideNavigationItems:"GenericPageSideNavigationUniqueWhere"
	},
	LocaleHeadersByRootUniqueWhere:{
		root:"HeaderUniqueWhere"
	},
	LocaleFootersByRootUniqueWhere:{
		root:"FooterUniqueWhere"
	},
	LocaleFootersByGroupsLinkListUniqueWhere:{
		groupsLinkList:"FooterGroupLinkListUniqueWhere"
	},
	LocaleProductCategoriesByRootUniqueWhere:{
		root:"ProductCategoryUniqueWhere"
	},
	LocaleProductsByRootUniqueWhere:{
		root:"ProductUniqueWhere"
	},
	LocaleGeneralsByRootUniqueWhere:{
		root:"GeneralUniqueWhere"
	},
	LocaleGeneralsBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	LocaleProductFeatureItemsByRootUniqueWhere:{
		root:"ProductFeatureUniqueWhere"
	},
	LocaleOrderPagesByRootUniqueWhere:{
		root:"OrderPageUniqueWhere"
	},
	LocaleOrderPagesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	LocaleOrderPagesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	Content:{
		blocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		blocksByReferences:{
			by:"ContentBlocksByReferencesUniqueWhere",
			filter:"ContentBlockWhere"
		},
		paginateBlocks:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		}
	},
	ContentBlock:{
		content:{
			filter:"ContentWhere"
		},
		references:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		referencesByLink:{
			by:"ContentBlockReferencesByLinkUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByLinkTwo:{
			by:"ContentBlockReferencesByLinkTwoUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByButtonLink:{
			by:"ContentBlockReferencesByButtonLinkUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByButtonLinkTwo:{
			by:"ContentBlockReferencesByButtonLinkTwoUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByFeatureList:{
			by:"ContentBlockReferencesByFeatureListUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByProjection:{
			by:"ContentBlockReferencesByProjectionUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByContentImageWithFeatures:{
			by:"ContentBlockReferencesByContentImageWithFeaturesUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByAsideContentMedia:{
			by:"ContentBlockReferencesByAsideContentMediaUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByImageList:{
			by:"ContentBlockReferencesByImageListUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByProductCategories:{
			by:"ContentBlockReferencesByProductCategoriesUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		referencesByFeatureSection:{
			by:"ContentBlockReferencesByFeatureSectionUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		paginateReferences:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		}
	},
	ContentReference:{
		block:{
			filter:"ContentBlockWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		linkTwo:{
			filter:"LinkWhere"
		},
		buttonLink:{
			filter:"ButtonLinkWhere"
		},
		buttonLinkTwo:{
			filter:"ButtonLinkWhere"
		},
		featureList:{
			filter:"FeatureListWhere"
		},
		projection:{
			filter:"ProjectionWhere"
		},
		contentImageWithFeatures:{
			filter:"ContentImageWithFeaturesWhere"
		},
		asideContentMedia:{
			filter:"AsideContentMediaWhere"
		},
		imageList:{
			filter:"ImageListWhere"
		},
		productCategories:{
			filter:"ProductCategoryListWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		secondaryImage:{
			filter:"ImageWhere"
		},
		featureSection:{
			filter:"FeatureSectionWhere"
		},
		terciaryImage:{
			filter:"ImageWhere"
		},
		video:{
			filter:"VideoWhere"
		},
		secondaryVideo:{
			filter:"VideoWhere"
		}
	},
	ButtonLink:{
		link:{
			filter:"LinkWhere"
		}
	},
	FeatureList:{
		items:{
			filter:"FeatureItemWhere",
			orderBy:"FeatureItemOrderBy"
		},
		paginateItems:{
			filter:"FeatureItemWhere",
			orderBy:"FeatureItemOrderBy"
		}
	},
	FeatureItem:{
		list:{
			filter:"FeatureListWhere"
		},
		feature:{
			filter:"FeatureWhere"
		}
	},
	Feature:{
		icon:{
			filter:"ImageWhere"
		}
	},
	FeatureItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"FeatureListOrderBy",
		feature:"FeatureOrderBy"
	},
	FeatureListOrderBy:{
		id:"OrderDirection"
	},
	FeatureOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		icon:"ImageOrderBy",
		text:"OrderDirection"
	},
	Projection:{
		items:{
			filter:"ProjectionItemWhere",
			orderBy:"ProjectionItemOrderBy"
		},
		paginateItems:{
			filter:"ProjectionItemWhere",
			orderBy:"ProjectionItemOrderBy"
		}
	},
	ProjectionItem:{
		list:{
			filter:"ProjectionWhere"
		},
		item:{
			filter:"ProjectionContentWhere"
		}
	},
	ProjectionContent:{
		image:{
			filter:"ImageWhere"
		}
	},
	ProjectionItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProjectionOrderBy",
		item:"ProjectionContentOrderBy"
	},
	ProjectionOrderBy:{
		id:"OrderDirection"
	},
	ProjectionContentOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		text:"OrderDirection",
		image:"ImageOrderBy"
	},
	ContentImageWithFeatures:{
		image:{
			filter:"ImageWhere"
		},
		features:{
			filter:"FeatureListWhere"
		}
	},
	AsideContentMedia:{
		media:{
			filter:"ImageWhere"
		},
		link:{
			filter:"LinkWhere"
		},
		tableSpecifiation:{
			filter:"AsideContentMediaSpecificationWhere",
			orderBy:"AsideContentMediaSpecificationOrderBy"
		},
		paginateTableSpecifiation:{
			filter:"AsideContentMediaSpecificationWhere",
			orderBy:"AsideContentMediaSpecificationOrderBy"
		}
	},
	AsideContentMediaSpecification:{
		specification:{
			filter:"AsideContentMediaWhere"
		}
	},
	AsideContentMediaSpecificationOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		label:"OrderDirection",
		value:"OrderDirection",
		specification:"AsideContentMediaOrderBy"
	},
	AsideContentMediaOrderBy:{
		id:"OrderDirection",
		subtitle:"OrderDirection",
		title:"OrderDirection",
		media:"ImageOrderBy",
		link:"LinkOrderBy"
	},
	ImageList:{
		items:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateItems:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		}
	},
	ImageListItem:{
		list:{
			filter:"ImageListWhere"
		},
		image:{
			filter:"ImageWhere"
		},
		imageMobile:{
			filter:"ImageWhere"
		}
	},
	ImageListItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		description:"OrderDirection",
		list:"ImageListOrderBy",
		image:"ImageOrderBy",
		imageMobile:"ImageOrderBy"
	},
	ImageListOrderBy:{
		id:"OrderDirection"
	},
	ProductCategoryList:{
		items:{
			filter:"ProductCategoryItemWhere",
			orderBy:"ProductCategoryItemOrderBy"
		},
		paginateItems:{
			filter:"ProductCategoryItemWhere",
			orderBy:"ProductCategoryItemOrderBy"
		}
	},
	ProductCategoryItem:{
		list:{
			filter:"ProductCategoryListWhere"
		},
		item:{
			filter:"ProductCategoryWhere"
		}
	},
	ProductCategoryItemOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"ProductCategoryListOrderBy",
		item:"ProductCategoryOrderBy"
	},
	ProductCategoryListOrderBy:{
		id:"OrderDirection"
	},
	FeatureSection:{
		temperature:{
			filter:"FeatureSectionTemperatureWhere"
		},
		heat:{
			filter:"FeatureSectionHeatWhere"
		},
		pump:{
			filter:"FeatureSectionPumpWhere"
		},
		tube:{
			filter:"FeatureSectionTubeWhere"
		},
		antimer:{
			filter:"FeatureSectionAntimerWhere"
		}
	},
	FeatureSectionHeat:{
		image:{
			filter:"ImageWhere"
		}
	},
	FeatureSectionTube:{
		image:{
			filter:"ImageWhere"
		},
		secondaryImage:{
			filter:"ImageWhere"
		}
	},
	FeatureSectionAntimer:{
		image:{
			filter:"ImageWhere"
		}
	},
	Video:{
		poster:{
			filter:"ImageWhere"
		}
	},
	ContentReferenceOrderBy:{
		id:"OrderDirection",
		type:"OrderDirection",
		block:"ContentBlockOrderBy",
		primaryText:"OrderDirection",
		link:"LinkOrderBy",
		linkTwo:"LinkOrderBy",
		buttonLink:"ButtonLinkOrderBy",
		buttonLinkTwo:"ButtonLinkOrderBy",
		featureList:"FeatureListOrderBy",
		projection:"ProjectionOrderBy",
		contentImageWithFeatures:"ContentImageWithFeaturesOrderBy",
		asideContentMedia:"AsideContentMediaOrderBy",
		imageList:"ImageListOrderBy",
		productCategories:"ProductCategoryListOrderBy",
		image:"ImageOrderBy",
		secondaryText:"OrderDirection",
		callToActionTypes:"OrderDirection",
		secondaryImage:"ImageOrderBy",
		featureSection:"FeatureSectionOrderBy",
		terciaryImage:"ImageOrderBy",
		video:"VideoOrderBy",
		isImageWithWhiteShadow:"OrderDirection",
		secondaryVideo:"VideoOrderBy"
	},
	ContentBlockOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		json:"OrderDirection",
		content:"ContentOrderBy"
	},
	ButtonLinkOrderBy:{
		id:"OrderDirection",
		visualType:"OrderDirection",
		link:"LinkOrderBy",
		fillType:"OrderDirection",
		widthType:"OrderDirection"
	},
	ContentImageWithFeaturesOrderBy:{
		id:"OrderDirection",
		subtitle:"OrderDirection",
		title:"OrderDirection",
		text:"OrderDirection",
		image:"ImageOrderBy",
		features:"FeatureListOrderBy"
	},
	FeatureSectionOrderBy:{
		id:"OrderDirection",
		temperature:"FeatureSectionTemperatureOrderBy",
		heat:"FeatureSectionHeatOrderBy",
		pump:"FeatureSectionPumpOrderBy",
		tube:"FeatureSectionTubeOrderBy",
		antimer:"FeatureSectionAntimerOrderBy"
	},
	FeatureSectionTemperatureOrderBy:{
		id:"OrderDirection",
		label:"OrderDirection",
		description:"OrderDirection",
		number:"OrderDirection"
	},
	FeatureSectionHeatOrderBy:{
		id:"OrderDirection",
		title1:"OrderDirection",
		titleHighlighted1:"OrderDirection",
		titleHighlighted2:"OrderDirection",
		description:"OrderDirection",
		image:"ImageOrderBy"
	},
	FeatureSectionPumpOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		sideTitle:"OrderDirection",
		description:"OrderDirection"
	},
	FeatureSectionTubeOrderBy:{
		id:"OrderDirection",
		title:"OrderDirection",
		description:"OrderDirection",
		image:"ImageOrderBy",
		secondaryImage:"ImageOrderBy"
	},
	FeatureSectionAntimerOrderBy:{
		id:"OrderDirection",
		label1:"OrderDirection",
		label2:"OrderDirection",
		description:"OrderDirection",
		number:"OrderDirection",
		image:"ImageOrderBy"
	},
	VideoOrderBy:{
		id:"OrderDirection",
		src:"OrderDirection",
		width:"OrderDirection",
		height:"OrderDirection",
		size:"OrderDirection",
		type:"OrderDirection",
		duration:"OrderDirection",
		poster:"ImageOrderBy",
		fileName:"OrderDirection"
	},
	ContentBlockReferencesByLinkUniqueWhere:{
		link:"LinkUniqueWhere"
	},
	ContentBlockReferencesByLinkTwoUniqueWhere:{
		linkTwo:"LinkUniqueWhere"
	},
	ContentBlockReferencesByButtonLinkUniqueWhere:{
		buttonLink:"ButtonLinkUniqueWhere"
	},
	ContentBlockReferencesByButtonLinkTwoUniqueWhere:{
		buttonLinkTwo:"ButtonLinkUniqueWhere"
	},
	ContentBlockReferencesByFeatureListUniqueWhere:{
		featureList:"FeatureListUniqueWhere"
	},
	ContentBlockReferencesByProjectionUniqueWhere:{
		projection:"ProjectionUniqueWhere"
	},
	ContentBlockReferencesByContentImageWithFeaturesUniqueWhere:{
		contentImageWithFeatures:"ContentImageWithFeaturesUniqueWhere"
	},
	ContentBlockReferencesByAsideContentMediaUniqueWhere:{
		asideContentMedia:"AsideContentMediaUniqueWhere"
	},
	ContentBlockReferencesByImageListUniqueWhere:{
		imageList:"ImageListUniqueWhere"
	},
	ContentBlockReferencesByProductCategoriesUniqueWhere:{
		productCategories:"ProductCategoryListUniqueWhere"
	},
	ContentBlockReferencesByFeatureSectionUniqueWhere:{
		featureSection:"FeatureSectionUniqueWhere"
	},
	ContentBlocksByReferencesUniqueWhere:{
		references:"ContentReferenceUniqueWhere"
	},
	GenericPageSideNavigation:{
		list:{
			filter:"GenericPageLocaleWhere"
		},
		link:{
			filter:"LinkWhere"
		}
	},
	GenericPageSideNavigationOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		list:"GenericPageLocaleOrderBy",
		link:"LinkOrderBy"
	},
	GenericPageLocalesByLocaleUniqueWhere:{
		locale:"LocaleUniqueWhere"
	},
	GenericPageLocalesBySeoUniqueWhere:{
		seo:"SeoUniqueWhere"
	},
	GenericPageLocalesByLinkUniqueWhere:{
		link:"LinkableUniqueWhere"
	},
	GenericPageLocalesByContentUniqueWhere:{
		content:"ContentUniqueWhere"
	},
	GenericPageLocalesBySideNavigationItemsUniqueWhere:{
		sideNavigationItems:"GenericPageSideNavigationUniqueWhere"
	},
	VideoUniqueWhere:{
		id:"UUID"
	},
	FeatureUniqueWhere:{
		id:"UUID",
		icon:"ImageUniqueWhere"
	},
	ProjectionContentUniqueWhere:{
		id:"UUID",
		image:"ImageUniqueWhere"
	},
	FileUniqueWhere:{
		id:"UUID"
	},
	FilledOrderForm:{
		productFeatures:{
			filter:"ProductFeatureWhere",
			orderBy:"ProductFeatureOrderBy"
		},
		product:{
			filter:"ProductWhere"
		},
		country:{
			filter:"FilledOrderFormCountryWhere"
		},
		paginateProductFeatures:{
			filter:"ProductFeatureWhere",
			orderBy:"ProductFeatureOrderBy"
		}
	},
	DateTime: `scalar.DateTime` as const,
	FilledOrderFormCountryWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		code:"StringCondition",
		label:"StringCondition",
		and:"FilledOrderFormCountryWhere",
		or:"FilledOrderFormCountryWhere",
		not:"FilledOrderFormCountryWhere"
	},
	FilledOrderFormUniqueWhere:{
		id:"UUID"
	},
	FilledOrderFormWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		email:"StringCondition",
		deliveryAddress:"StringCondition",
		note:"StringCondition",
		productFeatures:"ProductFeatureWhere",
		publishedAt:"DateTimeCondition",
		product:"ProductWhere",
		country:"FilledOrderFormCountryWhere",
		mobile:"StringCondition",
		and:"FilledOrderFormWhere",
		or:"FilledOrderFormWhere",
		not:"FilledOrderFormWhere"
	},
	DateTimeCondition:{
		and:"DateTimeCondition",
		or:"DateTimeCondition",
		not:"DateTimeCondition",
		eq:"DateTime",
		notEq:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime"
	},
	FilledOrderFormOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		email:"OrderDirection",
		deliveryAddress:"OrderDirection",
		note:"OrderDirection",
		publishedAt:"OrderDirection",
		product:"ProductOrderBy",
		country:"FilledOrderFormCountryOrderBy",
		mobile:"OrderDirection"
	},
	FilledOrderFormCountryOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		code:"OrderDirection",
		label:"OrderDirection"
	},
	FilledOrderFormCreateInput:{
		productFeatures:"FilledOrderFormCreateProductFeaturesEntityRelationInput",
		publishedAt:"DateTime",
		product:"FilledOrderFormCreateProductEntityRelationInput",
		country:"FilledOrderFormCreateCountryEntityRelationInput"
	},
	FilledOrderFormCreateProductFeaturesEntityRelationInput:{
		connect:"ProductFeatureUniqueWhere"
	},
	FilledOrderFormCreateProductEntityRelationInput:{
		connect:"ProductUniqueWhere"
	},
	FilledOrderFormCreateCountryEntityRelationInput:{
		connect:"FilledOrderFormCountryUniqueWhere"
	},
	FilledOrderFormCountryUniqueWhere:{
		id:"UUID"
	},
	FilledOrderFormUpdateInput:{
		productFeatures:"FilledOrderFormUpdateProductFeaturesEntityRelationInput",
		publishedAt:"DateTime",
		product:"FilledOrderFormUpdateProductEntityRelationInput",
		country:"FilledOrderFormUpdateCountryEntityRelationInput"
	},
	FilledOrderFormUpdateProductFeaturesEntityRelationInput:{
		connect:"ProductFeatureUniqueWhere",
		disconnect:"ProductFeatureUniqueWhere"
	},
	FilledOrderFormUpdateProductEntityRelationInput:{
		connect:"ProductUniqueWhere"
	},
	FilledOrderFormUpdateCountryEntityRelationInput:{
		connect:"FilledOrderFormCountryUniqueWhere"
	},
	FilledOrderFormEmailAddressUniqueWhere:{
		id:"UUID"
	},
	FilledOrderFormEmailAddressWhere:{
		id:"UUIDCondition",
		order:"IntCondition",
		email:"StringCondition",
		and:"FilledOrderFormEmailAddressWhere",
		or:"FilledOrderFormEmailAddressWhere",
		not:"FilledOrderFormEmailAddressWhere"
	},
	FilledOrderFormEmailAddressOrderBy:{
		id:"OrderDirection",
		order:"OrderDirection",
		email:"OrderDirection"
	},
	TranslationCatalogue:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		identifier:{
			filter:"TranslationCataloguesIdentifierWhere"
		},
		fallback:{
			filter:"TranslationCatalogueWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		valuesByKey:{
			by:"TranslationCatalogueValuesByKeyUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationDomain:{
		catalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		keys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		cataloguesByIdentifier:{
			by:"TranslationDomainCataloguesByIdentifierUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		cataloguesByValues:{
			by:"TranslationDomainCataloguesByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		keysByIdentifier:{
			by:"TranslationDomainKeysByIdentifierUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		keysByValues:{
			by:"TranslationDomainKeysByValuesUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		paginateCatalogues:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateKeys:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		}
	},
	TranslationCatalogueWhere:{
		id:"UUIDCondition",
		domain:"TranslationDomainWhere",
		identifier:"TranslationCataloguesIdentifierWhere",
		fallback:"TranslationCatalogueWhere",
		values:"TranslationValueWhere",
		and:"TranslationCatalogueWhere",
		or:"TranslationCatalogueWhere",
		not:"TranslationCatalogueWhere"
	},
	TranslationDomainWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		name:"StringCondition",
		catalogues:"TranslationCatalogueWhere",
		keys:"TranslationKeyWhere",
		and:"TranslationDomainWhere",
		or:"TranslationDomainWhere",
		not:"TranslationDomainWhere"
	},
	TranslationKeyWhere:{
		id:"UUIDCondition",
		identifier:"StringCondition",
		contentType:"TranslationKeyContentTypeCondition",
		note:"StringCondition",
		domain:"TranslationDomainWhere",
		values:"TranslationValueWhere",
		and:"TranslationKeyWhere",
		or:"TranslationKeyWhere",
		not:"TranslationKeyWhere"
	},
	TranslationKeyContentTypeCondition:{
		and:"TranslationKeyContentTypeCondition",
		or:"TranslationKeyContentTypeCondition",
		not:"TranslationKeyContentTypeCondition",
		eq:"TranslationKeyContentType",
		notEq:"TranslationKeyContentType",
		in:"TranslationKeyContentType",
		notIn:"TranslationKeyContentType",
		lt:"TranslationKeyContentType",
		lte:"TranslationKeyContentType",
		gt:"TranslationKeyContentType",
		gte:"TranslationKeyContentType"
	},
	TranslationKeyContentType: "enum" as const,
	TranslationValueWhere:{
		id:"UUIDCondition",
		value:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		key:"TranslationKeyWhere",
		and:"TranslationValueWhere",
		or:"TranslationValueWhere",
		not:"TranslationValueWhere"
	},
	TranslationCataloguesIdentifierWhere:{
		id:"UUIDCondition",
		name:"StringCondition",
		code:"StringCondition",
		catalogue:"TranslationCatalogueWhere",
		and:"TranslationCataloguesIdentifierWhere",
		or:"TranslationCataloguesIdentifierWhere",
		not:"TranslationCataloguesIdentifierWhere"
	},
	TranslationCatalogueOrderBy:{
		id:"OrderDirection",
		domain:"TranslationDomainOrderBy",
		identifier:"TranslationCataloguesIdentifierOrderBy",
		fallback:"TranslationCatalogueOrderBy"
	},
	TranslationDomainOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		name:"OrderDirection"
	},
	TranslationCataloguesIdentifierOrderBy:{
		id:"OrderDirection",
		name:"OrderDirection",
		code:"OrderDirection"
	},
	TranslationKey:{
		domain:{
			filter:"TranslationDomainWhere"
		},
		values:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		valuesByCatalogue:{
			by:"TranslationKeyValuesByCatalogueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		paginateValues:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		}
	},
	TranslationValue:{
		catalogue:{
			filter:"TranslationCatalogueWhere"
		},
		key:{
			filter:"TranslationKeyWhere"
		}
	},
	TranslationValueOrderBy:{
		id:"OrderDirection",
		value:"OrderDirection",
		catalogue:"TranslationCatalogueOrderBy",
		key:"TranslationKeyOrderBy"
	},
	TranslationKeyOrderBy:{
		id:"OrderDirection",
		identifier:"OrderDirection",
		contentType:"OrderDirection",
		note:"OrderDirection",
		domain:"TranslationDomainOrderBy"
	},
	TranslationKeyValuesByCatalogueUniqueWhere:{
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationCatalogueUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		identifier:"TranslationCataloguesIdentifierUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainUniqueWhere:{
		id:"UUID",
		catalogues:"TranslationCatalogueUniqueWhere",
		keys:"TranslationKeyUniqueWhere"
	},
	TranslationKeyUniqueWhere:{
		id:"UUID",
		domain:"TranslationDomainUniqueWhere",
		values:"TranslationValueUniqueWhere"
	},
	TranslationValueUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere",
		key:"TranslationKeyUniqueWhere"
	},
	TranslationCataloguesIdentifierUniqueWhere:{
		id:"UUID",
		catalogue:"TranslationCatalogueUniqueWhere"
	},
	TranslationDomainCataloguesByIdentifierUniqueWhere:{
		identifier:"TranslationCataloguesIdentifierUniqueWhere"
	},
	TranslationDomainCataloguesByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationDomainKeysByIdentifierUniqueWhere:{

	},
	TranslationDomainKeysByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationCataloguesIdentifier:{
		catalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		catalogueByDomain:{
			by:"TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		catalogueByValues:{
			by:"TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		paginateCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		}
	},
	TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere:{
		domain:"TranslationDomainUniqueWhere"
	},
	TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere:{
		values:"TranslationValueUniqueWhere"
	},
	TranslationCatalogueValuesByKeyUniqueWhere:{
		key:"TranslationKeyUniqueWhere"
	},
	FilledProductWaitlistForm:{
		product:{
			filter:"ProductWhere"
		}
	},
	FilledProductWaitlistFormUniqueWhere:{
		id:"UUID"
	},
	FilledProductWaitlistFormWhere:{
		id:"UUIDCondition",
		publishedAt:"DateTimeCondition",
		email:"StringCondition",
		product:"ProductWhere",
		and:"FilledProductWaitlistFormWhere",
		or:"FilledProductWaitlistFormWhere",
		not:"FilledProductWaitlistFormWhere"
	},
	FilledProductWaitlistFormOrderBy:{
		id:"OrderDirection",
		publishedAt:"OrderDirection",
		email:"OrderDirection",
		product:"ProductOrderBy"
	},
	FilledProductWaitlistFormCreateInput:{
		publishedAt:"DateTime",
		product:"FilledProductWaitlistFormCreateProductEntityRelationInput"
	},
	FilledProductWaitlistFormCreateProductEntityRelationInput:{
		connect:"ProductUniqueWhere"
	},
	FilledProductWaitlistFormUpdateInput:{
		publishedAt:"DateTime",
		product:"FilledProductWaitlistFormUpdateProductEntityRelationInput"
	},
	FilledProductWaitlistFormUpdateProductEntityRelationInput:{
		connect:"ProductUniqueWhere"
	},
	QueryTransaction:{
		getImage:{
			by:"ImageUniqueWhere",
			filter:"ImageWhere"
		},
		listImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		paginateImage:{
			filter:"ImageWhere",
			orderBy:"ImageOrderBy"
		},
		getGenericPage:{
			by:"GenericPageUniqueWhere",
			filter:"GenericPageWhere"
		},
		listGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		paginateGenericPage:{
			filter:"GenericPageWhere",
			orderBy:"GenericPageOrderBy"
		},
		getLinkable:{
			by:"LinkableUniqueWhere",
			filter:"LinkableWhere"
		},
		listLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		paginateLinkable:{
			filter:"LinkableWhere",
			orderBy:"LinkableOrderBy"
		},
		getSeo:{
			by:"SeoUniqueWhere",
			filter:"SeoWhere"
		},
		listSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		paginateSeo:{
			filter:"SeoWhere",
			orderBy:"SeoOrderBy"
		},
		getGenericPageLocale:{
			by:"GenericPageLocaleUniqueWhere",
			filter:"GenericPageLocaleWhere"
		},
		listGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		paginateGenericPageLocale:{
			filter:"GenericPageLocaleWhere",
			orderBy:"GenericPageLocaleOrderBy"
		},
		getLocale:{
			by:"LocaleUniqueWhere",
			filter:"LocaleWhere"
		},
		listLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		paginateLocale:{
			filter:"LocaleWhere",
			orderBy:"LocaleOrderBy"
		},
		getLink:{
			by:"LinkUniqueWhere",
			filter:"LinkWhere"
		},
		listLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		paginateLink:{
			filter:"LinkWhere",
			orderBy:"LinkOrderBy"
		},
		getHeader:{
			by:"HeaderUniqueWhere",
			filter:"HeaderWhere"
		},
		listHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		paginateHeader:{
			filter:"HeaderWhere",
			orderBy:"HeaderOrderBy"
		},
		getHeaderLocale:{
			by:"HeaderLocaleUniqueWhere",
			filter:"HeaderLocaleWhere"
		},
		listHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		paginateHeaderLocale:{
			filter:"HeaderLocaleWhere",
			orderBy:"HeaderLocaleOrderBy"
		},
		getContent:{
			by:"ContentUniqueWhere",
			filter:"ContentWhere"
		},
		listContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		paginateContent:{
			filter:"ContentWhere",
			orderBy:"ContentOrderBy"
		},
		getContentBlock:{
			by:"ContentBlockUniqueWhere",
			filter:"ContentBlockWhere"
		},
		listContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		paginateContentBlock:{
			filter:"ContentBlockWhere",
			orderBy:"ContentBlockOrderBy"
		},
		getContentReference:{
			by:"ContentReferenceUniqueWhere",
			filter:"ContentReferenceWhere"
		},
		listContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		paginateContentReference:{
			filter:"ContentReferenceWhere",
			orderBy:"ContentReferenceOrderBy"
		},
		getVideo:{
			by:"VideoUniqueWhere",
			filter:"VideoWhere"
		},
		listVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		paginateVideo:{
			filter:"VideoWhere",
			orderBy:"VideoOrderBy"
		},
		getButtonLink:{
			by:"ButtonLinkUniqueWhere",
			filter:"ButtonLinkWhere"
		},
		listButtonLink:{
			filter:"ButtonLinkWhere",
			orderBy:"ButtonLinkOrderBy"
		},
		paginateButtonLink:{
			filter:"ButtonLinkWhere",
			orderBy:"ButtonLinkOrderBy"
		},
		getFeature:{
			by:"FeatureUniqueWhere",
			filter:"FeatureWhere"
		},
		listFeature:{
			filter:"FeatureWhere",
			orderBy:"FeatureOrderBy"
		},
		paginateFeature:{
			filter:"FeatureWhere",
			orderBy:"FeatureOrderBy"
		},
		getFeatureItem:{
			by:"FeatureItemUniqueWhere",
			filter:"FeatureItemWhere"
		},
		listFeatureItem:{
			filter:"FeatureItemWhere",
			orderBy:"FeatureItemOrderBy"
		},
		paginateFeatureItem:{
			filter:"FeatureItemWhere",
			orderBy:"FeatureItemOrderBy"
		},
		getFeatureList:{
			by:"FeatureListUniqueWhere",
			filter:"FeatureListWhere"
		},
		listFeatureList:{
			filter:"FeatureListWhere",
			orderBy:"FeatureListOrderBy"
		},
		paginateFeatureList:{
			filter:"FeatureListWhere",
			orderBy:"FeatureListOrderBy"
		},
		getLinkItem:{
			by:"LinkItemUniqueWhere",
			filter:"LinkItemWhere"
		},
		listLinkItem:{
			filter:"LinkItemWhere",
			orderBy:"LinkItemOrderBy"
		},
		paginateLinkItem:{
			filter:"LinkItemWhere",
			orderBy:"LinkItemOrderBy"
		},
		getLinkList:{
			by:"LinkListUniqueWhere",
			filter:"LinkListWhere"
		},
		listLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		paginateLinkList:{
			filter:"LinkListWhere",
			orderBy:"LinkListOrderBy"
		},
		getFooter:{
			by:"FooterUniqueWhere",
			filter:"FooterWhere"
		},
		listFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		paginateFooter:{
			filter:"FooterWhere",
			orderBy:"FooterOrderBy"
		},
		getFooterGroupLinkList:{
			by:"FooterGroupLinkListUniqueWhere",
			filter:"FooterGroupLinkListWhere"
		},
		listFooterGroupLinkList:{
			filter:"FooterGroupLinkListWhere",
			orderBy:"FooterGroupLinkListOrderBy"
		},
		paginateFooterGroupLinkList:{
			filter:"FooterGroupLinkListWhere",
			orderBy:"FooterGroupLinkListOrderBy"
		},
		getFooterLocale:{
			by:"FooterLocaleUniqueWhere",
			filter:"FooterLocaleWhere"
		},
		listFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		paginateFooterLocale:{
			filter:"FooterLocaleWhere",
			orderBy:"FooterLocaleOrderBy"
		},
		getGenericPageSideNavigation:{
			by:"GenericPageSideNavigationUniqueWhere",
			filter:"GenericPageSideNavigationWhere"
		},
		listGenericPageSideNavigation:{
			filter:"GenericPageSideNavigationWhere",
			orderBy:"GenericPageSideNavigationOrderBy"
		},
		paginateGenericPageSideNavigation:{
			filter:"GenericPageSideNavigationWhere",
			orderBy:"GenericPageSideNavigationOrderBy"
		},
		getProjection:{
			by:"ProjectionUniqueWhere",
			filter:"ProjectionWhere"
		},
		listProjection:{
			filter:"ProjectionWhere",
			orderBy:"ProjectionOrderBy"
		},
		paginateProjection:{
			filter:"ProjectionWhere",
			orderBy:"ProjectionOrderBy"
		},
		getProjectionContent:{
			by:"ProjectionContentUniqueWhere",
			filter:"ProjectionContentWhere"
		},
		listProjectionContent:{
			filter:"ProjectionContentWhere",
			orderBy:"ProjectionContentOrderBy"
		},
		paginateProjectionContent:{
			filter:"ProjectionContentWhere",
			orderBy:"ProjectionContentOrderBy"
		},
		getProjectionItem:{
			by:"ProjectionItemUniqueWhere",
			filter:"ProjectionItemWhere"
		},
		listProjectionItem:{
			filter:"ProjectionItemWhere",
			orderBy:"ProjectionItemOrderBy"
		},
		paginateProjectionItem:{
			filter:"ProjectionItemWhere",
			orderBy:"ProjectionItemOrderBy"
		},
		getContentImageWithFeatures:{
			by:"ContentImageWithFeaturesUniqueWhere",
			filter:"ContentImageWithFeaturesWhere"
		},
		listContentImageWithFeatures:{
			filter:"ContentImageWithFeaturesWhere",
			orderBy:"ContentImageWithFeaturesOrderBy"
		},
		paginateContentImageWithFeatures:{
			filter:"ContentImageWithFeaturesWhere",
			orderBy:"ContentImageWithFeaturesOrderBy"
		},
		getAsideContentMedia:{
			by:"AsideContentMediaUniqueWhere",
			filter:"AsideContentMediaWhere"
		},
		listAsideContentMedia:{
			filter:"AsideContentMediaWhere",
			orderBy:"AsideContentMediaOrderBy"
		},
		paginateAsideContentMedia:{
			filter:"AsideContentMediaWhere",
			orderBy:"AsideContentMediaOrderBy"
		},
		getAsideContentMediaSpecification:{
			by:"AsideContentMediaSpecificationUniqueWhere",
			filter:"AsideContentMediaSpecificationWhere"
		},
		listAsideContentMediaSpecification:{
			filter:"AsideContentMediaSpecificationWhere",
			orderBy:"AsideContentMediaSpecificationOrderBy"
		},
		paginateAsideContentMediaSpecification:{
			filter:"AsideContentMediaSpecificationWhere",
			orderBy:"AsideContentMediaSpecificationOrderBy"
		},
		getFile:{
			by:"FileUniqueWhere",
			filter:"FileWhere"
		},
		listFile:{
			filter:"FileWhere",
			orderBy:"FileOrderBy"
		},
		paginateFile:{
			filter:"FileWhere",
			orderBy:"FileOrderBy"
		},
		getImageList:{
			by:"ImageListUniqueWhere",
			filter:"ImageListWhere"
		},
		listImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		paginateImageList:{
			filter:"ImageListWhere",
			orderBy:"ImageListOrderBy"
		},
		getImageListItem:{
			by:"ImageListItemUniqueWhere",
			filter:"ImageListItemWhere"
		},
		listImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		paginateImageListItem:{
			filter:"ImageListItemWhere",
			orderBy:"ImageListItemOrderBy"
		},
		getProduct:{
			by:"ProductUniqueWhere",
			filter:"ProductWhere"
		},
		listProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		paginateProduct:{
			filter:"ProductWhere",
			orderBy:"ProductOrderBy"
		},
		getProductCategory:{
			by:"ProductCategoryUniqueWhere",
			filter:"ProductCategoryWhere"
		},
		listProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		paginateProductCategory:{
			filter:"ProductCategoryWhere",
			orderBy:"ProductCategoryOrderBy"
		},
		getProductCategoryLocale:{
			by:"ProductCategoryLocaleUniqueWhere",
			filter:"ProductCategoryLocaleWhere"
		},
		listProductCategoryLocale:{
			filter:"ProductCategoryLocaleWhere",
			orderBy:"ProductCategoryLocaleOrderBy"
		},
		paginateProductCategoryLocale:{
			filter:"ProductCategoryLocaleWhere",
			orderBy:"ProductCategoryLocaleOrderBy"
		},
		getProductLocale:{
			by:"ProductLocaleUniqueWhere",
			filter:"ProductLocaleWhere"
		},
		listProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		paginateProductLocale:{
			filter:"ProductLocaleWhere",
			orderBy:"ProductLocaleOrderBy"
		},
		getProductCategoryItem:{
			by:"ProductCategoryItemUniqueWhere",
			filter:"ProductCategoryItemWhere"
		},
		listProductCategoryItem:{
			filter:"ProductCategoryItemWhere",
			orderBy:"ProductCategoryItemOrderBy"
		},
		paginateProductCategoryItem:{
			filter:"ProductCategoryItemWhere",
			orderBy:"ProductCategoryItemOrderBy"
		},
		getProductCategoryList:{
			by:"ProductCategoryListUniqueWhere",
			filter:"ProductCategoryListWhere"
		},
		listProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		paginateProductCategoryList:{
			filter:"ProductCategoryListWhere",
			orderBy:"ProductCategoryListOrderBy"
		},
		getFeatureSection:{
			by:"FeatureSectionUniqueWhere",
			filter:"FeatureSectionWhere"
		},
		listFeatureSection:{
			filter:"FeatureSectionWhere",
			orderBy:"FeatureSectionOrderBy"
		},
		paginateFeatureSection:{
			filter:"FeatureSectionWhere",
			orderBy:"FeatureSectionOrderBy"
		},
		getFeatureSectionHeat:{
			by:"FeatureSectionHeatUniqueWhere",
			filter:"FeatureSectionHeatWhere"
		},
		listFeatureSectionHeat:{
			filter:"FeatureSectionHeatWhere",
			orderBy:"FeatureSectionHeatOrderBy"
		},
		paginateFeatureSectionHeat:{
			filter:"FeatureSectionHeatWhere",
			orderBy:"FeatureSectionHeatOrderBy"
		},
		getFeatureSectionPump:{
			by:"FeatureSectionPumpUniqueWhere",
			filter:"FeatureSectionPumpWhere"
		},
		listFeatureSectionPump:{
			filter:"FeatureSectionPumpWhere",
			orderBy:"FeatureSectionPumpOrderBy"
		},
		paginateFeatureSectionPump:{
			filter:"FeatureSectionPumpWhere",
			orderBy:"FeatureSectionPumpOrderBy"
		},
		getFeatureSectionTemperature:{
			by:"FeatureSectionTemperatureUniqueWhere",
			filter:"FeatureSectionTemperatureWhere"
		},
		listFeatureSectionTemperature:{
			filter:"FeatureSectionTemperatureWhere",
			orderBy:"FeatureSectionTemperatureOrderBy"
		},
		paginateFeatureSectionTemperature:{
			filter:"FeatureSectionTemperatureWhere",
			orderBy:"FeatureSectionTemperatureOrderBy"
		},
		getFeatureSectionTube:{
			by:"FeatureSectionTubeUniqueWhere",
			filter:"FeatureSectionTubeWhere"
		},
		listFeatureSectionTube:{
			filter:"FeatureSectionTubeWhere",
			orderBy:"FeatureSectionTubeOrderBy"
		},
		paginateFeatureSectionTube:{
			filter:"FeatureSectionTubeWhere",
			orderBy:"FeatureSectionTubeOrderBy"
		},
		getFeatureSectionAntimer:{
			by:"FeatureSectionAntimerUniqueWhere",
			filter:"FeatureSectionAntimerWhere"
		},
		listFeatureSectionAntimer:{
			filter:"FeatureSectionAntimerWhere",
			orderBy:"FeatureSectionAntimerOrderBy"
		},
		paginateFeatureSectionAntimer:{
			filter:"FeatureSectionAntimerWhere",
			orderBy:"FeatureSectionAntimerOrderBy"
		},
		getRedirect:{
			by:"RedirectUniqueWhere",
			filter:"RedirectWhere"
		},
		listRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		paginateRedirect:{
			filter:"RedirectWhere",
			orderBy:"RedirectOrderBy"
		},
		getGeneral:{
			by:"GeneralUniqueWhere",
			filter:"GeneralWhere"
		},
		listGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		paginateGeneral:{
			filter:"GeneralWhere",
			orderBy:"GeneralOrderBy"
		},
		getGeneralLocale:{
			by:"GeneralLocaleUniqueWhere",
			filter:"GeneralLocaleWhere"
		},
		listGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		paginateGeneralLocale:{
			filter:"GeneralLocaleWhere",
			orderBy:"GeneralLocaleOrderBy"
		},
		getProductFeature:{
			by:"ProductFeatureUniqueWhere",
			filter:"ProductFeatureWhere"
		},
		listProductFeature:{
			filter:"ProductFeatureWhere",
			orderBy:"ProductFeatureOrderBy"
		},
		paginateProductFeature:{
			filter:"ProductFeatureWhere",
			orderBy:"ProductFeatureOrderBy"
		},
		getProductFeatureLocale:{
			by:"ProductFeatureLocaleUniqueWhere",
			filter:"ProductFeatureLocaleWhere"
		},
		listProductFeatureLocale:{
			filter:"ProductFeatureLocaleWhere",
			orderBy:"ProductFeatureLocaleOrderBy"
		},
		paginateProductFeatureLocale:{
			filter:"ProductFeatureLocaleWhere",
			orderBy:"ProductFeatureLocaleOrderBy"
		},
		getFilledOrderForm:{
			by:"FilledOrderFormUniqueWhere",
			filter:"FilledOrderFormWhere"
		},
		listFilledOrderForm:{
			filter:"FilledOrderFormWhere",
			orderBy:"FilledOrderFormOrderBy"
		},
		paginateFilledOrderForm:{
			filter:"FilledOrderFormWhere",
			orderBy:"FilledOrderFormOrderBy"
		},
		validateCreateFilledOrderForm:{
			data:"FilledOrderFormCreateInput"
		},
		validateUpdateFilledOrderForm:{
			by:"FilledOrderFormUniqueWhere",
			data:"FilledOrderFormUpdateInput"
		},
		getOrderPage:{
			by:"OrderPageUniqueWhere",
			filter:"OrderPageWhere"
		},
		listOrderPage:{
			filter:"OrderPageWhere",
			orderBy:"OrderPageOrderBy"
		},
		paginateOrderPage:{
			filter:"OrderPageWhere",
			orderBy:"OrderPageOrderBy"
		},
		getOrderPageLocale:{
			by:"OrderPageLocaleUniqueWhere",
			filter:"OrderPageLocaleWhere"
		},
		listOrderPageLocale:{
			filter:"OrderPageLocaleWhere",
			orderBy:"OrderPageLocaleOrderBy"
		},
		paginateOrderPageLocale:{
			filter:"OrderPageLocaleWhere",
			orderBy:"OrderPageLocaleOrderBy"
		},
		getFilledOrderFormEmailAddress:{
			by:"FilledOrderFormEmailAddressUniqueWhere",
			filter:"FilledOrderFormEmailAddressWhere"
		},
		listFilledOrderFormEmailAddress:{
			filter:"FilledOrderFormEmailAddressWhere",
			orderBy:"FilledOrderFormEmailAddressOrderBy"
		},
		paginateFilledOrderFormEmailAddress:{
			filter:"FilledOrderFormEmailAddressWhere",
			orderBy:"FilledOrderFormEmailAddressOrderBy"
		},
		getTranslationCatalogue:{
			by:"TranslationCatalogueUniqueWhere",
			filter:"TranslationCatalogueWhere"
		},
		listTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		paginateTranslationCatalogue:{
			filter:"TranslationCatalogueWhere",
			orderBy:"TranslationCatalogueOrderBy"
		},
		getTranslationCataloguesIdentifier:{
			by:"TranslationCataloguesIdentifierUniqueWhere",
			filter:"TranslationCataloguesIdentifierWhere"
		},
		listTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		paginateTranslationCataloguesIdentifier:{
			filter:"TranslationCataloguesIdentifierWhere",
			orderBy:"TranslationCataloguesIdentifierOrderBy"
		},
		getTranslationDomain:{
			by:"TranslationDomainUniqueWhere",
			filter:"TranslationDomainWhere"
		},
		listTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		paginateTranslationDomain:{
			filter:"TranslationDomainWhere",
			orderBy:"TranslationDomainOrderBy"
		},
		getTranslationKey:{
			by:"TranslationKeyUniqueWhere",
			filter:"TranslationKeyWhere"
		},
		listTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		paginateTranslationKey:{
			filter:"TranslationKeyWhere",
			orderBy:"TranslationKeyOrderBy"
		},
		getTranslationValue:{
			by:"TranslationValueUniqueWhere",
			filter:"TranslationValueWhere"
		},
		listTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		paginateTranslationValue:{
			filter:"TranslationValueWhere",
			orderBy:"TranslationValueOrderBy"
		},
		getFilledOrderFormCountry:{
			by:"FilledOrderFormCountryUniqueWhere",
			filter:"FilledOrderFormCountryWhere"
		},
		listFilledOrderFormCountry:{
			filter:"FilledOrderFormCountryWhere",
			orderBy:"FilledOrderFormCountryOrderBy"
		},
		paginateFilledOrderFormCountry:{
			filter:"FilledOrderFormCountryWhere",
			orderBy:"FilledOrderFormCountryOrderBy"
		},
		getFilledProductWaitlistForm:{
			by:"FilledProductWaitlistFormUniqueWhere",
			filter:"FilledProductWaitlistFormWhere"
		},
		listFilledProductWaitlistForm:{
			filter:"FilledProductWaitlistFormWhere",
			orderBy:"FilledProductWaitlistFormOrderBy"
		},
		paginateFilledProductWaitlistForm:{
			filter:"FilledProductWaitlistFormWhere",
			orderBy:"FilledProductWaitlistFormOrderBy"
		},
		validateCreateFilledProductWaitlistForm:{
			data:"FilledProductWaitlistFormCreateInput"
		},
		validateUpdateFilledProductWaitlistForm:{
			by:"FilledProductWaitlistFormUniqueWhere",
			data:"FilledProductWaitlistFormUpdateInput"
		}
	},
	Mutation:{
		createFilledOrderForm:{
			data:"FilledOrderFormCreateInput"
		},
		updateFilledOrderForm:{
			by:"FilledOrderFormUniqueWhere",
			filter:"FilledOrderFormWhere",
			data:"FilledOrderFormUpdateInput"
		},
		upsertFilledOrderForm:{
			by:"FilledOrderFormUniqueWhere",
			filter:"FilledOrderFormWhere",
			update:"FilledOrderFormUpdateInput",
			create:"FilledOrderFormCreateInput"
		},
		createFilledProductWaitlistForm:{
			data:"FilledProductWaitlistFormCreateInput"
		},
		updateFilledProductWaitlistForm:{
			by:"FilledProductWaitlistFormUniqueWhere",
			filter:"FilledProductWaitlistFormWhere",
			data:"FilledProductWaitlistFormUpdateInput"
		},
		upsertFilledProductWaitlistForm:{
			by:"FilledProductWaitlistFormUniqueWhere",
			filter:"FilledProductWaitlistFormWhere",
			update:"FilledProductWaitlistFormUpdateInput",
			create:"FilledProductWaitlistFormCreateInput"
		},
		transaction:{
			options:"MutationTransactionOptions"
		}
	},
	_MutationErrorType: "enum" as const,
	MutationTransaction:{
		createFilledOrderForm:{
			data:"FilledOrderFormCreateInput"
		},
		updateFilledOrderForm:{
			by:"FilledOrderFormUniqueWhere",
			filter:"FilledOrderFormWhere",
			data:"FilledOrderFormUpdateInput"
		},
		upsertFilledOrderForm:{
			by:"FilledOrderFormUniqueWhere",
			filter:"FilledOrderFormWhere",
			update:"FilledOrderFormUpdateInput",
			create:"FilledOrderFormCreateInput"
		},
		createFilledProductWaitlistForm:{
			data:"FilledProductWaitlistFormCreateInput"
		},
		updateFilledProductWaitlistForm:{
			by:"FilledProductWaitlistFormUniqueWhere",
			filter:"FilledProductWaitlistFormWhere",
			data:"FilledProductWaitlistFormUpdateInput"
		},
		upsertFilledProductWaitlistForm:{
			by:"FilledProductWaitlistFormUniqueWhere",
			filter:"FilledProductWaitlistFormWhere",
			update:"FilledProductWaitlistFormUpdateInput",
			create:"FilledProductWaitlistFormCreateInput"
		}
	},
	MutationTransactionOptions:{

	},
	Json: `scalar.Json` as const,
	_OnDeleteBehaviour: "enum" as const,
	_RelationSide: "enum" as const,
	_OrderByDirection: "enum" as const
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getGenericPage:"GenericPage",
		listGenericPage:"GenericPage",
		paginateGenericPage:"GenericPageConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getGenericPageLocale:"GenericPageLocale",
		listGenericPageLocale:"GenericPageLocale",
		paginateGenericPageLocale:"GenericPageLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getHeader:"Header",
		listHeader:"Header",
		paginateHeader:"HeaderConnection",
		getHeaderLocale:"HeaderLocale",
		listHeaderLocale:"HeaderLocale",
		paginateHeaderLocale:"HeaderLocaleConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getButtonLink:"ButtonLink",
		listButtonLink:"ButtonLink",
		paginateButtonLink:"ButtonLinkConnection",
		getFeature:"Feature",
		listFeature:"Feature",
		paginateFeature:"FeatureConnection",
		getFeatureItem:"FeatureItem",
		listFeatureItem:"FeatureItem",
		paginateFeatureItem:"FeatureItemConnection",
		getFeatureList:"FeatureList",
		listFeatureList:"FeatureList",
		paginateFeatureList:"FeatureListConnection",
		getLinkItem:"LinkItem",
		listLinkItem:"LinkItem",
		paginateLinkItem:"LinkItemConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getFooter:"Footer",
		listFooter:"Footer",
		paginateFooter:"FooterConnection",
		getFooterGroupLinkList:"FooterGroupLinkList",
		listFooterGroupLinkList:"FooterGroupLinkList",
		paginateFooterGroupLinkList:"FooterGroupLinkListConnection",
		getFooterLocale:"FooterLocale",
		listFooterLocale:"FooterLocale",
		paginateFooterLocale:"FooterLocaleConnection",
		getGenericPageSideNavigation:"GenericPageSideNavigation",
		listGenericPageSideNavigation:"GenericPageSideNavigation",
		paginateGenericPageSideNavigation:"GenericPageSideNavigationConnection",
		getProjection:"Projection",
		listProjection:"Projection",
		paginateProjection:"ProjectionConnection",
		getProjectionContent:"ProjectionContent",
		listProjectionContent:"ProjectionContent",
		paginateProjectionContent:"ProjectionContentConnection",
		getProjectionItem:"ProjectionItem",
		listProjectionItem:"ProjectionItem",
		paginateProjectionItem:"ProjectionItemConnection",
		getContentImageWithFeatures:"ContentImageWithFeatures",
		listContentImageWithFeatures:"ContentImageWithFeatures",
		paginateContentImageWithFeatures:"ContentImageWithFeaturesConnection",
		getAsideContentMedia:"AsideContentMedia",
		listAsideContentMedia:"AsideContentMedia",
		paginateAsideContentMedia:"AsideContentMediaConnection",
		getAsideContentMediaSpecification:"AsideContentMediaSpecification",
		listAsideContentMediaSpecification:"AsideContentMediaSpecification",
		paginateAsideContentMediaSpecification:"AsideContentMediaSpecificationConnection",
		getFile:"File",
		listFile:"File",
		paginateFile:"FileConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		getProductCategory:"ProductCategory",
		listProductCategory:"ProductCategory",
		paginateProductCategory:"ProductCategoryConnection",
		getProductCategoryLocale:"ProductCategoryLocale",
		listProductCategoryLocale:"ProductCategoryLocale",
		paginateProductCategoryLocale:"ProductCategoryLocaleConnection",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		getProductCategoryItem:"ProductCategoryItem",
		listProductCategoryItem:"ProductCategoryItem",
		paginateProductCategoryItem:"ProductCategoryItemConnection",
		getProductCategoryList:"ProductCategoryList",
		listProductCategoryList:"ProductCategoryList",
		paginateProductCategoryList:"ProductCategoryListConnection",
		getFeatureSection:"FeatureSection",
		listFeatureSection:"FeatureSection",
		paginateFeatureSection:"FeatureSectionConnection",
		getFeatureSectionHeat:"FeatureSectionHeat",
		listFeatureSectionHeat:"FeatureSectionHeat",
		paginateFeatureSectionHeat:"FeatureSectionHeatConnection",
		getFeatureSectionPump:"FeatureSectionPump",
		listFeatureSectionPump:"FeatureSectionPump",
		paginateFeatureSectionPump:"FeatureSectionPumpConnection",
		getFeatureSectionTemperature:"FeatureSectionTemperature",
		listFeatureSectionTemperature:"FeatureSectionTemperature",
		paginateFeatureSectionTemperature:"FeatureSectionTemperatureConnection",
		getFeatureSectionTube:"FeatureSectionTube",
		listFeatureSectionTube:"FeatureSectionTube",
		paginateFeatureSectionTube:"FeatureSectionTubeConnection",
		getFeatureSectionAntimer:"FeatureSectionAntimer",
		listFeatureSectionAntimer:"FeatureSectionAntimer",
		paginateFeatureSectionAntimer:"FeatureSectionAntimerConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getGeneral:"General",
		listGeneral:"General",
		paginateGeneral:"GeneralConnection",
		getGeneralLocale:"GeneralLocale",
		listGeneralLocale:"GeneralLocale",
		paginateGeneralLocale:"GeneralLocaleConnection",
		getProductFeature:"ProductFeature",
		listProductFeature:"ProductFeature",
		paginateProductFeature:"ProductFeatureConnection",
		getProductFeatureLocale:"ProductFeatureLocale",
		listProductFeatureLocale:"ProductFeatureLocale",
		paginateProductFeatureLocale:"ProductFeatureLocaleConnection",
		getFilledOrderForm:"FilledOrderForm",
		listFilledOrderForm:"FilledOrderForm",
		paginateFilledOrderForm:"FilledOrderFormConnection",
		validateCreateFilledOrderForm:"_ValidationResult",
		validateUpdateFilledOrderForm:"_ValidationResult",
		getOrderPage:"OrderPage",
		listOrderPage:"OrderPage",
		paginateOrderPage:"OrderPageConnection",
		getOrderPageLocale:"OrderPageLocale",
		listOrderPageLocale:"OrderPageLocale",
		paginateOrderPageLocale:"OrderPageLocaleConnection",
		getFilledOrderFormEmailAddress:"FilledOrderFormEmailAddress",
		listFilledOrderFormEmailAddress:"FilledOrderFormEmailAddress",
		paginateFilledOrderFormEmailAddress:"FilledOrderFormEmailAddressConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		getFilledOrderFormCountry:"FilledOrderFormCountry",
		listFilledOrderFormCountry:"FilledOrderFormCountry",
		paginateFilledOrderFormCountry:"FilledOrderFormCountryConnection",
		getFilledProductWaitlistForm:"FilledProductWaitlistForm",
		listFilledProductWaitlistForm:"FilledProductWaitlistForm",
		paginateFilledProductWaitlistForm:"FilledProductWaitlistFormConnection",
		validateCreateFilledProductWaitlistForm:"_ValidationResult",
		validateUpdateFilledProductWaitlistForm:"_ValidationResult",
		transaction:"QueryTransaction",
		_info:"Info",
		schema:"_Schema"
	},
	Image:{
		_meta:"ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		alt:"String",
		fileName:"String"
	},
	ImageMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		alt:"FieldMeta",
		fileName:"FieldMeta"
	},
	FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	UUID: `scalar.UUID` as const,
	ImageConnection:{
		pageInfo:"PageInfo",
		edges:"ImageEdge"
	},
	PageInfo:{
		totalCount:"Int"
	},
	ImageEdge:{
		node:"Image"
	},
	GenericPage:{
		_meta:"GenericPageMeta",
		id:"UUID",
		locales:"GenericPageLocale",
		isSideNavigation:"Boolean",
		localesByLocale:"GenericPageLocale",
		localesBySeo:"GenericPageLocale",
		localesByLink:"GenericPageLocale",
		localesByContent:"GenericPageLocale",
		localesBySideNavigationItems:"GenericPageLocale",
		paginateLocales:"GenericPageLocaleConnection"
	},
	GenericPageMeta:{
		id:"FieldMeta",
		locales:"FieldMeta",
		isSideNavigation:"FieldMeta"
	},
	GenericPageLocale:{
		_meta:"GenericPageLocaleMeta",
		id:"UUID",
		title:"String",
		root:"GenericPage",
		locale:"Locale",
		seo:"Seo",
		link:"Linkable",
		content:"Content",
		sideNavigationItems:"GenericPageSideNavigation",
		paginateSideNavigationItems:"GenericPageSideNavigationConnection"
	},
	GenericPageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta",
		link:"FieldMeta",
		content:"FieldMeta",
		sideNavigationItems:"FieldMeta"
	},
	Locale:{
		_meta:"LocaleMeta",
		id:"UUID",
		code:"String",
		label:"String",
		genericPages:"GenericPageLocale",
		headers:"HeaderLocale",
		footers:"FooterLocale",
		productCategories:"ProductCategoryLocale",
		products:"ProductLocale",
		generals:"GeneralLocale",
		currency:"String",
		productFeatureItems:"ProductFeatureLocale",
		orderPages:"OrderPageLocale",
		genericPagesByRoot:"GenericPageLocale",
		genericPagesBySeo:"GenericPageLocale",
		genericPagesByLink:"GenericPageLocale",
		genericPagesByContent:"GenericPageLocale",
		genericPagesBySideNavigationItems:"GenericPageLocale",
		headersByRoot:"HeaderLocale",
		footersByRoot:"FooterLocale",
		footersByGroupsLinkList:"FooterLocale",
		productCategoriesByRoot:"ProductCategoryLocale",
		productsByRoot:"ProductLocale",
		generalsByRoot:"GeneralLocale",
		generalsBySeo:"GeneralLocale",
		productFeatureItemsByRoot:"ProductFeatureLocale",
		orderPagesByRoot:"OrderPageLocale",
		orderPagesByLink:"OrderPageLocale",
		orderPagesBySeo:"OrderPageLocale",
		paginateGenericPages:"GenericPageLocaleConnection",
		paginateHeaders:"HeaderLocaleConnection",
		paginateFooters:"FooterLocaleConnection",
		paginateProductCategories:"ProductCategoryLocaleConnection",
		paginateProducts:"ProductLocaleConnection",
		paginateGenerals:"GeneralLocaleConnection",
		paginateProductFeatureItems:"ProductFeatureLocaleConnection",
		paginateOrderPages:"OrderPageLocaleConnection"
	},
	LocaleMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		label:"FieldMeta",
		genericPages:"FieldMeta",
		headers:"FieldMeta",
		footers:"FieldMeta",
		productCategories:"FieldMeta",
		products:"FieldMeta",
		generals:"FieldMeta",
		currency:"FieldMeta",
		productFeatureItems:"FieldMeta",
		orderPages:"FieldMeta"
	},
	HeaderLocale:{
		_meta:"HeaderLocaleMeta",
		id:"UUID",
		root:"Header",
		locale:"Locale",
		specialLink:"Link"
	},
	HeaderLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		specialLink:"FieldMeta"
	},
	Header:{
		_meta:"HeaderMeta",
		id:"UUID",
		unique:"One",
		logo:"Image",
		locales:"HeaderLocale",
		localesByLocale:"HeaderLocale",
		paginateLocales:"HeaderLocaleConnection"
	},
	HeaderMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		logo:"FieldMeta",
		locales:"FieldMeta"
	},
	HeaderLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"HeaderLocaleEdge"
	},
	HeaderLocaleEdge:{
		node:"HeaderLocale"
	},
	Link:{
		_meta:"LinkMeta",
		id:"UUID",
		type:"LinkType",
		title:"String",
		externalLink:"String",
		internalLink:"Linkable",
		scrollToTargetId:"String",
		file:"File"
	},
	LinkMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		externalLink:"FieldMeta",
		internalLink:"FieldMeta",
		scrollToTargetId:"FieldMeta",
		file:"FieldMeta"
	},
	Linkable:{
		_meta:"LinkableMeta",
		id:"UUID",
		url:"String",
		genericPage:"GenericPageLocale",
		redirect:"Redirect",
		orderPage:"OrderPageLocale"
	},
	LinkableMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		genericPage:"FieldMeta",
		redirect:"FieldMeta",
		orderPage:"FieldMeta"
	},
	Redirect:{
		_meta:"RedirectMeta",
		id:"UUID",
		link:"Linkable",
		target:"Link"
	},
	RedirectMeta:{
		id:"FieldMeta",
		link:"FieldMeta",
		target:"FieldMeta"
	},
	OrderPageLocale:{
		_meta:"OrderPageLocaleMeta",
		id:"UUID",
		title:"String",
		link:"Linkable",
		root:"OrderPage",
		locale:"Locale",
		seo:"Seo",
		termsAndConditions:"String"
	},
	OrderPageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		link:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta",
		termsAndConditions:"FieldMeta"
	},
	OrderPage:{
		_meta:"OrderPageMeta",
		id:"UUID",
		unique:"One",
		locales:"OrderPageLocale",
		logo:"Image",
		localesByLocale:"OrderPageLocale",
		localesByLink:"OrderPageLocale",
		localesBySeo:"OrderPageLocale",
		paginateLocales:"OrderPageLocaleConnection"
	},
	OrderPageMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta",
		logo:"FieldMeta"
	},
	OrderPageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"OrderPageLocaleEdge"
	},
	OrderPageLocaleEdge:{
		node:"OrderPageLocale"
	},
	Seo:{
		_meta:"SeoMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		noIndex:"Boolean",
		noFollow:"Boolean",
		ogImage:"Image"
	},
	SeoMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		ogTitle:"FieldMeta",
		ogDescription:"FieldMeta",
		noIndex:"FieldMeta",
		noFollow:"FieldMeta",
		ogImage:"FieldMeta"
	},
	File:{
		_meta:"FileMeta",
		id:"UUID",
		url:"String",
		size:"Int",
		type:"String",
		label:"String",
		fileName:"String"
	},
	FileMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		label:"FieldMeta",
		fileName:"FieldMeta"
	},
	FooterLocale:{
		_meta:"FooterLocaleMeta",
		id:"UUID",
		copyright:"String",
		groupsLinkList:"FooterGroupLinkList",
		root:"Footer",
		locale:"Locale",
		contact:"String",
		groupsLinkListByLinkList:"FooterGroupLinkList",
		paginateGroupsLinkList:"FooterGroupLinkListConnection"
	},
	FooterLocaleMeta:{
		id:"FieldMeta",
		copyright:"FieldMeta",
		groupsLinkList:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		contact:"FieldMeta"
	},
	FooterGroupLinkList:{
		_meta:"FooterGroupLinkListMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		footer:"FooterLocale",
		linkList:"LinkList",
		isAddress:"Boolean",
		addressValue:"String",
		addressLink:"Link"
	},
	FooterGroupLinkListMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		title:"FieldMeta",
		footer:"FieldMeta",
		linkList:"FieldMeta",
		isAddress:"FieldMeta",
		addressValue:"FieldMeta",
		addressLink:"FieldMeta"
	},
	LinkList:{
		_meta:"LinkListMeta",
		id:"UUID",
		items:"LinkItem",
		itemsByLink:"LinkItem",
		paginateItems:"LinkItemConnection"
	},
	LinkListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	LinkItem:{
		_meta:"LinkItemMeta",
		id:"UUID",
		order:"Int",
		list:"LinkList",
		link:"Link"
	},
	LinkItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		link:"FieldMeta"
	},
	LinkItemConnection:{
		pageInfo:"PageInfo",
		edges:"LinkItemEdge"
	},
	LinkItemEdge:{
		node:"LinkItem"
	},
	Footer:{
		_meta:"FooterMeta",
		id:"UUID",
		unique:"One",
		logo:"Image",
		locales:"FooterLocale",
		logoCompany:"Image",
		localesByLocale:"FooterLocale",
		localesByGroupsLinkList:"FooterLocale",
		paginateLocales:"FooterLocaleConnection"
	},
	FooterMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		logo:"FieldMeta",
		locales:"FieldMeta",
		logoCompany:"FieldMeta"
	},
	FooterLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"FooterLocaleEdge"
	},
	FooterLocaleEdge:{
		node:"FooterLocale"
	},
	FooterGroupLinkListConnection:{
		pageInfo:"PageInfo",
		edges:"FooterGroupLinkListEdge"
	},
	FooterGroupLinkListEdge:{
		node:"FooterGroupLinkList"
	},
	ProductCategoryLocale:{
		_meta:"ProductCategoryLocaleMeta",
		id:"UUID",
		name:"String",
		root:"ProductCategory",
		locale:"Locale"
	},
	ProductCategoryLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	ProductCategory:{
		_meta:"ProductCategoryMeta",
		id:"UUID",
		order:"Int",
		products:"Product",
		locales:"ProductCategoryLocale",
		productsBySlug:"Product",
		productsByLocales:"Product",
		localesByLocale:"ProductCategoryLocale",
		paginateProducts:"ProductConnection",
		paginateLocales:"ProductCategoryLocaleConnection"
	},
	ProductCategoryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		products:"FieldMeta",
		locales:"FieldMeta"
	},
	Product:{
		_meta:"ProductMeta",
		id:"UUID",
		order:"Int",
		miningPower:"String",
		image:"Image",
		category:"ProductCategory",
		tileImage:"Image",
		locales:"ProductLocale",
		isForSale:"Boolean",
		features:"ProductFeature",
		slug:"String",
		localesByLocale:"ProductLocale",
		paginateLocales:"ProductLocaleConnection",
		paginateFeatures:"ProductFeatureConnection"
	},
	ProductMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		miningPower:"FieldMeta",
		image:"FieldMeta",
		category:"FieldMeta",
		tileImage:"FieldMeta",
		locales:"FieldMeta",
		isForSale:"FieldMeta",
		features:"FieldMeta",
		slug:"FieldMeta"
	},
	ProductLocale:{
		_meta:"ProductLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		root:"Product",
		locale:"Locale",
		price:"Int",
		buttonLabel:"String",
		isCustomButtonLabel:"Boolean",
		discountedPrice:"Int"
	},
	ProductLocaleMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		description:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		price:"FieldMeta",
		buttonLabel:"FieldMeta",
		isCustomButtonLabel:"FieldMeta",
		discountedPrice:"FieldMeta"
	},
	ProductFeature:{
		_meta:"ProductFeatureMeta",
		id:"UUID",
		order:"Int",
		locales:"ProductFeatureLocale",
		localesByLocale:"ProductFeatureLocale",
		paginateLocales:"ProductFeatureLocaleConnection"
	},
	ProductFeatureMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		locales:"FieldMeta"
	},
	ProductFeatureLocale:{
		_meta:"ProductFeatureLocaleMeta",
		id:"UUID",
		price:"Int",
		root:"ProductFeature",
		locale:"Locale",
		title:"String"
	},
	ProductFeatureLocaleMeta:{
		id:"FieldMeta",
		price:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		title:"FieldMeta"
	},
	ProductFeatureLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductFeatureLocaleEdge"
	},
	ProductFeatureLocaleEdge:{
		node:"ProductFeatureLocale"
	},
	ProductLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductLocaleEdge"
	},
	ProductLocaleEdge:{
		node:"ProductLocale"
	},
	ProductFeatureConnection:{
		pageInfo:"PageInfo",
		edges:"ProductFeatureEdge"
	},
	ProductFeatureEdge:{
		node:"ProductFeature"
	},
	ProductConnection:{
		pageInfo:"PageInfo",
		edges:"ProductEdge"
	},
	ProductEdge:{
		node:"Product"
	},
	ProductCategoryLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryLocaleEdge"
	},
	ProductCategoryLocaleEdge:{
		node:"ProductCategoryLocale"
	},
	GeneralLocale:{
		_meta:"GeneralLocaleMeta",
		id:"UUID",
		root:"General",
		locale:"Locale",
		seo:"Seo"
	},
	GeneralLocaleMeta:{
		id:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		seo:"FieldMeta"
	},
	General:{
		_meta:"GeneralMeta",
		id:"UUID",
		unique:"One",
		locales:"GeneralLocale",
		localesByLocale:"GeneralLocale",
		localesBySeo:"GeneralLocale",
		paginateLocales:"GeneralLocaleConnection"
	},
	GeneralMeta:{
		id:"FieldMeta",
		unique:"FieldMeta",
		locales:"FieldMeta"
	},
	GeneralLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"GeneralLocaleEdge"
	},
	GeneralLocaleEdge:{
		node:"GeneralLocale"
	},
	GenericPageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"GenericPageLocaleEdge"
	},
	GenericPageLocaleEdge:{
		node:"GenericPageLocale"
	},
	Content:{
		_meta:"ContentMeta",
		id:"UUID",
		blocks:"ContentBlock",
		blocksByReferences:"ContentBlock",
		paginateBlocks:"ContentBlockConnection"
	},
	ContentMeta:{
		id:"FieldMeta",
		blocks:"FieldMeta"
	},
	ContentBlock:{
		_meta:"ContentBlockMeta",
		id:"UUID",
		order:"Int",
		json:"String",
		content:"Content",
		references:"ContentReference",
		referencesByLink:"ContentReference",
		referencesByLinkTwo:"ContentReference",
		referencesByButtonLink:"ContentReference",
		referencesByButtonLinkTwo:"ContentReference",
		referencesByFeatureList:"ContentReference",
		referencesByProjection:"ContentReference",
		referencesByContentImageWithFeatures:"ContentReference",
		referencesByAsideContentMedia:"ContentReference",
		referencesByImageList:"ContentReference",
		referencesByProductCategories:"ContentReference",
		referencesByFeatureSection:"ContentReference",
		paginateReferences:"ContentReferenceConnection"
	},
	ContentBlockMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		json:"FieldMeta",
		content:"FieldMeta",
		references:"FieldMeta"
	},
	ContentReference:{
		_meta:"ContentReferenceMeta",
		id:"UUID",
		type:"ContentReferenceType",
		block:"ContentBlock",
		primaryText:"String",
		link:"Link",
		linkTwo:"Link",
		buttonLink:"ButtonLink",
		buttonLinkTwo:"ButtonLink",
		featureList:"FeatureList",
		projection:"Projection",
		contentImageWithFeatures:"ContentImageWithFeatures",
		asideContentMedia:"AsideContentMedia",
		imageList:"ImageList",
		productCategories:"ProductCategoryList",
		image:"Image",
		secondaryText:"String",
		callToActionTypes:"CallToActionType",
		secondaryImage:"Image",
		featureSection:"FeatureSection",
		terciaryImage:"Image",
		video:"Video",
		isImageWithWhiteShadow:"Boolean",
		secondaryVideo:"Video"
	},
	ContentReferenceMeta:{
		id:"FieldMeta",
		type:"FieldMeta",
		block:"FieldMeta",
		primaryText:"FieldMeta",
		link:"FieldMeta",
		linkTwo:"FieldMeta",
		buttonLink:"FieldMeta",
		buttonLinkTwo:"FieldMeta",
		featureList:"FieldMeta",
		projection:"FieldMeta",
		contentImageWithFeatures:"FieldMeta",
		asideContentMedia:"FieldMeta",
		imageList:"FieldMeta",
		productCategories:"FieldMeta",
		image:"FieldMeta",
		secondaryText:"FieldMeta",
		callToActionTypes:"FieldMeta",
		secondaryImage:"FieldMeta",
		featureSection:"FieldMeta",
		terciaryImage:"FieldMeta",
		video:"FieldMeta",
		isImageWithWhiteShadow:"FieldMeta",
		secondaryVideo:"FieldMeta"
	},
	ButtonLink:{
		_meta:"ButtonLinkMeta",
		id:"UUID",
		visualType:"ButtonLinkVisualType",
		link:"Link",
		fillType:"ButtonLinkFillType",
		widthType:"ButtonLinkWidthType"
	},
	ButtonLinkMeta:{
		id:"FieldMeta",
		visualType:"FieldMeta",
		link:"FieldMeta",
		fillType:"FieldMeta",
		widthType:"FieldMeta"
	},
	FeatureList:{
		_meta:"FeatureListMeta",
		id:"UUID",
		items:"FeatureItem",
		paginateItems:"FeatureItemConnection"
	},
	FeatureListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	FeatureItem:{
		_meta:"FeatureItemMeta",
		id:"UUID",
		order:"Int",
		list:"FeatureList",
		feature:"Feature"
	},
	FeatureItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		feature:"FieldMeta"
	},
	Feature:{
		_meta:"FeatureMeta",
		id:"UUID",
		title:"String",
		icon:"Image",
		text:"String"
	},
	FeatureMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		icon:"FieldMeta",
		text:"FieldMeta"
	},
	FeatureItemConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureItemEdge"
	},
	FeatureItemEdge:{
		node:"FeatureItem"
	},
	Projection:{
		_meta:"ProjectionMeta",
		id:"UUID",
		items:"ProjectionItem",
		paginateItems:"ProjectionItemConnection"
	},
	ProjectionMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ProjectionItem:{
		_meta:"ProjectionItemMeta",
		id:"UUID",
		order:"Int",
		list:"Projection",
		item:"ProjectionContent"
	},
	ProjectionItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		item:"FieldMeta"
	},
	ProjectionContent:{
		_meta:"ProjectionContentMeta",
		id:"UUID",
		title:"String",
		text:"String",
		image:"Image"
	},
	ProjectionContentMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		text:"FieldMeta",
		image:"FieldMeta"
	},
	ProjectionItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProjectionItemEdge"
	},
	ProjectionItemEdge:{
		node:"ProjectionItem"
	},
	ContentImageWithFeatures:{
		_meta:"ContentImageWithFeaturesMeta",
		id:"UUID",
		subtitle:"String",
		title:"String",
		text:"String",
		image:"Image",
		features:"FeatureList"
	},
	ContentImageWithFeaturesMeta:{
		id:"FieldMeta",
		subtitle:"FieldMeta",
		title:"FieldMeta",
		text:"FieldMeta",
		image:"FieldMeta",
		features:"FieldMeta"
	},
	AsideContentMedia:{
		_meta:"AsideContentMediaMeta",
		id:"UUID",
		subtitle:"String",
		title:"String",
		media:"Image",
		link:"Link",
		tableSpecifiation:"AsideContentMediaSpecification",
		paginateTableSpecifiation:"AsideContentMediaSpecificationConnection"
	},
	AsideContentMediaMeta:{
		id:"FieldMeta",
		subtitle:"FieldMeta",
		title:"FieldMeta",
		media:"FieldMeta",
		link:"FieldMeta",
		tableSpecifiation:"FieldMeta"
	},
	AsideContentMediaSpecification:{
		_meta:"AsideContentMediaSpecificationMeta",
		id:"UUID",
		order:"Int",
		label:"String",
		value:"String",
		specification:"AsideContentMedia"
	},
	AsideContentMediaSpecificationMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		label:"FieldMeta",
		value:"FieldMeta",
		specification:"FieldMeta"
	},
	AsideContentMediaSpecificationConnection:{
		pageInfo:"PageInfo",
		edges:"AsideContentMediaSpecificationEdge"
	},
	AsideContentMediaSpecificationEdge:{
		node:"AsideContentMediaSpecification"
	},
	ImageList:{
		_meta:"ImageListMeta",
		id:"UUID",
		items:"ImageListItem",
		paginateItems:"ImageListItemConnection"
	},
	ImageListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ImageListItem:{
		_meta:"ImageListItemMeta",
		id:"UUID",
		order:"Int",
		description:"String",
		list:"ImageList",
		image:"Image",
		imageMobile:"Image"
	},
	ImageListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		description:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta",
		imageMobile:"FieldMeta"
	},
	ImageListItemConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListItemEdge"
	},
	ImageListItemEdge:{
		node:"ImageListItem"
	},
	ProductCategoryList:{
		_meta:"ProductCategoryListMeta",
		id:"UUID",
		items:"ProductCategoryItem",
		paginateItems:"ProductCategoryItemConnection"
	},
	ProductCategoryListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	ProductCategoryItem:{
		_meta:"ProductCategoryItemMeta",
		id:"UUID",
		order:"Int",
		list:"ProductCategoryList",
		item:"ProductCategory"
	},
	ProductCategoryItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		item:"FieldMeta"
	},
	ProductCategoryItemConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryItemEdge"
	},
	ProductCategoryItemEdge:{
		node:"ProductCategoryItem"
	},
	FeatureSection:{
		_meta:"FeatureSectionMeta",
		id:"UUID",
		temperature:"FeatureSectionTemperature",
		heat:"FeatureSectionHeat",
		pump:"FeatureSectionPump",
		tube:"FeatureSectionTube",
		antimer:"FeatureSectionAntimer"
	},
	FeatureSectionMeta:{
		id:"FieldMeta",
		temperature:"FieldMeta",
		heat:"FieldMeta",
		pump:"FieldMeta",
		tube:"FieldMeta",
		antimer:"FieldMeta"
	},
	FeatureSectionTemperature:{
		_meta:"FeatureSectionTemperatureMeta",
		id:"UUID",
		label:"String",
		description:"String",
		number:"Int"
	},
	FeatureSectionTemperatureMeta:{
		id:"FieldMeta",
		label:"FieldMeta",
		description:"FieldMeta",
		number:"FieldMeta"
	},
	FeatureSectionHeat:{
		_meta:"FeatureSectionHeatMeta",
		id:"UUID",
		title1:"String",
		titleHighlighted1:"String",
		titleHighlighted2:"String",
		description:"String",
		image:"Image"
	},
	FeatureSectionHeatMeta:{
		id:"FieldMeta",
		title1:"FieldMeta",
		titleHighlighted1:"FieldMeta",
		titleHighlighted2:"FieldMeta",
		description:"FieldMeta",
		image:"FieldMeta"
	},
	FeatureSectionPump:{
		_meta:"FeatureSectionPumpMeta",
		id:"UUID",
		title:"String",
		sideTitle:"String",
		description:"String"
	},
	FeatureSectionPumpMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		sideTitle:"FieldMeta",
		description:"FieldMeta"
	},
	FeatureSectionTube:{
		_meta:"FeatureSectionTubeMeta",
		id:"UUID",
		title:"String",
		description:"String",
		image:"Image",
		secondaryImage:"Image"
	},
	FeatureSectionTubeMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		image:"FieldMeta",
		secondaryImage:"FieldMeta"
	},
	FeatureSectionAntimer:{
		_meta:"FeatureSectionAntimerMeta",
		id:"UUID",
		label1:"String",
		label2:"String",
		description:"String",
		number:"Int",
		image:"Image"
	},
	FeatureSectionAntimerMeta:{
		id:"FieldMeta",
		label1:"FieldMeta",
		label2:"FieldMeta",
		description:"FieldMeta",
		number:"FieldMeta",
		image:"FieldMeta"
	},
	Video:{
		_meta:"VideoMeta",
		id:"UUID",
		src:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		duration:"Float",
		poster:"Image",
		fileName:"String"
	},
	VideoMeta:{
		id:"FieldMeta",
		src:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		duration:"FieldMeta",
		poster:"FieldMeta",
		fileName:"FieldMeta"
	},
	ContentReferenceConnection:{
		pageInfo:"PageInfo",
		edges:"ContentReferenceEdge"
	},
	ContentReferenceEdge:{
		node:"ContentReference"
	},
	ContentBlockConnection:{
		pageInfo:"PageInfo",
		edges:"ContentBlockEdge"
	},
	ContentBlockEdge:{
		node:"ContentBlock"
	},
	GenericPageSideNavigation:{
		_meta:"GenericPageSideNavigationMeta",
		id:"UUID",
		order:"Int",
		list:"GenericPageLocale",
		link:"Link"
	},
	GenericPageSideNavigationMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		link:"FieldMeta"
	},
	GenericPageSideNavigationConnection:{
		pageInfo:"PageInfo",
		edges:"GenericPageSideNavigationEdge"
	},
	GenericPageSideNavigationEdge:{
		node:"GenericPageSideNavigation"
	},
	GenericPageConnection:{
		pageInfo:"PageInfo",
		edges:"GenericPageEdge"
	},
	GenericPageEdge:{
		node:"GenericPage"
	},
	LinkableConnection:{
		pageInfo:"PageInfo",
		edges:"LinkableEdge"
	},
	LinkableEdge:{
		node:"Linkable"
	},
	SeoConnection:{
		pageInfo:"PageInfo",
		edges:"SeoEdge"
	},
	SeoEdge:{
		node:"Seo"
	},
	LocaleConnection:{
		pageInfo:"PageInfo",
		edges:"LocaleEdge"
	},
	LocaleEdge:{
		node:"Locale"
	},
	LinkConnection:{
		pageInfo:"PageInfo",
		edges:"LinkEdge"
	},
	LinkEdge:{
		node:"Link"
	},
	HeaderConnection:{
		pageInfo:"PageInfo",
		edges:"HeaderEdge"
	},
	HeaderEdge:{
		node:"Header"
	},
	ContentConnection:{
		pageInfo:"PageInfo",
		edges:"ContentEdge"
	},
	ContentEdge:{
		node:"Content"
	},
	VideoConnection:{
		pageInfo:"PageInfo",
		edges:"VideoEdge"
	},
	VideoEdge:{
		node:"Video"
	},
	ButtonLinkConnection:{
		pageInfo:"PageInfo",
		edges:"ButtonLinkEdge"
	},
	ButtonLinkEdge:{
		node:"ButtonLink"
	},
	FeatureConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureEdge"
	},
	FeatureEdge:{
		node:"Feature"
	},
	FeatureListConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureListEdge"
	},
	FeatureListEdge:{
		node:"FeatureList"
	},
	LinkListConnection:{
		pageInfo:"PageInfo",
		edges:"LinkListEdge"
	},
	LinkListEdge:{
		node:"LinkList"
	},
	FooterConnection:{
		pageInfo:"PageInfo",
		edges:"FooterEdge"
	},
	FooterEdge:{
		node:"Footer"
	},
	ProjectionConnection:{
		pageInfo:"PageInfo",
		edges:"ProjectionEdge"
	},
	ProjectionEdge:{
		node:"Projection"
	},
	ProjectionContentConnection:{
		pageInfo:"PageInfo",
		edges:"ProjectionContentEdge"
	},
	ProjectionContentEdge:{
		node:"ProjectionContent"
	},
	ContentImageWithFeaturesConnection:{
		pageInfo:"PageInfo",
		edges:"ContentImageWithFeaturesEdge"
	},
	ContentImageWithFeaturesEdge:{
		node:"ContentImageWithFeatures"
	},
	AsideContentMediaConnection:{
		pageInfo:"PageInfo",
		edges:"AsideContentMediaEdge"
	},
	AsideContentMediaEdge:{
		node:"AsideContentMedia"
	},
	FileConnection:{
		pageInfo:"PageInfo",
		edges:"FileEdge"
	},
	FileEdge:{
		node:"File"
	},
	ImageListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListEdge"
	},
	ImageListEdge:{
		node:"ImageList"
	},
	ProductCategoryConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryEdge"
	},
	ProductCategoryEdge:{
		node:"ProductCategory"
	},
	ProductCategoryListConnection:{
		pageInfo:"PageInfo",
		edges:"ProductCategoryListEdge"
	},
	ProductCategoryListEdge:{
		node:"ProductCategoryList"
	},
	FeatureSectionConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureSectionEdge"
	},
	FeatureSectionEdge:{
		node:"FeatureSection"
	},
	FeatureSectionHeatConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureSectionHeatEdge"
	},
	FeatureSectionHeatEdge:{
		node:"FeatureSectionHeat"
	},
	FeatureSectionPumpConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureSectionPumpEdge"
	},
	FeatureSectionPumpEdge:{
		node:"FeatureSectionPump"
	},
	FeatureSectionTemperatureConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureSectionTemperatureEdge"
	},
	FeatureSectionTemperatureEdge:{
		node:"FeatureSectionTemperature"
	},
	FeatureSectionTubeConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureSectionTubeEdge"
	},
	FeatureSectionTubeEdge:{
		node:"FeatureSectionTube"
	},
	FeatureSectionAntimerConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureSectionAntimerEdge"
	},
	FeatureSectionAntimerEdge:{
		node:"FeatureSectionAntimer"
	},
	RedirectConnection:{
		pageInfo:"PageInfo",
		edges:"RedirectEdge"
	},
	RedirectEdge:{
		node:"Redirect"
	},
	GeneralConnection:{
		pageInfo:"PageInfo",
		edges:"GeneralEdge"
	},
	GeneralEdge:{
		node:"General"
	},
	FilledOrderForm:{
		_meta:"FilledOrderFormMeta",
		id:"UUID",
		name:"String",
		email:"String",
		deliveryAddress:"String",
		note:"String",
		productFeatures:"ProductFeature",
		publishedAt:"DateTime",
		product:"Product",
		country:"FilledOrderFormCountry",
		mobile:"String",
		paginateProductFeatures:"ProductFeatureConnection"
	},
	FilledOrderFormMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		email:"FieldMeta",
		deliveryAddress:"FieldMeta",
		note:"FieldMeta",
		productFeatures:"FieldMeta",
		publishedAt:"FieldMeta",
		product:"FieldMeta",
		country:"FieldMeta",
		mobile:"FieldMeta"
	},
	DateTime: `scalar.DateTime` as const,
	FilledOrderFormCountry:{
		_meta:"FilledOrderFormCountryMeta",
		id:"UUID",
		order:"Int",
		code:"String",
		label:"String"
	},
	FilledOrderFormCountryMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		code:"FieldMeta",
		label:"FieldMeta"
	},
	FilledOrderFormConnection:{
		pageInfo:"PageInfo",
		edges:"FilledOrderFormEdge"
	},
	FilledOrderFormEdge:{
		node:"FilledOrderForm"
	},
	_ValidationResult:{
		valid:"Boolean",
		errors:"_ValidationError"
	},
	_ValidationError:{
		path:"_PathFragment",
		message:"_ValidationMessage"
	},
	_PathFragment:{
		"...on _FieldPathFragment":"_FieldPathFragment",
		"...on _IndexPathFragment":"_IndexPathFragment"
	},
	_FieldPathFragment:{
		field:"String"
	},
	_IndexPathFragment:{
		index:"Int",
		alias:"String"
	},
	_ValidationMessage:{
		text:"String"
	},
	OrderPageConnection:{
		pageInfo:"PageInfo",
		edges:"OrderPageEdge"
	},
	OrderPageEdge:{
		node:"OrderPage"
	},
	FilledOrderFormEmailAddress:{
		_meta:"FilledOrderFormEmailAddressMeta",
		id:"UUID",
		order:"Int",
		email:"String"
	},
	FilledOrderFormEmailAddressMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		email:"FieldMeta"
	},
	FilledOrderFormEmailAddressConnection:{
		pageInfo:"PageInfo",
		edges:"FilledOrderFormEmailAddressEdge"
	},
	FilledOrderFormEmailAddressEdge:{
		node:"FilledOrderFormEmailAddress"
	},
	TranslationCatalogue:{
		_meta:"TranslationCatalogueMeta",
		id:"UUID",
		domain:"TranslationDomain",
		identifier:"TranslationCataloguesIdentifier",
		fallback:"TranslationCatalogue",
		values:"TranslationValue",
		valuesByKey:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationCatalogueMeta:{
		id:"FieldMeta",
		domain:"FieldMeta",
		identifier:"FieldMeta",
		fallback:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationDomain:{
		_meta:"TranslationDomainMeta",
		id:"UUID",
		identifier:"String",
		name:"String",
		catalogues:"TranslationCatalogue",
		keys:"TranslationKey",
		cataloguesByIdentifier:"TranslationCatalogue",
		cataloguesByValues:"TranslationCatalogue",
		keysByIdentifier:"TranslationKey",
		keysByValues:"TranslationKey",
		paginateCatalogues:"TranslationCatalogueConnection",
		paginateKeys:"TranslationKeyConnection"
	},
	TranslationDomainMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		name:"FieldMeta",
		catalogues:"FieldMeta",
		keys:"FieldMeta"
	},
	TranslationKey:{
		_meta:"TranslationKeyMeta",
		id:"UUID",
		identifier:"String",
		contentType:"TranslationKeyContentType",
		note:"String",
		domain:"TranslationDomain",
		values:"TranslationValue",
		valuesByCatalogue:"TranslationValue",
		paginateValues:"TranslationValueConnection"
	},
	TranslationKeyMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		contentType:"FieldMeta",
		note:"FieldMeta",
		domain:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationValue:{
		_meta:"TranslationValueMeta",
		id:"UUID",
		value:"String",
		catalogue:"TranslationCatalogue",
		key:"TranslationKey"
	},
	TranslationValueMeta:{
		id:"FieldMeta",
		value:"FieldMeta",
		catalogue:"FieldMeta",
		key:"FieldMeta"
	},
	TranslationValueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationValueEdge"
	},
	TranslationValueEdge:{
		node:"TranslationValue"
	},
	TranslationCatalogueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCatalogueEdge"
	},
	TranslationCatalogueEdge:{
		node:"TranslationCatalogue"
	},
	TranslationKeyConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationKeyEdge"
	},
	TranslationKeyEdge:{
		node:"TranslationKey"
	},
	TranslationCataloguesIdentifier:{
		_meta:"TranslationCataloguesIdentifierMeta",
		id:"UUID",
		name:"String",
		code:"String",
		catalogue:"TranslationCatalogue",
		catalogueByDomain:"TranslationCatalogue",
		catalogueByValues:"TranslationCatalogue",
		paginateCatalogue:"TranslationCatalogueConnection"
	},
	TranslationCataloguesIdentifierMeta:{
		id:"FieldMeta",
		name:"FieldMeta",
		code:"FieldMeta",
		catalogue:"FieldMeta"
	},
	TranslationCataloguesIdentifierConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCataloguesIdentifierEdge"
	},
	TranslationCataloguesIdentifierEdge:{
		node:"TranslationCataloguesIdentifier"
	},
	TranslationDomainConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationDomainEdge"
	},
	TranslationDomainEdge:{
		node:"TranslationDomain"
	},
	FilledOrderFormCountryConnection:{
		pageInfo:"PageInfo",
		edges:"FilledOrderFormCountryEdge"
	},
	FilledOrderFormCountryEdge:{
		node:"FilledOrderFormCountry"
	},
	FilledProductWaitlistForm:{
		_meta:"FilledProductWaitlistFormMeta",
		id:"UUID",
		publishedAt:"DateTime",
		email:"String",
		product:"Product"
	},
	FilledProductWaitlistFormMeta:{
		id:"FieldMeta",
		publishedAt:"FieldMeta",
		email:"FieldMeta",
		product:"FieldMeta"
	},
	FilledProductWaitlistFormConnection:{
		pageInfo:"PageInfo",
		edges:"FilledProductWaitlistFormEdge"
	},
	FilledProductWaitlistFormEdge:{
		node:"FilledProductWaitlistForm"
	},
	QueryTransaction:{
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		getGenericPage:"GenericPage",
		listGenericPage:"GenericPage",
		paginateGenericPage:"GenericPageConnection",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		getGenericPageLocale:"GenericPageLocale",
		listGenericPageLocale:"GenericPageLocale",
		paginateGenericPageLocale:"GenericPageLocaleConnection",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		getHeader:"Header",
		listHeader:"Header",
		paginateHeader:"HeaderConnection",
		getHeaderLocale:"HeaderLocale",
		listHeaderLocale:"HeaderLocale",
		paginateHeaderLocale:"HeaderLocaleConnection",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		getContentReference:"ContentReference",
		listContentReference:"ContentReference",
		paginateContentReference:"ContentReferenceConnection",
		getVideo:"Video",
		listVideo:"Video",
		paginateVideo:"VideoConnection",
		getButtonLink:"ButtonLink",
		listButtonLink:"ButtonLink",
		paginateButtonLink:"ButtonLinkConnection",
		getFeature:"Feature",
		listFeature:"Feature",
		paginateFeature:"FeatureConnection",
		getFeatureItem:"FeatureItem",
		listFeatureItem:"FeatureItem",
		paginateFeatureItem:"FeatureItemConnection",
		getFeatureList:"FeatureList",
		listFeatureList:"FeatureList",
		paginateFeatureList:"FeatureListConnection",
		getLinkItem:"LinkItem",
		listLinkItem:"LinkItem",
		paginateLinkItem:"LinkItemConnection",
		getLinkList:"LinkList",
		listLinkList:"LinkList",
		paginateLinkList:"LinkListConnection",
		getFooter:"Footer",
		listFooter:"Footer",
		paginateFooter:"FooterConnection",
		getFooterGroupLinkList:"FooterGroupLinkList",
		listFooterGroupLinkList:"FooterGroupLinkList",
		paginateFooterGroupLinkList:"FooterGroupLinkListConnection",
		getFooterLocale:"FooterLocale",
		listFooterLocale:"FooterLocale",
		paginateFooterLocale:"FooterLocaleConnection",
		getGenericPageSideNavigation:"GenericPageSideNavigation",
		listGenericPageSideNavigation:"GenericPageSideNavigation",
		paginateGenericPageSideNavigation:"GenericPageSideNavigationConnection",
		getProjection:"Projection",
		listProjection:"Projection",
		paginateProjection:"ProjectionConnection",
		getProjectionContent:"ProjectionContent",
		listProjectionContent:"ProjectionContent",
		paginateProjectionContent:"ProjectionContentConnection",
		getProjectionItem:"ProjectionItem",
		listProjectionItem:"ProjectionItem",
		paginateProjectionItem:"ProjectionItemConnection",
		getContentImageWithFeatures:"ContentImageWithFeatures",
		listContentImageWithFeatures:"ContentImageWithFeatures",
		paginateContentImageWithFeatures:"ContentImageWithFeaturesConnection",
		getAsideContentMedia:"AsideContentMedia",
		listAsideContentMedia:"AsideContentMedia",
		paginateAsideContentMedia:"AsideContentMediaConnection",
		getAsideContentMediaSpecification:"AsideContentMediaSpecification",
		listAsideContentMediaSpecification:"AsideContentMediaSpecification",
		paginateAsideContentMediaSpecification:"AsideContentMediaSpecificationConnection",
		getFile:"File",
		listFile:"File",
		paginateFile:"FileConnection",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		getImageListItem:"ImageListItem",
		listImageListItem:"ImageListItem",
		paginateImageListItem:"ImageListItemConnection",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		getProductCategory:"ProductCategory",
		listProductCategory:"ProductCategory",
		paginateProductCategory:"ProductCategoryConnection",
		getProductCategoryLocale:"ProductCategoryLocale",
		listProductCategoryLocale:"ProductCategoryLocale",
		paginateProductCategoryLocale:"ProductCategoryLocaleConnection",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		getProductCategoryItem:"ProductCategoryItem",
		listProductCategoryItem:"ProductCategoryItem",
		paginateProductCategoryItem:"ProductCategoryItemConnection",
		getProductCategoryList:"ProductCategoryList",
		listProductCategoryList:"ProductCategoryList",
		paginateProductCategoryList:"ProductCategoryListConnection",
		getFeatureSection:"FeatureSection",
		listFeatureSection:"FeatureSection",
		paginateFeatureSection:"FeatureSectionConnection",
		getFeatureSectionHeat:"FeatureSectionHeat",
		listFeatureSectionHeat:"FeatureSectionHeat",
		paginateFeatureSectionHeat:"FeatureSectionHeatConnection",
		getFeatureSectionPump:"FeatureSectionPump",
		listFeatureSectionPump:"FeatureSectionPump",
		paginateFeatureSectionPump:"FeatureSectionPumpConnection",
		getFeatureSectionTemperature:"FeatureSectionTemperature",
		listFeatureSectionTemperature:"FeatureSectionTemperature",
		paginateFeatureSectionTemperature:"FeatureSectionTemperatureConnection",
		getFeatureSectionTube:"FeatureSectionTube",
		listFeatureSectionTube:"FeatureSectionTube",
		paginateFeatureSectionTube:"FeatureSectionTubeConnection",
		getFeatureSectionAntimer:"FeatureSectionAntimer",
		listFeatureSectionAntimer:"FeatureSectionAntimer",
		paginateFeatureSectionAntimer:"FeatureSectionAntimerConnection",
		getRedirect:"Redirect",
		listRedirect:"Redirect",
		paginateRedirect:"RedirectConnection",
		getGeneral:"General",
		listGeneral:"General",
		paginateGeneral:"GeneralConnection",
		getGeneralLocale:"GeneralLocale",
		listGeneralLocale:"GeneralLocale",
		paginateGeneralLocale:"GeneralLocaleConnection",
		getProductFeature:"ProductFeature",
		listProductFeature:"ProductFeature",
		paginateProductFeature:"ProductFeatureConnection",
		getProductFeatureLocale:"ProductFeatureLocale",
		listProductFeatureLocale:"ProductFeatureLocale",
		paginateProductFeatureLocale:"ProductFeatureLocaleConnection",
		getFilledOrderForm:"FilledOrderForm",
		listFilledOrderForm:"FilledOrderForm",
		paginateFilledOrderForm:"FilledOrderFormConnection",
		validateCreateFilledOrderForm:"_ValidationResult",
		validateUpdateFilledOrderForm:"_ValidationResult",
		getOrderPage:"OrderPage",
		listOrderPage:"OrderPage",
		paginateOrderPage:"OrderPageConnection",
		getOrderPageLocale:"OrderPageLocale",
		listOrderPageLocale:"OrderPageLocale",
		paginateOrderPageLocale:"OrderPageLocaleConnection",
		getFilledOrderFormEmailAddress:"FilledOrderFormEmailAddress",
		listFilledOrderFormEmailAddress:"FilledOrderFormEmailAddress",
		paginateFilledOrderFormEmailAddress:"FilledOrderFormEmailAddressConnection",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		getTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		listTranslationCataloguesIdentifier:"TranslationCataloguesIdentifier",
		paginateTranslationCataloguesIdentifier:"TranslationCataloguesIdentifierConnection",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		getFilledOrderFormCountry:"FilledOrderFormCountry",
		listFilledOrderFormCountry:"FilledOrderFormCountry",
		paginateFilledOrderFormCountry:"FilledOrderFormCountryConnection",
		getFilledProductWaitlistForm:"FilledProductWaitlistForm",
		listFilledProductWaitlistForm:"FilledProductWaitlistForm",
		paginateFilledProductWaitlistForm:"FilledProductWaitlistFormConnection",
		validateCreateFilledProductWaitlistForm:"_ValidationResult",
		validateUpdateFilledProductWaitlistForm:"_ValidationResult"
	},
	Info:{
		description:"String"
	},
	Mutation:{
		createFilledOrderForm:"FilledOrderFormCreateResult",
		updateFilledOrderForm:"FilledOrderFormUpdateResult",
		upsertFilledOrderForm:"FilledOrderFormUpsertResult",
		createFilledProductWaitlistForm:"FilledProductWaitlistFormCreateResult",
		updateFilledProductWaitlistForm:"FilledProductWaitlistFormUpdateResult",
		upsertFilledProductWaitlistForm:"FilledProductWaitlistFormUpsertResult",
		transaction:"MutationTransaction",
		query:"Query"
	},
	FilledOrderFormCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FilledOrderForm",
		validation:"_ValidationResult"
	},
	MutationResult:{
		"...on FilledOrderFormCreateResult": "FilledOrderFormCreateResult",
		"...on FilledOrderFormUpdateResult": "FilledOrderFormUpdateResult",
		"...on FilledOrderFormUpsertResult": "FilledOrderFormUpsertResult",
		"...on FilledProductWaitlistFormCreateResult": "FilledProductWaitlistFormCreateResult",
		"...on FilledProductWaitlistFormUpdateResult": "FilledProductWaitlistFormUpdateResult",
		"...on FilledProductWaitlistFormUpsertResult": "FilledProductWaitlistFormUpsertResult",
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError"
	},
	_MutationError:{
		path:"_PathFragment",
		paths:"_PathFragment",
		type:"_MutationErrorType",
		message:"String"
	},
	FilledOrderFormUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FilledOrderForm",
		validation:"_ValidationResult"
	},
	FilledOrderFormUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FilledOrderForm",
		validation:"_ValidationResult"
	},
	FilledProductWaitlistFormCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FilledProductWaitlistForm",
		validation:"_ValidationResult"
	},
	FilledProductWaitlistFormUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FilledProductWaitlistForm",
		validation:"_ValidationResult"
	},
	FilledProductWaitlistFormUpsertResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FilledProductWaitlistForm",
		validation:"_ValidationResult"
	},
	MutationTransaction:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult",
		createFilledOrderForm:"FilledOrderFormCreateResult",
		updateFilledOrderForm:"FilledOrderFormUpdateResult",
		upsertFilledOrderForm:"FilledOrderFormUpsertResult",
		createFilledProductWaitlistForm:"FilledProductWaitlistFormCreateResult",
		updateFilledProductWaitlistForm:"FilledProductWaitlistFormUpdateResult",
		upsertFilledProductWaitlistForm:"FilledProductWaitlistFormUpsertResult",
		query:"Query"
	},
	Json: `scalar.Json` as const,
	_Schema:{
		enums:"_Enum",
		entities:"_Entity"
	},
	_Entity:{
		name:"String",
		customPrimaryAllowed:"Boolean",
		fields:"_Field",
		unique:"_UniqueConstraint"
	},
	_UniqueConstraint:{
		fields:"String"
	},
	_Field:{
		"...on _Column": "_Column",
		"...on _Relation": "_Relation",
		name:"String",
		type:"String",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Column:{
		name:"String",
		type:"String",
		enumName:"String",
		defaultValue:"Json",
		nullable:"Boolean",
		rules:"_Rule",
		validators:"_Validator"
	},
	_OrderBy:{
		path:"String",
		direction:"_OrderByDirection"
	},
	_Relation:{
		name:"String",
		type:"String",
		side:"_RelationSide",
		targetEntity:"String",
		ownedBy:"String",
		inversedBy:"String",
		nullable:"Boolean",
		onDelete:"_OnDeleteBehaviour",
		orphanRemoval:"Boolean",
		orderBy:"_OrderBy",
		rules:"_Rule",
		validators:"_Validator"
	},
	_Rule:{
		message:"_RuleMessage",
		validator:"Int"
	},
	_Validator:{
		operation:"String",
		arguments:"_Argument"
	},
	_Argument:{
		"...on _ValidatorArgument":"_ValidatorArgument",
		"...on _PathArgument":"_PathArgument",
		"...on _LiteralArgument":"_LiteralArgument"
	},
	_ValidatorArgument:{
		validator:"Int"
	},
	_PathArgument:{
		path:"String"
	},
	_LiteralArgument:{
		value:"Json"
	},
	_RuleMessage:{
		text:"String"
	},
	_Enum:{
		name:"String",
		values:"String"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}


export const HOST="Specify host"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])]));
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : Record<string, unknown>
        : never;
    }[keyof DST] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = GraphQLTypes["MutationResult"] | GraphQLTypes["_Field"]
export type ScalarCoders = {
	UUID?: ScalarResolver;
	DateTime?: ScalarResolver;
	Json?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["_PathFragment"] | GraphQLTypes["_Argument"]

export type ValueTypes = {
    ["Query"]: AliasType<{
getImage?: [{	by: ValueTypes["ImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageConnection"]],
getGenericPage?: [{	by: ValueTypes["GenericPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
listGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkableConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoConnection"]],
getGenericPageLocale?: [{	by: ValueTypes["GenericPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LocaleConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkConnection"]],
getHeader?: [{	by: ValueTypes["HeaderUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
listHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Header"]],
paginateHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ValueTypes["HeaderLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["VideoConnection"]],
getButtonLink?: [{	by: ValueTypes["ButtonLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["ButtonLink"]],
listButtonLink?: [{	filter?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ButtonLinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ButtonLink"]],
paginateButtonLink?: [{	filter?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ButtonLinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ButtonLinkConnection"]],
getFeature?: [{	by: ValueTypes["FeatureUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["Feature"]],
listFeature?: [{	filter?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Feature"]],
paginateFeature?: [{	filter?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureConnection"]],
getFeatureItem?: [{	by: ValueTypes["FeatureItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureItem"]],
listFeatureItem?: [{	filter?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureItem"]],
paginateFeatureItem?: [{	filter?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureItemConnection"]],
getFeatureList?: [{	by: ValueTypes["FeatureListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureList"]],
listFeatureList?: [{	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureList"]],
paginateFeatureList?: [{	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureListConnection"]],
getLinkItem?: [{	by: ValueTypes["LinkItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkItem"]],
listLinkItem?: [{	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkItem"]],
paginateLinkItem?: [{	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkItemConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListConnection"]],
getFooter?: [{	by: ValueTypes["FooterUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
listFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
paginateFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterConnection"]],
getFooterGroupLinkList?: [{	by: ValueTypes["FooterGroupLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkList"]],
listFooterGroupLinkList?: [{	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterGroupLinkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkList"]],
paginateFooterGroupLinkList?: [{	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterGroupLinkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkListConnection"]],
getFooterLocale?: [{	by: ValueTypes["FooterLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
getGenericPageSideNavigation?: [{	by: ValueTypes["GenericPageSideNavigationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageSideNavigation"]],
listGenericPageSideNavigation?: [{	filter?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageSideNavigationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageSideNavigation"]],
paginateGenericPageSideNavigation?: [{	filter?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageSideNavigationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageSideNavigationConnection"]],
getProjection?: [{	by: ValueTypes["ProjectionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Projection"]],
listProjection?: [{	filter?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Projection"]],
paginateProjection?: [{	filter?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionConnection"]],
getProjectionContent?: [{	by: ValueTypes["ProjectionContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProjectionContent"]],
listProjectionContent?: [{	filter?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionContentOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionContent"]],
paginateProjectionContent?: [{	filter?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionContentOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionContentConnection"]],
getProjectionItem?: [{	by: ValueTypes["ProjectionItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProjectionItem"]],
listProjectionItem?: [{	filter?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionItem"]],
paginateProjectionItem?: [{	filter?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionItemConnection"]],
getContentImageWithFeatures?: [{	by: ValueTypes["ContentImageWithFeaturesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentImageWithFeatures"]],
listContentImageWithFeatures?: [{	filter?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentImageWithFeaturesOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentImageWithFeatures"]],
paginateContentImageWithFeatures?: [{	filter?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentImageWithFeaturesOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentImageWithFeaturesConnection"]],
getAsideContentMedia?: [{	by: ValueTypes["AsideContentMediaUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>},ValueTypes["AsideContentMedia"]],
listAsideContentMedia?: [{	filter?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMedia"]],
paginateAsideContentMedia?: [{	filter?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaConnection"]],
getAsideContentMediaSpecification?: [{	by: ValueTypes["AsideContentMediaSpecificationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaSpecification"]],
listAsideContentMediaSpecification?: [{	filter?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaSpecification"]],
paginateAsideContentMediaSpecification?: [{	filter?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaSpecificationConnection"]],
getFile?: [{	by: ValueTypes["FileUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>},ValueTypes["File"]],
listFile?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["File"]],
paginateFile?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FileConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
getProduct?: [{	by: ValueTypes["ProductUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
listProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Product"]],
paginateProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductConnection"]],
getProductCategory?: [{	by: ValueTypes["ProductCategoryUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryConnection"]],
getProductCategoryLocale?: [{	by: ValueTypes["ProductCategoryLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocale"]],
listProductCategoryLocale?: [{	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocale"]],
paginateProductCategoryLocale?: [{	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocaleConnection"]],
getProductLocale?: [{	by: ValueTypes["ProductLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductLocaleConnection"]],
getProductCategoryItem?: [{	by: ValueTypes["ProductCategoryItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryItem"]],
listProductCategoryItem?: [{	filter?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryItem"]],
paginateProductCategoryItem?: [{	filter?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryItemConnection"]],
getProductCategoryList?: [{	by: ValueTypes["ProductCategoryListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryListConnection"]],
getFeatureSection?: [{	by: ValueTypes["FeatureSectionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSection"]],
listFeatureSection?: [{	filter?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSection"]],
paginateFeatureSection?: [{	filter?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionConnection"]],
getFeatureSectionHeat?: [{	by: ValueTypes["FeatureSectionHeatUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionHeat"]],
listFeatureSectionHeat?: [{	filter?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionHeatOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionHeat"]],
paginateFeatureSectionHeat?: [{	filter?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionHeatOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionHeatConnection"]],
getFeatureSectionPump?: [{	by: ValueTypes["FeatureSectionPumpUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionPump"]],
listFeatureSectionPump?: [{	filter?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionPumpOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionPump"]],
paginateFeatureSectionPump?: [{	filter?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionPumpOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionPumpConnection"]],
getFeatureSectionTemperature?: [{	by: ValueTypes["FeatureSectionTemperatureUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTemperature"]],
listFeatureSectionTemperature?: [{	filter?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionTemperatureOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTemperature"]],
paginateFeatureSectionTemperature?: [{	filter?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionTemperatureOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTemperatureConnection"]],
getFeatureSectionTube?: [{	by: ValueTypes["FeatureSectionTubeUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTube"]],
listFeatureSectionTube?: [{	filter?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionTubeOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTube"]],
paginateFeatureSectionTube?: [{	filter?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionTubeOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTubeConnection"]],
getFeatureSectionAntimer?: [{	by: ValueTypes["FeatureSectionAntimerUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionAntimer"]],
listFeatureSectionAntimer?: [{	filter?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionAntimerOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionAntimer"]],
paginateFeatureSectionAntimer?: [{	filter?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionAntimerOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionAntimerConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RedirectConnection"]],
getGeneral?: [{	by: ValueTypes["GeneralUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
listGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["General"]],
paginateGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralConnection"]],
getGeneralLocale?: [{	by: ValueTypes["GeneralLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
getProductFeature?: [{	by: ValueTypes["ProductFeatureUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductFeature"]],
listProductFeature?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeature"]],
paginateProductFeature?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureConnection"]],
getProductFeatureLocale?: [{	by: ValueTypes["ProductFeatureLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocale"]],
listProductFeatureLocale?: [{	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocale"]],
paginateProductFeatureLocale?: [{	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocaleConnection"]],
getFilledOrderForm?: [{	by: ValueTypes["FilledOrderFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledOrderForm"]],
listFilledOrderForm?: [{	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderForm"]],
paginateFilledOrderForm?: [{	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormConnection"]],
validateCreateFilledOrderForm?: [{	data: ValueTypes["FilledOrderFormCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateUpdateFilledOrderForm?: [{	by: ValueTypes["FilledOrderFormUniqueWhere"] | Variable<any, string>,	data: ValueTypes["FilledOrderFormUpdateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getOrderPage?: [{	by: ValueTypes["OrderPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPage"]],
listOrderPage?: [{	filter?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPage"]],
paginateOrderPage?: [{	filter?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageConnection"]],
getOrderPageLocale?: [{	by: ValueTypes["OrderPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
listOrderPageLocale?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
paginateOrderPageLocale?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocaleConnection"]],
getFilledOrderFormEmailAddress?: [{	by: ValueTypes["FilledOrderFormEmailAddressUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormEmailAddress"]],
listFilledOrderFormEmailAddress?: [{	filter?: ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormEmailAddressOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormEmailAddress"]],
paginateFilledOrderFormEmailAddress?: [{	filter?: ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormEmailAddressOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormEmailAddressConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationDomainConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValueConnection"]],
getFilledOrderFormCountry?: [{	by: ValueTypes["FilledOrderFormCountryUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormCountry"]],
listFilledOrderFormCountry?: [{	filter?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormCountryOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormCountry"]],
paginateFilledOrderFormCountry?: [{	filter?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormCountryOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormCountryConnection"]],
getFilledProductWaitlistForm?: [{	by: ValueTypes["FilledProductWaitlistFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledProductWaitlistForm"]],
listFilledProductWaitlistForm?: [{	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledProductWaitlistFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FilledProductWaitlistForm"]],
paginateFilledProductWaitlistForm?: [{	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledProductWaitlistFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FilledProductWaitlistFormConnection"]],
validateCreateFilledProductWaitlistForm?: [{	data: ValueTypes["FilledProductWaitlistFormCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateUpdateFilledProductWaitlistForm?: [{	by: ValueTypes["FilledProductWaitlistFormUniqueWhere"] | Variable<any, string>,	data: ValueTypes["FilledProductWaitlistFormUpdateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
	transaction?:ValueTypes["QueryTransaction"],
	_info?:ValueTypes["Info"],
	schema?:ValueTypes["_Schema"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ValueTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
	fileName?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:unknown;
	["ImageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ImageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	alt?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>
};
	["UUIDCondition"]: {
	and?: Array<ValueTypes["UUIDCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["UUIDCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["UUID"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["UUID"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["StringCondition"]: {
	and?: Array<ValueTypes["StringCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["StringCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: string | undefined | null | Variable<any, string>,
	notEq?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	contains?: string | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	containsCI?: string | undefined | null | Variable<any, string>,
	startsWithCI?: string | undefined | null | Variable<any, string>,
	endsWithCI?: string | undefined | null | Variable<any, string>
};
	["IntCondition"]: {
	and?: Array<ValueTypes["IntCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["IntCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: number | undefined | null | Variable<any, string>,
	notEq?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	alt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["OrderDirection"]:OrderDirection;
	["ImageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ValueTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPage"]: AliasType<{
	_meta?:ValueTypes["GenericPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
	isSideNavigation?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["GenericPageLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesBySeo?: [{	by: ValueTypes["GenericPageLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesByLink?: [{	by: ValueTypes["GenericPageLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesByContent?: [{	by: ValueTypes["GenericPageLocalesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
localesBySideNavigationItems?: [{	by: ValueTypes["GenericPageLocalesBySideNavigationItemsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	isSideNavigation?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocale"]: AliasType<{
	_meta?:ValueTypes["GenericPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
sideNavigationItems?: [{	filter?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageSideNavigationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageSideNavigation"]],
paginateSideNavigationItems?: [{	filter?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageSideNavigationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageSideNavigationConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	sideNavigationItems?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	isSideNavigation?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GenericPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GenericPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	sideNavigationItems?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>
};
	["LocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	genericPages?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	headers?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,
	footers?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,
	productCategories?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,
	products?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,
	generals?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,
	currency?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	productFeatureItems?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,
	orderPages?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	specialLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>
};
	["HeaderWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["HeaderWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["HeaderWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>
};
	["OneCondition"]: {
	and?: Array<ValueTypes["OneCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["OneCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["One"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["One"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["One"] | undefined | null | Variable<any, string>
};
	["One"]:One;
	["LinkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["LinkTypeCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	externalLink?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	internalLink?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	scrollToTargetId?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	file?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>
};
	["LinkTypeCondition"]: {
	and?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["LinkType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["LinkType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["LinkType"] | undefined | null | Variable<any, string>
};
	["LinkType"]:LinkType;
	["LinkableWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,
	orderPage?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkableWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>
};
	["RedirectWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["RedirectWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>
};
	["OrderPageLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	termsAndConditions?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["OrderPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["OrderPageLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>
};
	["OrderPageWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["OrderPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["OrderPageWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>
};
	["SeoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ogTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	ogDescription?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	noIndex?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	noFollow?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["SeoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>
};
	["BooleanCondition"]: {
	and?: Array<ValueTypes["BooleanCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["BooleanCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: boolean | undefined | null | Variable<any, string>,
	notEq?: boolean | undefined | null | Variable<any, string>,
	in?: Array<boolean> | undefined | null | Variable<any, string>,
	notIn?: Array<boolean> | undefined | null | Variable<any, string>,
	lt?: boolean | undefined | null | Variable<any, string>,
	lte?: boolean | undefined | null | Variable<any, string>,
	gt?: boolean | undefined | null | Variable<any, string>,
	gte?: boolean | undefined | null | Variable<any, string>
};
	["FileWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FileWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FileWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	copyright?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	groupsLinkList?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	contact?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FooterLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FooterLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>
};
	["FooterGroupLinkListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	footer?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,
	linkList?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,
	isAddress?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	addressValue?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	addressLink?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FooterGroupLinkListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FooterGroupLinkListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>
};
	["LinkListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>
};
	["LinkItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["LinkItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["LinkItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>
};
	["FooterWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,
	logoCompany?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FooterWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FooterWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>
};
	["ProductCategoryLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProductCategoryLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProductCategoryLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>
};
	["ProductCategoryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	products?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProductCategoryWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProductCategoryWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>
};
	["ProductWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	miningPower?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>,
	tileImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,
	isForSale?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	features?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProductWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProductWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>
};
	["ProductLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	price?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	buttonLabel?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	isCustomButtonLabel?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	discountedPrice?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProductLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProductLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>
};
	["ProductFeatureWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProductFeatureWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProductFeatureWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>
};
	["ProductFeatureLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	price?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProductFeatureLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProductFeatureLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>
};
	["GeneralWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OneCondition"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GeneralWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GeneralWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>
};
	["ContentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	blocks?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	json?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,
	references?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentBlockWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null | Variable<any, string>,
	block?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,
	primaryText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	linkTwo?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	buttonLink?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>,
	buttonLinkTwo?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>,
	featureList?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>,
	projection?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>,
	contentImageWithFeatures?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>,
	asideContentMedia?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>,
	imageList?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,
	productCategories?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	secondaryText?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	callToActionTypes?: ValueTypes["CallToActionTypeCondition"] | undefined | null | Variable<any, string>,
	secondaryImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	featureSection?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>,
	terciaryImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	video?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,
	isImageWithWhiteShadow?: ValueTypes["BooleanCondition"] | undefined | null | Variable<any, string>,
	secondaryVideo?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentReferenceTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentReferenceTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ContentReferenceType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ContentReferenceType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ContentReferenceType"] | undefined | null | Variable<any, string>
};
	["ContentReferenceType"]:ContentReferenceType;
	["ButtonLinkWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	visualType?: ValueTypes["ButtonLinkVisualTypeCondition"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	fillType?: ValueTypes["ButtonLinkFillTypeCondition"] | undefined | null | Variable<any, string>,
	widthType?: ValueTypes["ButtonLinkWidthTypeCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ButtonLinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ButtonLinkWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>
};
	["ButtonLinkVisualTypeCondition"]: {
	and?: Array<ValueTypes["ButtonLinkVisualTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ButtonLinkVisualTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ButtonLinkVisualTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ButtonLinkVisualType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ButtonLinkVisualType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ButtonLinkVisualType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ButtonLinkVisualType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ButtonLinkVisualType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ButtonLinkVisualType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ButtonLinkVisualType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ButtonLinkVisualType"] | undefined | null | Variable<any, string>
};
	["ButtonLinkVisualType"]:ButtonLinkVisualType;
	["ButtonLinkFillTypeCondition"]: {
	and?: Array<ValueTypes["ButtonLinkFillTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ButtonLinkFillTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ButtonLinkFillTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ButtonLinkFillType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ButtonLinkFillType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ButtonLinkFillType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ButtonLinkFillType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ButtonLinkFillType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ButtonLinkFillType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ButtonLinkFillType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ButtonLinkFillType"] | undefined | null | Variable<any, string>
};
	["ButtonLinkFillType"]:ButtonLinkFillType;
	["ButtonLinkWidthTypeCondition"]: {
	and?: Array<ValueTypes["ButtonLinkWidthTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ButtonLinkWidthTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ButtonLinkWidthTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["ButtonLinkWidthType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["ButtonLinkWidthType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["ButtonLinkWidthType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["ButtonLinkWidthType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["ButtonLinkWidthType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["ButtonLinkWidthType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["ButtonLinkWidthType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["ButtonLinkWidthType"] | undefined | null | Variable<any, string>
};
	["ButtonLinkWidthType"]:ButtonLinkWidthType;
	["FeatureListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>
};
	["FeatureItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>,
	feature?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>
};
	["FeatureWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>
};
	["ProjectionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProjectionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProjectionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>
};
	["ProjectionItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProjectionItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProjectionItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>
};
	["ProjectionContentWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProjectionContentWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProjectionContentWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>
};
	["ContentImageWithFeaturesWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	subtitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	features?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>
};
	["AsideContentMediaWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	subtitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	media?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	tableSpecifiation?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["AsideContentMediaWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["AsideContentMediaWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>
};
	["AsideContentMediaSpecificationWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	specification?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>
};
	["ImageListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	imageMobile?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ImageListItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>
};
	["ProductCategoryListWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>
};
	["ProductCategoryItemWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["ProductCategoryItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["ProductCategoryItemWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>
};
	["CallToActionTypeCondition"]: {
	and?: Array<ValueTypes["CallToActionTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["CallToActionTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["CallToActionTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["CallToActionType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["CallToActionType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["CallToActionType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["CallToActionType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["CallToActionType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["CallToActionType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["CallToActionType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["CallToActionType"] | undefined | null | Variable<any, string>
};
	["CallToActionType"]:CallToActionType;
	["FeatureSectionWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	temperature?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>,
	heat?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>,
	pump?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>,
	tube?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>,
	antimer?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureSectionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureSectionWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>
};
	["FeatureSectionTemperatureWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	number?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>
};
	["FeatureSectionHeatWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title1?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	titleHighlighted1?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	titleHighlighted2?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureSectionHeatWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureSectionHeatWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>
};
	["FeatureSectionPumpWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	sideTitle?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureSectionPumpWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureSectionPumpWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>
};
	["FeatureSectionTubeWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	secondaryImage?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureSectionTubeWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureSectionTubeWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>
};
	["FeatureSectionAntimerWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	label1?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	label2?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	number?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FeatureSectionAntimerWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FeatureSectionAntimerWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>
};
	["VideoWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	src?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	duration?: ValueTypes["FloatCondition"] | undefined | null | Variable<any, string>,
	poster?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["VideoWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>
};
	["FloatCondition"]: {
	and?: Array<ValueTypes["FloatCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FloatCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FloatCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: number | undefined | null | Variable<any, string>,
	notEq?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>
};
	["GenericPageSideNavigationWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["GenericPageSideNavigationWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["GenericPageSideNavigationWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>
};
	["Locale"]: AliasType<{
	_meta?:ValueTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
genericPages?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
headers?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
footers?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
productCategories?: [{	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocale"]],
products?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductLocale"]],
generals?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
	currency?:boolean | `@${string}`,
productFeatureItems?: [{	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocale"]],
orderPages?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
genericPagesByRoot?: [{	by: ValueTypes["LocaleGenericPagesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesBySeo?: [{	by: ValueTypes["LocaleGenericPagesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesByLink?: [{	by: ValueTypes["LocaleGenericPagesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesByContent?: [{	by: ValueTypes["LocaleGenericPagesByContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
genericPagesBySideNavigationItems?: [{	by: ValueTypes["LocaleGenericPagesBySideNavigationItemsUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
headersByRoot?: [{	by: ValueTypes["LocaleHeadersByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
footersByRoot?: [{	by: ValueTypes["LocaleFootersByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
footersByGroupsLinkList?: [{	by: ValueTypes["LocaleFootersByGroupsLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
productCategoriesByRoot?: [{	by: ValueTypes["LocaleProductCategoriesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocale"]],
productsByRoot?: [{	by: ValueTypes["LocaleProductsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductLocale"]],
generalsByRoot?: [{	by: ValueTypes["LocaleGeneralsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
generalsBySeo?: [{	by: ValueTypes["LocaleGeneralsBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
productFeatureItemsByRoot?: [{	by: ValueTypes["LocaleProductFeatureItemsByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocale"]],
orderPagesByRoot?: [{	by: ValueTypes["LocaleOrderPagesByRootUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
orderPagesByLink?: [{	by: ValueTypes["LocaleOrderPagesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
orderPagesBySeo?: [{	by: ValueTypes["LocaleOrderPagesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
paginateGenericPages?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
paginateHeaders?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
paginateFooters?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
paginateProductCategories?: [{	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocaleConnection"]],
paginateProducts?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductLocaleConnection"]],
paginateGenerals?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
paginateProductFeatureItems?: [{	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocaleConnection"]],
paginateOrderPages?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	genericPages?:ValueTypes["FieldMeta"],
	headers?:ValueTypes["FieldMeta"],
	footers?:ValueTypes["FieldMeta"],
	productCategories?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	generals?:ValueTypes["FieldMeta"],
	currency?:ValueTypes["FieldMeta"],
	productFeatureItems?:ValueTypes["FieldMeta"],
	orderPages?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	isSideNavigation?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	currency?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogDescription?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	noIndex?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	noFollow?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleOrderBy"] | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectOrderBy"] | undefined | null | Variable<any, string>,
	orderPage?: ValueTypes["OrderPageLocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	externalLink?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	internalLink?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	scrollToTargetId?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	file?: ValueTypes["FileOrderBy"] | undefined | null | Variable<any, string>
};
	["FileOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["OrderPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableOrderBy"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["OrderPageOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>,
	termsAndConditions?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["OrderPageOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["HeaderLocale"]: AliasType<{
	_meta?:ValueTypes["HeaderLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
specialLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	specialLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Header"]: AliasType<{
	_meta?:ValueTypes["HeaderMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
locales?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
localesByLocale?: [{	by: ValueTypes["HeaderLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateLocales?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	specialLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	code?: string | undefined | null | Variable<any, string>,
	genericPages?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	headers?: ValueTypes["HeaderLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	footers?: ValueTypes["FooterLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	productCategories?: ValueTypes["ProductCategoryLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	products?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	generals?: ValueTypes["GeneralLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	productFeatureItems?: ValueTypes["ProductFeatureLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	orderPages?: ValueTypes["OrderPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GenericPageUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>,
	sideNavigationItems?: ValueTypes["GenericPageSideNavigationUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["SeoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	ogImage?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkableUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>,
	genericPage?: ValueTypes["GenericPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	redirect?: ValueTypes["RedirectUniqueWhere"] | undefined | null | Variable<any, string>,
	orderPage?: ValueTypes["OrderPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["RedirectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	target?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["OrderPageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["OrderPageUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["OrderPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["OrderPageLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	blocks?: ValueTypes["ContentBlockUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	linkTwo?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>,
	buttonLink?: ValueTypes["ButtonLinkUniqueWhere"] | undefined | null | Variable<any, string>,
	buttonLinkTwo?: ValueTypes["ButtonLinkUniqueWhere"] | undefined | null | Variable<any, string>,
	featureList?: ValueTypes["FeatureListUniqueWhere"] | undefined | null | Variable<any, string>,
	projection?: ValueTypes["ProjectionUniqueWhere"] | undefined | null | Variable<any, string>,
	contentImageWithFeatures?: ValueTypes["ContentImageWithFeaturesUniqueWhere"] | undefined | null | Variable<any, string>,
	asideContentMedia?: ValueTypes["AsideContentMediaUniqueWhere"] | undefined | null | Variable<any, string>,
	imageList?: ValueTypes["ImageListUniqueWhere"] | undefined | null | Variable<any, string>,
	productCategories?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null | Variable<any, string>,
	featureSection?: ValueTypes["FeatureSectionUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ButtonLinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FeatureListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["FeatureItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FeatureItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ProjectionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ProjectionItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProjectionItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ContentImageWithFeaturesUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	features?: ValueTypes["FeatureListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["AsideContentMediaUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	tableSpecifiation?: ValueTypes["AsideContentMediaSpecificationUniqueWhere"] | undefined | null | Variable<any, string>
};
	["AsideContentMediaSpecificationUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ImageListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ImageListItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ImageListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["ProductCategoryListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["ProductCategoryItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductCategoryItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FeatureSectionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	temperature?: ValueTypes["FeatureSectionTemperatureUniqueWhere"] | undefined | null | Variable<any, string>,
	heat?: ValueTypes["FeatureSectionHeatUniqueWhere"] | undefined | null | Variable<any, string>,
	pump?: ValueTypes["FeatureSectionPumpUniqueWhere"] | undefined | null | Variable<any, string>,
	tube?: ValueTypes["FeatureSectionTubeUniqueWhere"] | undefined | null | Variable<any, string>,
	antimer?: ValueTypes["FeatureSectionAntimerUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FeatureSectionTemperatureUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FeatureSectionHeatUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FeatureSectionPumpUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FeatureSectionTubeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FeatureSectionAntimerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["GenericPageSideNavigationUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["HeaderUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["HeaderLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	groupsLinkList?: ValueTypes["FooterGroupLinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["FooterLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterGroupLinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	linkList?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	items?: ValueTypes["LinkItemUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductCategoryUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	products?: ValueTypes["ProductUniqueWhere"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["ProductCategoryLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	slug?: string | undefined | null | Variable<any, string>,
	locales?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["One"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["GeneralLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductFeatureLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductFeatureUniqueWhere"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductFeatureUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	locales?: ValueTypes["ProductFeatureLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["HeaderLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeaderLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleEdge"]: AliasType<{
	node?:ValueTypes["HeaderLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ValueTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
	scrollToTargetId?:boolean | `@${string}`,
file?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>},ValueTypes["File"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	externalLink?:ValueTypes["FieldMeta"],
	internalLink?:ValueTypes["FieldMeta"],
	scrollToTargetId?:ValueTypes["FieldMeta"],
	file?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ValueTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
genericPage?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
redirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
orderPage?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	genericPage?:ValueTypes["FieldMeta"],
	redirect?:ValueTypes["FieldMeta"],
	orderPage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ValueTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
target?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	target?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageLocale"]: AliasType<{
	_meta?:ValueTypes["OrderPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
root?: [{	filter?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPage"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
	termsAndConditions?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderPageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	termsAndConditions?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPage"]: AliasType<{
	_meta?:ValueTypes["OrderPageMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
localesByLocale?: [{	by: ValueTypes["OrderPageLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
localesByLink?: [{	by: ValueTypes["OrderPageLocalesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
localesBySeo?: [{	by: ValueTypes["OrderPageLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["OrderPageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["OrderPageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["OrderPageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageLocaleEdge"]: AliasType<{
	node?:ValueTypes["OrderPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ValueTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
	noIndex?:boolean | `@${string}`,
	noFollow?:boolean | `@${string}`,
ogImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	ogTitle?:ValueTypes["FieldMeta"],
	ogDescription?:ValueTypes["FieldMeta"],
	noIndex?:ValueTypes["FieldMeta"],
	noFollow?:ValueTypes["FieldMeta"],
	ogImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["File"]: AliasType<{
	_meta?:ValueTypes["FileMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	fileName?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocale"]: AliasType<{
	_meta?:ValueTypes["FooterLocaleMeta"],
	id?:boolean | `@${string}`,
	copyright?:boolean | `@${string}`,
groupsLinkList?: [{	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterGroupLinkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkList"]],
root?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	contact?:boolean | `@${string}`,
groupsLinkListByLinkList?: [{	by: ValueTypes["FooterLocaleGroupsLinkListByLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkList"]],
paginateGroupsLinkList?: [{	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterGroupLinkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkListConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	copyright?:ValueTypes["FieldMeta"],
	groupsLinkList?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	contact?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterGroupLinkList"]: AliasType<{
	_meta?:ValueTypes["FooterGroupLinkListMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
footer?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
linkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
	isAddress?:boolean | `@${string}`,
	addressValue?:boolean | `@${string}`,
addressLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterGroupLinkListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	footer?:ValueTypes["FieldMeta"],
	linkList?:ValueTypes["FieldMeta"],
	isAddress?:ValueTypes["FieldMeta"],
	addressValue?:ValueTypes["FieldMeta"],
	addressLink?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkList"]: AliasType<{
	_meta?:ValueTypes["LinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkItem"]],
itemsByLink?: [{	by: ValueTypes["LinkListItemsByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkItem"]],
paginateItems?: [{	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkItem"]: AliasType<{
	_meta?:ValueTypes["LinkItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["LinkListOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["LinkListItemsByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LinkItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkItemEdge"]: AliasType<{
	node?:ValueTypes["LinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["FooterGroupLinkListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	footer?: ValueTypes["FooterLocaleOrderBy"] | undefined | null | Variable<any, string>,
	linkList?: ValueTypes["LinkListOrderBy"] | undefined | null | Variable<any, string>,
	isAddress?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	addressValue?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	addressLink?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	copyright?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["FooterOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	contact?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FooterOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	logo?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	logoCompany?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["Footer"]: AliasType<{
	_meta?:ValueTypes["FooterMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
logo?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
locales?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
logoCompany?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
localesByLocale?: [{	by: ValueTypes["FooterLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
localesByGroupsLinkList?: [{	by: ValueTypes["FooterLocalesByGroupsLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateLocales?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	logo?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	logoCompany?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocalesByGroupsLinkListUniqueWhere"]: {
	groupsLinkList?: ValueTypes["FooterGroupLinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleEdge"]: AliasType<{
	node?:ValueTypes["FooterLocale"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleGroupsLinkListByLinkListUniqueWhere"]: {
	linkList?: ValueTypes["LinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FooterGroupLinkListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterGroupLinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterGroupLinkListEdge"]: AliasType<{
	node?:ValueTypes["FooterGroupLinkList"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategory"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategory"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Product"]],
locales?: [{	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocale"]],
productsBySlug?: [{	by: ValueTypes["ProductCategoryProductsBySlugUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
productsByLocales?: [{	by: ValueTypes["ProductCategoryProductsByLocalesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
localesByLocale?: [{	by: ValueTypes["ProductCategoryLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocale"]],
paginateProducts?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductConnection"]],
paginateLocales?: [{	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	_meta?:ValueTypes["ProductMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	miningPower?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
category?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategory"]],
tileImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
locales?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductLocale"]],
	isForSale?:boolean | `@${string}`,
features?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeature"]],
	slug?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["ProductLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductLocale"]],
paginateLocales?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductLocaleConnection"]],
paginateFeatures?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	miningPower?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	category?:ValueTypes["FieldMeta"],
	tileImage?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	isForSale?:ValueTypes["FieldMeta"],
	features?:ValueTypes["FieldMeta"],
	slug?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocale"]: AliasType<{
	_meta?:ValueTypes["ProductLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	price?:boolean | `@${string}`,
	buttonLabel?:boolean | `@${string}`,
	isCustomButtonLabel?:boolean | `@${string}`,
	discountedPrice?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	price?:ValueTypes["FieldMeta"],
	buttonLabel?:ValueTypes["FieldMeta"],
	isCustomButtonLabel?:ValueTypes["FieldMeta"],
	discountedPrice?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	price?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	buttonLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	isCustomButtonLabel?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	discountedPrice?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ProductOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	miningPower?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	category?: ValueTypes["ProductCategoryOrderBy"] | undefined | null | Variable<any, string>,
	tileImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	isForSale?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	slug?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ProductCategoryOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ProductFeature"]: AliasType<{
	_meta?:ValueTypes["ProductFeatureMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocale"]],
localesByLocale?: [{	by: ValueTypes["ProductFeatureLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocale"]],
paginateLocales?: [{	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureLocale"]: AliasType<{
	_meta?:ValueTypes["ProductFeatureLocaleMeta"],
	id?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductFeature"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	price?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	price?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductFeatureOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ProductFeatureOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ProductFeatureLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductFeatureLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductFeatureLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductFeatureLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductFeatureEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureEdge"]: AliasType<{
	node?:ValueTypes["ProductFeature"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["ProductCategoryOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>
};
	["ProductCategoryProductsBySlugUniqueWhere"]: {
	slug?: string | undefined | null | Variable<any, string>
};
	["ProductCategoryProductsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["ProductLocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductEdge"]: AliasType<{
	node?:ValueTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocale"]: AliasType<{
	_meta?:ValueTypes["GeneralLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
locale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
seo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["General"]: AliasType<{
	_meta?:ValueTypes["GeneralMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
localesByLocale?: [{	by: ValueTypes["GeneralLocalesByLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
localesBySeo?: [{	by: ValueTypes["GeneralLocalesBySeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateLocales?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	unique?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	root?: ValueTypes["GeneralOrderBy"] | undefined | null | Variable<any, string>,
	locale?: ValueTypes["LocaleOrderBy"] | undefined | null | Variable<any, string>,
	seo?: ValueTypes["SeoOrderBy"] | undefined | null | Variable<any, string>
};
	["GeneralOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	unique?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GeneralLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GeneralLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleEdge"]: AliasType<{
	node?:ValueTypes["GeneralLocale"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ValueTypes["GenericPageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGenericPagesBySideNavigationItemsUniqueWhere"]: {
	sideNavigationItems?: ValueTypes["GenericPageSideNavigationUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ValueTypes["HeaderUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ValueTypes["FooterUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleFootersByGroupsLinkListUniqueWhere"]: {
	groupsLinkList?: ValueTypes["FooterGroupLinkListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleProductCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["ProductCategoryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleProductsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGeneralsByRootUniqueWhere"]: {
	root?: ValueTypes["GeneralUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleGeneralsBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleProductFeatureItemsByRootUniqueWhere"]: {
	root?: ValueTypes["ProductFeatureUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleOrderPagesByRootUniqueWhere"]: {
	root?: ValueTypes["OrderPageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleOrderPagesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["LocaleOrderPagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GenericPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleEdge"]: AliasType<{
	node?:ValueTypes["GenericPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ValueTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
blocksByReferences?: [{	by: ValueTypes["ContentBlocksByReferencesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	blocks?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ValueTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
references?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByLink?: [{	by: ValueTypes["ContentBlockReferencesByLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByLinkTwo?: [{	by: ValueTypes["ContentBlockReferencesByLinkTwoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByButtonLink?: [{	by: ValueTypes["ContentBlockReferencesByButtonLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByButtonLinkTwo?: [{	by: ValueTypes["ContentBlockReferencesByButtonLinkTwoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByFeatureList?: [{	by: ValueTypes["ContentBlockReferencesByFeatureListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByProjection?: [{	by: ValueTypes["ContentBlockReferencesByProjectionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByContentImageWithFeatures?: [{	by: ValueTypes["ContentBlockReferencesByContentImageWithFeaturesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByAsideContentMedia?: [{	by: ValueTypes["ContentBlockReferencesByAsideContentMediaUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByImageList?: [{	by: ValueTypes["ContentBlockReferencesByImageListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByProductCategories?: [{	by: ValueTypes["ContentBlockReferencesByProductCategoriesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
referencesByFeatureSection?: [{	by: ValueTypes["ContentBlockReferencesByFeatureSectionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateReferences?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	json?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	references?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ValueTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
block?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
	primaryText?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
linkTwo?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
buttonLink?: [{	filter?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["ButtonLink"]],
buttonLinkTwo?: [{	filter?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["ButtonLink"]],
featureList?: [{	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureList"]],
projection?: [{	filter?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Projection"]],
contentImageWithFeatures?: [{	filter?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentImageWithFeatures"]],
asideContentMedia?: [{	filter?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>},ValueTypes["AsideContentMedia"]],
imageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
productCategories?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryList"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	secondaryText?:boolean | `@${string}`,
	callToActionTypes?:boolean | `@${string}`,
secondaryImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
featureSection?: [{	filter?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSection"]],
terciaryImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
video?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
	isImageWithWhiteShadow?:boolean | `@${string}`,
secondaryVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	block?:ValueTypes["FieldMeta"],
	primaryText?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	linkTwo?:ValueTypes["FieldMeta"],
	buttonLink?:ValueTypes["FieldMeta"],
	buttonLinkTwo?:ValueTypes["FieldMeta"],
	featureList?:ValueTypes["FieldMeta"],
	projection?:ValueTypes["FieldMeta"],
	contentImageWithFeatures?:ValueTypes["FieldMeta"],
	asideContentMedia?:ValueTypes["FieldMeta"],
	imageList?:ValueTypes["FieldMeta"],
	productCategories?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	secondaryText?:ValueTypes["FieldMeta"],
	callToActionTypes?:ValueTypes["FieldMeta"],
	secondaryImage?:ValueTypes["FieldMeta"],
	featureSection?:ValueTypes["FieldMeta"],
	terciaryImage?:ValueTypes["FieldMeta"],
	video?:ValueTypes["FieldMeta"],
	isImageWithWhiteShadow?:ValueTypes["FieldMeta"],
	secondaryVideo?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonLink"]: AliasType<{
	_meta?:ValueTypes["ButtonLinkMeta"],
	id?:boolean | `@${string}`,
	visualType?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
	fillType?:boolean | `@${string}`,
	widthType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ButtonLinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	visualType?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	fillType?:ValueTypes["FieldMeta"],
	widthType?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureList"]: AliasType<{
	_meta?:ValueTypes["FeatureListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureItem"]],
paginateItems?: [{	filter?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureItem"]: AliasType<{
	_meta?:ValueTypes["FeatureItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureList"]],
feature?: [{	filter?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["Feature"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	feature?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Feature"]: AliasType<{
	_meta?:ValueTypes["FeatureMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FeatureMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["FeatureListOrderBy"] | undefined | null | Variable<any, string>,
	feature?: ValueTypes["FeatureOrderBy"] | undefined | null | Variable<any, string>
};
	["FeatureListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FeatureOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FeatureItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureItemEdge"]: AliasType<{
	node?:ValueTypes["FeatureItem"],
		__typename?: boolean | `@${string}`
}>;
	["Projection"]: AliasType<{
	_meta?:ValueTypes["ProjectionMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionItem"]],
paginateItems?: [{	filter?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionItem"]: AliasType<{
	_meta?:ValueTypes["ProjectionItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Projection"]],
item?: [{	filter?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProjectionContent"]],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionContent"]: AliasType<{
	_meta?:ValueTypes["ProjectionContentMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ProjectionOrderBy"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ProjectionContentOrderBy"] | undefined | null | Variable<any, string>
};
	["ProjectionOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ProjectionContentOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["ProjectionItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProjectionItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionItemEdge"]: AliasType<{
	node?:ValueTypes["ProjectionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentImageWithFeatures"]: AliasType<{
	_meta?:ValueTypes["ContentImageWithFeaturesMeta"],
	id?:boolean | `@${string}`,
	subtitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
features?: [{	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureList"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentImageWithFeaturesMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	subtitle?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	features?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMedia"]: AliasType<{
	_meta?:ValueTypes["AsideContentMediaMeta"],
	id?:boolean | `@${string}`,
	subtitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
media?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
tableSpecifiation?: [{	filter?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaSpecification"]],
paginateTableSpecifiation?: [{	filter?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaSpecificationConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	subtitle?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	media?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	tableSpecifiation?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaSpecification"]: AliasType<{
	_meta?:ValueTypes["AsideContentMediaSpecificationMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
specification?: [{	filter?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>},ValueTypes["AsideContentMedia"]],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaSpecificationMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	value?:ValueTypes["FieldMeta"],
	specification?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaSpecificationOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	specification?: ValueTypes["AsideContentMediaOrderBy"] | undefined | null | Variable<any, string>
};
	["AsideContentMediaOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	subtitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	media?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["AsideContentMediaSpecificationConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AsideContentMediaSpecificationEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaSpecificationEdge"]: AliasType<{
	node?:ValueTypes["AsideContentMediaSpecification"],
		__typename?: boolean | `@${string}`
}>;
	["ImageList"]: AliasType<{
	_meta?:ValueTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateItems?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ValueTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
imageMobile?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	imageMobile?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ImageListOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	imageMobile?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ValueTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryList"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryItem"]],
paginateItems?: [{	filter?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryItem"]: AliasType<{
	_meta?:ValueTypes["ProductCategoryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryList"]],
item?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategory"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	item?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryItemOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null | Variable<any, string>,
	item?: ValueTypes["ProductCategoryOrderBy"] | undefined | null | Variable<any, string>
};
	["ProductCategoryListOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ProductCategoryItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryItemEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryItem"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSection"]: AliasType<{
	_meta?:ValueTypes["FeatureSectionMeta"],
	id?:boolean | `@${string}`,
temperature?: [{	filter?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTemperature"]],
heat?: [{	filter?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionHeat"]],
pump?: [{	filter?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionPump"]],
tube?: [{	filter?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTube"]],
antimer?: [{	filter?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionAntimer"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	temperature?:ValueTypes["FieldMeta"],
	heat?:ValueTypes["FieldMeta"],
	pump?:ValueTypes["FieldMeta"],
	tube?:ValueTypes["FieldMeta"],
	antimer?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTemperature"]: AliasType<{
	_meta?:ValueTypes["FeatureSectionTemperatureMeta"],
	id?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	number?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTemperatureMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	number?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionHeat"]: AliasType<{
	_meta?:ValueTypes["FeatureSectionHeatMeta"],
	id?:boolean | `@${string}`,
	title1?:boolean | `@${string}`,
	titleHighlighted1?:boolean | `@${string}`,
	titleHighlighted2?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionHeatMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title1?:ValueTypes["FieldMeta"],
	titleHighlighted1?:ValueTypes["FieldMeta"],
	titleHighlighted2?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionPump"]: AliasType<{
	_meta?:ValueTypes["FeatureSectionPumpMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	sideTitle?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionPumpMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	sideTitle?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTube"]: AliasType<{
	_meta?:ValueTypes["FeatureSectionTubeMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
secondaryImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTubeMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	secondaryImage?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionAntimer"]: AliasType<{
	_meta?:ValueTypes["FeatureSectionAntimerMeta"],
	id?:boolean | `@${string}`,
	label1?:boolean | `@${string}`,
	label2?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	number?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionAntimerMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	label1?:ValueTypes["FieldMeta"],
	label2?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	number?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ValueTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
poster?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
	fileName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	src?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	duration?:ValueTypes["FieldMeta"],
	poster?:ValueTypes["FieldMeta"],
	fileName?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	block?: ValueTypes["ContentBlockOrderBy"] | undefined | null | Variable<any, string>,
	primaryText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	linkTwo?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	buttonLink?: ValueTypes["ButtonLinkOrderBy"] | undefined | null | Variable<any, string>,
	buttonLinkTwo?: ValueTypes["ButtonLinkOrderBy"] | undefined | null | Variable<any, string>,
	featureList?: ValueTypes["FeatureListOrderBy"] | undefined | null | Variable<any, string>,
	projection?: ValueTypes["ProjectionOrderBy"] | undefined | null | Variable<any, string>,
	contentImageWithFeatures?: ValueTypes["ContentImageWithFeaturesOrderBy"] | undefined | null | Variable<any, string>,
	asideContentMedia?: ValueTypes["AsideContentMediaOrderBy"] | undefined | null | Variable<any, string>,
	imageList?: ValueTypes["ImageListOrderBy"] | undefined | null | Variable<any, string>,
	productCategories?: ValueTypes["ProductCategoryListOrderBy"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	secondaryText?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	callToActionTypes?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	secondaryImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	featureSection?: ValueTypes["FeatureSectionOrderBy"] | undefined | null | Variable<any, string>,
	terciaryImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	video?: ValueTypes["VideoOrderBy"] | undefined | null | Variable<any, string>,
	isImageWithWhiteShadow?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	secondaryVideo?: ValueTypes["VideoOrderBy"] | undefined | null | Variable<any, string>
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	json?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	content?: ValueTypes["ContentOrderBy"] | undefined | null | Variable<any, string>
};
	["ButtonLinkOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	visualType?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>,
	fillType?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	widthType?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ContentImageWithFeaturesOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	subtitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	text?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	features?: ValueTypes["FeatureListOrderBy"] | undefined | null | Variable<any, string>
};
	["FeatureSectionOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	temperature?: ValueTypes["FeatureSectionTemperatureOrderBy"] | undefined | null | Variable<any, string>,
	heat?: ValueTypes["FeatureSectionHeatOrderBy"] | undefined | null | Variable<any, string>,
	pump?: ValueTypes["FeatureSectionPumpOrderBy"] | undefined | null | Variable<any, string>,
	tube?: ValueTypes["FeatureSectionTubeOrderBy"] | undefined | null | Variable<any, string>,
	antimer?: ValueTypes["FeatureSectionAntimerOrderBy"] | undefined | null | Variable<any, string>
};
	["FeatureSectionTemperatureOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	number?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FeatureSectionHeatOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title1?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	titleHighlighted1?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	titleHighlighted2?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["FeatureSectionPumpOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	sideTitle?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FeatureSectionTubeOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	title?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	secondaryImage?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["FeatureSectionAntimerOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label1?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label2?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	description?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	number?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	src?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	width?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	height?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	size?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	duration?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	poster?: ValueTypes["ImageOrderBy"] | undefined | null | Variable<any, string>,
	fileName?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByLinkTwoUniqueWhere"]: {
	linkTwo?: ValueTypes["LinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByButtonLinkUniqueWhere"]: {
	buttonLink?: ValueTypes["ButtonLinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByButtonLinkTwoUniqueWhere"]: {
	buttonLinkTwo?: ValueTypes["ButtonLinkUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["FeatureListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByProjectionUniqueWhere"]: {
	projection?: ValueTypes["ProjectionUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByContentImageWithFeaturesUniqueWhere"]: {
	contentImageWithFeatures?: ValueTypes["ContentImageWithFeaturesUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByAsideContentMediaUniqueWhere"]: {
	asideContentMedia?: ValueTypes["AsideContentMediaUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByImageListUniqueWhere"]: {
	imageList?: ValueTypes["ImageListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByProductCategoriesUniqueWhere"]: {
	productCategories?: ValueTypes["ProductCategoryListUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockReferencesByFeatureSectionUniqueWhere"]: {
	featureSection?: ValueTypes["FeatureSectionUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ValueTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ValueTypes["ContentReferenceUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageSideNavigation"]: AliasType<{
	_meta?:ValueTypes["GenericPageSideNavigationMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
link?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageSideNavigationMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageSideNavigationOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	list?: ValueTypes["GenericPageLocaleOrderBy"] | undefined | null | Variable<any, string>,
	link?: ValueTypes["LinkOrderBy"] | undefined | null | Variable<any, string>
};
	["GenericPageSideNavigationConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GenericPageSideNavigationEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageSideNavigationEdge"]: AliasType<{
	node?:ValueTypes["GenericPageSideNavigation"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["LocaleUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["SeoUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["LinkableUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["ContentUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageLocalesBySideNavigationItemsUniqueWhere"]: {
	sideNavigationItems?: ValueTypes["GenericPageSideNavigationUniqueWhere"] | undefined | null | Variable<any, string>
};
	["GenericPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GenericPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageEdge"]: AliasType<{
	node?:ValueTypes["GenericPage"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ValueTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ValueTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ValueTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ValueTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["HeaderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderEdge"]: AliasType<{
	node?:ValueTypes["Header"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ValueTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["VideoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ValueTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonLinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ButtonLinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonLinkEdge"]: AliasType<{
	node?:ValueTypes["ButtonLink"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	icon?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FeatureConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureEdge"]: AliasType<{
	node?:ValueTypes["Feature"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureListEdge"]: AliasType<{
	node?:ValueTypes["FeatureList"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListEdge"]: AliasType<{
	node?:ValueTypes["LinkList"],
		__typename?: boolean | `@${string}`
}>;
	["FooterConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FooterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterEdge"]: AliasType<{
	node?:ValueTypes["Footer"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProjectionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionEdge"]: AliasType<{
	node?:ValueTypes["Projection"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionContentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	image?: ValueTypes["ImageUniqueWhere"] | undefined | null | Variable<any, string>
};
	["ProjectionContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProjectionContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionContentEdge"]: AliasType<{
	node?:ValueTypes["ProjectionContent"],
		__typename?: boolean | `@${string}`
}>;
	["ContentImageWithFeaturesConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentImageWithFeaturesEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentImageWithFeaturesEdge"]: AliasType<{
	node?:ValueTypes["ContentImageWithFeatures"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AsideContentMediaEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaEdge"]: AliasType<{
	node?:ValueTypes["AsideContentMedia"],
		__typename?: boolean | `@${string}`
}>;
	["FileUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FileConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FileEdge"]: AliasType<{
	node?:ValueTypes["File"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ValueTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryEdge"]: AliasType<{
	node?:ValueTypes["ProductCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductCategoryListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListEdge"]: AliasType<{
	node?:ValueTypes["ProductCategoryList"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureSectionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionEdge"]: AliasType<{
	node?:ValueTypes["FeatureSection"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionHeatConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureSectionHeatEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionHeatEdge"]: AliasType<{
	node?:ValueTypes["FeatureSectionHeat"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionPumpConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureSectionPumpEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionPumpEdge"]: AliasType<{
	node?:ValueTypes["FeatureSectionPump"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTemperatureConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureSectionTemperatureEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTemperatureEdge"]: AliasType<{
	node?:ValueTypes["FeatureSectionTemperature"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTubeConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureSectionTubeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTubeEdge"]: AliasType<{
	node?:ValueTypes["FeatureSectionTube"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionAntimerConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureSectionAntimerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionAntimerEdge"]: AliasType<{
	node?:ValueTypes["FeatureSectionAntimer"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ValueTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GeneralEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralEdge"]: AliasType<{
	node?:ValueTypes["General"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderForm"]: AliasType<{
	_meta?:ValueTypes["FilledOrderFormMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	deliveryAddress?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
productFeatures?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeature"]],
	publishedAt?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
country?: [{	filter?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormCountry"]],
	mobile?:boolean | `@${string}`,
paginateProductFeatures?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	deliveryAddress?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	productFeatures?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	country?:ValueTypes["FieldMeta"],
	mobile?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DateTime"]:unknown;
	["FilledOrderFormCountry"]: AliasType<{
	_meta?:ValueTypes["FilledOrderFormCountryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCountryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	label?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCountryWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FilledOrderFormCountryWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FilledOrderFormCountryWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	deliveryAddress?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	productFeatures?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	product?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>,
	mobile?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FilledOrderFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FilledOrderFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>
};
	["DateTimeCondition"]: {
	and?: Array<ValueTypes["DateTimeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["DateTimeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	deliveryAddress?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	product?: ValueTypes["ProductOrderBy"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["FilledOrderFormCountryOrderBy"] | undefined | null | Variable<any, string>,
	mobile?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormCountryOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	label?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FilledOrderFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEdge"]: AliasType<{
	node?:ValueTypes["FilledOrderForm"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ValueTypes["_ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	message?:ValueTypes["_ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["_PathFragment"]: AliasType<{		["...on _FieldPathFragment"] : ValueTypes["_FieldPathFragment"],
		["...on _IndexPathFragment"] : ValueTypes["_IndexPathFragment"]
		__typename?: boolean | `@${string}`
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCreateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	deliveryAddress?: string | undefined | null | Variable<any, string>,
	note?: string | undefined | null | Variable<any, string>,
	productFeatures?: Array<ValueTypes["FilledOrderFormCreateProductFeaturesEntityRelationInput"]> | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	product?: ValueTypes["FilledOrderFormCreateProductEntityRelationInput"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["FilledOrderFormCreateCountryEntityRelationInput"] | undefined | null | Variable<any, string>,
	mobile?: string | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["FilledOrderFormCreateProductFeaturesEntityRelationInput"]: {
	connect?: ValueTypes["ProductFeatureUniqueWhere"] | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>
};
	["FilledOrderFormCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["ProductUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormCreateCountryEntityRelationInput"]: {
	connect?: ValueTypes["FilledOrderFormCountryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormCountryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	code?: string | undefined | null | Variable<any, string>
};
	["FilledOrderFormUpdateInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	deliveryAddress?: string | undefined | null | Variable<any, string>,
	note?: string | undefined | null | Variable<any, string>,
	productFeatures?: Array<ValueTypes["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]> | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	product?: ValueTypes["FilledOrderFormUpdateProductEntityRelationInput"] | undefined | null | Variable<any, string>,
	country?: ValueTypes["FilledOrderFormUpdateCountryEntityRelationInput"] | undefined | null | Variable<any, string>,
	mobile?: string | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]: {
	connect?: ValueTypes["ProductFeatureUniqueWhere"] | undefined | null | Variable<any, string>,
	disconnect?: ValueTypes["ProductFeatureUniqueWhere"] | undefined | null | Variable<any, string>,
	alias?: string | undefined | null | Variable<any, string>
};
	["FilledOrderFormUpdateProductEntityRelationInput"]: {
	connect?: ValueTypes["ProductUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormUpdateCountryEntityRelationInput"]: {
	connect?: ValueTypes["FilledOrderFormCountryUniqueWhere"] | undefined | null | Variable<any, string>
};
	["OrderPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["OrderPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageEdge"]: AliasType<{
	node?:ValueTypes["OrderPage"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEmailAddress"]: AliasType<{
	_meta?:ValueTypes["FilledOrderFormEmailAddressMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEmailAddressMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEmailAddressUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormEmailAddressWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["IntCondition"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormEmailAddressOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	order?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["FilledOrderFormEmailAddressConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FilledOrderFormEmailAddressEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEmailAddressEdge"]: AliasType<{
	node?:ValueTypes["FilledOrderFormEmailAddress"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogue"]: AliasType<{
	_meta?:ValueTypes["TranslationCatalogueMeta"],
	id?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
identifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
fallback?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
valuesByKey?: [{	by: ValueTypes["TranslationCatalogueValuesByKeyUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	fallback?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomain"]: AliasType<{
	_meta?:ValueTypes["TranslationDomainMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
catalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
keys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
cataloguesByIdentifier?: [{	by: ValueTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
cataloguesByValues?: [{	by: ValueTypes["TranslationDomainCataloguesByValuesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
keysByIdentifier?: [{	by: ValueTypes["TranslationDomainKeysByIdentifierUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
keysByValues?: [{	by: ValueTypes["TranslationDomainKeysByValuesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
paginateCatalogues?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogueConnection"]],
paginateKeys?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKeyConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	catalogues?:ValueTypes["FieldMeta"],
	keys?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,
	fallback?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationDomainWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	catalogues?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,
	keys?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationDomainWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>
};
	["TranslationKeyWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["TranslationKeyContentTypeCondition"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,
	values?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationKeyWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>
};
	["TranslationKeyContentTypeCondition"]: {
	and?: Array<ValueTypes["TranslationKeyContentTypeCondition"]> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationKeyContentTypeCondition"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationKeyContentTypeCondition"] | undefined | null | Variable<any, string>,
	null?: boolean | undefined | null | Variable<any, string>,
	isNull?: boolean | undefined | null | Variable<any, string>,
	eq?: ValueTypes["TranslationKeyContentType"] | undefined | null | Variable<any, string>,
	notEq?: ValueTypes["TranslationKeyContentType"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["TranslationKeyContentType"]> | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["TranslationKeyContentType"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["TranslationKeyContentType"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["TranslationKeyContentType"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["TranslationKeyContentType"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["TranslationKeyContentType"] | undefined | null | Variable<any, string>
};
	["TranslationKeyContentType"]:TranslationKeyContentType;
	["TranslationValueWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationValueWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null | Variable<any, string>,
	fallback?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null | Variable<any, string>
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	code?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>
};
	["TranslationKey"]: AliasType<{
	_meta?:ValueTypes["TranslationKeyMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
values?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
valuesByCatalogue?: [{	by: ValueTypes["TranslationKeyValuesByCatalogueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	contentType?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValue"]: AliasType<{
	_meta?:ValueTypes["TranslationValueMeta"],
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
key?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	value?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
	key?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	value?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueOrderBy"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["TranslationKeyOrderBy"] | undefined | null | Variable<any, string>
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	contentType?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	note?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainOrderBy"] | undefined | null | Variable<any, string>
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null | Variable<any, string>,
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null | Variable<any, string>,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationDomainUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	identifier?: string | undefined | null | Variable<any, string>,
	catalogues?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null | Variable<any, string>,
	keys?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationKeyUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null | Variable<any, string>,
	identifier?: string | undefined | null | Variable<any, string>,
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationValueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null | Variable<any, string>,
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,
	code?: string | undefined | null | Variable<any, string>,
	catalogue?: ValueTypes["TranslationCatalogueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationValueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationValueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueEdge"]: AliasType<{
	node?:ValueTypes["TranslationValue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined | null | Variable<any, string>
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCatalogueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueEdge"]: AliasType<{
	node?:ValueTypes["TranslationCatalogue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationKeyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyEdge"]: AliasType<{
	node?:ValueTypes["TranslationKey"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifier"]: AliasType<{
	_meta?:ValueTypes["TranslationCataloguesIdentifierMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
catalogueByDomain?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
catalogueByValues?: [{	by: ValueTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
paginateCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ValueTypes["TranslationDomainUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ValueTypes["TranslationValueUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ValueTypes["TranslationKeyUniqueWhere"] | undefined | null | Variable<any, string>
};
	["TranslationCataloguesIdentifierConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCataloguesIdentifierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierEdge"]: AliasType<{
	node?:ValueTypes["TranslationCataloguesIdentifier"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationDomainEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainEdge"]: AliasType<{
	node?:ValueTypes["TranslationDomain"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCountryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FilledOrderFormCountryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCountryEdge"]: AliasType<{
	node?:ValueTypes["FilledOrderFormCountry"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistForm"]: AliasType<{
	_meta?:ValueTypes["FilledProductWaitlistFormMeta"],
	id?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	publishedAt?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["FilledProductWaitlistFormWhere"]: {
	id?: ValueTypes["UUIDCondition"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["DateTimeCondition"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringCondition"] | undefined | null | Variable<any, string>,
	product?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,
	and?: Array<ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	or?: Array<ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null> | undefined | null | Variable<any, string>,
	not?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>
};
	["FilledProductWaitlistFormOrderBy"]: {
	_random?: boolean | undefined | null | Variable<any, string>,
	_randomSeeded?: number | undefined | null | Variable<any, string>,
	id?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	publishedAt?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["OrderDirection"] | undefined | null | Variable<any, string>,
	product?: ValueTypes["ProductOrderBy"] | undefined | null | Variable<any, string>
};
	["FilledProductWaitlistFormConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FilledProductWaitlistFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormEdge"]: AliasType<{
	node?:ValueTypes["FilledProductWaitlistForm"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormCreateInput"]: {
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	product?: ValueTypes["FilledProductWaitlistFormCreateProductEntityRelationInput"] | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["FilledProductWaitlistFormCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["ProductUniqueWhere"] | undefined | null | Variable<any, string>
};
	["FilledProductWaitlistFormUpdateInput"]: {
	publishedAt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	product?: ValueTypes["FilledProductWaitlistFormUpdateProductEntityRelationInput"] | undefined | null | Variable<any, string>,
	_dummy_field_?: boolean | undefined | null | Variable<any, string>
};
	["FilledProductWaitlistFormUpdateProductEntityRelationInput"]: {
	connect?: ValueTypes["ProductUniqueWhere"] | undefined | null | Variable<any, string>
};
	["QueryTransaction"]: AliasType<{
getImage?: [{	by: ValueTypes["ImageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>},ValueTypes["Image"]],
listImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Image"]],
paginateImage?: [{	filter?: ValueTypes["ImageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageConnection"]],
getGenericPage?: [{	by: ValueTypes["GenericPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
listGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ValueTypes["GenericPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageConnection"]],
getLinkable?: [{	by: ValueTypes["LinkableUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
listLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?: ValueTypes["LinkableWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkableOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkableConnection"]],
getSeo?: [{	by: ValueTypes["SeoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
listSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Seo"]],
paginateSeo?: [{	filter?: ValueTypes["SeoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["SeoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["SeoConnection"]],
getGenericPageLocale?: [{	by: ValueTypes["GenericPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ValueTypes["GenericPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageLocaleConnection"]],
getLocale?: [{	by: ValueTypes["LocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
listLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Locale"]],
paginateLocale?: [{	filter?: ValueTypes["LocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LocaleConnection"]],
getLink?: [{	by: ValueTypes["LinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["Link"]],
listLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Link"]],
paginateLink?: [{	filter?: ValueTypes["LinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkConnection"]],
getHeader?: [{	by: ValueTypes["HeaderUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>},ValueTypes["Header"]],
listHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Header"]],
paginateHeader?: [{	filter?: ValueTypes["HeaderWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ValueTypes["HeaderLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ValueTypes["HeaderLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["HeaderLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["HeaderLocaleConnection"]],
getContent?: [{	by: ValueTypes["ContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["Content"]],
listContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Content"]],
paginateContent?: [{	filter?: ValueTypes["ContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentConnection"]],
getContentBlock?: [{	by: ValueTypes["ContentBlockUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ValueTypes["ContentBlockWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentBlockOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ValueTypes["ContentReferenceUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
listContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ValueTypes["ContentReferenceWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentReferenceOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentReferenceConnection"]],
getVideo?: [{	by: ValueTypes["VideoUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>},ValueTypes["Video"]],
listVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Video"]],
paginateVideo?: [{	filter?: ValueTypes["VideoWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["VideoOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["VideoConnection"]],
getButtonLink?: [{	by: ValueTypes["ButtonLinkUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>},ValueTypes["ButtonLink"]],
listButtonLink?: [{	filter?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ButtonLinkOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ButtonLink"]],
paginateButtonLink?: [{	filter?: ValueTypes["ButtonLinkWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ButtonLinkOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ButtonLinkConnection"]],
getFeature?: [{	by: ValueTypes["FeatureUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["Feature"]],
listFeature?: [{	filter?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Feature"]],
paginateFeature?: [{	filter?: ValueTypes["FeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureConnection"]],
getFeatureItem?: [{	by: ValueTypes["FeatureItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureItem"]],
listFeatureItem?: [{	filter?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureItem"]],
paginateFeatureItem?: [{	filter?: ValueTypes["FeatureItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureItemConnection"]],
getFeatureList?: [{	by: ValueTypes["FeatureListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureList"]],
listFeatureList?: [{	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureList"]],
paginateFeatureList?: [{	filter?: ValueTypes["FeatureListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureListConnection"]],
getLinkItem?: [{	by: ValueTypes["LinkItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkItem"]],
listLinkItem?: [{	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkItem"]],
paginateLinkItem?: [{	filter?: ValueTypes["LinkItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkItemConnection"]],
getLinkList?: [{	by: ValueTypes["LinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
listLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["LinkList"]],
paginateLinkList?: [{	filter?: ValueTypes["LinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["LinkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["LinkListConnection"]],
getFooter?: [{	by: ValueTypes["FooterUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
listFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Footer"]],
paginateFooter?: [{	filter?: ValueTypes["FooterWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterConnection"]],
getFooterGroupLinkList?: [{	by: ValueTypes["FooterGroupLinkListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkList"]],
listFooterGroupLinkList?: [{	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterGroupLinkListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkList"]],
paginateFooterGroupLinkList?: [{	filter?: ValueTypes["FooterGroupLinkListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterGroupLinkListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterGroupLinkListConnection"]],
getFooterLocale?: [{	by: ValueTypes["FooterLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ValueTypes["FooterLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FooterLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FooterLocaleConnection"]],
getGenericPageSideNavigation?: [{	by: ValueTypes["GenericPageSideNavigationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>},ValueTypes["GenericPageSideNavigation"]],
listGenericPageSideNavigation?: [{	filter?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageSideNavigationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageSideNavigation"]],
paginateGenericPageSideNavigation?: [{	filter?: ValueTypes["GenericPageSideNavigationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GenericPageSideNavigationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GenericPageSideNavigationConnection"]],
getProjection?: [{	by: ValueTypes["ProjectionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>},ValueTypes["Projection"]],
listProjection?: [{	filter?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Projection"]],
paginateProjection?: [{	filter?: ValueTypes["ProjectionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionConnection"]],
getProjectionContent?: [{	by: ValueTypes["ProjectionContentUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProjectionContent"]],
listProjectionContent?: [{	filter?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionContentOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionContent"]],
paginateProjectionContent?: [{	filter?: ValueTypes["ProjectionContentWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionContentOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionContentConnection"]],
getProjectionItem?: [{	by: ValueTypes["ProjectionItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProjectionItem"]],
listProjectionItem?: [{	filter?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionItem"]],
paginateProjectionItem?: [{	filter?: ValueTypes["ProjectionItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProjectionItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProjectionItemConnection"]],
getContentImageWithFeatures?: [{	by: ValueTypes["ContentImageWithFeaturesUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>},ValueTypes["ContentImageWithFeatures"]],
listContentImageWithFeatures?: [{	filter?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentImageWithFeaturesOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ContentImageWithFeatures"]],
paginateContentImageWithFeatures?: [{	filter?: ValueTypes["ContentImageWithFeaturesWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ContentImageWithFeaturesOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ContentImageWithFeaturesConnection"]],
getAsideContentMedia?: [{	by: ValueTypes["AsideContentMediaUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>},ValueTypes["AsideContentMedia"]],
listAsideContentMedia?: [{	filter?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMedia"]],
paginateAsideContentMedia?: [{	filter?: ValueTypes["AsideContentMediaWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaConnection"]],
getAsideContentMediaSpecification?: [{	by: ValueTypes["AsideContentMediaSpecificationUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaSpecification"]],
listAsideContentMediaSpecification?: [{	filter?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaSpecification"]],
paginateAsideContentMediaSpecification?: [{	filter?: ValueTypes["AsideContentMediaSpecificationWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["AsideContentMediaSpecificationConnection"]],
getFile?: [{	by: ValueTypes["FileUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>},ValueTypes["File"]],
listFile?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["File"]],
paginateFile?: [{	filter?: ValueTypes["FileWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FileOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FileConnection"]],
getImageList?: [{	by: ValueTypes["ImageListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
listImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?: ValueTypes["ImageListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListConnection"]],
getImageListItem?: [{	by: ValueTypes["ImageListItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ValueTypes["ImageListItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ImageListItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ImageListItemConnection"]],
getProduct?: [{	by: ValueTypes["ProductUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>},ValueTypes["Product"]],
listProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Product"]],
paginateProduct?: [{	filter?: ValueTypes["ProductWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductConnection"]],
getProductCategory?: [{	by: ValueTypes["ProductCategoryUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ValueTypes["ProductCategoryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryConnection"]],
getProductCategoryLocale?: [{	by: ValueTypes["ProductCategoryLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocale"]],
listProductCategoryLocale?: [{	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocale"]],
paginateProductCategoryLocale?: [{	filter?: ValueTypes["ProductCategoryLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryLocaleConnection"]],
getProductLocale?: [{	by: ValueTypes["ProductLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ValueTypes["ProductLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductLocaleConnection"]],
getProductCategoryItem?: [{	by: ValueTypes["ProductCategoryItemUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryItem"]],
listProductCategoryItem?: [{	filter?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryItem"]],
paginateProductCategoryItem?: [{	filter?: ValueTypes["ProductCategoryItemWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryItemOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryItemConnection"]],
getProductCategoryList?: [{	by: ValueTypes["ProductCategoryListUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ValueTypes["ProductCategoryListWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductCategoryListOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductCategoryListConnection"]],
getFeatureSection?: [{	by: ValueTypes["FeatureSectionUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSection"]],
listFeatureSection?: [{	filter?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSection"]],
paginateFeatureSection?: [{	filter?: ValueTypes["FeatureSectionWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionConnection"]],
getFeatureSectionHeat?: [{	by: ValueTypes["FeatureSectionHeatUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionHeat"]],
listFeatureSectionHeat?: [{	filter?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionHeatOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionHeat"]],
paginateFeatureSectionHeat?: [{	filter?: ValueTypes["FeatureSectionHeatWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionHeatOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionHeatConnection"]],
getFeatureSectionPump?: [{	by: ValueTypes["FeatureSectionPumpUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionPump"]],
listFeatureSectionPump?: [{	filter?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionPumpOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionPump"]],
paginateFeatureSectionPump?: [{	filter?: ValueTypes["FeatureSectionPumpWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionPumpOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionPumpConnection"]],
getFeatureSectionTemperature?: [{	by: ValueTypes["FeatureSectionTemperatureUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTemperature"]],
listFeatureSectionTemperature?: [{	filter?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionTemperatureOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTemperature"]],
paginateFeatureSectionTemperature?: [{	filter?: ValueTypes["FeatureSectionTemperatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionTemperatureOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTemperatureConnection"]],
getFeatureSectionTube?: [{	by: ValueTypes["FeatureSectionTubeUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTube"]],
listFeatureSectionTube?: [{	filter?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionTubeOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTube"]],
paginateFeatureSectionTube?: [{	filter?: ValueTypes["FeatureSectionTubeWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionTubeOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionTubeConnection"]],
getFeatureSectionAntimer?: [{	by: ValueTypes["FeatureSectionAntimerUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionAntimer"]],
listFeatureSectionAntimer?: [{	filter?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionAntimerOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionAntimer"]],
paginateFeatureSectionAntimer?: [{	filter?: ValueTypes["FeatureSectionAntimerWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FeatureSectionAntimerOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FeatureSectionAntimerConnection"]],
getRedirect?: [{	by: ValueTypes["RedirectUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
listRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["Redirect"]],
paginateRedirect?: [{	filter?: ValueTypes["RedirectWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["RedirectOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["RedirectConnection"]],
getGeneral?: [{	by: ValueTypes["GeneralUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>},ValueTypes["General"]],
listGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["General"]],
paginateGeneral?: [{	filter?: ValueTypes["GeneralWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralConnection"]],
getGeneralLocale?: [{	by: ValueTypes["GeneralLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ValueTypes["GeneralLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["GeneralLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["GeneralLocaleConnection"]],
getProductFeature?: [{	by: ValueTypes["ProductFeatureUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductFeature"]],
listProductFeature?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeature"]],
paginateProductFeature?: [{	filter?: ValueTypes["ProductFeatureWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureConnection"]],
getProductFeatureLocale?: [{	by: ValueTypes["ProductFeatureLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocale"]],
listProductFeatureLocale?: [{	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocale"]],
paginateProductFeatureLocale?: [{	filter?: ValueTypes["ProductFeatureLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["ProductFeatureLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["ProductFeatureLocaleConnection"]],
getFilledOrderForm?: [{	by: ValueTypes["FilledOrderFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledOrderForm"]],
listFilledOrderForm?: [{	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderForm"]],
paginateFilledOrderForm?: [{	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormConnection"]],
validateCreateFilledOrderForm?: [{	data: ValueTypes["FilledOrderFormCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateUpdateFilledOrderForm?: [{	by: ValueTypes["FilledOrderFormUniqueWhere"] | Variable<any, string>,	data: ValueTypes["FilledOrderFormUpdateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
getOrderPage?: [{	by: ValueTypes["OrderPageUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPage"]],
listOrderPage?: [{	filter?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPage"]],
paginateOrderPage?: [{	filter?: ValueTypes["OrderPageWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageConnection"]],
getOrderPageLocale?: [{	by: ValueTypes["OrderPageLocaleUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
listOrderPageLocale?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocale"]],
paginateOrderPageLocale?: [{	filter?: ValueTypes["OrderPageLocaleWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["OrderPageLocaleOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["OrderPageLocaleConnection"]],
getFilledOrderFormEmailAddress?: [{	by: ValueTypes["FilledOrderFormEmailAddressUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormEmailAddress"]],
listFilledOrderFormEmailAddress?: [{	filter?: ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormEmailAddressOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormEmailAddress"]],
paginateFilledOrderFormEmailAddress?: [{	filter?: ValueTypes["FilledOrderFormEmailAddressWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormEmailAddressOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormEmailAddressConnection"]],
getTranslationCatalogue?: [{	by: ValueTypes["TranslationCatalogueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ValueTypes["TranslationCatalogueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCatalogueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCatalogueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ValueTypes["TranslationCataloguesIdentifierWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationCataloguesIdentifierConnection"]],
getTranslationDomain?: [{	by: ValueTypes["TranslationDomainUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ValueTypes["TranslationDomainWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationDomainOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationDomainConnection"]],
getTranslationKey?: [{	by: ValueTypes["TranslationKeyUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ValueTypes["TranslationKeyWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationKeyOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ValueTypes["TranslationValueUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ValueTypes["TranslationValueWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["TranslationValueOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["TranslationValueConnection"]],
getFilledOrderFormCountry?: [{	by: ValueTypes["FilledOrderFormCountryUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormCountry"]],
listFilledOrderFormCountry?: [{	filter?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormCountryOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormCountry"]],
paginateFilledOrderFormCountry?: [{	filter?: ValueTypes["FilledOrderFormCountryWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledOrderFormCountryOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FilledOrderFormCountryConnection"]],
getFilledProductWaitlistForm?: [{	by: ValueTypes["FilledProductWaitlistFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>},ValueTypes["FilledProductWaitlistForm"]],
listFilledProductWaitlistForm?: [{	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledProductWaitlistFormOrderBy"]> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	limit?: number | undefined | null | Variable<any, string>},ValueTypes["FilledProductWaitlistForm"]],
paginateFilledProductWaitlistForm?: [{	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>,	orderBy?: Array<ValueTypes["FilledProductWaitlistFormOrderBy"]> | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>},ValueTypes["FilledProductWaitlistFormConnection"]],
validateCreateFilledProductWaitlistForm?: [{	data: ValueTypes["FilledProductWaitlistFormCreateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
validateUpdateFilledProductWaitlistForm?: [{	by: ValueTypes["FilledProductWaitlistFormUniqueWhere"] | Variable<any, string>,	data: ValueTypes["FilledProductWaitlistFormUpdateInput"] | Variable<any, string>},ValueTypes["_ValidationResult"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createFilledOrderForm?: [{	data: ValueTypes["FilledOrderFormCreateInput"] | Variable<any, string>},ValueTypes["FilledOrderFormCreateResult"]],
updateFilledOrderForm?: [{	by: ValueTypes["FilledOrderFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>,	data: ValueTypes["FilledOrderFormUpdateInput"] | Variable<any, string>},ValueTypes["FilledOrderFormUpdateResult"]],
upsertFilledOrderForm?: [{	by: ValueTypes["FilledOrderFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>,	update: ValueTypes["FilledOrderFormUpdateInput"] | Variable<any, string>,	create: ValueTypes["FilledOrderFormCreateInput"] | Variable<any, string>},ValueTypes["FilledOrderFormUpsertResult"]],
createFilledProductWaitlistForm?: [{	data: ValueTypes["FilledProductWaitlistFormCreateInput"] | Variable<any, string>},ValueTypes["FilledProductWaitlistFormCreateResult"]],
updateFilledProductWaitlistForm?: [{	by: ValueTypes["FilledProductWaitlistFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>,	data: ValueTypes["FilledProductWaitlistFormUpdateInput"] | Variable<any, string>},ValueTypes["FilledProductWaitlistFormUpdateResult"]],
upsertFilledProductWaitlistForm?: [{	by: ValueTypes["FilledProductWaitlistFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>,	update: ValueTypes["FilledProductWaitlistFormUpdateInput"] | Variable<any, string>,	create: ValueTypes["FilledProductWaitlistFormCreateInput"] | Variable<any, string>},ValueTypes["FilledProductWaitlistFormUpsertResult"]],
transaction?: [{	options?: ValueTypes["MutationTransactionOptions"] | undefined | null | Variable<any, string>},ValueTypes["MutationTransaction"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FilledOrderForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"];
		['...on FilledOrderFormCreateResult']?: Omit<ValueTypes["FilledOrderFormCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on FilledOrderFormUpdateResult']?: Omit<ValueTypes["FilledOrderFormUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on FilledOrderFormUpsertResult']?: Omit<ValueTypes["FilledOrderFormUpsertResult"],keyof ValueTypes["MutationResult"]>;
		['...on FilledProductWaitlistFormCreateResult']?: Omit<ValueTypes["FilledProductWaitlistFormCreateResult"],keyof ValueTypes["MutationResult"]>;
		['...on FilledProductWaitlistFormUpdateResult']?: Omit<ValueTypes["FilledProductWaitlistFormUpdateResult"],keyof ValueTypes["MutationResult"]>;
		['...on FilledProductWaitlistFormUpsertResult']?: Omit<ValueTypes["FilledProductWaitlistFormUpsertResult"],keyof ValueTypes["MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["_MutationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	paths?:ValueTypes["_PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["FilledOrderFormUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FilledOrderForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FilledOrderForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FilledProductWaitlistForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FilledProductWaitlistForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FilledProductWaitlistForm"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransaction"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
createFilledOrderForm?: [{	data: ValueTypes["FilledOrderFormCreateInput"] | Variable<any, string>},ValueTypes["FilledOrderFormCreateResult"]],
updateFilledOrderForm?: [{	by: ValueTypes["FilledOrderFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>,	data: ValueTypes["FilledOrderFormUpdateInput"] | Variable<any, string>},ValueTypes["FilledOrderFormUpdateResult"]],
upsertFilledOrderForm?: [{	by: ValueTypes["FilledOrderFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledOrderFormWhere"] | undefined | null | Variable<any, string>,	update: ValueTypes["FilledOrderFormUpdateInput"] | Variable<any, string>,	create: ValueTypes["FilledOrderFormCreateInput"] | Variable<any, string>},ValueTypes["FilledOrderFormUpsertResult"]],
createFilledProductWaitlistForm?: [{	data: ValueTypes["FilledProductWaitlistFormCreateInput"] | Variable<any, string>},ValueTypes["FilledProductWaitlistFormCreateResult"]],
updateFilledProductWaitlistForm?: [{	by: ValueTypes["FilledProductWaitlistFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>,	data: ValueTypes["FilledProductWaitlistFormUpdateInput"] | Variable<any, string>},ValueTypes["FilledProductWaitlistFormUpdateResult"]],
upsertFilledProductWaitlistForm?: [{	by: ValueTypes["FilledProductWaitlistFormUniqueWhere"] | Variable<any, string>,	filter?: ValueTypes["FilledProductWaitlistFormWhere"] | undefined | null | Variable<any, string>,	update: ValueTypes["FilledProductWaitlistFormUpdateInput"] | Variable<any, string>,	create: ValueTypes["FilledProductWaitlistFormCreateInput"] | Variable<any, string>},ValueTypes["FilledProductWaitlistFormUpsertResult"]],
	query?:ValueTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null | Variable<any, string>
};
	["Json"]:unknown;
	["_Schema"]: AliasType<{
	enums?:ValueTypes["_Enum"],
	entities?:ValueTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ValueTypes["_Field"],
	unique?:ValueTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"];
		['...on _Column']?: Omit<ValueTypes["_Column"],keyof ValueTypes["_Field"]>;
		['...on _Relation']?: Omit<ValueTypes["_Relation"],keyof ValueTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_RelationSide"]:_RelationSide;
	["_OrderByDirection"]:_OrderByDirection;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ValueTypes["_OrderBy"],
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ValueTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ValueTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{		["...on _ValidatorArgument"] : ValueTypes["_ValidatorArgument"],
		["...on _PathArgument"] : ValueTypes["_PathArgument"],
		["...on _LiteralArgument"] : ValueTypes["_LiteralArgument"]
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ResolverInputTypes = {
    ["Query"]: AliasType<{
getImage?: [{	by: ResolverInputTypes["ImageUniqueWhere"],	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
listImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Image"]],
paginateImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageConnection"]],
getGenericPage?: [{	by: ResolverInputTypes["GenericPageUniqueWhere"],	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
listGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageConnection"]],
getLinkable?: [{	by: ResolverInputTypes["LinkableUniqueWhere"],	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
listLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Linkable"]],
paginateLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkableConnection"]],
getSeo?: [{	by: ResolverInputTypes["SeoUniqueWhere"],	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
listSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Seo"]],
paginateSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoConnection"]],
getGenericPageLocale?: [{	by: ResolverInputTypes["GenericPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
getLocale?: [{	by: ResolverInputTypes["LocaleUniqueWhere"],	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
listLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Locale"]],
paginateLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LocaleConnection"]],
getLink?: [{	by: ResolverInputTypes["LinkUniqueWhere"],	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
listLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Link"]],
paginateLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkConnection"]],
getHeader?: [{	by: ResolverInputTypes["HeaderUniqueWhere"],	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
listHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Header"]],
paginateHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ResolverInputTypes["HeaderLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
getContent?: [{	by: ResolverInputTypes["ContentUniqueWhere"],	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
listContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Content"]],
paginateContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentConnection"]],
getContentBlock?: [{	by: ResolverInputTypes["ContentBlockUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ResolverInputTypes["ContentReferenceUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
listContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
getVideo?: [{	by: ResolverInputTypes["VideoUniqueWhere"],	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
listVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Video"]],
paginateVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["VideoConnection"]],
getButtonLink?: [{	by: ResolverInputTypes["ButtonLinkUniqueWhere"],	filter?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null},ResolverInputTypes["ButtonLink"]],
listButtonLink?: [{	filter?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ButtonLinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ButtonLink"]],
paginateButtonLink?: [{	filter?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ButtonLinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ButtonLinkConnection"]],
getFeature?: [{	by: ResolverInputTypes["FeatureUniqueWhere"],	filter?: ResolverInputTypes["FeatureWhere"] | undefined | null},ResolverInputTypes["Feature"]],
listFeature?: [{	filter?: ResolverInputTypes["FeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Feature"]],
paginateFeature?: [{	filter?: ResolverInputTypes["FeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureConnection"]],
getFeatureItem?: [{	by: ResolverInputTypes["FeatureItemUniqueWhere"],	filter?: ResolverInputTypes["FeatureItemWhere"] | undefined | null},ResolverInputTypes["FeatureItem"]],
listFeatureItem?: [{	filter?: ResolverInputTypes["FeatureItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureItem"]],
paginateFeatureItem?: [{	filter?: ResolverInputTypes["FeatureItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureItemConnection"]],
getFeatureList?: [{	by: ResolverInputTypes["FeatureListUniqueWhere"],	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null},ResolverInputTypes["FeatureList"]],
listFeatureList?: [{	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureList"]],
paginateFeatureList?: [{	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureListConnection"]],
getLinkItem?: [{	by: ResolverInputTypes["LinkItemUniqueWhere"],	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null},ResolverInputTypes["LinkItem"]],
listLinkItem?: [{	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkItem"]],
paginateLinkItem?: [{	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkItemConnection"]],
getLinkList?: [{	by: ResolverInputTypes["LinkListUniqueWhere"],	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
listLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkList"]],
paginateLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkListConnection"]],
getFooter?: [{	by: ResolverInputTypes["FooterUniqueWhere"],	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
listFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Footer"]],
paginateFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterConnection"]],
getFooterGroupLinkList?: [{	by: ResolverInputTypes["FooterGroupLinkListUniqueWhere"],	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null},ResolverInputTypes["FooterGroupLinkList"]],
listFooterGroupLinkList?: [{	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterGroupLinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterGroupLinkList"]],
paginateFooterGroupLinkList?: [{	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterGroupLinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterGroupLinkListConnection"]],
getFooterLocale?: [{	by: ResolverInputTypes["FooterLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
getGenericPageSideNavigation?: [{	by: ResolverInputTypes["GenericPageSideNavigationUniqueWhere"],	filter?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null},ResolverInputTypes["GenericPageSideNavigation"]],
listGenericPageSideNavigation?: [{	filter?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageSideNavigationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageSideNavigation"]],
paginateGenericPageSideNavigation?: [{	filter?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageSideNavigationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageSideNavigationConnection"]],
getProjection?: [{	by: ResolverInputTypes["ProjectionUniqueWhere"],	filter?: ResolverInputTypes["ProjectionWhere"] | undefined | null},ResolverInputTypes["Projection"]],
listProjection?: [{	filter?: ResolverInputTypes["ProjectionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Projection"]],
paginateProjection?: [{	filter?: ResolverInputTypes["ProjectionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProjectionConnection"]],
getProjectionContent?: [{	by: ResolverInputTypes["ProjectionContentUniqueWhere"],	filter?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null},ResolverInputTypes["ProjectionContent"]],
listProjectionContent?: [{	filter?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProjectionContent"]],
paginateProjectionContent?: [{	filter?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProjectionContentConnection"]],
getProjectionItem?: [{	by: ResolverInputTypes["ProjectionItemUniqueWhere"],	filter?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null},ResolverInputTypes["ProjectionItem"]],
listProjectionItem?: [{	filter?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProjectionItem"]],
paginateProjectionItem?: [{	filter?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProjectionItemConnection"]],
getContentImageWithFeatures?: [{	by: ResolverInputTypes["ContentImageWithFeaturesUniqueWhere"],	filter?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null},ResolverInputTypes["ContentImageWithFeatures"]],
listContentImageWithFeatures?: [{	filter?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentImageWithFeaturesOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentImageWithFeatures"]],
paginateContentImageWithFeatures?: [{	filter?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentImageWithFeaturesOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentImageWithFeaturesConnection"]],
getAsideContentMedia?: [{	by: ResolverInputTypes["AsideContentMediaUniqueWhere"],	filter?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null},ResolverInputTypes["AsideContentMedia"]],
listAsideContentMedia?: [{	filter?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AsideContentMedia"]],
paginateAsideContentMedia?: [{	filter?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AsideContentMediaConnection"]],
getAsideContentMediaSpecification?: [{	by: ResolverInputTypes["AsideContentMediaSpecificationUniqueWhere"],	filter?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null},ResolverInputTypes["AsideContentMediaSpecification"]],
listAsideContentMediaSpecification?: [{	filter?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AsideContentMediaSpecification"]],
paginateAsideContentMediaSpecification?: [{	filter?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AsideContentMediaSpecificationConnection"]],
getFile?: [{	by: ResolverInputTypes["FileUniqueWhere"],	filter?: ResolverInputTypes["FileWhere"] | undefined | null},ResolverInputTypes["File"]],
listFile?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["File"]],
paginateFile?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FileConnection"]],
getImageList?: [{	by: ResolverInputTypes["ImageListUniqueWhere"],	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
listImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageList"]],
paginateImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListConnection"]],
getImageListItem?: [{	by: ResolverInputTypes["ImageListItemUniqueWhere"],	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null},ResolverInputTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
getProduct?: [{	by: ResolverInputTypes["ProductUniqueWhere"],	filter?: ResolverInputTypes["ProductWhere"] | undefined | null},ResolverInputTypes["Product"]],
listProduct?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Product"]],
paginateProduct?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductConnection"]],
getProductCategory?: [{	by: ResolverInputTypes["ProductCategoryUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null},ResolverInputTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryConnection"]],
getProductCategoryLocale?: [{	by: ResolverInputTypes["ProductCategoryLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null},ResolverInputTypes["ProductCategoryLocale"]],
listProductCategoryLocale?: [{	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryLocale"]],
paginateProductCategoryLocale?: [{	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryLocaleConnection"]],
getProductLocale?: [{	by: ResolverInputTypes["ProductLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null},ResolverInputTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductLocaleConnection"]],
getProductCategoryItem?: [{	by: ResolverInputTypes["ProductCategoryItemUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null},ResolverInputTypes["ProductCategoryItem"]],
listProductCategoryItem?: [{	filter?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryItem"]],
paginateProductCategoryItem?: [{	filter?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryItemConnection"]],
getProductCategoryList?: [{	by: ResolverInputTypes["ProductCategoryListUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null},ResolverInputTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryListConnection"]],
getFeatureSection?: [{	by: ResolverInputTypes["FeatureSectionUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null},ResolverInputTypes["FeatureSection"]],
listFeatureSection?: [{	filter?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSection"]],
paginateFeatureSection?: [{	filter?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionConnection"]],
getFeatureSectionHeat?: [{	by: ResolverInputTypes["FeatureSectionHeatUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null},ResolverInputTypes["FeatureSectionHeat"]],
listFeatureSectionHeat?: [{	filter?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionHeatOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionHeat"]],
paginateFeatureSectionHeat?: [{	filter?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionHeatOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionHeatConnection"]],
getFeatureSectionPump?: [{	by: ResolverInputTypes["FeatureSectionPumpUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null},ResolverInputTypes["FeatureSectionPump"]],
listFeatureSectionPump?: [{	filter?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionPumpOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionPump"]],
paginateFeatureSectionPump?: [{	filter?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionPumpOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionPumpConnection"]],
getFeatureSectionTemperature?: [{	by: ResolverInputTypes["FeatureSectionTemperatureUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null},ResolverInputTypes["FeatureSectionTemperature"]],
listFeatureSectionTemperature?: [{	filter?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionTemperatureOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionTemperature"]],
paginateFeatureSectionTemperature?: [{	filter?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionTemperatureOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionTemperatureConnection"]],
getFeatureSectionTube?: [{	by: ResolverInputTypes["FeatureSectionTubeUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null},ResolverInputTypes["FeatureSectionTube"]],
listFeatureSectionTube?: [{	filter?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionTubeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionTube"]],
paginateFeatureSectionTube?: [{	filter?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionTubeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionTubeConnection"]],
getFeatureSectionAntimer?: [{	by: ResolverInputTypes["FeatureSectionAntimerUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null},ResolverInputTypes["FeatureSectionAntimer"]],
listFeatureSectionAntimer?: [{	filter?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionAntimerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionAntimer"]],
paginateFeatureSectionAntimer?: [{	filter?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionAntimerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionAntimerConnection"]],
getRedirect?: [{	by: ResolverInputTypes["RedirectUniqueWhere"],	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
listRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Redirect"]],
paginateRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RedirectConnection"]],
getGeneral?: [{	by: ResolverInputTypes["GeneralUniqueWhere"],	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
listGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["General"]],
paginateGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralConnection"]],
getGeneralLocale?: [{	by: ResolverInputTypes["GeneralLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
getProductFeature?: [{	by: ResolverInputTypes["ProductFeatureUniqueWhere"],	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null},ResolverInputTypes["ProductFeature"]],
listProductFeature?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductFeature"]],
paginateProductFeature?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductFeatureConnection"]],
getProductFeatureLocale?: [{	by: ResolverInputTypes["ProductFeatureLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null},ResolverInputTypes["ProductFeatureLocale"]],
listProductFeatureLocale?: [{	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductFeatureLocale"]],
paginateProductFeatureLocale?: [{	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductFeatureLocaleConnection"]],
getFilledOrderForm?: [{	by: ResolverInputTypes["FilledOrderFormUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null},ResolverInputTypes["FilledOrderForm"]],
listFilledOrderForm?: [{	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FilledOrderForm"]],
paginateFilledOrderForm?: [{	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FilledOrderFormConnection"]],
validateCreateFilledOrderForm?: [{	data: ResolverInputTypes["FilledOrderFormCreateInput"]},ResolverInputTypes["_ValidationResult"]],
validateUpdateFilledOrderForm?: [{	by: ResolverInputTypes["FilledOrderFormUniqueWhere"],	data: ResolverInputTypes["FilledOrderFormUpdateInput"]},ResolverInputTypes["_ValidationResult"]],
getOrderPage?: [{	by: ResolverInputTypes["OrderPageUniqueWhere"],	filter?: ResolverInputTypes["OrderPageWhere"] | undefined | null},ResolverInputTypes["OrderPage"]],
listOrderPage?: [{	filter?: ResolverInputTypes["OrderPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["OrderPage"]],
paginateOrderPage?: [{	filter?: ResolverInputTypes["OrderPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["OrderPageConnection"]],
getOrderPageLocale?: [{	by: ResolverInputTypes["OrderPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
listOrderPageLocale?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["OrderPageLocale"]],
paginateOrderPageLocale?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["OrderPageLocaleConnection"]],
getFilledOrderFormEmailAddress?: [{	by: ResolverInputTypes["FilledOrderFormEmailAddressUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null},ResolverInputTypes["FilledOrderFormEmailAddress"]],
listFilledOrderFormEmailAddress?: [{	filter?: ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormEmailAddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FilledOrderFormEmailAddress"]],
paginateFilledOrderFormEmailAddress?: [{	filter?: ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormEmailAddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FilledOrderFormEmailAddressConnection"]],
getTranslationCatalogue?: [{	by: ResolverInputTypes["TranslationCatalogueUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCatalogueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ResolverInputTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifierConnection"]],
getTranslationDomain?: [{	by: ResolverInputTypes["TranslationDomainUniqueWhere"],	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null},ResolverInputTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationDomainConnection"]],
getTranslationKey?: [{	by: ResolverInputTypes["TranslationKeyUniqueWhere"],	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ResolverInputTypes["TranslationValueUniqueWhere"],	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null},ResolverInputTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationValueConnection"]],
getFilledOrderFormCountry?: [{	by: ResolverInputTypes["FilledOrderFormCountryUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null},ResolverInputTypes["FilledOrderFormCountry"]],
listFilledOrderFormCountry?: [{	filter?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormCountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FilledOrderFormCountry"]],
paginateFilledOrderFormCountry?: [{	filter?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormCountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FilledOrderFormCountryConnection"]],
getFilledProductWaitlistForm?: [{	by: ResolverInputTypes["FilledProductWaitlistFormUniqueWhere"],	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null},ResolverInputTypes["FilledProductWaitlistForm"]],
listFilledProductWaitlistForm?: [{	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledProductWaitlistFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FilledProductWaitlistForm"]],
paginateFilledProductWaitlistForm?: [{	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledProductWaitlistFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FilledProductWaitlistFormConnection"]],
validateCreateFilledProductWaitlistForm?: [{	data: ResolverInputTypes["FilledProductWaitlistFormCreateInput"]},ResolverInputTypes["_ValidationResult"]],
validateUpdateFilledProductWaitlistForm?: [{	by: ResolverInputTypes["FilledProductWaitlistFormUniqueWhere"],	data: ResolverInputTypes["FilledProductWaitlistFormUpdateInput"]},ResolverInputTypes["_ValidationResult"]],
	transaction?:ResolverInputTypes["QueryTransaction"],
	_info?:ResolverInputTypes["Info"],
	schema?:ResolverInputTypes["_Schema"],
		__typename?: boolean | `@${string}`
}>;
	["Image"]: AliasType<{
	_meta?:ResolverInputTypes["ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	url?:ResolverInputTypes["FieldMeta"],
	width?:ResolverInputTypes["FieldMeta"],
	height?:ResolverInputTypes["FieldMeta"],
	size?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	alt?:ResolverInputTypes["FieldMeta"],
	fileName?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:unknown;
	["ImageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ImageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	url?: ResolverInputTypes["StringCondition"] | undefined | null,
	width?: ResolverInputTypes["IntCondition"] | undefined | null,
	height?: ResolverInputTypes["IntCondition"] | undefined | null,
	size?: ResolverInputTypes["IntCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	alt?: ResolverInputTypes["StringCondition"] | undefined | null,
	fileName?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageWhere"] | undefined | null
};
	["UUIDCondition"]: {
	and?: Array<ResolverInputTypes["UUIDCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["UUIDCondition"]> | undefined | null,
	not?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["UUID"] | undefined | null,
	notEq?: ResolverInputTypes["UUID"] | undefined | null,
	in?: Array<ResolverInputTypes["UUID"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["UUID"]> | undefined | null,
	lt?: ResolverInputTypes["UUID"] | undefined | null,
	lte?: ResolverInputTypes["UUID"] | undefined | null,
	gt?: ResolverInputTypes["UUID"] | undefined | null,
	gte?: ResolverInputTypes["UUID"] | undefined | null
};
	["StringCondition"]: {
	and?: Array<ResolverInputTypes["StringCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["StringCondition"]> | undefined | null,
	not?: ResolverInputTypes["StringCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: string | undefined | null,
	notEq?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	containsCI?: string | undefined | null,
	startsWithCI?: string | undefined | null,
	endsWithCI?: string | undefined | null
};
	["IntCondition"]: {
	and?: Array<ResolverInputTypes["IntCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["IntCondition"]> | undefined | null,
	not?: ResolverInputTypes["IntCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	url?: ResolverInputTypes["OrderDirection"] | undefined | null,
	width?: ResolverInputTypes["OrderDirection"] | undefined | null,
	height?: ResolverInputTypes["OrderDirection"] | undefined | null,
	size?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	alt?: ResolverInputTypes["OrderDirection"] | undefined | null,
	fileName?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["OrderDirection"]:OrderDirection;
	["ImageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImageEdge"]: AliasType<{
	node?:ResolverInputTypes["Image"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPage"]: AliasType<{
	_meta?:ResolverInputTypes["GenericPageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
	isSideNavigation?:boolean | `@${string}`,
localesByLocale?: [{	by: ResolverInputTypes["GenericPageLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["GenericPageLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesByLink?: [{	by: ResolverInputTypes["GenericPageLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesByContent?: [{	by: ResolverInputTypes["GenericPageLocalesByContentUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
localesBySideNavigationItems?: [{	by: ResolverInputTypes["GenericPageLocalesBySideNavigationItemsUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	isSideNavigation?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocale"]: AliasType<{
	_meta?:ResolverInputTypes["GenericPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
sideNavigationItems?: [{	filter?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageSideNavigationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageSideNavigation"]],
paginateSideNavigationItems?: [{	filter?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageSideNavigationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageSideNavigationConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	sideNavigationItems?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	locales?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	isSideNavigation?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["GenericPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GenericPageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GenericPageWhere"] | undefined | null
};
	["GenericPageLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["GenericPageWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	sideNavigationItems?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null
};
	["LocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	code?: ResolverInputTypes["StringCondition"] | undefined | null,
	label?: ResolverInputTypes["StringCondition"] | undefined | null,
	genericPages?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	headers?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,
	footers?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,
	productCategories?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,
	products?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,
	generals?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,
	currency?: ResolverInputTypes["StringCondition"] | undefined | null,
	productFeatureItems?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,
	orderPages?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LocaleWhere"] | undefined | null
};
	["HeaderLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["HeaderWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	specialLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HeaderLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null
};
	["HeaderWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	locales?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["HeaderWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["HeaderWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["HeaderWhere"] | undefined | null
};
	["OneCondition"]: {
	and?: Array<ResolverInputTypes["OneCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["OneCondition"]> | undefined | null,
	not?: ResolverInputTypes["OneCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["One"] | undefined | null,
	notEq?: ResolverInputTypes["One"] | undefined | null,
	in?: Array<ResolverInputTypes["One"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["One"]> | undefined | null,
	lt?: ResolverInputTypes["One"] | undefined | null,
	lte?: ResolverInputTypes["One"] | undefined | null,
	gt?: ResolverInputTypes["One"] | undefined | null,
	gte?: ResolverInputTypes["One"] | undefined | null
};
	["One"]:One;
	["LinkWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["LinkTypeCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	externalLink?: ResolverInputTypes["StringCondition"] | undefined | null,
	internalLink?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	scrollToTargetId?: ResolverInputTypes["StringCondition"] | undefined | null,
	file?: ResolverInputTypes["FileWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkWhere"] | undefined | null
};
	["LinkTypeCondition"]: {
	and?: Array<ResolverInputTypes["LinkTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["LinkTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["LinkTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["LinkType"] | undefined | null,
	notEq?: ResolverInputTypes["LinkType"] | undefined | null,
	in?: Array<ResolverInputTypes["LinkType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["LinkType"]> | undefined | null,
	lt?: ResolverInputTypes["LinkType"] | undefined | null,
	lte?: ResolverInputTypes["LinkType"] | undefined | null,
	gt?: ResolverInputTypes["LinkType"] | undefined | null,
	gte?: ResolverInputTypes["LinkType"] | undefined | null
};
	["LinkType"]:LinkType;
	["LinkableWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	url?: ResolverInputTypes["StringCondition"] | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	redirect?: ResolverInputTypes["RedirectWhere"] | undefined | null,
	orderPage?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkableWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkableWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkableWhere"] | undefined | null
};
	["RedirectWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	target?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["RedirectWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["RedirectWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["RedirectWhere"] | undefined | null
};
	["OrderPageLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkableWhere"] | undefined | null,
	root?: ResolverInputTypes["OrderPageWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	termsAndConditions?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null
};
	["OrderPageWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["OrderPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["OrderPageWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["OrderPageWhere"] | undefined | null
};
	["SeoWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	ogTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	ogDescription?: ResolverInputTypes["StringCondition"] | undefined | null,
	noIndex?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	noFollow?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["SeoWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["SeoWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["SeoWhere"] | undefined | null
};
	["BooleanCondition"]: {
	and?: Array<ResolverInputTypes["BooleanCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["BooleanCondition"]> | undefined | null,
	not?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: boolean | undefined | null,
	notEq?: boolean | undefined | null,
	in?: Array<boolean> | undefined | null,
	notIn?: Array<boolean> | undefined | null,
	lt?: boolean | undefined | null,
	lte?: boolean | undefined | null,
	gt?: boolean | undefined | null,
	gte?: boolean | undefined | null
};
	["FileWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	url?: ResolverInputTypes["StringCondition"] | undefined | null,
	size?: ResolverInputTypes["IntCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	label?: ResolverInputTypes["StringCondition"] | undefined | null,
	fileName?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FileWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FileWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FileWhere"] | undefined | null
};
	["FooterLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	copyright?: ResolverInputTypes["StringCondition"] | undefined | null,
	groupsLinkList?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null,
	root?: ResolverInputTypes["FooterWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	contact?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FooterLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FooterLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null
};
	["FooterGroupLinkListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	footer?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,
	linkList?: ResolverInputTypes["LinkListWhere"] | undefined | null,
	isAddress?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	addressValue?: ResolverInputTypes["StringCondition"] | undefined | null,
	addressLink?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null
};
	["LinkListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["LinkItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkListWhere"] | undefined | null
};
	["LinkItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["LinkListWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["LinkItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["LinkItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["LinkItemWhere"] | undefined | null
};
	["FooterWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	logo?: ResolverInputTypes["ImageWhere"] | undefined | null,
	locales?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,
	logoCompany?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FooterWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FooterWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FooterWhere"] | undefined | null
};
	["ProductCategoryLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null
};
	["ProductCategoryWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	products?: ResolverInputTypes["ProductWhere"] | undefined | null,
	locales?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ProductCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProductCategoryWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null
};
	["ProductWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	miningPower?: ResolverInputTypes["StringCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	category?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null,
	tileImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	locales?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,
	isForSale?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	features?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,
	slug?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProductWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProductWhere"] | undefined | null
};
	["ProductLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	root?: ResolverInputTypes["ProductWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	price?: ResolverInputTypes["IntCondition"] | undefined | null,
	buttonLabel?: ResolverInputTypes["StringCondition"] | undefined | null,
	isCustomButtonLabel?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	discountedPrice?: ResolverInputTypes["IntCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ProductLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProductLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null
};
	["ProductFeatureWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	locales?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ProductFeatureWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProductFeatureWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null
};
	["ProductFeatureLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	price?: ResolverInputTypes["IntCondition"] | undefined | null,
	root?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null
};
	["GeneralLocaleWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	root?: ResolverInputTypes["GeneralWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GeneralLocaleWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null
};
	["GeneralWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	unique?: ResolverInputTypes["OneCondition"] | undefined | null,
	locales?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GeneralWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GeneralWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GeneralWhere"] | undefined | null
};
	["ContentWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	blocks?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentWhere"] | undefined | null
};
	["ContentBlockWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	json?: ResolverInputTypes["StringCondition"] | undefined | null,
	content?: ResolverInputTypes["ContentWhere"] | undefined | null,
	references?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentBlockWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentBlockWhere"] | undefined | null
};
	["ContentReferenceWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	type?: ResolverInputTypes["ContentReferenceTypeCondition"] | undefined | null,
	block?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,
	primaryText?: ResolverInputTypes["StringCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	linkTwo?: ResolverInputTypes["LinkWhere"] | undefined | null,
	buttonLink?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null,
	buttonLinkTwo?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null,
	featureList?: ResolverInputTypes["FeatureListWhere"] | undefined | null,
	projection?: ResolverInputTypes["ProjectionWhere"] | undefined | null,
	contentImageWithFeatures?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null,
	asideContentMedia?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null,
	imageList?: ResolverInputTypes["ImageListWhere"] | undefined | null,
	productCategories?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	secondaryText?: ResolverInputTypes["StringCondition"] | undefined | null,
	callToActionTypes?: ResolverInputTypes["CallToActionTypeCondition"] | undefined | null,
	secondaryImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	featureSection?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null,
	terciaryImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	video?: ResolverInputTypes["VideoWhere"] | undefined | null,
	isImageWithWhiteShadow?: ResolverInputTypes["BooleanCondition"] | undefined | null,
	secondaryVideo?: ResolverInputTypes["VideoWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ResolverInputTypes["ContentReferenceTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ContentReferenceTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["ContentReferenceTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	notEq?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	in?: Array<ResolverInputTypes["ContentReferenceType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ContentReferenceType"]> | undefined | null,
	lt?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	lte?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	gt?: ResolverInputTypes["ContentReferenceType"] | undefined | null,
	gte?: ResolverInputTypes["ContentReferenceType"] | undefined | null
};
	["ContentReferenceType"]:ContentReferenceType;
	["ButtonLinkWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	visualType?: ResolverInputTypes["ButtonLinkVisualTypeCondition"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	fillType?: ResolverInputTypes["ButtonLinkFillTypeCondition"] | undefined | null,
	widthType?: ResolverInputTypes["ButtonLinkWidthTypeCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["ButtonLinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ButtonLinkWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null
};
	["ButtonLinkVisualTypeCondition"]: {
	and?: Array<ResolverInputTypes["ButtonLinkVisualTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ButtonLinkVisualTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["ButtonLinkVisualTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ButtonLinkVisualType"] | undefined | null,
	notEq?: ResolverInputTypes["ButtonLinkVisualType"] | undefined | null,
	in?: Array<ResolverInputTypes["ButtonLinkVisualType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ButtonLinkVisualType"]> | undefined | null,
	lt?: ResolverInputTypes["ButtonLinkVisualType"] | undefined | null,
	lte?: ResolverInputTypes["ButtonLinkVisualType"] | undefined | null,
	gt?: ResolverInputTypes["ButtonLinkVisualType"] | undefined | null,
	gte?: ResolverInputTypes["ButtonLinkVisualType"] | undefined | null
};
	["ButtonLinkVisualType"]:ButtonLinkVisualType;
	["ButtonLinkFillTypeCondition"]: {
	and?: Array<ResolverInputTypes["ButtonLinkFillTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ButtonLinkFillTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["ButtonLinkFillTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ButtonLinkFillType"] | undefined | null,
	notEq?: ResolverInputTypes["ButtonLinkFillType"] | undefined | null,
	in?: Array<ResolverInputTypes["ButtonLinkFillType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ButtonLinkFillType"]> | undefined | null,
	lt?: ResolverInputTypes["ButtonLinkFillType"] | undefined | null,
	lte?: ResolverInputTypes["ButtonLinkFillType"] | undefined | null,
	gt?: ResolverInputTypes["ButtonLinkFillType"] | undefined | null,
	gte?: ResolverInputTypes["ButtonLinkFillType"] | undefined | null
};
	["ButtonLinkFillType"]:ButtonLinkFillType;
	["ButtonLinkWidthTypeCondition"]: {
	and?: Array<ResolverInputTypes["ButtonLinkWidthTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["ButtonLinkWidthTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["ButtonLinkWidthTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["ButtonLinkWidthType"] | undefined | null,
	notEq?: ResolverInputTypes["ButtonLinkWidthType"] | undefined | null,
	in?: Array<ResolverInputTypes["ButtonLinkWidthType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["ButtonLinkWidthType"]> | undefined | null,
	lt?: ResolverInputTypes["ButtonLinkWidthType"] | undefined | null,
	lte?: ResolverInputTypes["ButtonLinkWidthType"] | undefined | null,
	gt?: ResolverInputTypes["ButtonLinkWidthType"] | undefined | null,
	gte?: ResolverInputTypes["ButtonLinkWidthType"] | undefined | null
};
	["ButtonLinkWidthType"]:ButtonLinkWidthType;
	["FeatureListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["FeatureItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureListWhere"] | undefined | null
};
	["FeatureItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["FeatureListWhere"] | undefined | null,
	feature?: ResolverInputTypes["FeatureWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureItemWhere"] | undefined | null
};
	["FeatureWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	icon?: ResolverInputTypes["ImageWhere"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureWhere"] | undefined | null
};
	["ProjectionWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ProjectionWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProjectionWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProjectionWhere"] | undefined | null
};
	["ProjectionItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["ProjectionWhere"] | undefined | null,
	item?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ProjectionItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProjectionItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null
};
	["ProjectionContentWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ProjectionContentWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProjectionContentWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null
};
	["ContentImageWithFeaturesWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	subtitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	text?: ResolverInputTypes["StringCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	features?: ResolverInputTypes["FeatureListWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null
};
	["AsideContentMediaWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	subtitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	media?: ResolverInputTypes["ImageWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	tableSpecifiation?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["AsideContentMediaWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["AsideContentMediaWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null
};
	["AsideContentMediaSpecificationWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	label?: ResolverInputTypes["StringCondition"] | undefined | null,
	value?: ResolverInputTypes["StringCondition"] | undefined | null,
	specification?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null
};
	["ImageListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageListWhere"] | undefined | null
};
	["ImageListItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	list?: ResolverInputTypes["ImageListWhere"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	imageMobile?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ImageListItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ImageListItemWhere"] | undefined | null
};
	["ProductCategoryListWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	items?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProductCategoryListWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null
};
	["ProductCategoryItemWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null,
	item?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null
};
	["CallToActionTypeCondition"]: {
	and?: Array<ResolverInputTypes["CallToActionTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["CallToActionTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["CallToActionTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["CallToActionType"] | undefined | null,
	notEq?: ResolverInputTypes["CallToActionType"] | undefined | null,
	in?: Array<ResolverInputTypes["CallToActionType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["CallToActionType"]> | undefined | null,
	lt?: ResolverInputTypes["CallToActionType"] | undefined | null,
	lte?: ResolverInputTypes["CallToActionType"] | undefined | null,
	gt?: ResolverInputTypes["CallToActionType"] | undefined | null,
	gte?: ResolverInputTypes["CallToActionType"] | undefined | null
};
	["CallToActionType"]:CallToActionType;
	["FeatureSectionWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	temperature?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null,
	heat?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null,
	pump?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null,
	tube?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null,
	antimer?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureSectionWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureSectionWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null
};
	["FeatureSectionTemperatureWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	label?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	number?: ResolverInputTypes["IntCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null
};
	["FeatureSectionHeatWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title1?: ResolverInputTypes["StringCondition"] | undefined | null,
	titleHighlighted1?: ResolverInputTypes["StringCondition"] | undefined | null,
	titleHighlighted2?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null
};
	["FeatureSectionPumpWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	sideTitle?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null
};
	["FeatureSectionTubeWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	title?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	secondaryImage?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null
};
	["FeatureSectionAntimerWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	label1?: ResolverInputTypes["StringCondition"] | undefined | null,
	label2?: ResolverInputTypes["StringCondition"] | undefined | null,
	description?: ResolverInputTypes["StringCondition"] | undefined | null,
	number?: ResolverInputTypes["IntCondition"] | undefined | null,
	image?: ResolverInputTypes["ImageWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null
};
	["VideoWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	src?: ResolverInputTypes["StringCondition"] | undefined | null,
	width?: ResolverInputTypes["IntCondition"] | undefined | null,
	height?: ResolverInputTypes["IntCondition"] | undefined | null,
	size?: ResolverInputTypes["IntCondition"] | undefined | null,
	type?: ResolverInputTypes["StringCondition"] | undefined | null,
	duration?: ResolverInputTypes["FloatCondition"] | undefined | null,
	poster?: ResolverInputTypes["ImageWhere"] | undefined | null,
	fileName?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["VideoWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["VideoWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["VideoWhere"] | undefined | null
};
	["FloatCondition"]: {
	and?: Array<ResolverInputTypes["FloatCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["FloatCondition"]> | undefined | null,
	not?: ResolverInputTypes["FloatCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["GenericPageSideNavigationWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	list?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null
};
	["Locale"]: AliasType<{
	_meta?:ResolverInputTypes["LocaleMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
genericPages?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
headers?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
footers?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
productCategories?: [{	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryLocale"]],
products?: [{	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductLocale"]],
generals?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
	currency?:boolean | `@${string}`,
productFeatureItems?: [{	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductFeatureLocale"]],
orderPages?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["OrderPageLocale"]],
genericPagesByRoot?: [{	by: ResolverInputTypes["LocaleGenericPagesByRootUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesBySeo?: [{	by: ResolverInputTypes["LocaleGenericPagesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesByLink?: [{	by: ResolverInputTypes["LocaleGenericPagesByLinkUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesByContent?: [{	by: ResolverInputTypes["LocaleGenericPagesByContentUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
genericPagesBySideNavigationItems?: [{	by: ResolverInputTypes["LocaleGenericPagesBySideNavigationItemsUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
headersByRoot?: [{	by: ResolverInputTypes["LocaleHeadersByRootUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
footersByRoot?: [{	by: ResolverInputTypes["LocaleFootersByRootUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
footersByGroupsLinkList?: [{	by: ResolverInputTypes["LocaleFootersByGroupsLinkListUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
productCategoriesByRoot?: [{	by: ResolverInputTypes["LocaleProductCategoriesByRootUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null},ResolverInputTypes["ProductCategoryLocale"]],
productsByRoot?: [{	by: ResolverInputTypes["LocaleProductsByRootUniqueWhere"],	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null},ResolverInputTypes["ProductLocale"]],
generalsByRoot?: [{	by: ResolverInputTypes["LocaleGeneralsByRootUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
generalsBySeo?: [{	by: ResolverInputTypes["LocaleGeneralsBySeoUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
productFeatureItemsByRoot?: [{	by: ResolverInputTypes["LocaleProductFeatureItemsByRootUniqueWhere"],	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null},ResolverInputTypes["ProductFeatureLocale"]],
orderPagesByRoot?: [{	by: ResolverInputTypes["LocaleOrderPagesByRootUniqueWhere"],	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
orderPagesByLink?: [{	by: ResolverInputTypes["LocaleOrderPagesByLinkUniqueWhere"],	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
orderPagesBySeo?: [{	by: ResolverInputTypes["LocaleOrderPagesBySeoUniqueWhere"],	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
paginateGenericPages?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
paginateHeaders?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
paginateFooters?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
paginateProductCategories?: [{	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryLocaleConnection"]],
paginateProducts?: [{	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductLocaleConnection"]],
paginateGenerals?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
paginateProductFeatureItems?: [{	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductFeatureLocaleConnection"]],
paginateOrderPages?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["OrderPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	code?:ResolverInputTypes["FieldMeta"],
	label?:ResolverInputTypes["FieldMeta"],
	genericPages?:ResolverInputTypes["FieldMeta"],
	headers?:ResolverInputTypes["FieldMeta"],
	footers?:ResolverInputTypes["FieldMeta"],
	productCategories?:ResolverInputTypes["FieldMeta"],
	products?:ResolverInputTypes["FieldMeta"],
	generals?:ResolverInputTypes["FieldMeta"],
	currency?:ResolverInputTypes["FieldMeta"],
	productFeatureItems?:ResolverInputTypes["FieldMeta"],
	orderPages?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["GenericPageOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	isSideNavigation?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	code?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label?: ResolverInputTypes["OrderDirection"] | undefined | null,
	currency?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogDescription?: ResolverInputTypes["OrderDirection"] | undefined | null,
	noIndex?: ResolverInputTypes["OrderDirection"] | undefined | null,
	noFollow?: ResolverInputTypes["OrderDirection"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	url?: ResolverInputTypes["OrderDirection"] | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleOrderBy"] | undefined | null,
	redirect?: ResolverInputTypes["RedirectOrderBy"] | undefined | null,
	orderPage?: ResolverInputTypes["OrderPageLocaleOrderBy"] | undefined | null
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	target?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	externalLink?: ResolverInputTypes["OrderDirection"] | undefined | null,
	internalLink?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	scrollToTargetId?: ResolverInputTypes["OrderDirection"] | undefined | null,
	file?: ResolverInputTypes["FileOrderBy"] | undefined | null
};
	["FileOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	url?: ResolverInputTypes["OrderDirection"] | undefined | null,
	size?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label?: ResolverInputTypes["OrderDirection"] | undefined | null,
	fileName?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["OrderPageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkableOrderBy"] | undefined | null,
	root?: ResolverInputTypes["OrderPageOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null,
	termsAndConditions?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["OrderPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["HeaderLocale"]: AliasType<{
	_meta?:ResolverInputTypes["HeaderLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
specialLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	specialLink?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Header"]: AliasType<{
	_meta?:ResolverInputTypes["HeaderMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
locales?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["HeaderLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["HeaderMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["HeaderOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	specialLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["LocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	genericPages?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null,
	headers?: ResolverInputTypes["HeaderLocaleUniqueWhere"] | undefined | null,
	footers?: ResolverInputTypes["FooterLocaleUniqueWhere"] | undefined | null,
	productCategories?: ResolverInputTypes["ProductCategoryLocaleUniqueWhere"] | undefined | null,
	products?: ResolverInputTypes["ProductLocaleUniqueWhere"] | undefined | null,
	generals?: ResolverInputTypes["GeneralLocaleUniqueWhere"] | undefined | null,
	productFeatureItems?: ResolverInputTypes["ProductFeatureLocaleUniqueWhere"] | undefined | null,
	orderPages?: ResolverInputTypes["OrderPageLocaleUniqueWhere"] | undefined | null
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["GenericPageUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null,
	sideNavigationItems?: ResolverInputTypes["GenericPageSideNavigationUniqueWhere"] | undefined | null
};
	["GenericPageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null
};
	["SeoUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	ogImage?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["LinkableUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	genericPage?: ResolverInputTypes["GenericPageLocaleUniqueWhere"] | undefined | null,
	redirect?: ResolverInputTypes["RedirectUniqueWhere"] | undefined | null,
	orderPage?: ResolverInputTypes["OrderPageLocaleUniqueWhere"] | undefined | null
};
	["RedirectUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	target?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LinkUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["OrderPageLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["OrderPageUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["OrderPageUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["OrderPageLocaleUniqueWhere"] | undefined | null
};
	["ContentUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	blocks?: ResolverInputTypes["ContentBlockUniqueWhere"] | undefined | null
};
	["ContentBlockUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	references?: ResolverInputTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentReferenceUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	linkTwo?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null,
	buttonLink?: ResolverInputTypes["ButtonLinkUniqueWhere"] | undefined | null,
	buttonLinkTwo?: ResolverInputTypes["ButtonLinkUniqueWhere"] | undefined | null,
	featureList?: ResolverInputTypes["FeatureListUniqueWhere"] | undefined | null,
	projection?: ResolverInputTypes["ProjectionUniqueWhere"] | undefined | null,
	contentImageWithFeatures?: ResolverInputTypes["ContentImageWithFeaturesUniqueWhere"] | undefined | null,
	asideContentMedia?: ResolverInputTypes["AsideContentMediaUniqueWhere"] | undefined | null,
	imageList?: ResolverInputTypes["ImageListUniqueWhere"] | undefined | null,
	productCategories?: ResolverInputTypes["ProductCategoryListUniqueWhere"] | undefined | null,
	featureSection?: ResolverInputTypes["FeatureSectionUniqueWhere"] | undefined | null
};
	["ButtonLinkUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["FeatureListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["FeatureItemUniqueWhere"] | undefined | null
};
	["FeatureItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ProjectionUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["ProjectionItemUniqueWhere"] | undefined | null
};
	["ProjectionItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ContentImageWithFeaturesUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	features?: ResolverInputTypes["FeatureListUniqueWhere"] | undefined | null
};
	["AsideContentMediaUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	tableSpecifiation?: ResolverInputTypes["AsideContentMediaSpecificationUniqueWhere"] | undefined | null
};
	["AsideContentMediaSpecificationUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ImageListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["ImageListItemUniqueWhere"] | undefined | null
};
	["ImageListItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["ProductCategoryListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["ProductCategoryItemUniqueWhere"] | undefined | null
};
	["ProductCategoryItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FeatureSectionUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	temperature?: ResolverInputTypes["FeatureSectionTemperatureUniqueWhere"] | undefined | null,
	heat?: ResolverInputTypes["FeatureSectionHeatUniqueWhere"] | undefined | null,
	pump?: ResolverInputTypes["FeatureSectionPumpUniqueWhere"] | undefined | null,
	tube?: ResolverInputTypes["FeatureSectionTubeUniqueWhere"] | undefined | null,
	antimer?: ResolverInputTypes["FeatureSectionAntimerUniqueWhere"] | undefined | null
};
	["FeatureSectionTemperatureUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FeatureSectionHeatUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FeatureSectionPumpUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FeatureSectionTubeUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FeatureSectionAntimerUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["GenericPageSideNavigationUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["HeaderUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["HeaderUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["HeaderLocaleUniqueWhere"] | undefined | null
};
	["FooterLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["FooterUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	groupsLinkList?: ResolverInputTypes["FooterGroupLinkListUniqueWhere"] | undefined | null
};
	["FooterUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["FooterLocaleUniqueWhere"] | undefined | null
};
	["FooterGroupLinkListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	linkList?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["LinkListUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	items?: ResolverInputTypes["LinkItemUniqueWhere"] | undefined | null
};
	["LinkItemUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["ProductCategoryLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["ProductCategoryUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductCategoryUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	products?: ResolverInputTypes["ProductUniqueWhere"] | undefined | null,
	locales?: ResolverInputTypes["ProductCategoryLocaleUniqueWhere"] | undefined | null
};
	["ProductUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	slug?: string | undefined | null,
	locales?: ResolverInputTypes["ProductLocaleUniqueWhere"] | undefined | null
};
	["ProductLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["ProductUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["GeneralUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null,
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GeneralUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	unique?: ResolverInputTypes["One"] | undefined | null,
	locales?: ResolverInputTypes["GeneralLocaleUniqueWhere"] | undefined | null
};
	["ProductFeatureLocaleUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	root?: ResolverInputTypes["ProductFeatureUniqueWhere"] | undefined | null,
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductFeatureUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	locales?: ResolverInputTypes["ProductFeatureLocaleUniqueWhere"] | undefined | null
};
	["HeaderLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HeaderLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["HeaderLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Link"]: AliasType<{
	_meta?:ResolverInputTypes["LinkMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
	scrollToTargetId?:boolean | `@${string}`,
file?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null},ResolverInputTypes["File"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	externalLink?:ResolverInputTypes["FieldMeta"],
	internalLink?:ResolverInputTypes["FieldMeta"],
	scrollToTargetId?:ResolverInputTypes["FieldMeta"],
	file?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Linkable"]: AliasType<{
	_meta?:ResolverInputTypes["LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
genericPage?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
redirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
orderPage?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkableMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	url?:ResolverInputTypes["FieldMeta"],
	genericPage?:ResolverInputTypes["FieldMeta"],
	redirect?:ResolverInputTypes["FieldMeta"],
	orderPage?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Redirect"]: AliasType<{
	_meta?:ResolverInputTypes["RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
target?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["RedirectMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	target?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageLocale"]: AliasType<{
	_meta?:ResolverInputTypes["OrderPageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
root?: [{	filter?: ResolverInputTypes["OrderPageWhere"] | undefined | null},ResolverInputTypes["OrderPage"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
	termsAndConditions?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderPageLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
	termsAndConditions?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPage"]: AliasType<{
	_meta?:ResolverInputTypes["OrderPageMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["OrderPageLocale"]],
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
localesByLocale?: [{	by: ResolverInputTypes["OrderPageLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
localesByLink?: [{	by: ResolverInputTypes["OrderPageLocalesByLinkUniqueWhere"],	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["OrderPageLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["OrderPageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["OrderPageLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["OrderPageLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["OrderPageLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["OrderPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["OrderPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Seo"]: AliasType<{
	_meta?:ResolverInputTypes["SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
	noIndex?:boolean | `@${string}`,
	noFollow?:boolean | `@${string}`,
ogImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["SeoMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	ogTitle?:ResolverInputTypes["FieldMeta"],
	ogDescription?:ResolverInputTypes["FieldMeta"],
	noIndex?:ResolverInputTypes["FieldMeta"],
	noFollow?:ResolverInputTypes["FieldMeta"],
	ogImage?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["File"]: AliasType<{
	_meta?:ResolverInputTypes["FileMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	fileName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FileMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	url?:ResolverInputTypes["FieldMeta"],
	size?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	label?:ResolverInputTypes["FieldMeta"],
	fileName?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocale"]: AliasType<{
	_meta?:ResolverInputTypes["FooterLocaleMeta"],
	id?:boolean | `@${string}`,
	copyright?:boolean | `@${string}`,
groupsLinkList?: [{	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterGroupLinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterGroupLinkList"]],
root?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	contact?:boolean | `@${string}`,
groupsLinkListByLinkList?: [{	by: ResolverInputTypes["FooterLocaleGroupsLinkListByLinkListUniqueWhere"],	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null},ResolverInputTypes["FooterGroupLinkList"]],
paginateGroupsLinkList?: [{	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterGroupLinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterGroupLinkListConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	copyright?:ResolverInputTypes["FieldMeta"],
	groupsLinkList?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	contact?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterGroupLinkList"]: AliasType<{
	_meta?:ResolverInputTypes["FooterGroupLinkListMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
footer?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
linkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
	isAddress?:boolean | `@${string}`,
	addressValue?:boolean | `@${string}`,
addressLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterGroupLinkListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	footer?:ResolverInputTypes["FieldMeta"],
	linkList?:ResolverInputTypes["FieldMeta"],
	isAddress?:ResolverInputTypes["FieldMeta"],
	addressValue?:ResolverInputTypes["FieldMeta"],
	addressLink?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkList"]: AliasType<{
	_meta?:ResolverInputTypes["LinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkItem"]],
itemsByLink?: [{	by: ResolverInputTypes["LinkListItemsByLinkUniqueWhere"],	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null},ResolverInputTypes["LinkItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkItem"]: AliasType<{
	_meta?:ResolverInputTypes["LinkItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["LinkItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["LinkItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["LinkListOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["LinkListItemsByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["LinkItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkItemEdge"]: AliasType<{
	node?:ResolverInputTypes["LinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["FooterGroupLinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	footer?: ResolverInputTypes["FooterLocaleOrderBy"] | undefined | null,
	linkList?: ResolverInputTypes["LinkListOrderBy"] | undefined | null,
	isAddress?: ResolverInputTypes["OrderDirection"] | undefined | null,
	addressValue?: ResolverInputTypes["OrderDirection"] | undefined | null,
	addressLink?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	copyright?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["FooterOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	contact?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FooterOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null,
	logo?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	logoCompany?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["Footer"]: AliasType<{
	_meta?:ResolverInputTypes["FooterMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
logo?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
locales?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
logoCompany?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
localesByLocale?: [{	by: ResolverInputTypes["FooterLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
localesByGroupsLinkList?: [{	by: ResolverInputTypes["FooterLocalesByGroupsLinkListUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FooterMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	logo?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	logoCompany?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["FooterLocalesByGroupsLinkListUniqueWhere"]: {
	groupsLinkList?: ResolverInputTypes["FooterGroupLinkListUniqueWhere"] | undefined | null
};
	["FooterLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FooterLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["FooterLocale"],
		__typename?: boolean | `@${string}`
}>;
	["FooterLocaleGroupsLinkListByLinkListUniqueWhere"]: {
	linkList?: ResolverInputTypes["LinkListUniqueWhere"] | undefined | null
};
	["FooterGroupLinkListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FooterGroupLinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterGroupLinkListEdge"]: AliasType<{
	node?:ResolverInputTypes["FooterGroupLinkList"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocale"]: AliasType<{
	_meta?:ResolverInputTypes["ProductCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null},ResolverInputTypes["ProductCategory"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategory"]: AliasType<{
	_meta?:ResolverInputTypes["ProductCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
products?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Product"]],
locales?: [{	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryLocale"]],
productsBySlug?: [{	by: ResolverInputTypes["ProductCategoryProductsBySlugUniqueWhere"],	filter?: ResolverInputTypes["ProductWhere"] | undefined | null},ResolverInputTypes["Product"]],
productsByLocales?: [{	by: ResolverInputTypes["ProductCategoryProductsByLocalesUniqueWhere"],	filter?: ResolverInputTypes["ProductWhere"] | undefined | null},ResolverInputTypes["Product"]],
localesByLocale?: [{	by: ResolverInputTypes["ProductCategoryLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null},ResolverInputTypes["ProductCategoryLocale"]],
paginateProducts?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductConnection"]],
paginateLocales?: [{	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	products?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Product"]: AliasType<{
	_meta?:ResolverInputTypes["ProductMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	miningPower?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
category?: [{	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null},ResolverInputTypes["ProductCategory"]],
tileImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
locales?: [{	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductLocale"]],
	isForSale?:boolean | `@${string}`,
features?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductFeature"]],
	slug?:boolean | `@${string}`,
localesByLocale?: [{	by: ResolverInputTypes["ProductLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null},ResolverInputTypes["ProductLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductLocaleConnection"]],
paginateFeatures?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductFeatureConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	miningPower?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	category?:ResolverInputTypes["FieldMeta"],
	tileImage?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
	isForSale?:ResolverInputTypes["FieldMeta"],
	features?:ResolverInputTypes["FieldMeta"],
	slug?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocale"]: AliasType<{
	_meta?:ResolverInputTypes["ProductLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null},ResolverInputTypes["Product"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	price?:boolean | `@${string}`,
	buttonLabel?:boolean | `@${string}`,
	isCustomButtonLabel?:boolean | `@${string}`,
	discountedPrice?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	price?:ResolverInputTypes["FieldMeta"],
	buttonLabel?:ResolverInputTypes["FieldMeta"],
	isCustomButtonLabel?:ResolverInputTypes["FieldMeta"],
	discountedPrice?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["ProductOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	price?: ResolverInputTypes["OrderDirection"] | undefined | null,
	buttonLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	isCustomButtonLabel?: ResolverInputTypes["OrderDirection"] | undefined | null,
	discountedPrice?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	miningPower?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	category?: ResolverInputTypes["ProductCategoryOrderBy"] | undefined | null,
	tileImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	isForSale?: ResolverInputTypes["OrderDirection"] | undefined | null,
	slug?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ProductFeature"]: AliasType<{
	_meta?:ResolverInputTypes["ProductFeatureMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductFeatureLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["ProductFeatureLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null},ResolverInputTypes["ProductFeatureLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductFeatureLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureLocale"]: AliasType<{
	_meta?:ResolverInputTypes["ProductFeatureLocaleMeta"],
	id?:boolean | `@${string}`,
	price?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null},ResolverInputTypes["ProductFeature"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	price?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	price?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["ProductFeatureOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ProductFeatureOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ProductFeatureLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductFeatureLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProductFeatureLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["ProductFeatureLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProductLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["ProductLocale"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProductFeatureEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductFeatureEdge"]: AliasType<{
	node?:ResolverInputTypes["ProductFeature"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["ProductCategoryOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null
};
	["ProductCategoryProductsBySlugUniqueWhere"]: {
	slug?: string | undefined | null
};
	["ProductCategoryProductsByLocalesUniqueWhere"]: {
	locales?: ResolverInputTypes["ProductLocaleUniqueWhere"] | undefined | null
};
	["ProductCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["ProductConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductEdge"]: AliasType<{
	node?:ResolverInputTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProductCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["ProductCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocale"]: AliasType<{
	_meta?:ResolverInputTypes["GeneralLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
locale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
seo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	root?:ResolverInputTypes["FieldMeta"],
	locale?:ResolverInputTypes["FieldMeta"],
	seo?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["General"]: AliasType<{
	_meta?:ResolverInputTypes["GeneralMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
localesByLocale?: [{	by: ResolverInputTypes["GeneralLocalesByLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
localesBySeo?: [{	by: ResolverInputTypes["GeneralLocalesBySeoUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateLocales?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["GeneralMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	unique?:ResolverInputTypes["FieldMeta"],
	locales?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	root?: ResolverInputTypes["GeneralOrderBy"] | undefined | null,
	locale?: ResolverInputTypes["LocaleOrderBy"] | undefined | null,
	seo?: ResolverInputTypes["SeoOrderBy"] | undefined | null
};
	["GeneralOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	unique?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GeneralLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GeneralLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["GeneralLocale"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["GenericPageUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["LocaleGenericPagesBySideNavigationItemsUniqueWhere"]: {
	sideNavigationItems?: ResolverInputTypes["GenericPageSideNavigationUniqueWhere"] | undefined | null
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ResolverInputTypes["HeaderUniqueWhere"] | undefined | null
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ResolverInputTypes["FooterUniqueWhere"] | undefined | null
};
	["LocaleFootersByGroupsLinkListUniqueWhere"]: {
	groupsLinkList?: ResolverInputTypes["FooterGroupLinkListUniqueWhere"] | undefined | null
};
	["LocaleProductCategoriesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["ProductCategoryUniqueWhere"] | undefined | null
};
	["LocaleProductsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["ProductUniqueWhere"] | undefined | null
};
	["LocaleGeneralsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["GeneralUniqueWhere"] | undefined | null
};
	["LocaleGeneralsBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["LocaleProductFeatureItemsByRootUniqueWhere"]: {
	root?: ResolverInputTypes["ProductFeatureUniqueWhere"] | undefined | null
};
	["LocaleOrderPagesByRootUniqueWhere"]: {
	root?: ResolverInputTypes["OrderPageUniqueWhere"] | undefined | null
};
	["LocaleOrderPagesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["LocaleOrderPagesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GenericPageLocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GenericPageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["GenericPageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Content"]: AliasType<{
	_meta?:ResolverInputTypes["ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
blocksByReferences?: [{	by: ResolverInputTypes["ContentBlocksByReferencesUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateBlocks?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	blocks?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ResolverInputTypes["ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
references?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByLink?: [{	by: ResolverInputTypes["ContentBlockReferencesByLinkUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByLinkTwo?: [{	by: ResolverInputTypes["ContentBlockReferencesByLinkTwoUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByButtonLink?: [{	by: ResolverInputTypes["ContentBlockReferencesByButtonLinkUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByButtonLinkTwo?: [{	by: ResolverInputTypes["ContentBlockReferencesByButtonLinkTwoUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByFeatureList?: [{	by: ResolverInputTypes["ContentBlockReferencesByFeatureListUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByProjection?: [{	by: ResolverInputTypes["ContentBlockReferencesByProjectionUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByContentImageWithFeatures?: [{	by: ResolverInputTypes["ContentBlockReferencesByContentImageWithFeaturesUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByAsideContentMedia?: [{	by: ResolverInputTypes["ContentBlockReferencesByAsideContentMediaUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByImageList?: [{	by: ResolverInputTypes["ContentBlockReferencesByImageListUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByProductCategories?: [{	by: ResolverInputTypes["ContentBlockReferencesByProductCategoriesUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
referencesByFeatureSection?: [{	by: ResolverInputTypes["ContentBlockReferencesByFeatureSectionUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
paginateReferences?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	json?:ResolverInputTypes["FieldMeta"],
	content?:ResolverInputTypes["FieldMeta"],
	references?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReference"]: AliasType<{
	_meta?:ResolverInputTypes["ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
block?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
	primaryText?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
linkTwo?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
buttonLink?: [{	filter?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null},ResolverInputTypes["ButtonLink"]],
buttonLinkTwo?: [{	filter?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null},ResolverInputTypes["ButtonLink"]],
featureList?: [{	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null},ResolverInputTypes["FeatureList"]],
projection?: [{	filter?: ResolverInputTypes["ProjectionWhere"] | undefined | null},ResolverInputTypes["Projection"]],
contentImageWithFeatures?: [{	filter?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null},ResolverInputTypes["ContentImageWithFeatures"]],
asideContentMedia?: [{	filter?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null},ResolverInputTypes["AsideContentMedia"]],
imageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
productCategories?: [{	filter?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null},ResolverInputTypes["ProductCategoryList"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	secondaryText?:boolean | `@${string}`,
	callToActionTypes?:boolean | `@${string}`,
secondaryImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
featureSection?: [{	filter?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null},ResolverInputTypes["FeatureSection"]],
terciaryImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
video?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
	isImageWithWhiteShadow?:boolean | `@${string}`,
secondaryVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	block?:ResolverInputTypes["FieldMeta"],
	primaryText?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	linkTwo?:ResolverInputTypes["FieldMeta"],
	buttonLink?:ResolverInputTypes["FieldMeta"],
	buttonLinkTwo?:ResolverInputTypes["FieldMeta"],
	featureList?:ResolverInputTypes["FieldMeta"],
	projection?:ResolverInputTypes["FieldMeta"],
	contentImageWithFeatures?:ResolverInputTypes["FieldMeta"],
	asideContentMedia?:ResolverInputTypes["FieldMeta"],
	imageList?:ResolverInputTypes["FieldMeta"],
	productCategories?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	secondaryText?:ResolverInputTypes["FieldMeta"],
	callToActionTypes?:ResolverInputTypes["FieldMeta"],
	secondaryImage?:ResolverInputTypes["FieldMeta"],
	featureSection?:ResolverInputTypes["FieldMeta"],
	terciaryImage?:ResolverInputTypes["FieldMeta"],
	video?:ResolverInputTypes["FieldMeta"],
	isImageWithWhiteShadow?:ResolverInputTypes["FieldMeta"],
	secondaryVideo?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonLink"]: AliasType<{
	_meta?:ResolverInputTypes["ButtonLinkMeta"],
	id?:boolean | `@${string}`,
	visualType?:boolean | `@${string}`,
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
	fillType?:boolean | `@${string}`,
	widthType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ButtonLinkMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	visualType?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	fillType?:ResolverInputTypes["FieldMeta"],
	widthType?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureList"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["FeatureItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["FeatureItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureItem"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null},ResolverInputTypes["FeatureList"]],
feature?: [{	filter?: ResolverInputTypes["FeatureWhere"] | undefined | null},ResolverInputTypes["Feature"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	feature?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Feature"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
icon?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FeatureMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	icon?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["FeatureListOrderBy"] | undefined | null,
	feature?: ResolverInputTypes["FeatureOrderBy"] | undefined | null
};
	["FeatureListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FeatureOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	icon?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FeatureItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureItemEdge"]: AliasType<{
	node?:ResolverInputTypes["FeatureItem"],
		__typename?: boolean | `@${string}`
}>;
	["Projection"]: AliasType<{
	_meta?:ResolverInputTypes["ProjectionMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProjectionItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProjectionItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionItem"]: AliasType<{
	_meta?:ResolverInputTypes["ProjectionItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["ProjectionWhere"] | undefined | null},ResolverInputTypes["Projection"]],
item?: [{	filter?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null},ResolverInputTypes["ProjectionContent"]],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	item?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionContent"]: AliasType<{
	_meta?:ResolverInputTypes["ProjectionContentMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionContentMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["ProjectionOrderBy"] | undefined | null,
	item?: ResolverInputTypes["ProjectionContentOrderBy"] | undefined | null
};
	["ProjectionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ProjectionContentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["ProjectionItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProjectionItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ProjectionItem"],
		__typename?: boolean | `@${string}`
}>;
	["ContentImageWithFeatures"]: AliasType<{
	_meta?:ResolverInputTypes["ContentImageWithFeaturesMeta"],
	id?:boolean | `@${string}`,
	subtitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
features?: [{	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null},ResolverInputTypes["FeatureList"]],
		__typename?: boolean | `@${string}`
}>;
	["ContentImageWithFeaturesMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	subtitle?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	text?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	features?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMedia"]: AliasType<{
	_meta?:ResolverInputTypes["AsideContentMediaMeta"],
	id?:boolean | `@${string}`,
	subtitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
media?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
tableSpecifiation?: [{	filter?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AsideContentMediaSpecification"]],
paginateTableSpecifiation?: [{	filter?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AsideContentMediaSpecificationConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	subtitle?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	media?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
	tableSpecifiation?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaSpecification"]: AliasType<{
	_meta?:ResolverInputTypes["AsideContentMediaSpecificationMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
specification?: [{	filter?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null},ResolverInputTypes["AsideContentMedia"]],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaSpecificationMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	label?:ResolverInputTypes["FieldMeta"],
	value?:ResolverInputTypes["FieldMeta"],
	specification?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaSpecificationOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label?: ResolverInputTypes["OrderDirection"] | undefined | null,
	value?: ResolverInputTypes["OrderDirection"] | undefined | null,
	specification?: ResolverInputTypes["AsideContentMediaOrderBy"] | undefined | null
};
	["AsideContentMediaOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	subtitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	media?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["AsideContentMediaSpecificationConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["AsideContentMediaSpecificationEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaSpecificationEdge"]: AliasType<{
	node?:ResolverInputTypes["AsideContentMediaSpecification"],
		__typename?: boolean | `@${string}`
}>;
	["ImageList"]: AliasType<{
	_meta?:ResolverInputTypes["ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItem"]: AliasType<{
	_meta?:ResolverInputTypes["ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
imageMobile?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	imageMobile?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["ImageListOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	imageMobile?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ImageListItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryList"]: AliasType<{
	_meta?:ResolverInputTypes["ProductCategoryListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryItem"]],
paginateItems?: [{	filter?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	items?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryItem"]: AliasType<{
	_meta?:ResolverInputTypes["ProductCategoryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null},ResolverInputTypes["ProductCategoryList"]],
item?: [{	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null},ResolverInputTypes["ProductCategory"]],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryItemMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	item?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["ProductCategoryListOrderBy"] | undefined | null,
	item?: ResolverInputTypes["ProductCategoryOrderBy"] | undefined | null
};
	["ProductCategoryListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ProductCategoryItemConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProductCategoryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryItemEdge"]: AliasType<{
	node?:ResolverInputTypes["ProductCategoryItem"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSection"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureSectionMeta"],
	id?:boolean | `@${string}`,
temperature?: [{	filter?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null},ResolverInputTypes["FeatureSectionTemperature"]],
heat?: [{	filter?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null},ResolverInputTypes["FeatureSectionHeat"]],
pump?: [{	filter?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null},ResolverInputTypes["FeatureSectionPump"]],
tube?: [{	filter?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null},ResolverInputTypes["FeatureSectionTube"]],
antimer?: [{	filter?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null},ResolverInputTypes["FeatureSectionAntimer"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	temperature?:ResolverInputTypes["FieldMeta"],
	heat?:ResolverInputTypes["FieldMeta"],
	pump?:ResolverInputTypes["FieldMeta"],
	tube?:ResolverInputTypes["FieldMeta"],
	antimer?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTemperature"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureSectionTemperatureMeta"],
	id?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	number?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTemperatureMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	label?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	number?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionHeat"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureSectionHeatMeta"],
	id?:boolean | `@${string}`,
	title1?:boolean | `@${string}`,
	titleHighlighted1?:boolean | `@${string}`,
	titleHighlighted2?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionHeatMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title1?:ResolverInputTypes["FieldMeta"],
	titleHighlighted1?:ResolverInputTypes["FieldMeta"],
	titleHighlighted2?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionPump"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureSectionPumpMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	sideTitle?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionPumpMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	sideTitle?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTube"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureSectionTubeMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
secondaryImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTubeMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	title?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
	secondaryImage?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionAntimer"]: AliasType<{
	_meta?:ResolverInputTypes["FeatureSectionAntimerMeta"],
	id?:boolean | `@${string}`,
	label1?:boolean | `@${string}`,
	label2?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	number?:boolean | `@${string}`,
image?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionAntimerMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	label1?:ResolverInputTypes["FieldMeta"],
	label2?:ResolverInputTypes["FieldMeta"],
	description?:ResolverInputTypes["FieldMeta"],
	number?:ResolverInputTypes["FieldMeta"],
	image?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Video"]: AliasType<{
	_meta?:ResolverInputTypes["VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
poster?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
	fileName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VideoMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	src?:ResolverInputTypes["FieldMeta"],
	width?:ResolverInputTypes["FieldMeta"],
	height?:ResolverInputTypes["FieldMeta"],
	size?:ResolverInputTypes["FieldMeta"],
	type?:ResolverInputTypes["FieldMeta"],
	duration?:ResolverInputTypes["FieldMeta"],
	poster?:ResolverInputTypes["FieldMeta"],
	fileName?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	block?: ResolverInputTypes["ContentBlockOrderBy"] | undefined | null,
	primaryText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	linkTwo?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	buttonLink?: ResolverInputTypes["ButtonLinkOrderBy"] | undefined | null,
	buttonLinkTwo?: ResolverInputTypes["ButtonLinkOrderBy"] | undefined | null,
	featureList?: ResolverInputTypes["FeatureListOrderBy"] | undefined | null,
	projection?: ResolverInputTypes["ProjectionOrderBy"] | undefined | null,
	contentImageWithFeatures?: ResolverInputTypes["ContentImageWithFeaturesOrderBy"] | undefined | null,
	asideContentMedia?: ResolverInputTypes["AsideContentMediaOrderBy"] | undefined | null,
	imageList?: ResolverInputTypes["ImageListOrderBy"] | undefined | null,
	productCategories?: ResolverInputTypes["ProductCategoryListOrderBy"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	secondaryText?: ResolverInputTypes["OrderDirection"] | undefined | null,
	callToActionTypes?: ResolverInputTypes["OrderDirection"] | undefined | null,
	secondaryImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	featureSection?: ResolverInputTypes["FeatureSectionOrderBy"] | undefined | null,
	terciaryImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	video?: ResolverInputTypes["VideoOrderBy"] | undefined | null,
	isImageWithWhiteShadow?: ResolverInputTypes["OrderDirection"] | undefined | null,
	secondaryVideo?: ResolverInputTypes["VideoOrderBy"] | undefined | null
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	json?: ResolverInputTypes["OrderDirection"] | undefined | null,
	content?: ResolverInputTypes["ContentOrderBy"] | undefined | null
};
	["ButtonLinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	visualType?: ResolverInputTypes["OrderDirection"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null,
	fillType?: ResolverInputTypes["OrderDirection"] | undefined | null,
	widthType?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ContentImageWithFeaturesOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	subtitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	text?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	features?: ResolverInputTypes["FeatureListOrderBy"] | undefined | null
};
	["FeatureSectionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	temperature?: ResolverInputTypes["FeatureSectionTemperatureOrderBy"] | undefined | null,
	heat?: ResolverInputTypes["FeatureSectionHeatOrderBy"] | undefined | null,
	pump?: ResolverInputTypes["FeatureSectionPumpOrderBy"] | undefined | null,
	tube?: ResolverInputTypes["FeatureSectionTubeOrderBy"] | undefined | null,
	antimer?: ResolverInputTypes["FeatureSectionAntimerOrderBy"] | undefined | null
};
	["FeatureSectionTemperatureOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	number?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FeatureSectionHeatOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title1?: ResolverInputTypes["OrderDirection"] | undefined | null,
	titleHighlighted1?: ResolverInputTypes["OrderDirection"] | undefined | null,
	titleHighlighted2?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["FeatureSectionPumpOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	sideTitle?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FeatureSectionTubeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	title?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	secondaryImage?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["FeatureSectionAntimerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label1?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label2?: ResolverInputTypes["OrderDirection"] | undefined | null,
	description?: ResolverInputTypes["OrderDirection"] | undefined | null,
	number?: ResolverInputTypes["OrderDirection"] | undefined | null,
	image?: ResolverInputTypes["ImageOrderBy"] | undefined | null
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	src?: ResolverInputTypes["OrderDirection"] | undefined | null,
	width?: ResolverInputTypes["OrderDirection"] | undefined | null,
	height?: ResolverInputTypes["OrderDirection"] | undefined | null,
	size?: ResolverInputTypes["OrderDirection"] | undefined | null,
	type?: ResolverInputTypes["OrderDirection"] | undefined | null,
	duration?: ResolverInputTypes["OrderDirection"] | undefined | null,
	poster?: ResolverInputTypes["ImageOrderBy"] | undefined | null,
	fileName?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByLinkTwoUniqueWhere"]: {
	linkTwo?: ResolverInputTypes["LinkUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByButtonLinkUniqueWhere"]: {
	buttonLink?: ResolverInputTypes["ButtonLinkUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByButtonLinkTwoUniqueWhere"]: {
	buttonLinkTwo?: ResolverInputTypes["ButtonLinkUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByFeatureListUniqueWhere"]: {
	featureList?: ResolverInputTypes["FeatureListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByProjectionUniqueWhere"]: {
	projection?: ResolverInputTypes["ProjectionUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByContentImageWithFeaturesUniqueWhere"]: {
	contentImageWithFeatures?: ResolverInputTypes["ContentImageWithFeaturesUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByAsideContentMediaUniqueWhere"]: {
	asideContentMedia?: ResolverInputTypes["AsideContentMediaUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByImageListUniqueWhere"]: {
	imageList?: ResolverInputTypes["ImageListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByProductCategoriesUniqueWhere"]: {
	productCategories?: ResolverInputTypes["ProductCategoryListUniqueWhere"] | undefined | null
};
	["ContentBlockReferencesByFeatureSectionUniqueWhere"]: {
	featureSection?: ResolverInputTypes["FeatureSectionUniqueWhere"] | undefined | null
};
	["ContentReferenceConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentReferenceEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ResolverInputTypes["ContentReferenceUniqueWhere"] | undefined | null
};
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageSideNavigation"]: AliasType<{
	_meta?:ResolverInputTypes["GenericPageSideNavigationMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
link?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageSideNavigationMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	list?:ResolverInputTypes["FieldMeta"],
	link?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageSideNavigationOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	list?: ResolverInputTypes["GenericPageLocaleOrderBy"] | undefined | null,
	link?: ResolverInputTypes["LinkOrderBy"] | undefined | null
};
	["GenericPageSideNavigationConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GenericPageSideNavigationEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageSideNavigationEdge"]: AliasType<{
	node?:ResolverInputTypes["GenericPageSideNavigation"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ResolverInputTypes["LocaleUniqueWhere"] | undefined | null
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ResolverInputTypes["SeoUniqueWhere"] | undefined | null
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ResolverInputTypes["LinkableUniqueWhere"] | undefined | null
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ResolverInputTypes["ContentUniqueWhere"] | undefined | null
};
	["GenericPageLocalesBySideNavigationItemsUniqueWhere"]: {
	sideNavigationItems?: ResolverInputTypes["GenericPageSideNavigationUniqueWhere"] | undefined | null
};
	["GenericPageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GenericPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GenericPageEdge"]: AliasType<{
	node?:ResolverInputTypes["GenericPage"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkableEdge"]: AliasType<{
	node?:ResolverInputTypes["Linkable"],
		__typename?: boolean | `@${string}`
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["SeoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["SeoEdge"]: AliasType<{
	node?:ResolverInputTypes["Seo"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LocaleEdge"]: AliasType<{
	node?:ResolverInputTypes["Locale"],
		__typename?: boolean | `@${string}`
}>;
	["LinkConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkEdge"]: AliasType<{
	node?:ResolverInputTypes["Link"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["HeaderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["HeaderEdge"]: AliasType<{
	node?:ResolverInputTypes["Header"],
		__typename?: boolean | `@${string}`
}>;
	["ContentConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentEdge"]: AliasType<{
	node?:ResolverInputTypes["Content"],
		__typename?: boolean | `@${string}`
}>;
	["VideoUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["VideoConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["VideoEdge"],
		__typename?: boolean | `@${string}`
}>;
	["VideoEdge"]: AliasType<{
	node?:ResolverInputTypes["Video"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonLinkConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ButtonLinkEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ButtonLinkEdge"]: AliasType<{
	node?:ResolverInputTypes["ButtonLink"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	icon?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["FeatureConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureEdge"]: AliasType<{
	node?:ResolverInputTypes["Feature"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureListEdge"]: AliasType<{
	node?:ResolverInputTypes["FeatureList"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["LinkListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["LinkListEdge"]: AliasType<{
	node?:ResolverInputTypes["LinkList"],
		__typename?: boolean | `@${string}`
}>;
	["FooterConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FooterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FooterEdge"]: AliasType<{
	node?:ResolverInputTypes["Footer"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProjectionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionEdge"]: AliasType<{
	node?:ResolverInputTypes["Projection"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionContentUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	image?: ResolverInputTypes["ImageUniqueWhere"] | undefined | null
};
	["ProjectionContentConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProjectionContentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProjectionContentEdge"]: AliasType<{
	node?:ResolverInputTypes["ProjectionContent"],
		__typename?: boolean | `@${string}`
}>;
	["ContentImageWithFeaturesConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ContentImageWithFeaturesEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ContentImageWithFeaturesEdge"]: AliasType<{
	node?:ResolverInputTypes["ContentImageWithFeatures"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["AsideContentMediaEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AsideContentMediaEdge"]: AliasType<{
	node?:ResolverInputTypes["AsideContentMedia"],
		__typename?: boolean | `@${string}`
}>;
	["FileUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FileConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FileEdge"]: AliasType<{
	node?:ResolverInputTypes["File"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ImageListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ImageListEdge"]: AliasType<{
	node?:ResolverInputTypes["ImageList"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProductCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryEdge"]: AliasType<{
	node?:ResolverInputTypes["ProductCategory"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["ProductCategoryListEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductCategoryListEdge"]: AliasType<{
	node?:ResolverInputTypes["ProductCategoryList"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureSectionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionEdge"]: AliasType<{
	node?:ResolverInputTypes["FeatureSection"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionHeatConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureSectionHeatEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionHeatEdge"]: AliasType<{
	node?:ResolverInputTypes["FeatureSectionHeat"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionPumpConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureSectionPumpEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionPumpEdge"]: AliasType<{
	node?:ResolverInputTypes["FeatureSectionPump"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTemperatureConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureSectionTemperatureEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTemperatureEdge"]: AliasType<{
	node?:ResolverInputTypes["FeatureSectionTemperature"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTubeConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureSectionTubeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionTubeEdge"]: AliasType<{
	node?:ResolverInputTypes["FeatureSectionTube"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionAntimerConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FeatureSectionAntimerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FeatureSectionAntimerEdge"]: AliasType<{
	node?:ResolverInputTypes["FeatureSectionAntimer"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["RedirectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["RedirectEdge"]: AliasType<{
	node?:ResolverInputTypes["Redirect"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["GeneralEdge"],
		__typename?: boolean | `@${string}`
}>;
	["GeneralEdge"]: AliasType<{
	node?:ResolverInputTypes["General"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderForm"]: AliasType<{
	_meta?:ResolverInputTypes["FilledOrderFormMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	deliveryAddress?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
productFeatures?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductFeature"]],
	publishedAt?:boolean | `@${string}`,
product?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null},ResolverInputTypes["Product"]],
country?: [{	filter?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null},ResolverInputTypes["FilledOrderFormCountry"]],
	mobile?:boolean | `@${string}`,
paginateProductFeatures?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductFeatureConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	email?:ResolverInputTypes["FieldMeta"],
	deliveryAddress?:ResolverInputTypes["FieldMeta"],
	note?:ResolverInputTypes["FieldMeta"],
	productFeatures?:ResolverInputTypes["FieldMeta"],
	publishedAt?:ResolverInputTypes["FieldMeta"],
	product?:ResolverInputTypes["FieldMeta"],
	country?:ResolverInputTypes["FieldMeta"],
	mobile?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DateTime"]:unknown;
	["FilledOrderFormCountry"]: AliasType<{
	_meta?:ResolverInputTypes["FilledOrderFormCountryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCountryMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	code?:ResolverInputTypes["FieldMeta"],
	label?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCountryWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	code?: ResolverInputTypes["StringCondition"] | undefined | null,
	label?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null
};
	["FilledOrderFormUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FilledOrderFormWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	email?: ResolverInputTypes["StringCondition"] | undefined | null,
	deliveryAddress?: ResolverInputTypes["StringCondition"] | undefined | null,
	note?: ResolverInputTypes["StringCondition"] | undefined | null,
	productFeatures?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	product?: ResolverInputTypes["ProductWhere"] | undefined | null,
	country?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null,
	mobile?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FilledOrderFormWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FilledOrderFormWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null
};
	["DateTimeCondition"]: {
	and?: Array<ResolverInputTypes["DateTimeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["DateTimeCondition"]> | undefined | null,
	not?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["DateTime"] | undefined | null,
	notEq?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null
};
	["FilledOrderFormOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	email?: ResolverInputTypes["OrderDirection"] | undefined | null,
	deliveryAddress?: ResolverInputTypes["OrderDirection"] | undefined | null,
	note?: ResolverInputTypes["OrderDirection"] | undefined | null,
	publishedAt?: ResolverInputTypes["OrderDirection"] | undefined | null,
	product?: ResolverInputTypes["ProductOrderBy"] | undefined | null,
	country?: ResolverInputTypes["FilledOrderFormCountryOrderBy"] | undefined | null,
	mobile?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FilledOrderFormCountryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	code?: ResolverInputTypes["OrderDirection"] | undefined | null,
	label?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FilledOrderFormConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FilledOrderFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEdge"]: AliasType<{
	node?:ResolverInputTypes["FilledOrderForm"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidationError"]: AliasType<{
	path?:ResolverInputTypes["_PathFragment"],
	message?:ResolverInputTypes["_ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["_PathFragment"]: AliasType<{
	_FieldPathFragment?:ResolverInputTypes["_FieldPathFragment"],
	_IndexPathFragment?:ResolverInputTypes["_IndexPathFragment"],
		__typename?: boolean | `@${string}`
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCreateInput"]: {
	name?: string | undefined | null,
	email?: string | undefined | null,
	deliveryAddress?: string | undefined | null,
	note?: string | undefined | null,
	productFeatures?: Array<ResolverInputTypes["FilledOrderFormCreateProductFeaturesEntityRelationInput"]> | undefined | null,
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	product?: ResolverInputTypes["FilledOrderFormCreateProductEntityRelationInput"] | undefined | null,
	country?: ResolverInputTypes["FilledOrderFormCreateCountryEntityRelationInput"] | undefined | null,
	mobile?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["FilledOrderFormCreateProductFeaturesEntityRelationInput"]: {
	connect?: ResolverInputTypes["ProductFeatureUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["FilledOrderFormCreateProductEntityRelationInput"]: {
	connect?: ResolverInputTypes["ProductUniqueWhere"] | undefined | null
};
	["FilledOrderFormCreateCountryEntityRelationInput"]: {
	connect?: ResolverInputTypes["FilledOrderFormCountryUniqueWhere"] | undefined | null
};
	["FilledOrderFormCountryUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["FilledOrderFormUpdateInput"]: {
	name?: string | undefined | null,
	email?: string | undefined | null,
	deliveryAddress?: string | undefined | null,
	note?: string | undefined | null,
	productFeatures?: Array<ResolverInputTypes["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]> | undefined | null,
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	product?: ResolverInputTypes["FilledOrderFormUpdateProductEntityRelationInput"] | undefined | null,
	country?: ResolverInputTypes["FilledOrderFormUpdateCountryEntityRelationInput"] | undefined | null,
	mobile?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]: {
	connect?: ResolverInputTypes["ProductFeatureUniqueWhere"] | undefined | null,
	disconnect?: ResolverInputTypes["ProductFeatureUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["FilledOrderFormUpdateProductEntityRelationInput"]: {
	connect?: ResolverInputTypes["ProductUniqueWhere"] | undefined | null
};
	["FilledOrderFormUpdateCountryEntityRelationInput"]: {
	connect?: ResolverInputTypes["FilledOrderFormCountryUniqueWhere"] | undefined | null
};
	["OrderPageConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["OrderPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPageEdge"]: AliasType<{
	node?:ResolverInputTypes["OrderPage"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEmailAddress"]: AliasType<{
	_meta?:ResolverInputTypes["FilledOrderFormEmailAddressMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEmailAddressMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	order?:ResolverInputTypes["FieldMeta"],
	email?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEmailAddressUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FilledOrderFormEmailAddressWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	order?: ResolverInputTypes["IntCondition"] | undefined | null,
	email?: ResolverInputTypes["StringCondition"] | undefined | null,
	and?: Array<ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null
};
	["FilledOrderFormEmailAddressOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	order?: ResolverInputTypes["OrderDirection"] | undefined | null,
	email?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["FilledOrderFormEmailAddressConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FilledOrderFormEmailAddressEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormEmailAddressEdge"]: AliasType<{
	node?:ResolverInputTypes["FilledOrderFormEmailAddress"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogue"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationCatalogueMeta"],
	id?:boolean | `@${string}`,
domain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null},ResolverInputTypes["TranslationDomain"]],
identifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
fallback?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
values?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationValue"]],
valuesByKey?: [{	by: ResolverInputTypes["TranslationCatalogueValuesByKeyUniqueWhere"],	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null},ResolverInputTypes["TranslationValue"]],
paginateValues?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	domain?:ResolverInputTypes["FieldMeta"],
	identifier?:ResolverInputTypes["FieldMeta"],
	fallback?:ResolverInputTypes["FieldMeta"],
	values?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomain"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationDomainMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
catalogues?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
keys?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationKey"]],
cataloguesByIdentifier?: [{	by: ResolverInputTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
cataloguesByValues?: [{	by: ResolverInputTypes["TranslationDomainCataloguesByValuesUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
keysByIdentifier?: [{	by: ResolverInputTypes["TranslationDomainKeysByIdentifierUniqueWhere"],	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
keysByValues?: [{	by: ResolverInputTypes["TranslationDomainKeysByValuesUniqueWhere"],	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
paginateCatalogues?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCatalogueConnection"]],
paginateKeys?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationKeyConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	identifier?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	catalogues?:ResolverInputTypes["FieldMeta"],
	keys?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,
	identifier?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,
	fallback?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,
	values?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null
};
	["TranslationDomainWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	identifier?: ResolverInputTypes["StringCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	catalogues?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,
	keys?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationDomainWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null
};
	["TranslationKeyWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	identifier?: ResolverInputTypes["StringCondition"] | undefined | null,
	contentType?: ResolverInputTypes["TranslationKeyContentTypeCondition"] | undefined | null,
	note?: ResolverInputTypes["StringCondition"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,
	values?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationKeyWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null
};
	["TranslationKeyContentTypeCondition"]: {
	and?: Array<ResolverInputTypes["TranslationKeyContentTypeCondition"]> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationKeyContentTypeCondition"]> | undefined | null,
	not?: ResolverInputTypes["TranslationKeyContentTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ResolverInputTypes["TranslationKeyContentType"] | undefined | null,
	notEq?: ResolverInputTypes["TranslationKeyContentType"] | undefined | null,
	in?: Array<ResolverInputTypes["TranslationKeyContentType"]> | undefined | null,
	notIn?: Array<ResolverInputTypes["TranslationKeyContentType"]> | undefined | null,
	lt?: ResolverInputTypes["TranslationKeyContentType"] | undefined | null,
	lte?: ResolverInputTypes["TranslationKeyContentType"] | undefined | null,
	gt?: ResolverInputTypes["TranslationKeyContentType"] | undefined | null,
	gte?: ResolverInputTypes["TranslationKeyContentType"] | undefined | null
};
	["TranslationKeyContentType"]:TranslationKeyContentType;
	["TranslationValueWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	value?: ResolverInputTypes["StringCondition"] | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,
	key?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationValueWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationValueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	name?: ResolverInputTypes["StringCondition"] | undefined | null,
	code?: ResolverInputTypes["StringCondition"] | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null
};
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainOrderBy"] | undefined | null,
	identifier?: ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"] | undefined | null,
	fallback?: ResolverInputTypes["TranslationCatalogueOrderBy"] | undefined | null
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	identifier?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	name?: ResolverInputTypes["OrderDirection"] | undefined | null,
	code?: ResolverInputTypes["OrderDirection"] | undefined | null
};
	["TranslationKey"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationKeyMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
domain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null},ResolverInputTypes["TranslationDomain"]],
values?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationValue"]],
valuesByCatalogue?: [{	by: ResolverInputTypes["TranslationKeyValuesByCatalogueUniqueWhere"],	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null},ResolverInputTypes["TranslationValue"]],
paginateValues?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	identifier?:ResolverInputTypes["FieldMeta"],
	contentType?:ResolverInputTypes["FieldMeta"],
	note?:ResolverInputTypes["FieldMeta"],
	domain?:ResolverInputTypes["FieldMeta"],
	values?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValue"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationValueMeta"],
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
catalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
key?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	value?:ResolverInputTypes["FieldMeta"],
	catalogue?:ResolverInputTypes["FieldMeta"],
	key?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	value?: ResolverInputTypes["OrderDirection"] | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueOrderBy"] | undefined | null,
	key?: ResolverInputTypes["TranslationKeyOrderBy"] | undefined | null
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	identifier?: ResolverInputTypes["OrderDirection"] | undefined | null,
	contentType?: ResolverInputTypes["OrderDirection"] | undefined | null,
	note?: ResolverInputTypes["OrderDirection"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainOrderBy"] | undefined | null
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ResolverInputTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: ResolverInputTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	catalogues?: ResolverInputTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	keys?: ResolverInputTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationKeyUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	domain?: ResolverInputTypes["TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: string | undefined | null,
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationValueUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueUniqueWhere"] | undefined | null,
	key?: ResolverInputTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	catalogue?: ResolverInputTypes["TranslationCatalogueUniqueWhere"] | undefined | null
};
	["TranslationValueConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationValueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationValueEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationValue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ResolverInputTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined | null
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined | null
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationCatalogueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCatalogueEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationCatalogue"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationKeyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationKeyEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationKey"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifier"]: AliasType<{
	_meta?:ResolverInputTypes["TranslationCataloguesIdentifierMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
catalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
catalogueByDomain?: [{	by: ResolverInputTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
catalogueByValues?: [{	by: ResolverInputTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
paginateCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCatalogueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	name?:ResolverInputTypes["FieldMeta"],
	code?:ResolverInputTypes["FieldMeta"],
	catalogue?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ResolverInputTypes["TranslationDomainUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ResolverInputTypes["TranslationValueUniqueWhere"] | undefined | null
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ResolverInputTypes["TranslationKeyUniqueWhere"] | undefined | null
};
	["TranslationCataloguesIdentifierConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationCataloguesIdentifierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationCataloguesIdentifierEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationCataloguesIdentifier"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["TranslationDomainEdge"],
		__typename?: boolean | `@${string}`
}>;
	["TranslationDomainEdge"]: AliasType<{
	node?:ResolverInputTypes["TranslationDomain"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCountryConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FilledOrderFormCountryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCountryEdge"]: AliasType<{
	node?:ResolverInputTypes["FilledOrderFormCountry"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistForm"]: AliasType<{
	_meta?:ResolverInputTypes["FilledProductWaitlistFormMeta"],
	id?:boolean | `@${string}`,
	publishedAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
product?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null},ResolverInputTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormMeta"]: AliasType<{
	id?:ResolverInputTypes["FieldMeta"],
	publishedAt?:ResolverInputTypes["FieldMeta"],
	email?:ResolverInputTypes["FieldMeta"],
	product?:ResolverInputTypes["FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormUniqueWhere"]: {
	id?: ResolverInputTypes["UUID"] | undefined | null
};
	["FilledProductWaitlistFormWhere"]: {
	id?: ResolverInputTypes["UUIDCondition"] | undefined | null,
	publishedAt?: ResolverInputTypes["DateTimeCondition"] | undefined | null,
	email?: ResolverInputTypes["StringCondition"] | undefined | null,
	product?: ResolverInputTypes["ProductWhere"] | undefined | null,
	and?: Array<ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null> | undefined | null,
	or?: Array<ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null> | undefined | null,
	not?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null
};
	["FilledProductWaitlistFormOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ResolverInputTypes["OrderDirection"] | undefined | null,
	publishedAt?: ResolverInputTypes["OrderDirection"] | undefined | null,
	email?: ResolverInputTypes["OrderDirection"] | undefined | null,
	product?: ResolverInputTypes["ProductOrderBy"] | undefined | null
};
	["FilledProductWaitlistFormConnection"]: AliasType<{
	pageInfo?:ResolverInputTypes["PageInfo"],
	edges?:ResolverInputTypes["FilledProductWaitlistFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormEdge"]: AliasType<{
	node?:ResolverInputTypes["FilledProductWaitlistForm"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormCreateInput"]: {
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	product?: ResolverInputTypes["FilledProductWaitlistFormCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["FilledProductWaitlistFormCreateProductEntityRelationInput"]: {
	connect?: ResolverInputTypes["ProductUniqueWhere"] | undefined | null
};
	["FilledProductWaitlistFormUpdateInput"]: {
	publishedAt?: ResolverInputTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	product?: ResolverInputTypes["FilledProductWaitlistFormUpdateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["FilledProductWaitlistFormUpdateProductEntityRelationInput"]: {
	connect?: ResolverInputTypes["ProductUniqueWhere"] | undefined | null
};
	["QueryTransaction"]: AliasType<{
getImage?: [{	by: ResolverInputTypes["ImageUniqueWhere"],	filter?: ResolverInputTypes["ImageWhere"] | undefined | null},ResolverInputTypes["Image"]],
listImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Image"]],
paginateImage?: [{	filter?: ResolverInputTypes["ImageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageConnection"]],
getGenericPage?: [{	by: ResolverInputTypes["GenericPageUniqueWhere"],	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null},ResolverInputTypes["GenericPage"]],
listGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPage"]],
paginateGenericPage?: [{	filter?: ResolverInputTypes["GenericPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageConnection"]],
getLinkable?: [{	by: ResolverInputTypes["LinkableUniqueWhere"],	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null},ResolverInputTypes["Linkable"]],
listLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Linkable"]],
paginateLinkable?: [{	filter?: ResolverInputTypes["LinkableWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkableConnection"]],
getSeo?: [{	by: ResolverInputTypes["SeoUniqueWhere"],	filter?: ResolverInputTypes["SeoWhere"] | undefined | null},ResolverInputTypes["Seo"]],
listSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Seo"]],
paginateSeo?: [{	filter?: ResolverInputTypes["SeoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["SeoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["SeoConnection"]],
getGenericPageLocale?: [{	by: ResolverInputTypes["GenericPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null},ResolverInputTypes["GenericPageLocale"]],
listGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageLocale"]],
paginateGenericPageLocale?: [{	filter?: ResolverInputTypes["GenericPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageLocaleConnection"]],
getLocale?: [{	by: ResolverInputTypes["LocaleUniqueWhere"],	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null},ResolverInputTypes["Locale"]],
listLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Locale"]],
paginateLocale?: [{	filter?: ResolverInputTypes["LocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LocaleConnection"]],
getLink?: [{	by: ResolverInputTypes["LinkUniqueWhere"],	filter?: ResolverInputTypes["LinkWhere"] | undefined | null},ResolverInputTypes["Link"]],
listLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Link"]],
paginateLink?: [{	filter?: ResolverInputTypes["LinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkConnection"]],
getHeader?: [{	by: ResolverInputTypes["HeaderUniqueWhere"],	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null},ResolverInputTypes["Header"]],
listHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Header"]],
paginateHeader?: [{	filter?: ResolverInputTypes["HeaderWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderConnection"]],
getHeaderLocale?: [{	by: ResolverInputTypes["HeaderLocaleUniqueWhere"],	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null},ResolverInputTypes["HeaderLocale"]],
listHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["HeaderLocale"]],
paginateHeaderLocale?: [{	filter?: ResolverInputTypes["HeaderLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["HeaderLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["HeaderLocaleConnection"]],
getContent?: [{	by: ResolverInputTypes["ContentUniqueWhere"],	filter?: ResolverInputTypes["ContentWhere"] | undefined | null},ResolverInputTypes["Content"]],
listContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Content"]],
paginateContent?: [{	filter?: ResolverInputTypes["ContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentConnection"]],
getContentBlock?: [{	by: ResolverInputTypes["ContentBlockUniqueWhere"],	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null},ResolverInputTypes["ContentBlock"]],
listContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?: ResolverInputTypes["ContentBlockWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentBlockConnection"]],
getContentReference?: [{	by: ResolverInputTypes["ContentReferenceUniqueWhere"],	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null},ResolverInputTypes["ContentReference"]],
listContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentReference"]],
paginateContentReference?: [{	filter?: ResolverInputTypes["ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentReferenceConnection"]],
getVideo?: [{	by: ResolverInputTypes["VideoUniqueWhere"],	filter?: ResolverInputTypes["VideoWhere"] | undefined | null},ResolverInputTypes["Video"]],
listVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Video"]],
paginateVideo?: [{	filter?: ResolverInputTypes["VideoWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["VideoOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["VideoConnection"]],
getButtonLink?: [{	by: ResolverInputTypes["ButtonLinkUniqueWhere"],	filter?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null},ResolverInputTypes["ButtonLink"]],
listButtonLink?: [{	filter?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ButtonLinkOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ButtonLink"]],
paginateButtonLink?: [{	filter?: ResolverInputTypes["ButtonLinkWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ButtonLinkOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ButtonLinkConnection"]],
getFeature?: [{	by: ResolverInputTypes["FeatureUniqueWhere"],	filter?: ResolverInputTypes["FeatureWhere"] | undefined | null},ResolverInputTypes["Feature"]],
listFeature?: [{	filter?: ResolverInputTypes["FeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Feature"]],
paginateFeature?: [{	filter?: ResolverInputTypes["FeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureConnection"]],
getFeatureItem?: [{	by: ResolverInputTypes["FeatureItemUniqueWhere"],	filter?: ResolverInputTypes["FeatureItemWhere"] | undefined | null},ResolverInputTypes["FeatureItem"]],
listFeatureItem?: [{	filter?: ResolverInputTypes["FeatureItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureItem"]],
paginateFeatureItem?: [{	filter?: ResolverInputTypes["FeatureItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureItemConnection"]],
getFeatureList?: [{	by: ResolverInputTypes["FeatureListUniqueWhere"],	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null},ResolverInputTypes["FeatureList"]],
listFeatureList?: [{	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureList"]],
paginateFeatureList?: [{	filter?: ResolverInputTypes["FeatureListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureListConnection"]],
getLinkItem?: [{	by: ResolverInputTypes["LinkItemUniqueWhere"],	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null},ResolverInputTypes["LinkItem"]],
listLinkItem?: [{	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkItem"]],
paginateLinkItem?: [{	filter?: ResolverInputTypes["LinkItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkItemConnection"]],
getLinkList?: [{	by: ResolverInputTypes["LinkListUniqueWhere"],	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null},ResolverInputTypes["LinkList"]],
listLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["LinkList"]],
paginateLinkList?: [{	filter?: ResolverInputTypes["LinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["LinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["LinkListConnection"]],
getFooter?: [{	by: ResolverInputTypes["FooterUniqueWhere"],	filter?: ResolverInputTypes["FooterWhere"] | undefined | null},ResolverInputTypes["Footer"]],
listFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Footer"]],
paginateFooter?: [{	filter?: ResolverInputTypes["FooterWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterConnection"]],
getFooterGroupLinkList?: [{	by: ResolverInputTypes["FooterGroupLinkListUniqueWhere"],	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null},ResolverInputTypes["FooterGroupLinkList"]],
listFooterGroupLinkList?: [{	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterGroupLinkListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterGroupLinkList"]],
paginateFooterGroupLinkList?: [{	filter?: ResolverInputTypes["FooterGroupLinkListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterGroupLinkListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterGroupLinkListConnection"]],
getFooterLocale?: [{	by: ResolverInputTypes["FooterLocaleUniqueWhere"],	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null},ResolverInputTypes["FooterLocale"]],
listFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FooterLocale"]],
paginateFooterLocale?: [{	filter?: ResolverInputTypes["FooterLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FooterLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FooterLocaleConnection"]],
getGenericPageSideNavigation?: [{	by: ResolverInputTypes["GenericPageSideNavigationUniqueWhere"],	filter?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null},ResolverInputTypes["GenericPageSideNavigation"]],
listGenericPageSideNavigation?: [{	filter?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageSideNavigationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GenericPageSideNavigation"]],
paginateGenericPageSideNavigation?: [{	filter?: ResolverInputTypes["GenericPageSideNavigationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GenericPageSideNavigationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GenericPageSideNavigationConnection"]],
getProjection?: [{	by: ResolverInputTypes["ProjectionUniqueWhere"],	filter?: ResolverInputTypes["ProjectionWhere"] | undefined | null},ResolverInputTypes["Projection"]],
listProjection?: [{	filter?: ResolverInputTypes["ProjectionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Projection"]],
paginateProjection?: [{	filter?: ResolverInputTypes["ProjectionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProjectionConnection"]],
getProjectionContent?: [{	by: ResolverInputTypes["ProjectionContentUniqueWhere"],	filter?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null},ResolverInputTypes["ProjectionContent"]],
listProjectionContent?: [{	filter?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionContentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProjectionContent"]],
paginateProjectionContent?: [{	filter?: ResolverInputTypes["ProjectionContentWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionContentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProjectionContentConnection"]],
getProjectionItem?: [{	by: ResolverInputTypes["ProjectionItemUniqueWhere"],	filter?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null},ResolverInputTypes["ProjectionItem"]],
listProjectionItem?: [{	filter?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProjectionItem"]],
paginateProjectionItem?: [{	filter?: ResolverInputTypes["ProjectionItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProjectionItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProjectionItemConnection"]],
getContentImageWithFeatures?: [{	by: ResolverInputTypes["ContentImageWithFeaturesUniqueWhere"],	filter?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null},ResolverInputTypes["ContentImageWithFeatures"]],
listContentImageWithFeatures?: [{	filter?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentImageWithFeaturesOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ContentImageWithFeatures"]],
paginateContentImageWithFeatures?: [{	filter?: ResolverInputTypes["ContentImageWithFeaturesWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ContentImageWithFeaturesOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ContentImageWithFeaturesConnection"]],
getAsideContentMedia?: [{	by: ResolverInputTypes["AsideContentMediaUniqueWhere"],	filter?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null},ResolverInputTypes["AsideContentMedia"]],
listAsideContentMedia?: [{	filter?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AsideContentMedia"]],
paginateAsideContentMedia?: [{	filter?: ResolverInputTypes["AsideContentMediaWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AsideContentMediaConnection"]],
getAsideContentMediaSpecification?: [{	by: ResolverInputTypes["AsideContentMediaSpecificationUniqueWhere"],	filter?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null},ResolverInputTypes["AsideContentMediaSpecification"]],
listAsideContentMediaSpecification?: [{	filter?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["AsideContentMediaSpecification"]],
paginateAsideContentMediaSpecification?: [{	filter?: ResolverInputTypes["AsideContentMediaSpecificationWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["AsideContentMediaSpecificationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["AsideContentMediaSpecificationConnection"]],
getFile?: [{	by: ResolverInputTypes["FileUniqueWhere"],	filter?: ResolverInputTypes["FileWhere"] | undefined | null},ResolverInputTypes["File"]],
listFile?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["File"]],
paginateFile?: [{	filter?: ResolverInputTypes["FileWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FileConnection"]],
getImageList?: [{	by: ResolverInputTypes["ImageListUniqueWhere"],	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null},ResolverInputTypes["ImageList"]],
listImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageList"]],
paginateImageList?: [{	filter?: ResolverInputTypes["ImageListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListConnection"]],
getImageListItem?: [{	by: ResolverInputTypes["ImageListItemUniqueWhere"],	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null},ResolverInputTypes["ImageListItem"]],
listImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ImageListItem"]],
paginateImageListItem?: [{	filter?: ResolverInputTypes["ImageListItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ImageListItemConnection"]],
getProduct?: [{	by: ResolverInputTypes["ProductUniqueWhere"],	filter?: ResolverInputTypes["ProductWhere"] | undefined | null},ResolverInputTypes["Product"]],
listProduct?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Product"]],
paginateProduct?: [{	filter?: ResolverInputTypes["ProductWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductConnection"]],
getProductCategory?: [{	by: ResolverInputTypes["ProductCategoryUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null},ResolverInputTypes["ProductCategory"]],
listProductCategory?: [{	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategory"]],
paginateProductCategory?: [{	filter?: ResolverInputTypes["ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryConnection"]],
getProductCategoryLocale?: [{	by: ResolverInputTypes["ProductCategoryLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null},ResolverInputTypes["ProductCategoryLocale"]],
listProductCategoryLocale?: [{	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryLocale"]],
paginateProductCategoryLocale?: [{	filter?: ResolverInputTypes["ProductCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryLocaleConnection"]],
getProductLocale?: [{	by: ResolverInputTypes["ProductLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null},ResolverInputTypes["ProductLocale"]],
listProductLocale?: [{	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?: ResolverInputTypes["ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductLocaleConnection"]],
getProductCategoryItem?: [{	by: ResolverInputTypes["ProductCategoryItemUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null},ResolverInputTypes["ProductCategoryItem"]],
listProductCategoryItem?: [{	filter?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryItem"]],
paginateProductCategoryItem?: [{	filter?: ResolverInputTypes["ProductCategoryItemWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryItemConnection"]],
getProductCategoryList?: [{	by: ResolverInputTypes["ProductCategoryListUniqueWhere"],	filter?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null},ResolverInputTypes["ProductCategoryList"]],
listProductCategoryList?: [{	filter?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryListOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductCategoryList"]],
paginateProductCategoryList?: [{	filter?: ResolverInputTypes["ProductCategoryListWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductCategoryListOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductCategoryListConnection"]],
getFeatureSection?: [{	by: ResolverInputTypes["FeatureSectionUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null},ResolverInputTypes["FeatureSection"]],
listFeatureSection?: [{	filter?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSection"]],
paginateFeatureSection?: [{	filter?: ResolverInputTypes["FeatureSectionWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionConnection"]],
getFeatureSectionHeat?: [{	by: ResolverInputTypes["FeatureSectionHeatUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null},ResolverInputTypes["FeatureSectionHeat"]],
listFeatureSectionHeat?: [{	filter?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionHeatOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionHeat"]],
paginateFeatureSectionHeat?: [{	filter?: ResolverInputTypes["FeatureSectionHeatWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionHeatOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionHeatConnection"]],
getFeatureSectionPump?: [{	by: ResolverInputTypes["FeatureSectionPumpUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null},ResolverInputTypes["FeatureSectionPump"]],
listFeatureSectionPump?: [{	filter?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionPumpOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionPump"]],
paginateFeatureSectionPump?: [{	filter?: ResolverInputTypes["FeatureSectionPumpWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionPumpOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionPumpConnection"]],
getFeatureSectionTemperature?: [{	by: ResolverInputTypes["FeatureSectionTemperatureUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null},ResolverInputTypes["FeatureSectionTemperature"]],
listFeatureSectionTemperature?: [{	filter?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionTemperatureOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionTemperature"]],
paginateFeatureSectionTemperature?: [{	filter?: ResolverInputTypes["FeatureSectionTemperatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionTemperatureOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionTemperatureConnection"]],
getFeatureSectionTube?: [{	by: ResolverInputTypes["FeatureSectionTubeUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null},ResolverInputTypes["FeatureSectionTube"]],
listFeatureSectionTube?: [{	filter?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionTubeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionTube"]],
paginateFeatureSectionTube?: [{	filter?: ResolverInputTypes["FeatureSectionTubeWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionTubeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionTubeConnection"]],
getFeatureSectionAntimer?: [{	by: ResolverInputTypes["FeatureSectionAntimerUniqueWhere"],	filter?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null},ResolverInputTypes["FeatureSectionAntimer"]],
listFeatureSectionAntimer?: [{	filter?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionAntimerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FeatureSectionAntimer"]],
paginateFeatureSectionAntimer?: [{	filter?: ResolverInputTypes["FeatureSectionAntimerWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FeatureSectionAntimerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FeatureSectionAntimerConnection"]],
getRedirect?: [{	by: ResolverInputTypes["RedirectUniqueWhere"],	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null},ResolverInputTypes["Redirect"]],
listRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["Redirect"]],
paginateRedirect?: [{	filter?: ResolverInputTypes["RedirectWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["RedirectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["RedirectConnection"]],
getGeneral?: [{	by: ResolverInputTypes["GeneralUniqueWhere"],	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null},ResolverInputTypes["General"]],
listGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["General"]],
paginateGeneral?: [{	filter?: ResolverInputTypes["GeneralWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralConnection"]],
getGeneralLocale?: [{	by: ResolverInputTypes["GeneralLocaleUniqueWhere"],	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null},ResolverInputTypes["GeneralLocale"]],
listGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["GeneralLocale"]],
paginateGeneralLocale?: [{	filter?: ResolverInputTypes["GeneralLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["GeneralLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["GeneralLocaleConnection"]],
getProductFeature?: [{	by: ResolverInputTypes["ProductFeatureUniqueWhere"],	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null},ResolverInputTypes["ProductFeature"]],
listProductFeature?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductFeature"]],
paginateProductFeature?: [{	filter?: ResolverInputTypes["ProductFeatureWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductFeatureConnection"]],
getProductFeatureLocale?: [{	by: ResolverInputTypes["ProductFeatureLocaleUniqueWhere"],	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null},ResolverInputTypes["ProductFeatureLocale"]],
listProductFeatureLocale?: [{	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["ProductFeatureLocale"]],
paginateProductFeatureLocale?: [{	filter?: ResolverInputTypes["ProductFeatureLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["ProductFeatureLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["ProductFeatureLocaleConnection"]],
getFilledOrderForm?: [{	by: ResolverInputTypes["FilledOrderFormUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null},ResolverInputTypes["FilledOrderForm"]],
listFilledOrderForm?: [{	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FilledOrderForm"]],
paginateFilledOrderForm?: [{	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FilledOrderFormConnection"]],
validateCreateFilledOrderForm?: [{	data: ResolverInputTypes["FilledOrderFormCreateInput"]},ResolverInputTypes["_ValidationResult"]],
validateUpdateFilledOrderForm?: [{	by: ResolverInputTypes["FilledOrderFormUniqueWhere"],	data: ResolverInputTypes["FilledOrderFormUpdateInput"]},ResolverInputTypes["_ValidationResult"]],
getOrderPage?: [{	by: ResolverInputTypes["OrderPageUniqueWhere"],	filter?: ResolverInputTypes["OrderPageWhere"] | undefined | null},ResolverInputTypes["OrderPage"]],
listOrderPage?: [{	filter?: ResolverInputTypes["OrderPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["OrderPage"]],
paginateOrderPage?: [{	filter?: ResolverInputTypes["OrderPageWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["OrderPageConnection"]],
getOrderPageLocale?: [{	by: ResolverInputTypes["OrderPageLocaleUniqueWhere"],	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null},ResolverInputTypes["OrderPageLocale"]],
listOrderPageLocale?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["OrderPageLocale"]],
paginateOrderPageLocale?: [{	filter?: ResolverInputTypes["OrderPageLocaleWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["OrderPageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["OrderPageLocaleConnection"]],
getFilledOrderFormEmailAddress?: [{	by: ResolverInputTypes["FilledOrderFormEmailAddressUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null},ResolverInputTypes["FilledOrderFormEmailAddress"]],
listFilledOrderFormEmailAddress?: [{	filter?: ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormEmailAddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FilledOrderFormEmailAddress"]],
paginateFilledOrderFormEmailAddress?: [{	filter?: ResolverInputTypes["FilledOrderFormEmailAddressWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormEmailAddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FilledOrderFormEmailAddressConnection"]],
getTranslationCatalogue?: [{	by: ResolverInputTypes["TranslationCatalogueUniqueWhere"],	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?: ResolverInputTypes["TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCatalogueConnection"]],
getTranslationCataloguesIdentifier?: [{	by: ResolverInputTypes["TranslationCataloguesIdentifierUniqueWhere"],	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
listTranslationCataloguesIdentifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifier"]],
paginateTranslationCataloguesIdentifier?: [{	filter?: ResolverInputTypes["TranslationCataloguesIdentifierWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationCataloguesIdentifierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationCataloguesIdentifierConnection"]],
getTranslationDomain?: [{	by: ResolverInputTypes["TranslationDomainUniqueWhere"],	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null},ResolverInputTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationDomainOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?: ResolverInputTypes["TranslationDomainWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationDomainOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationDomainConnection"]],
getTranslationKey?: [{	by: ResolverInputTypes["TranslationKeyUniqueWhere"],	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null},ResolverInputTypes["TranslationKey"]],
listTranslationKey?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?: ResolverInputTypes["TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationKeyConnection"]],
getTranslationValue?: [{	by: ResolverInputTypes["TranslationValueUniqueWhere"],	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null},ResolverInputTypes["TranslationValue"]],
listTranslationValue?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?: ResolverInputTypes["TranslationValueWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["TranslationValueConnection"]],
getFilledOrderFormCountry?: [{	by: ResolverInputTypes["FilledOrderFormCountryUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null},ResolverInputTypes["FilledOrderFormCountry"]],
listFilledOrderFormCountry?: [{	filter?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormCountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FilledOrderFormCountry"]],
paginateFilledOrderFormCountry?: [{	filter?: ResolverInputTypes["FilledOrderFormCountryWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledOrderFormCountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FilledOrderFormCountryConnection"]],
getFilledProductWaitlistForm?: [{	by: ResolverInputTypes["FilledProductWaitlistFormUniqueWhere"],	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null},ResolverInputTypes["FilledProductWaitlistForm"]],
listFilledProductWaitlistForm?: [{	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledProductWaitlistFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ResolverInputTypes["FilledProductWaitlistForm"]],
paginateFilledProductWaitlistForm?: [{	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null,	orderBy?: Array<ResolverInputTypes["FilledProductWaitlistFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ResolverInputTypes["FilledProductWaitlistFormConnection"]],
validateCreateFilledProductWaitlistForm?: [{	data: ResolverInputTypes["FilledProductWaitlistFormCreateInput"]},ResolverInputTypes["_ValidationResult"]],
validateUpdateFilledProductWaitlistForm?: [{	by: ResolverInputTypes["FilledProductWaitlistFormUniqueWhere"],	data: ResolverInputTypes["FilledProductWaitlistFormUpdateInput"]},ResolverInputTypes["_ValidationResult"]],
		__typename?: boolean | `@${string}`
}>;
	["Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createFilledOrderForm?: [{	data: ResolverInputTypes["FilledOrderFormCreateInput"]},ResolverInputTypes["FilledOrderFormCreateResult"]],
updateFilledOrderForm?: [{	by: ResolverInputTypes["FilledOrderFormUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null,	data: ResolverInputTypes["FilledOrderFormUpdateInput"]},ResolverInputTypes["FilledOrderFormUpdateResult"]],
upsertFilledOrderForm?: [{	by: ResolverInputTypes["FilledOrderFormUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null,	update: ResolverInputTypes["FilledOrderFormUpdateInput"],	create: ResolverInputTypes["FilledOrderFormCreateInput"]},ResolverInputTypes["FilledOrderFormUpsertResult"]],
createFilledProductWaitlistForm?: [{	data: ResolverInputTypes["FilledProductWaitlistFormCreateInput"]},ResolverInputTypes["FilledProductWaitlistFormCreateResult"]],
updateFilledProductWaitlistForm?: [{	by: ResolverInputTypes["FilledProductWaitlistFormUniqueWhere"],	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null,	data: ResolverInputTypes["FilledProductWaitlistFormUpdateInput"]},ResolverInputTypes["FilledProductWaitlistFormUpdateResult"]],
upsertFilledProductWaitlistForm?: [{	by: ResolverInputTypes["FilledProductWaitlistFormUniqueWhere"],	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null,	update: ResolverInputTypes["FilledProductWaitlistFormUpdateInput"],	create: ResolverInputTypes["FilledProductWaitlistFormCreateInput"]},ResolverInputTypes["FilledProductWaitlistFormUpsertResult"]],
transaction?: [{	options?: ResolverInputTypes["MutationTransactionOptions"] | undefined | null},ResolverInputTypes["MutationTransaction"]],
	query?:ResolverInputTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["FilledOrderForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"];
		['...on FilledOrderFormCreateResult']?: Omit<ResolverInputTypes["FilledOrderFormCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on FilledOrderFormUpdateResult']?: Omit<ResolverInputTypes["FilledOrderFormUpdateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on FilledOrderFormUpsertResult']?: Omit<ResolverInputTypes["FilledOrderFormUpsertResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on FilledProductWaitlistFormCreateResult']?: Omit<ResolverInputTypes["FilledProductWaitlistFormCreateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on FilledProductWaitlistFormUpdateResult']?: Omit<ResolverInputTypes["FilledProductWaitlistFormUpdateResult"],keyof ResolverInputTypes["MutationResult"]>;
		['...on FilledProductWaitlistFormUpsertResult']?: Omit<ResolverInputTypes["FilledProductWaitlistFormUpsertResult"],keyof ResolverInputTypes["MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["_MutationError"]: AliasType<{
	path?:ResolverInputTypes["_PathFragment"],
	paths?:ResolverInputTypes["_PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["FilledOrderFormUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["FilledOrderForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["FilledOrderFormUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["FilledOrderForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormCreateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["FilledProductWaitlistForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["FilledProductWaitlistForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["FilledProductWaitlistFormUpsertResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	node?:ResolverInputTypes["FilledProductWaitlistForm"],
	validation?:ResolverInputTypes["_ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransaction"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ResolverInputTypes["_MutationError"],
	validation?:ResolverInputTypes["_ValidationResult"],
createFilledOrderForm?: [{	data: ResolverInputTypes["FilledOrderFormCreateInput"]},ResolverInputTypes["FilledOrderFormCreateResult"]],
updateFilledOrderForm?: [{	by: ResolverInputTypes["FilledOrderFormUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null,	data: ResolverInputTypes["FilledOrderFormUpdateInput"]},ResolverInputTypes["FilledOrderFormUpdateResult"]],
upsertFilledOrderForm?: [{	by: ResolverInputTypes["FilledOrderFormUniqueWhere"],	filter?: ResolverInputTypes["FilledOrderFormWhere"] | undefined | null,	update: ResolverInputTypes["FilledOrderFormUpdateInput"],	create: ResolverInputTypes["FilledOrderFormCreateInput"]},ResolverInputTypes["FilledOrderFormUpsertResult"]],
createFilledProductWaitlistForm?: [{	data: ResolverInputTypes["FilledProductWaitlistFormCreateInput"]},ResolverInputTypes["FilledProductWaitlistFormCreateResult"]],
updateFilledProductWaitlistForm?: [{	by: ResolverInputTypes["FilledProductWaitlistFormUniqueWhere"],	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null,	data: ResolverInputTypes["FilledProductWaitlistFormUpdateInput"]},ResolverInputTypes["FilledProductWaitlistFormUpdateResult"]],
upsertFilledProductWaitlistForm?: [{	by: ResolverInputTypes["FilledProductWaitlistFormUniqueWhere"],	filter?: ResolverInputTypes["FilledProductWaitlistFormWhere"] | undefined | null,	update: ResolverInputTypes["FilledProductWaitlistFormUpdateInput"],	create: ResolverInputTypes["FilledProductWaitlistFormCreateInput"]},ResolverInputTypes["FilledProductWaitlistFormUpsertResult"]],
	query?:ResolverInputTypes["Query"],
		__typename?: boolean | `@${string}`
}>;
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null
};
	["Json"]:unknown;
	["_Schema"]: AliasType<{
	enums?:ResolverInputTypes["_Enum"],
	entities?:ResolverInputTypes["_Entity"],
		__typename?: boolean | `@${string}`
}>;
	["_Entity"]: AliasType<{
	name?:boolean | `@${string}`,
	customPrimaryAllowed?:boolean | `@${string}`,
	fields?:ResolverInputTypes["_Field"],
	unique?:ResolverInputTypes["_UniqueConstraint"],
		__typename?: boolean | `@${string}`
}>;
	["_UniqueConstraint"]: AliasType<{
	fields?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Field"]:AliasType<{
		name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"];
		['...on _Column']?: Omit<ResolverInputTypes["_Column"],keyof ResolverInputTypes["_Field"]>;
		['...on _Relation']?: Omit<ResolverInputTypes["_Relation"],keyof ResolverInputTypes["_Field"]>;
		__typename?: boolean | `@${string}`
}>;
	["_Column"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	enumName?:boolean | `@${string}`,
	defaultValue?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_RelationSide"]:_RelationSide;
	["_OrderByDirection"]:_OrderByDirection;
	["_OrderBy"]: AliasType<{
	path?:boolean | `@${string}`,
	direction?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Relation"]: AliasType<{
	name?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	side?:boolean | `@${string}`,
	targetEntity?:boolean | `@${string}`,
	ownedBy?:boolean | `@${string}`,
	inversedBy?:boolean | `@${string}`,
	nullable?:boolean | `@${string}`,
	onDelete?:boolean | `@${string}`,
	orphanRemoval?:boolean | `@${string}`,
	orderBy?:ResolverInputTypes["_OrderBy"],
	rules?:ResolverInputTypes["_Rule"],
	validators?:ResolverInputTypes["_Validator"],
		__typename?: boolean | `@${string}`
}>;
	["_Rule"]: AliasType<{
	message?:ResolverInputTypes["_RuleMessage"],
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Validator"]: AliasType<{
	operation?:boolean | `@${string}`,
	arguments?:ResolverInputTypes["_Argument"],
		__typename?: boolean | `@${string}`
}>;
	["_Argument"]: AliasType<{
	_ValidatorArgument?:ResolverInputTypes["_ValidatorArgument"],
	_PathArgument?:ResolverInputTypes["_PathArgument"],
	_LiteralArgument?:ResolverInputTypes["_LiteralArgument"],
		__typename?: boolean | `@${string}`
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_PathArgument"]: AliasType<{
	path?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_LiteralArgument"]: AliasType<{
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_RuleMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["_Enum"]: AliasType<{
	name?:boolean | `@${string}`,
	values?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Query"]: {
		getImage?: ModelTypes["Image"] | undefined,
	listImage: Array<ModelTypes["Image"]>,
	paginateImage: ModelTypes["ImageConnection"],
	getGenericPage?: ModelTypes["GenericPage"] | undefined,
	listGenericPage: Array<ModelTypes["GenericPage"]>,
	paginateGenericPage: ModelTypes["GenericPageConnection"],
	getLinkable?: ModelTypes["Linkable"] | undefined,
	listLinkable: Array<ModelTypes["Linkable"]>,
	paginateLinkable: ModelTypes["LinkableConnection"],
	getSeo?: ModelTypes["Seo"] | undefined,
	listSeo: Array<ModelTypes["Seo"]>,
	paginateSeo: ModelTypes["SeoConnection"],
	getGenericPageLocale?: ModelTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<ModelTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: ModelTypes["GenericPageLocaleConnection"],
	getLocale?: ModelTypes["Locale"] | undefined,
	listLocale: Array<ModelTypes["Locale"]>,
	paginateLocale: ModelTypes["LocaleConnection"],
	getLink?: ModelTypes["Link"] | undefined,
	listLink: Array<ModelTypes["Link"]>,
	paginateLink: ModelTypes["LinkConnection"],
	getHeader?: ModelTypes["Header"] | undefined,
	listHeader: Array<ModelTypes["Header"]>,
	paginateHeader: ModelTypes["HeaderConnection"],
	getHeaderLocale?: ModelTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<ModelTypes["HeaderLocale"]>,
	paginateHeaderLocale: ModelTypes["HeaderLocaleConnection"],
	getContent?: ModelTypes["Content"] | undefined,
	listContent: Array<ModelTypes["Content"]>,
	paginateContent: ModelTypes["ContentConnection"],
	getContentBlock?: ModelTypes["ContentBlock"] | undefined,
	listContentBlock: Array<ModelTypes["ContentBlock"]>,
	paginateContentBlock: ModelTypes["ContentBlockConnection"],
	getContentReference?: ModelTypes["ContentReference"] | undefined,
	listContentReference: Array<ModelTypes["ContentReference"]>,
	paginateContentReference: ModelTypes["ContentReferenceConnection"],
	getVideo?: ModelTypes["Video"] | undefined,
	listVideo: Array<ModelTypes["Video"]>,
	paginateVideo: ModelTypes["VideoConnection"],
	getButtonLink?: ModelTypes["ButtonLink"] | undefined,
	listButtonLink: Array<ModelTypes["ButtonLink"]>,
	paginateButtonLink: ModelTypes["ButtonLinkConnection"],
	getFeature?: ModelTypes["Feature"] | undefined,
	listFeature: Array<ModelTypes["Feature"]>,
	paginateFeature: ModelTypes["FeatureConnection"],
	getFeatureItem?: ModelTypes["FeatureItem"] | undefined,
	listFeatureItem: Array<ModelTypes["FeatureItem"]>,
	paginateFeatureItem: ModelTypes["FeatureItemConnection"],
	getFeatureList?: ModelTypes["FeatureList"] | undefined,
	listFeatureList: Array<ModelTypes["FeatureList"]>,
	paginateFeatureList: ModelTypes["FeatureListConnection"],
	getLinkItem?: ModelTypes["LinkItem"] | undefined,
	listLinkItem: Array<ModelTypes["LinkItem"]>,
	paginateLinkItem: ModelTypes["LinkItemConnection"],
	getLinkList?: ModelTypes["LinkList"] | undefined,
	listLinkList: Array<ModelTypes["LinkList"]>,
	paginateLinkList: ModelTypes["LinkListConnection"],
	getFooter?: ModelTypes["Footer"] | undefined,
	listFooter: Array<ModelTypes["Footer"]>,
	paginateFooter: ModelTypes["FooterConnection"],
	getFooterGroupLinkList?: ModelTypes["FooterGroupLinkList"] | undefined,
	listFooterGroupLinkList: Array<ModelTypes["FooterGroupLinkList"]>,
	paginateFooterGroupLinkList: ModelTypes["FooterGroupLinkListConnection"],
	getFooterLocale?: ModelTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<ModelTypes["FooterLocale"]>,
	paginateFooterLocale: ModelTypes["FooterLocaleConnection"],
	getGenericPageSideNavigation?: ModelTypes["GenericPageSideNavigation"] | undefined,
	listGenericPageSideNavigation: Array<ModelTypes["GenericPageSideNavigation"]>,
	paginateGenericPageSideNavigation: ModelTypes["GenericPageSideNavigationConnection"],
	getProjection?: ModelTypes["Projection"] | undefined,
	listProjection: Array<ModelTypes["Projection"]>,
	paginateProjection: ModelTypes["ProjectionConnection"],
	getProjectionContent?: ModelTypes["ProjectionContent"] | undefined,
	listProjectionContent: Array<ModelTypes["ProjectionContent"]>,
	paginateProjectionContent: ModelTypes["ProjectionContentConnection"],
	getProjectionItem?: ModelTypes["ProjectionItem"] | undefined,
	listProjectionItem: Array<ModelTypes["ProjectionItem"]>,
	paginateProjectionItem: ModelTypes["ProjectionItemConnection"],
	getContentImageWithFeatures?: ModelTypes["ContentImageWithFeatures"] | undefined,
	listContentImageWithFeatures: Array<ModelTypes["ContentImageWithFeatures"]>,
	paginateContentImageWithFeatures: ModelTypes["ContentImageWithFeaturesConnection"],
	getAsideContentMedia?: ModelTypes["AsideContentMedia"] | undefined,
	listAsideContentMedia: Array<ModelTypes["AsideContentMedia"]>,
	paginateAsideContentMedia: ModelTypes["AsideContentMediaConnection"],
	getAsideContentMediaSpecification?: ModelTypes["AsideContentMediaSpecification"] | undefined,
	listAsideContentMediaSpecification: Array<ModelTypes["AsideContentMediaSpecification"]>,
	paginateAsideContentMediaSpecification: ModelTypes["AsideContentMediaSpecificationConnection"],
	getFile?: ModelTypes["File"] | undefined,
	listFile: Array<ModelTypes["File"]>,
	paginateFile: ModelTypes["FileConnection"],
	getImageList?: ModelTypes["ImageList"] | undefined,
	listImageList: Array<ModelTypes["ImageList"]>,
	paginateImageList: ModelTypes["ImageListConnection"],
	getImageListItem?: ModelTypes["ImageListItem"] | undefined,
	listImageListItem: Array<ModelTypes["ImageListItem"]>,
	paginateImageListItem: ModelTypes["ImageListItemConnection"],
	getProduct?: ModelTypes["Product"] | undefined,
	listProduct: Array<ModelTypes["Product"]>,
	paginateProduct: ModelTypes["ProductConnection"],
	getProductCategory?: ModelTypes["ProductCategory"] | undefined,
	listProductCategory: Array<ModelTypes["ProductCategory"]>,
	paginateProductCategory: ModelTypes["ProductCategoryConnection"],
	getProductCategoryLocale?: ModelTypes["ProductCategoryLocale"] | undefined,
	listProductCategoryLocale: Array<ModelTypes["ProductCategoryLocale"]>,
	paginateProductCategoryLocale: ModelTypes["ProductCategoryLocaleConnection"],
	getProductLocale?: ModelTypes["ProductLocale"] | undefined,
	listProductLocale: Array<ModelTypes["ProductLocale"]>,
	paginateProductLocale: ModelTypes["ProductLocaleConnection"],
	getProductCategoryItem?: ModelTypes["ProductCategoryItem"] | undefined,
	listProductCategoryItem: Array<ModelTypes["ProductCategoryItem"]>,
	paginateProductCategoryItem: ModelTypes["ProductCategoryItemConnection"],
	getProductCategoryList?: ModelTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<ModelTypes["ProductCategoryList"]>,
	paginateProductCategoryList: ModelTypes["ProductCategoryListConnection"],
	getFeatureSection?: ModelTypes["FeatureSection"] | undefined,
	listFeatureSection: Array<ModelTypes["FeatureSection"]>,
	paginateFeatureSection: ModelTypes["FeatureSectionConnection"],
	getFeatureSectionHeat?: ModelTypes["FeatureSectionHeat"] | undefined,
	listFeatureSectionHeat: Array<ModelTypes["FeatureSectionHeat"]>,
	paginateFeatureSectionHeat: ModelTypes["FeatureSectionHeatConnection"],
	getFeatureSectionPump?: ModelTypes["FeatureSectionPump"] | undefined,
	listFeatureSectionPump: Array<ModelTypes["FeatureSectionPump"]>,
	paginateFeatureSectionPump: ModelTypes["FeatureSectionPumpConnection"],
	getFeatureSectionTemperature?: ModelTypes["FeatureSectionTemperature"] | undefined,
	listFeatureSectionTemperature: Array<ModelTypes["FeatureSectionTemperature"]>,
	paginateFeatureSectionTemperature: ModelTypes["FeatureSectionTemperatureConnection"],
	getFeatureSectionTube?: ModelTypes["FeatureSectionTube"] | undefined,
	listFeatureSectionTube: Array<ModelTypes["FeatureSectionTube"]>,
	paginateFeatureSectionTube: ModelTypes["FeatureSectionTubeConnection"],
	getFeatureSectionAntimer?: ModelTypes["FeatureSectionAntimer"] | undefined,
	listFeatureSectionAntimer: Array<ModelTypes["FeatureSectionAntimer"]>,
	paginateFeatureSectionAntimer: ModelTypes["FeatureSectionAntimerConnection"],
	getRedirect?: ModelTypes["Redirect"] | undefined,
	listRedirect: Array<ModelTypes["Redirect"]>,
	paginateRedirect: ModelTypes["RedirectConnection"],
	getGeneral?: ModelTypes["General"] | undefined,
	listGeneral: Array<ModelTypes["General"]>,
	paginateGeneral: ModelTypes["GeneralConnection"],
	getGeneralLocale?: ModelTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<ModelTypes["GeneralLocale"]>,
	paginateGeneralLocale: ModelTypes["GeneralLocaleConnection"],
	getProductFeature?: ModelTypes["ProductFeature"] | undefined,
	listProductFeature: Array<ModelTypes["ProductFeature"]>,
	paginateProductFeature: ModelTypes["ProductFeatureConnection"],
	getProductFeatureLocale?: ModelTypes["ProductFeatureLocale"] | undefined,
	listProductFeatureLocale: Array<ModelTypes["ProductFeatureLocale"]>,
	paginateProductFeatureLocale: ModelTypes["ProductFeatureLocaleConnection"],
	getFilledOrderForm?: ModelTypes["FilledOrderForm"] | undefined,
	listFilledOrderForm: Array<ModelTypes["FilledOrderForm"]>,
	paginateFilledOrderForm: ModelTypes["FilledOrderFormConnection"],
	validateCreateFilledOrderForm: ModelTypes["_ValidationResult"],
	validateUpdateFilledOrderForm: ModelTypes["_ValidationResult"],
	getOrderPage?: ModelTypes["OrderPage"] | undefined,
	listOrderPage: Array<ModelTypes["OrderPage"]>,
	paginateOrderPage: ModelTypes["OrderPageConnection"],
	getOrderPageLocale?: ModelTypes["OrderPageLocale"] | undefined,
	listOrderPageLocale: Array<ModelTypes["OrderPageLocale"]>,
	paginateOrderPageLocale: ModelTypes["OrderPageLocaleConnection"],
	getFilledOrderFormEmailAddress?: ModelTypes["FilledOrderFormEmailAddress"] | undefined,
	listFilledOrderFormEmailAddress: Array<ModelTypes["FilledOrderFormEmailAddress"]>,
	paginateFilledOrderFormEmailAddress: ModelTypes["FilledOrderFormEmailAddressConnection"],
	getTranslationCatalogue?: ModelTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<ModelTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: ModelTypes["TranslationCatalogueConnection"],
	getTranslationCataloguesIdentifier?: ModelTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<ModelTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: ModelTypes["TranslationCataloguesIdentifierConnection"],
	getTranslationDomain?: ModelTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<ModelTypes["TranslationDomain"]>,
	paginateTranslationDomain: ModelTypes["TranslationDomainConnection"],
	getTranslationKey?: ModelTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<ModelTypes["TranslationKey"]>,
	paginateTranslationKey: ModelTypes["TranslationKeyConnection"],
	getTranslationValue?: ModelTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<ModelTypes["TranslationValue"]>,
	paginateTranslationValue: ModelTypes["TranslationValueConnection"],
	getFilledOrderFormCountry?: ModelTypes["FilledOrderFormCountry"] | undefined,
	listFilledOrderFormCountry: Array<ModelTypes["FilledOrderFormCountry"]>,
	paginateFilledOrderFormCountry: ModelTypes["FilledOrderFormCountryConnection"],
	getFilledProductWaitlistForm?: ModelTypes["FilledProductWaitlistForm"] | undefined,
	listFilledProductWaitlistForm: Array<ModelTypes["FilledProductWaitlistForm"]>,
	paginateFilledProductWaitlistForm: ModelTypes["FilledProductWaitlistFormConnection"],
	validateCreateFilledProductWaitlistForm: ModelTypes["_ValidationResult"],
	validateUpdateFilledProductWaitlistForm: ModelTypes["_ValidationResult"],
	transaction?: ModelTypes["QueryTransaction"] | undefined,
	_info?: ModelTypes["Info"] | undefined,
	schema?: ModelTypes["_Schema"] | undefined
};
	["Image"]: {
		_meta?: ModelTypes["ImageMeta"] | undefined,
	id: ModelTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined,
	fileName?: string | undefined
};
	["ImageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	url?: ModelTypes["FieldMeta"] | undefined,
	width?: ModelTypes["FieldMeta"] | undefined,
	height?: ModelTypes["FieldMeta"] | undefined,
	size?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	alt?: ModelTypes["FieldMeta"] | undefined,
	fileName?: ModelTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
		readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]:any;
	["ImageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ImageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	url?: ModelTypes["StringCondition"] | undefined,
	width?: ModelTypes["IntCondition"] | undefined,
	height?: ModelTypes["IntCondition"] | undefined,
	size?: ModelTypes["IntCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	alt?: ModelTypes["StringCondition"] | undefined,
	fileName?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageWhere"] | undefined
};
	["UUIDCondition"]: {
	and?: Array<ModelTypes["UUIDCondition"]> | undefined,
	or?: Array<ModelTypes["UUIDCondition"]> | undefined,
	not?: ModelTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["UUID"] | undefined,
	notEq?: ModelTypes["UUID"] | undefined,
	in?: Array<ModelTypes["UUID"]> | undefined,
	notIn?: Array<ModelTypes["UUID"]> | undefined,
	lt?: ModelTypes["UUID"] | undefined,
	lte?: ModelTypes["UUID"] | undefined,
	gt?: ModelTypes["UUID"] | undefined,
	gte?: ModelTypes["UUID"] | undefined
};
	["StringCondition"]: {
	and?: Array<ModelTypes["StringCondition"]> | undefined,
	or?: Array<ModelTypes["StringCondition"]> | undefined,
	not?: ModelTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["IntCondition"]: {
	and?: Array<ModelTypes["IntCondition"]> | undefined,
	or?: Array<ModelTypes["IntCondition"]> | undefined,
	not?: ModelTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ImageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	url?: ModelTypes["OrderDirection"] | undefined,
	width?: ModelTypes["OrderDirection"] | undefined,
	height?: ModelTypes["OrderDirection"] | undefined,
	size?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	alt?: ModelTypes["OrderDirection"] | undefined,
	fileName?: ModelTypes["OrderDirection"] | undefined
};
	["OrderDirection"]:OrderDirection;
	["ImageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageEdge"]>
};
	["PageInfo"]: {
		totalCount: number
};
	["ImageEdge"]: {
		node: ModelTypes["Image"]
};
	["GenericPage"]: {
		_meta?: ModelTypes["GenericPageMeta"] | undefined,
	id: ModelTypes["UUID"],
	locales: Array<ModelTypes["GenericPageLocale"]>,
	isSideNavigation: boolean,
	localesByLocale?: ModelTypes["GenericPageLocale"] | undefined,
	localesBySeo?: ModelTypes["GenericPageLocale"] | undefined,
	localesByLink?: ModelTypes["GenericPageLocale"] | undefined,
	localesByContent?: ModelTypes["GenericPageLocale"] | undefined,
	localesBySideNavigationItems?: ModelTypes["GenericPageLocale"] | undefined,
	paginateLocales: ModelTypes["GenericPageLocaleConnection"]
};
	["GenericPageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	isSideNavigation?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPageLocale"]: {
		_meta?: ModelTypes["GenericPageLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	root?: ModelTypes["GenericPage"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	content?: ModelTypes["Content"] | undefined,
	sideNavigationItems: Array<ModelTypes["GenericPageSideNavigation"]>,
	paginateSideNavigationItems: ModelTypes["GenericPageSideNavigationConnection"]
};
	["GenericPageLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	sideNavigationItems?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	locales?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	isSideNavigation?: ModelTypes["BooleanCondition"] | undefined,
	and?: Array<ModelTypes["GenericPageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GenericPageWhere"] | undefined> | undefined,
	not?: ModelTypes["GenericPageWhere"] | undefined
};
	["GenericPageLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["GenericPageWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	sideNavigationItems?: ModelTypes["GenericPageSideNavigationWhere"] | undefined,
	and?: Array<ModelTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["GenericPageLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	code?: ModelTypes["StringCondition"] | undefined,
	label?: ModelTypes["StringCondition"] | undefined,
	genericPages?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	headers?: ModelTypes["HeaderLocaleWhere"] | undefined,
	footers?: ModelTypes["FooterLocaleWhere"] | undefined,
	productCategories?: ModelTypes["ProductCategoryLocaleWhere"] | undefined,
	products?: ModelTypes["ProductLocaleWhere"] | undefined,
	generals?: ModelTypes["GeneralLocaleWhere"] | undefined,
	currency?: ModelTypes["StringCondition"] | undefined,
	productFeatureItems?: ModelTypes["ProductFeatureLocaleWhere"] | undefined,
	orderPages?: ModelTypes["OrderPageLocaleWhere"] | undefined,
	and?: Array<ModelTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["LocaleWhere"] | undefined
};
	["HeaderLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["HeaderWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	specialLink?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["HeaderLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HeaderLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["HeaderLocaleWhere"] | undefined
};
	["HeaderWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	locales?: ModelTypes["HeaderLocaleWhere"] | undefined,
	and?: Array<ModelTypes["HeaderWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["HeaderWhere"] | undefined> | undefined,
	not?: ModelTypes["HeaderWhere"] | undefined
};
	["OneCondition"]: {
	and?: Array<ModelTypes["OneCondition"]> | undefined,
	or?: Array<ModelTypes["OneCondition"]> | undefined,
	not?: ModelTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["One"] | undefined,
	notEq?: ModelTypes["One"] | undefined,
	in?: Array<ModelTypes["One"]> | undefined,
	notIn?: Array<ModelTypes["One"]> | undefined,
	lt?: ModelTypes["One"] | undefined,
	lte?: ModelTypes["One"] | undefined,
	gt?: ModelTypes["One"] | undefined,
	gte?: ModelTypes["One"] | undefined
};
	["One"]:One;
	["LinkWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["LinkTypeCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	externalLink?: ModelTypes["StringCondition"] | undefined,
	internalLink?: ModelTypes["LinkableWhere"] | undefined,
	scrollToTargetId?: ModelTypes["StringCondition"] | undefined,
	file?: ModelTypes["FileWhere"] | undefined,
	and?: Array<ModelTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkWhere"] | undefined
};
	["LinkTypeCondition"]: {
	and?: Array<ModelTypes["LinkTypeCondition"]> | undefined,
	or?: Array<ModelTypes["LinkTypeCondition"]> | undefined,
	not?: ModelTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["LinkType"] | undefined,
	notEq?: ModelTypes["LinkType"] | undefined,
	in?: Array<ModelTypes["LinkType"]> | undefined,
	notIn?: Array<ModelTypes["LinkType"]> | undefined,
	lt?: ModelTypes["LinkType"] | undefined,
	lte?: ModelTypes["LinkType"] | undefined,
	gt?: ModelTypes["LinkType"] | undefined,
	gte?: ModelTypes["LinkType"] | undefined
};
	["LinkType"]:LinkType;
	["LinkableWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	url?: ModelTypes["StringCondition"] | undefined,
	genericPage?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	redirect?: ModelTypes["RedirectWhere"] | undefined,
	orderPage?: ModelTypes["OrderPageLocaleWhere"] | undefined,
	and?: Array<ModelTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkableWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkableWhere"] | undefined
};
	["RedirectWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	target?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["RedirectWhere"] | undefined> | undefined,
	not?: ModelTypes["RedirectWhere"] | undefined
};
	["OrderPageLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	link?: ModelTypes["LinkableWhere"] | undefined,
	root?: ModelTypes["OrderPageWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	termsAndConditions?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["OrderPageLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["OrderPageLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["OrderPageLocaleWhere"] | undefined
};
	["OrderPageWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["OrderPageLocaleWhere"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["OrderPageWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["OrderPageWhere"] | undefined> | undefined,
	not?: ModelTypes["OrderPageWhere"] | undefined
};
	["SeoWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	ogTitle?: ModelTypes["StringCondition"] | undefined,
	ogDescription?: ModelTypes["StringCondition"] | undefined,
	noIndex?: ModelTypes["BooleanCondition"] | undefined,
	noFollow?: ModelTypes["BooleanCondition"] | undefined,
	ogImage?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["SeoWhere"] | undefined> | undefined,
	not?: ModelTypes["SeoWhere"] | undefined
};
	["BooleanCondition"]: {
	and?: Array<ModelTypes["BooleanCondition"]> | undefined,
	or?: Array<ModelTypes["BooleanCondition"]> | undefined,
	not?: ModelTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["FileWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	url?: ModelTypes["StringCondition"] | undefined,
	size?: ModelTypes["IntCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	label?: ModelTypes["StringCondition"] | undefined,
	fileName?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["FileWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FileWhere"] | undefined> | undefined,
	not?: ModelTypes["FileWhere"] | undefined
};
	["FooterLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	copyright?: ModelTypes["StringCondition"] | undefined,
	groupsLinkList?: ModelTypes["FooterGroupLinkListWhere"] | undefined,
	root?: ModelTypes["FooterWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	contact?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["FooterLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FooterLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["FooterLocaleWhere"] | undefined
};
	["FooterGroupLinkListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	footer?: ModelTypes["FooterLocaleWhere"] | undefined,
	linkList?: ModelTypes["LinkListWhere"] | undefined,
	isAddress?: ModelTypes["BooleanCondition"] | undefined,
	addressValue?: ModelTypes["StringCondition"] | undefined,
	addressLink?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["FooterGroupLinkListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FooterGroupLinkListWhere"] | undefined> | undefined,
	not?: ModelTypes["FooterGroupLinkListWhere"] | undefined
};
	["LinkListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["LinkItemWhere"] | undefined,
	and?: Array<ModelTypes["LinkListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkListWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkListWhere"] | undefined
};
	["LinkItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["LinkListWhere"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["LinkItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["LinkItemWhere"] | undefined> | undefined,
	not?: ModelTypes["LinkItemWhere"] | undefined
};
	["FooterWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	logo?: ModelTypes["ImageWhere"] | undefined,
	locales?: ModelTypes["FooterLocaleWhere"] | undefined,
	logoCompany?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["FooterWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FooterWhere"] | undefined> | undefined,
	not?: ModelTypes["FooterWhere"] | undefined
};
	["ProductCategoryLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["ProductCategoryWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	and?: Array<ModelTypes["ProductCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProductCategoryLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["ProductCategoryLocaleWhere"] | undefined
};
	["ProductCategoryWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	products?: ModelTypes["ProductWhere"] | undefined,
	locales?: ModelTypes["ProductCategoryLocaleWhere"] | undefined,
	and?: Array<ModelTypes["ProductCategoryWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProductCategoryWhere"] | undefined> | undefined,
	not?: ModelTypes["ProductCategoryWhere"] | undefined
};
	["ProductWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	miningPower?: ModelTypes["StringCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	category?: ModelTypes["ProductCategoryWhere"] | undefined,
	tileImage?: ModelTypes["ImageWhere"] | undefined,
	locales?: ModelTypes["ProductLocaleWhere"] | undefined,
	isForSale?: ModelTypes["BooleanCondition"] | undefined,
	features?: ModelTypes["ProductFeatureWhere"] | undefined,
	slug?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["ProductWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProductWhere"] | undefined> | undefined,
	not?: ModelTypes["ProductWhere"] | undefined
};
	["ProductLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	root?: ModelTypes["ProductWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	price?: ModelTypes["IntCondition"] | undefined,
	buttonLabel?: ModelTypes["StringCondition"] | undefined,
	isCustomButtonLabel?: ModelTypes["BooleanCondition"] | undefined,
	discountedPrice?: ModelTypes["IntCondition"] | undefined,
	and?: Array<ModelTypes["ProductLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProductLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["ProductLocaleWhere"] | undefined
};
	["ProductFeatureWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	locales?: ModelTypes["ProductFeatureLocaleWhere"] | undefined,
	and?: Array<ModelTypes["ProductFeatureWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProductFeatureWhere"] | undefined> | undefined,
	not?: ModelTypes["ProductFeatureWhere"] | undefined
};
	["ProductFeatureLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	price?: ModelTypes["IntCondition"] | undefined,
	root?: ModelTypes["ProductFeatureWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["ProductFeatureLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProductFeatureLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["ProductFeatureLocaleWhere"] | undefined
};
	["GeneralLocaleWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	root?: ModelTypes["GeneralWhere"] | undefined,
	locale?: ModelTypes["LocaleWhere"] | undefined,
	seo?: ModelTypes["SeoWhere"] | undefined,
	and?: Array<ModelTypes["GeneralLocaleWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GeneralLocaleWhere"] | undefined> | undefined,
	not?: ModelTypes["GeneralLocaleWhere"] | undefined
};
	["GeneralWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	unique?: ModelTypes["OneCondition"] | undefined,
	locales?: ModelTypes["GeneralLocaleWhere"] | undefined,
	and?: Array<ModelTypes["GeneralWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GeneralWhere"] | undefined> | undefined,
	not?: ModelTypes["GeneralWhere"] | undefined
};
	["ContentWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	blocks?: ModelTypes["ContentBlockWhere"] | undefined,
	and?: Array<ModelTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	json?: ModelTypes["StringCondition"] | undefined,
	content?: ModelTypes["ContentWhere"] | undefined,
	references?: ModelTypes["ContentReferenceWhere"] | undefined,
	and?: Array<ModelTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	type?: ModelTypes["ContentReferenceTypeCondition"] | undefined,
	block?: ModelTypes["ContentBlockWhere"] | undefined,
	primaryText?: ModelTypes["StringCondition"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	linkTwo?: ModelTypes["LinkWhere"] | undefined,
	buttonLink?: ModelTypes["ButtonLinkWhere"] | undefined,
	buttonLinkTwo?: ModelTypes["ButtonLinkWhere"] | undefined,
	featureList?: ModelTypes["FeatureListWhere"] | undefined,
	projection?: ModelTypes["ProjectionWhere"] | undefined,
	contentImageWithFeatures?: ModelTypes["ContentImageWithFeaturesWhere"] | undefined,
	asideContentMedia?: ModelTypes["AsideContentMediaWhere"] | undefined,
	imageList?: ModelTypes["ImageListWhere"] | undefined,
	productCategories?: ModelTypes["ProductCategoryListWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	secondaryText?: ModelTypes["StringCondition"] | undefined,
	callToActionTypes?: ModelTypes["CallToActionTypeCondition"] | undefined,
	secondaryImage?: ModelTypes["ImageWhere"] | undefined,
	featureSection?: ModelTypes["FeatureSectionWhere"] | undefined,
	terciaryImage?: ModelTypes["ImageWhere"] | undefined,
	video?: ModelTypes["VideoWhere"] | undefined,
	isImageWithWhiteShadow?: ModelTypes["BooleanCondition"] | undefined,
	secondaryVideo?: ModelTypes["VideoWhere"] | undefined,
	and?: Array<ModelTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
	and?: Array<ModelTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<ModelTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: ModelTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ContentReferenceType"] | undefined,
	notEq?: ModelTypes["ContentReferenceType"] | undefined,
	in?: Array<ModelTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<ModelTypes["ContentReferenceType"]> | undefined,
	lt?: ModelTypes["ContentReferenceType"] | undefined,
	lte?: ModelTypes["ContentReferenceType"] | undefined,
	gt?: ModelTypes["ContentReferenceType"] | undefined,
	gte?: ModelTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]:ContentReferenceType;
	["ButtonLinkWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	visualType?: ModelTypes["ButtonLinkVisualTypeCondition"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	fillType?: ModelTypes["ButtonLinkFillTypeCondition"] | undefined,
	widthType?: ModelTypes["ButtonLinkWidthTypeCondition"] | undefined,
	and?: Array<ModelTypes["ButtonLinkWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ButtonLinkWhere"] | undefined> | undefined,
	not?: ModelTypes["ButtonLinkWhere"] | undefined
};
	["ButtonLinkVisualTypeCondition"]: {
	and?: Array<ModelTypes["ButtonLinkVisualTypeCondition"]> | undefined,
	or?: Array<ModelTypes["ButtonLinkVisualTypeCondition"]> | undefined,
	not?: ModelTypes["ButtonLinkVisualTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ButtonLinkVisualType"] | undefined,
	notEq?: ModelTypes["ButtonLinkVisualType"] | undefined,
	in?: Array<ModelTypes["ButtonLinkVisualType"]> | undefined,
	notIn?: Array<ModelTypes["ButtonLinkVisualType"]> | undefined,
	lt?: ModelTypes["ButtonLinkVisualType"] | undefined,
	lte?: ModelTypes["ButtonLinkVisualType"] | undefined,
	gt?: ModelTypes["ButtonLinkVisualType"] | undefined,
	gte?: ModelTypes["ButtonLinkVisualType"] | undefined
};
	["ButtonLinkVisualType"]:ButtonLinkVisualType;
	["ButtonLinkFillTypeCondition"]: {
	and?: Array<ModelTypes["ButtonLinkFillTypeCondition"]> | undefined,
	or?: Array<ModelTypes["ButtonLinkFillTypeCondition"]> | undefined,
	not?: ModelTypes["ButtonLinkFillTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ButtonLinkFillType"] | undefined,
	notEq?: ModelTypes["ButtonLinkFillType"] | undefined,
	in?: Array<ModelTypes["ButtonLinkFillType"]> | undefined,
	notIn?: Array<ModelTypes["ButtonLinkFillType"]> | undefined,
	lt?: ModelTypes["ButtonLinkFillType"] | undefined,
	lte?: ModelTypes["ButtonLinkFillType"] | undefined,
	gt?: ModelTypes["ButtonLinkFillType"] | undefined,
	gte?: ModelTypes["ButtonLinkFillType"] | undefined
};
	["ButtonLinkFillType"]:ButtonLinkFillType;
	["ButtonLinkWidthTypeCondition"]: {
	and?: Array<ModelTypes["ButtonLinkWidthTypeCondition"]> | undefined,
	or?: Array<ModelTypes["ButtonLinkWidthTypeCondition"]> | undefined,
	not?: ModelTypes["ButtonLinkWidthTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["ButtonLinkWidthType"] | undefined,
	notEq?: ModelTypes["ButtonLinkWidthType"] | undefined,
	in?: Array<ModelTypes["ButtonLinkWidthType"]> | undefined,
	notIn?: Array<ModelTypes["ButtonLinkWidthType"]> | undefined,
	lt?: ModelTypes["ButtonLinkWidthType"] | undefined,
	lte?: ModelTypes["ButtonLinkWidthType"] | undefined,
	gt?: ModelTypes["ButtonLinkWidthType"] | undefined,
	gte?: ModelTypes["ButtonLinkWidthType"] | undefined
};
	["ButtonLinkWidthType"]:ButtonLinkWidthType;
	["FeatureListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["FeatureItemWhere"] | undefined,
	and?: Array<ModelTypes["FeatureListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureListWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureListWhere"] | undefined
};
	["FeatureItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["FeatureListWhere"] | undefined,
	feature?: ModelTypes["FeatureWhere"] | undefined,
	and?: Array<ModelTypes["FeatureItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureItemWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureItemWhere"] | undefined
};
	["FeatureWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	icon?: ModelTypes["ImageWhere"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["FeatureWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureWhere"] | undefined
};
	["ProjectionWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["ProjectionItemWhere"] | undefined,
	and?: Array<ModelTypes["ProjectionWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProjectionWhere"] | undefined> | undefined,
	not?: ModelTypes["ProjectionWhere"] | undefined
};
	["ProjectionItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["ProjectionWhere"] | undefined,
	item?: ModelTypes["ProjectionContentWhere"] | undefined,
	and?: Array<ModelTypes["ProjectionItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProjectionItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ProjectionItemWhere"] | undefined
};
	["ProjectionContentWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["ProjectionContentWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProjectionContentWhere"] | undefined> | undefined,
	not?: ModelTypes["ProjectionContentWhere"] | undefined
};
	["ContentImageWithFeaturesWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	subtitle?: ModelTypes["StringCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	text?: ModelTypes["StringCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	features?: ModelTypes["FeatureListWhere"] | undefined,
	and?: Array<ModelTypes["ContentImageWithFeaturesWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ContentImageWithFeaturesWhere"] | undefined> | undefined,
	not?: ModelTypes["ContentImageWithFeaturesWhere"] | undefined
};
	["AsideContentMediaWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	subtitle?: ModelTypes["StringCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	media?: ModelTypes["ImageWhere"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	tableSpecifiation?: ModelTypes["AsideContentMediaSpecificationWhere"] | undefined,
	and?: Array<ModelTypes["AsideContentMediaWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["AsideContentMediaWhere"] | undefined> | undefined,
	not?: ModelTypes["AsideContentMediaWhere"] | undefined
};
	["AsideContentMediaSpecificationWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	label?: ModelTypes["StringCondition"] | undefined,
	value?: ModelTypes["StringCondition"] | undefined,
	specification?: ModelTypes["AsideContentMediaWhere"] | undefined,
	and?: Array<ModelTypes["AsideContentMediaSpecificationWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["AsideContentMediaSpecificationWhere"] | undefined> | undefined,
	not?: ModelTypes["AsideContentMediaSpecificationWhere"] | undefined
};
	["ImageListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["ImageListItemWhere"] | undefined,
	and?: Array<ModelTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageListWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	list?: ModelTypes["ImageListWhere"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	imageMobile?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ImageListItemWhere"] | undefined
};
	["ProductCategoryListWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	items?: ModelTypes["ProductCategoryItemWhere"] | undefined,
	and?: Array<ModelTypes["ProductCategoryListWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProductCategoryListWhere"] | undefined> | undefined,
	not?: ModelTypes["ProductCategoryListWhere"] | undefined
};
	["ProductCategoryItemWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["ProductCategoryListWhere"] | undefined,
	item?: ModelTypes["ProductCategoryWhere"] | undefined,
	and?: Array<ModelTypes["ProductCategoryItemWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["ProductCategoryItemWhere"] | undefined> | undefined,
	not?: ModelTypes["ProductCategoryItemWhere"] | undefined
};
	["CallToActionTypeCondition"]: {
	and?: Array<ModelTypes["CallToActionTypeCondition"]> | undefined,
	or?: Array<ModelTypes["CallToActionTypeCondition"]> | undefined,
	not?: ModelTypes["CallToActionTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["CallToActionType"] | undefined,
	notEq?: ModelTypes["CallToActionType"] | undefined,
	in?: Array<ModelTypes["CallToActionType"]> | undefined,
	notIn?: Array<ModelTypes["CallToActionType"]> | undefined,
	lt?: ModelTypes["CallToActionType"] | undefined,
	lte?: ModelTypes["CallToActionType"] | undefined,
	gt?: ModelTypes["CallToActionType"] | undefined,
	gte?: ModelTypes["CallToActionType"] | undefined
};
	["CallToActionType"]:CallToActionType;
	["FeatureSectionWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	temperature?: ModelTypes["FeatureSectionTemperatureWhere"] | undefined,
	heat?: ModelTypes["FeatureSectionHeatWhere"] | undefined,
	pump?: ModelTypes["FeatureSectionPumpWhere"] | undefined,
	tube?: ModelTypes["FeatureSectionTubeWhere"] | undefined,
	antimer?: ModelTypes["FeatureSectionAntimerWhere"] | undefined,
	and?: Array<ModelTypes["FeatureSectionWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureSectionWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureSectionWhere"] | undefined
};
	["FeatureSectionTemperatureWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	label?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	number?: ModelTypes["IntCondition"] | undefined,
	and?: Array<ModelTypes["FeatureSectionTemperatureWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureSectionTemperatureWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureSectionTemperatureWhere"] | undefined
};
	["FeatureSectionHeatWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title1?: ModelTypes["StringCondition"] | undefined,
	titleHighlighted1?: ModelTypes["StringCondition"] | undefined,
	titleHighlighted2?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["FeatureSectionHeatWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureSectionHeatWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureSectionHeatWhere"] | undefined
};
	["FeatureSectionPumpWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	sideTitle?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["FeatureSectionPumpWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureSectionPumpWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureSectionPumpWhere"] | undefined
};
	["FeatureSectionTubeWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	title?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	secondaryImage?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["FeatureSectionTubeWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureSectionTubeWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureSectionTubeWhere"] | undefined
};
	["FeatureSectionAntimerWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	label1?: ModelTypes["StringCondition"] | undefined,
	label2?: ModelTypes["StringCondition"] | undefined,
	description?: ModelTypes["StringCondition"] | undefined,
	number?: ModelTypes["IntCondition"] | undefined,
	image?: ModelTypes["ImageWhere"] | undefined,
	and?: Array<ModelTypes["FeatureSectionAntimerWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FeatureSectionAntimerWhere"] | undefined> | undefined,
	not?: ModelTypes["FeatureSectionAntimerWhere"] | undefined
};
	["VideoWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	src?: ModelTypes["StringCondition"] | undefined,
	width?: ModelTypes["IntCondition"] | undefined,
	height?: ModelTypes["IntCondition"] | undefined,
	size?: ModelTypes["IntCondition"] | undefined,
	type?: ModelTypes["StringCondition"] | undefined,
	duration?: ModelTypes["FloatCondition"] | undefined,
	poster?: ModelTypes["ImageWhere"] | undefined,
	fileName?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["VideoWhere"] | undefined> | undefined,
	not?: ModelTypes["VideoWhere"] | undefined
};
	["FloatCondition"]: {
	and?: Array<ModelTypes["FloatCondition"]> | undefined,
	or?: Array<ModelTypes["FloatCondition"]> | undefined,
	not?: ModelTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["GenericPageSideNavigationWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	list?: ModelTypes["GenericPageLocaleWhere"] | undefined,
	link?: ModelTypes["LinkWhere"] | undefined,
	and?: Array<ModelTypes["GenericPageSideNavigationWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["GenericPageSideNavigationWhere"] | undefined> | undefined,
	not?: ModelTypes["GenericPageSideNavigationWhere"] | undefined
};
	["Locale"]: {
		_meta?: ModelTypes["LocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	code: string,
	label?: string | undefined,
	genericPages: Array<ModelTypes["GenericPageLocale"]>,
	headers: Array<ModelTypes["HeaderLocale"]>,
	footers: Array<ModelTypes["FooterLocale"]>,
	productCategories: Array<ModelTypes["ProductCategoryLocale"]>,
	products: Array<ModelTypes["ProductLocale"]>,
	generals: Array<ModelTypes["GeneralLocale"]>,
	currency?: string | undefined,
	productFeatureItems: Array<ModelTypes["ProductFeatureLocale"]>,
	orderPages: Array<ModelTypes["OrderPageLocale"]>,
	genericPagesByRoot?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesBySeo?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesByLink?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesByContent?: ModelTypes["GenericPageLocale"] | undefined,
	genericPagesBySideNavigationItems?: ModelTypes["GenericPageLocale"] | undefined,
	headersByRoot?: ModelTypes["HeaderLocale"] | undefined,
	footersByRoot?: ModelTypes["FooterLocale"] | undefined,
	footersByGroupsLinkList?: ModelTypes["FooterLocale"] | undefined,
	productCategoriesByRoot?: ModelTypes["ProductCategoryLocale"] | undefined,
	productsByRoot?: ModelTypes["ProductLocale"] | undefined,
	generalsByRoot?: ModelTypes["GeneralLocale"] | undefined,
	generalsBySeo?: ModelTypes["GeneralLocale"] | undefined,
	productFeatureItemsByRoot?: ModelTypes["ProductFeatureLocale"] | undefined,
	orderPagesByRoot?: ModelTypes["OrderPageLocale"] | undefined,
	orderPagesByLink?: ModelTypes["OrderPageLocale"] | undefined,
	orderPagesBySeo?: ModelTypes["OrderPageLocale"] | undefined,
	paginateGenericPages: ModelTypes["GenericPageLocaleConnection"],
	paginateHeaders: ModelTypes["HeaderLocaleConnection"],
	paginateFooters: ModelTypes["FooterLocaleConnection"],
	paginateProductCategories: ModelTypes["ProductCategoryLocaleConnection"],
	paginateProducts: ModelTypes["ProductLocaleConnection"],
	paginateGenerals: ModelTypes["GeneralLocaleConnection"],
	paginateProductFeatureItems: ModelTypes["ProductFeatureLocaleConnection"],
	paginateOrderPages: ModelTypes["OrderPageLocaleConnection"]
};
	["LocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	code?: ModelTypes["FieldMeta"] | undefined,
	label?: ModelTypes["FieldMeta"] | undefined,
	genericPages?: ModelTypes["FieldMeta"] | undefined,
	headers?: ModelTypes["FieldMeta"] | undefined,
	footers?: ModelTypes["FieldMeta"] | undefined,
	productCategories?: ModelTypes["FieldMeta"] | undefined,
	products?: ModelTypes["FieldMeta"] | undefined,
	generals?: ModelTypes["FieldMeta"] | undefined,
	currency?: ModelTypes["FieldMeta"] | undefined,
	productFeatureItems?: ModelTypes["FieldMeta"] | undefined,
	orderPages?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPageLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["GenericPageOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined
};
	["GenericPageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	isSideNavigation?: ModelTypes["OrderDirection"] | undefined
};
	["LocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	code?: ModelTypes["OrderDirection"] | undefined,
	label?: ModelTypes["OrderDirection"] | undefined,
	currency?: ModelTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	ogTitle?: ModelTypes["OrderDirection"] | undefined,
	ogDescription?: ModelTypes["OrderDirection"] | undefined,
	noIndex?: ModelTypes["OrderDirection"] | undefined,
	noFollow?: ModelTypes["OrderDirection"] | undefined,
	ogImage?: ModelTypes["ImageOrderBy"] | undefined
};
	["LinkableOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	url?: ModelTypes["OrderDirection"] | undefined,
	genericPage?: ModelTypes["GenericPageLocaleOrderBy"] | undefined,
	redirect?: ModelTypes["RedirectOrderBy"] | undefined,
	orderPage?: ModelTypes["OrderPageLocaleOrderBy"] | undefined
};
	["RedirectOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	target?: ModelTypes["LinkOrderBy"] | undefined
};
	["LinkOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	externalLink?: ModelTypes["OrderDirection"] | undefined,
	internalLink?: ModelTypes["LinkableOrderBy"] | undefined,
	scrollToTargetId?: ModelTypes["OrderDirection"] | undefined,
	file?: ModelTypes["FileOrderBy"] | undefined
};
	["FileOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	url?: ModelTypes["OrderDirection"] | undefined,
	size?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	label?: ModelTypes["OrderDirection"] | undefined,
	fileName?: ModelTypes["OrderDirection"] | undefined
};
	["OrderPageLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkableOrderBy"] | undefined,
	root?: ModelTypes["OrderPageOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined,
	termsAndConditions?: ModelTypes["OrderDirection"] | undefined
};
	["OrderPageOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined
};
	["ContentOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["HeaderLocale"]: {
		_meta?: ModelTypes["HeaderLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["Header"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	specialLink?: ModelTypes["Link"] | undefined
};
	["HeaderLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	specialLink?: ModelTypes["FieldMeta"] | undefined
};
	["Header"]: {
		_meta?: ModelTypes["HeaderMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	logo?: ModelTypes["Image"] | undefined,
	locales: Array<ModelTypes["HeaderLocale"]>,
	localesByLocale?: ModelTypes["HeaderLocale"] | undefined,
	paginateLocales: ModelTypes["HeaderLocaleConnection"]
};
	["HeaderMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["HeaderLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["HeaderOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	specialLink?: ModelTypes["LinkOrderBy"] | undefined
};
	["HeaderOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	code?: string | undefined,
	genericPages?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined,
	headers?: ModelTypes["HeaderLocaleUniqueWhere"] | undefined,
	footers?: ModelTypes["FooterLocaleUniqueWhere"] | undefined,
	productCategories?: ModelTypes["ProductCategoryLocaleUniqueWhere"] | undefined,
	products?: ModelTypes["ProductLocaleUniqueWhere"] | undefined,
	generals?: ModelTypes["GeneralLocaleUniqueWhere"] | undefined,
	productFeatureItems?: ModelTypes["ProductFeatureLocaleUniqueWhere"] | undefined,
	orderPages?: ModelTypes["OrderPageLocaleUniqueWhere"] | undefined
};
	["GenericPageLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["GenericPageUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	content?: ModelTypes["ContentUniqueWhere"] | undefined,
	sideNavigationItems?: ModelTypes["GenericPageSideNavigationUniqueWhere"] | undefined
};
	["GenericPageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	ogImage?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	url?: string | undefined,
	genericPage?: ModelTypes["GenericPageLocaleUniqueWhere"] | undefined,
	redirect?: ModelTypes["RedirectUniqueWhere"] | undefined,
	orderPage?: ModelTypes["OrderPageLocaleUniqueWhere"] | undefined
};
	["RedirectUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	target?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LinkUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["OrderPageLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["OrderPageUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	link?: ModelTypes["LinkableUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["OrderPageUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["OrderPageLocaleUniqueWhere"] | undefined
};
	["ContentUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	blocks?: ModelTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	references?: ModelTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined,
	linkTwo?: ModelTypes["LinkUniqueWhere"] | undefined,
	buttonLink?: ModelTypes["ButtonLinkUniqueWhere"] | undefined,
	buttonLinkTwo?: ModelTypes["ButtonLinkUniqueWhere"] | undefined,
	featureList?: ModelTypes["FeatureListUniqueWhere"] | undefined,
	projection?: ModelTypes["ProjectionUniqueWhere"] | undefined,
	contentImageWithFeatures?: ModelTypes["ContentImageWithFeaturesUniqueWhere"] | undefined,
	asideContentMedia?: ModelTypes["AsideContentMediaUniqueWhere"] | undefined,
	imageList?: ModelTypes["ImageListUniqueWhere"] | undefined,
	productCategories?: ModelTypes["ProductCategoryListUniqueWhere"] | undefined,
	featureSection?: ModelTypes["FeatureSectionUniqueWhere"] | undefined
};
	["ButtonLinkUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["FeatureListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["FeatureItemUniqueWhere"] | undefined
};
	["FeatureItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ProjectionUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["ProjectionItemUniqueWhere"] | undefined
};
	["ProjectionItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ContentImageWithFeaturesUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	features?: ModelTypes["FeatureListUniqueWhere"] | undefined
};
	["AsideContentMediaUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	tableSpecifiation?: ModelTypes["AsideContentMediaSpecificationUniqueWhere"] | undefined
};
	["AsideContentMediaSpecificationUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ImageListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["ProductCategoryListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["ProductCategoryItemUniqueWhere"] | undefined
};
	["ProductCategoryItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FeatureSectionUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	temperature?: ModelTypes["FeatureSectionTemperatureUniqueWhere"] | undefined,
	heat?: ModelTypes["FeatureSectionHeatUniqueWhere"] | undefined,
	pump?: ModelTypes["FeatureSectionPumpUniqueWhere"] | undefined,
	tube?: ModelTypes["FeatureSectionTubeUniqueWhere"] | undefined,
	antimer?: ModelTypes["FeatureSectionAntimerUniqueWhere"] | undefined
};
	["FeatureSectionTemperatureUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FeatureSectionHeatUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FeatureSectionPumpUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FeatureSectionTubeUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FeatureSectionAntimerUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["GenericPageSideNavigationUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["HeaderLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["HeaderUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["HeaderUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["HeaderLocaleUniqueWhere"] | undefined
};
	["FooterLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["FooterUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	groupsLinkList?: ModelTypes["FooterGroupLinkListUniqueWhere"] | undefined
};
	["FooterUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["FooterLocaleUniqueWhere"] | undefined
};
	["FooterGroupLinkListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	linkList?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["LinkListUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	items?: ModelTypes["LinkItemUniqueWhere"] | undefined
};
	["LinkItemUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["ProductCategoryLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["ProductCategoryUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["ProductCategoryUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	products?: ModelTypes["ProductUniqueWhere"] | undefined,
	locales?: ModelTypes["ProductCategoryLocaleUniqueWhere"] | undefined
};
	["ProductUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	slug?: string | undefined,
	locales?: ModelTypes["ProductLocaleUniqueWhere"] | undefined
};
	["ProductLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["ProductUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["GeneralLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["GeneralUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined,
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GeneralUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	unique?: ModelTypes["One"] | undefined,
	locales?: ModelTypes["GeneralLocaleUniqueWhere"] | undefined
};
	["ProductFeatureLocaleUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	root?: ModelTypes["ProductFeatureUniqueWhere"] | undefined,
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["ProductFeatureUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	locales?: ModelTypes["ProductFeatureLocaleUniqueWhere"] | undefined
};
	["HeaderLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HeaderLocaleEdge"]>
};
	["HeaderLocaleEdge"]: {
		node: ModelTypes["HeaderLocale"]
};
	["Link"]: {
		_meta?: ModelTypes["LinkMeta"] | undefined,
	id: ModelTypes["UUID"],
	type: ModelTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: ModelTypes["Linkable"] | undefined,
	scrollToTargetId?: string | undefined,
	file?: ModelTypes["File"] | undefined
};
	["LinkMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	externalLink?: ModelTypes["FieldMeta"] | undefined,
	internalLink?: ModelTypes["FieldMeta"] | undefined,
	scrollToTargetId?: ModelTypes["FieldMeta"] | undefined,
	file?: ModelTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
		_meta?: ModelTypes["LinkableMeta"] | undefined,
	id: ModelTypes["UUID"],
	url: string,
	genericPage?: ModelTypes["GenericPageLocale"] | undefined,
	redirect?: ModelTypes["Redirect"] | undefined,
	orderPage?: ModelTypes["OrderPageLocale"] | undefined
};
	["LinkableMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	url?: ModelTypes["FieldMeta"] | undefined,
	genericPage?: ModelTypes["FieldMeta"] | undefined,
	redirect?: ModelTypes["FieldMeta"] | undefined,
	orderPage?: ModelTypes["FieldMeta"] | undefined
};
	["Redirect"]: {
		_meta?: ModelTypes["RedirectMeta"] | undefined,
	id: ModelTypes["UUID"],
	link?: ModelTypes["Linkable"] | undefined,
	target?: ModelTypes["Link"] | undefined
};
	["RedirectMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	target?: ModelTypes["FieldMeta"] | undefined
};
	["OrderPageLocale"]: {
		_meta?: ModelTypes["OrderPageLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	link?: ModelTypes["Linkable"] | undefined,
	root?: ModelTypes["OrderPage"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	seo?: ModelTypes["Seo"] | undefined,
	termsAndConditions?: string | undefined
};
	["OrderPageLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined,
	termsAndConditions?: ModelTypes["FieldMeta"] | undefined
};
	["OrderPage"]: {
		_meta?: ModelTypes["OrderPageMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["OrderPageLocale"]>,
	logo?: ModelTypes["Image"] | undefined,
	localesByLocale?: ModelTypes["OrderPageLocale"] | undefined,
	localesByLink?: ModelTypes["OrderPageLocale"] | undefined,
	localesBySeo?: ModelTypes["OrderPageLocale"] | undefined,
	paginateLocales: ModelTypes["OrderPageLocaleConnection"]
};
	["OrderPageMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined
};
	["OrderPageLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["OrderPageLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["OrderPageLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["OrderPageLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["OrderPageLocaleEdge"]>
};
	["OrderPageLocaleEdge"]: {
		node: ModelTypes["OrderPageLocale"]
};
	["Seo"]: {
		_meta?: ModelTypes["SeoMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	noIndex?: boolean | undefined,
	noFollow?: boolean | undefined,
	ogImage?: ModelTypes["Image"] | undefined
};
	["SeoMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	ogTitle?: ModelTypes["FieldMeta"] | undefined,
	ogDescription?: ModelTypes["FieldMeta"] | undefined,
	noIndex?: ModelTypes["FieldMeta"] | undefined,
	noFollow?: ModelTypes["FieldMeta"] | undefined,
	ogImage?: ModelTypes["FieldMeta"] | undefined
};
	["File"]: {
		_meta?: ModelTypes["FileMeta"] | undefined,
	id: ModelTypes["UUID"],
	url: string,
	size?: number | undefined,
	type?: string | undefined,
	label?: string | undefined,
	fileName?: string | undefined
};
	["FileMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	url?: ModelTypes["FieldMeta"] | undefined,
	size?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	label?: ModelTypes["FieldMeta"] | undefined,
	fileName?: ModelTypes["FieldMeta"] | undefined
};
	["FooterLocale"]: {
		_meta?: ModelTypes["FooterLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	copyright?: string | undefined,
	groupsLinkList: Array<ModelTypes["FooterGroupLinkList"]>,
	root?: ModelTypes["Footer"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	contact?: string | undefined,
	groupsLinkListByLinkList?: ModelTypes["FooterGroupLinkList"] | undefined,
	paginateGroupsLinkList: ModelTypes["FooterGroupLinkListConnection"]
};
	["FooterLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	copyright?: ModelTypes["FieldMeta"] | undefined,
	groupsLinkList?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	contact?: ModelTypes["FieldMeta"] | undefined
};
	["FooterGroupLinkList"]: {
		_meta?: ModelTypes["FooterGroupLinkListMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	title?: string | undefined,
	footer?: ModelTypes["FooterLocale"] | undefined,
	linkList?: ModelTypes["LinkList"] | undefined,
	isAddress: boolean,
	addressValue?: string | undefined,
	addressLink?: ModelTypes["Link"] | undefined
};
	["FooterGroupLinkListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	footer?: ModelTypes["FieldMeta"] | undefined,
	linkList?: ModelTypes["FieldMeta"] | undefined,
	isAddress?: ModelTypes["FieldMeta"] | undefined,
	addressValue?: ModelTypes["FieldMeta"] | undefined,
	addressLink?: ModelTypes["FieldMeta"] | undefined
};
	["LinkList"]: {
		_meta?: ModelTypes["LinkListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["LinkItem"]>,
	itemsByLink?: ModelTypes["LinkItem"] | undefined,
	paginateItems: ModelTypes["LinkItemConnection"]
};
	["LinkListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["LinkItem"]: {
		_meta?: ModelTypes["LinkItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["LinkList"] | undefined,
	link?: ModelTypes["Link"] | undefined
};
	["LinkItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined
};
	["LinkItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["LinkListOrderBy"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["LinkListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["LinkListItemsByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["LinkItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkItemEdge"]>
};
	["LinkItemEdge"]: {
		node: ModelTypes["LinkItem"]
};
	["FooterGroupLinkListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	footer?: ModelTypes["FooterLocaleOrderBy"] | undefined,
	linkList?: ModelTypes["LinkListOrderBy"] | undefined,
	isAddress?: ModelTypes["OrderDirection"] | undefined,
	addressValue?: ModelTypes["OrderDirection"] | undefined,
	addressLink?: ModelTypes["LinkOrderBy"] | undefined
};
	["FooterLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	copyright?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["FooterOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	contact?: ModelTypes["OrderDirection"] | undefined
};
	["FooterOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined,
	logo?: ModelTypes["ImageOrderBy"] | undefined,
	logoCompany?: ModelTypes["ImageOrderBy"] | undefined
};
	["Footer"]: {
		_meta?: ModelTypes["FooterMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	logo?: ModelTypes["Image"] | undefined,
	locales: Array<ModelTypes["FooterLocale"]>,
	logoCompany?: ModelTypes["Image"] | undefined,
	localesByLocale?: ModelTypes["FooterLocale"] | undefined,
	localesByGroupsLinkList?: ModelTypes["FooterLocale"] | undefined,
	paginateLocales: ModelTypes["FooterLocaleConnection"]
};
	["FooterMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	logo?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	logoCompany?: ModelTypes["FieldMeta"] | undefined
};
	["FooterLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["FooterLocalesByGroupsLinkListUniqueWhere"]: {
	groupsLinkList?: ModelTypes["FooterGroupLinkListUniqueWhere"] | undefined
};
	["FooterLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FooterLocaleEdge"]>
};
	["FooterLocaleEdge"]: {
		node: ModelTypes["FooterLocale"]
};
	["FooterLocaleGroupsLinkListByLinkListUniqueWhere"]: {
	linkList?: ModelTypes["LinkListUniqueWhere"] | undefined
};
	["FooterGroupLinkListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FooterGroupLinkListEdge"]>
};
	["FooterGroupLinkListEdge"]: {
		node: ModelTypes["FooterGroupLinkList"]
};
	["ProductCategoryLocale"]: {
		_meta?: ModelTypes["ProductCategoryLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	name: string,
	root?: ModelTypes["ProductCategory"] | undefined,
	locale?: ModelTypes["Locale"] | undefined
};
	["ProductCategoryLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined
};
	["ProductCategory"]: {
		_meta?: ModelTypes["ProductCategoryMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	products: Array<ModelTypes["Product"]>,
	locales: Array<ModelTypes["ProductCategoryLocale"]>,
	productsBySlug?: ModelTypes["Product"] | undefined,
	productsByLocales?: ModelTypes["Product"] | undefined,
	localesByLocale?: ModelTypes["ProductCategoryLocale"] | undefined,
	paginateProducts: ModelTypes["ProductConnection"],
	paginateLocales: ModelTypes["ProductCategoryLocaleConnection"]
};
	["ProductCategoryMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	products?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["Product"]: {
		_meta?: ModelTypes["ProductMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	miningPower?: string | undefined,
	image?: ModelTypes["Image"] | undefined,
	category?: ModelTypes["ProductCategory"] | undefined,
	tileImage?: ModelTypes["Image"] | undefined,
	locales: Array<ModelTypes["ProductLocale"]>,
	isForSale: boolean,
	features: Array<ModelTypes["ProductFeature"]>,
	slug?: string | undefined,
	localesByLocale?: ModelTypes["ProductLocale"] | undefined,
	paginateLocales: ModelTypes["ProductLocaleConnection"],
	paginateFeatures: ModelTypes["ProductFeatureConnection"]
};
	["ProductMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	miningPower?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	category?: ModelTypes["FieldMeta"] | undefined,
	tileImage?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined,
	isForSale?: ModelTypes["FieldMeta"] | undefined,
	features?: ModelTypes["FieldMeta"] | undefined,
	slug?: ModelTypes["FieldMeta"] | undefined
};
	["ProductLocale"]: {
		_meta?: ModelTypes["ProductLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	name?: string | undefined,
	description?: string | undefined,
	root?: ModelTypes["Product"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	price: number,
	buttonLabel?: string | undefined,
	isCustomButtonLabel: boolean,
	discountedPrice: number
};
	["ProductLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	price?: ModelTypes["FieldMeta"] | undefined,
	buttonLabel?: ModelTypes["FieldMeta"] | undefined,
	isCustomButtonLabel?: ModelTypes["FieldMeta"] | undefined,
	discountedPrice?: ModelTypes["FieldMeta"] | undefined
};
	["ProductLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["ProductOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	price?: ModelTypes["OrderDirection"] | undefined,
	buttonLabel?: ModelTypes["OrderDirection"] | undefined,
	isCustomButtonLabel?: ModelTypes["OrderDirection"] | undefined,
	discountedPrice?: ModelTypes["OrderDirection"] | undefined
};
	["ProductOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	miningPower?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	category?: ModelTypes["ProductCategoryOrderBy"] | undefined,
	tileImage?: ModelTypes["ImageOrderBy"] | undefined,
	isForSale?: ModelTypes["OrderDirection"] | undefined,
	slug?: ModelTypes["OrderDirection"] | undefined
};
	["ProductCategoryOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined
};
	["ProductFeature"]: {
		_meta?: ModelTypes["ProductFeatureMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	locales: Array<ModelTypes["ProductFeatureLocale"]>,
	localesByLocale?: ModelTypes["ProductFeatureLocale"] | undefined,
	paginateLocales: ModelTypes["ProductFeatureLocaleConnection"]
};
	["ProductFeatureMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["ProductFeatureLocale"]: {
		_meta?: ModelTypes["ProductFeatureLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	price: number,
	root?: ModelTypes["ProductFeature"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	title: string
};
	["ProductFeatureLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	price?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined
};
	["ProductFeatureLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	price?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["ProductFeatureOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined
};
	["ProductFeatureOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined
};
	["ProductFeatureLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["ProductFeatureLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProductFeatureLocaleEdge"]>
};
	["ProductFeatureLocaleEdge"]: {
		node: ModelTypes["ProductFeatureLocale"]
};
	["ProductLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["ProductLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProductLocaleEdge"]>
};
	["ProductLocaleEdge"]: {
		node: ModelTypes["ProductLocale"]
};
	["ProductFeatureConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProductFeatureEdge"]>
};
	["ProductFeatureEdge"]: {
		node: ModelTypes["ProductFeature"]
};
	["ProductCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["ProductCategoryOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined
};
	["ProductCategoryProductsBySlugUniqueWhere"]: {
	slug?: string | undefined
};
	["ProductCategoryProductsByLocalesUniqueWhere"]: {
	locales?: ModelTypes["ProductLocaleUniqueWhere"] | undefined
};
	["ProductCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["ProductConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProductEdge"]>
};
	["ProductEdge"]: {
		node: ModelTypes["Product"]
};
	["ProductCategoryLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProductCategoryLocaleEdge"]>
};
	["ProductCategoryLocaleEdge"]: {
		node: ModelTypes["ProductCategoryLocale"]
};
	["GeneralLocale"]: {
		_meta?: ModelTypes["GeneralLocaleMeta"] | undefined,
	id: ModelTypes["UUID"],
	root?: ModelTypes["General"] | undefined,
	locale?: ModelTypes["Locale"] | undefined,
	seo?: ModelTypes["Seo"] | undefined
};
	["GeneralLocaleMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	root?: ModelTypes["FieldMeta"] | undefined,
	locale?: ModelTypes["FieldMeta"] | undefined,
	seo?: ModelTypes["FieldMeta"] | undefined
};
	["General"]: {
		_meta?: ModelTypes["GeneralMeta"] | undefined,
	id: ModelTypes["UUID"],
	unique: ModelTypes["One"],
	locales: Array<ModelTypes["GeneralLocale"]>,
	localesByLocale?: ModelTypes["GeneralLocale"] | undefined,
	localesBySeo?: ModelTypes["GeneralLocale"] | undefined,
	paginateLocales: ModelTypes["GeneralLocaleConnection"]
};
	["GeneralMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	unique?: ModelTypes["FieldMeta"] | undefined,
	locales?: ModelTypes["FieldMeta"] | undefined
};
	["GeneralLocaleOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	root?: ModelTypes["GeneralOrderBy"] | undefined,
	locale?: ModelTypes["LocaleOrderBy"] | undefined,
	seo?: ModelTypes["SeoOrderBy"] | undefined
};
	["GeneralOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	unique?: ModelTypes["OrderDirection"] | undefined
};
	["GeneralLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["GeneralLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GeneralLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GeneralLocaleEdge"]>
};
	["GeneralLocaleEdge"]: {
		node: ModelTypes["GeneralLocale"]
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
	root?: ModelTypes["GenericPageUniqueWhere"] | undefined
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["LocaleGenericPagesBySideNavigationItemsUniqueWhere"]: {
	sideNavigationItems?: ModelTypes["GenericPageSideNavigationUniqueWhere"] | undefined
};
	["LocaleHeadersByRootUniqueWhere"]: {
	root?: ModelTypes["HeaderUniqueWhere"] | undefined
};
	["LocaleFootersByRootUniqueWhere"]: {
	root?: ModelTypes["FooterUniqueWhere"] | undefined
};
	["LocaleFootersByGroupsLinkListUniqueWhere"]: {
	groupsLinkList?: ModelTypes["FooterGroupLinkListUniqueWhere"] | undefined
};
	["LocaleProductCategoriesByRootUniqueWhere"]: {
	root?: ModelTypes["ProductCategoryUniqueWhere"] | undefined
};
	["LocaleProductsByRootUniqueWhere"]: {
	root?: ModelTypes["ProductUniqueWhere"] | undefined
};
	["LocaleGeneralsByRootUniqueWhere"]: {
	root?: ModelTypes["GeneralUniqueWhere"] | undefined
};
	["LocaleGeneralsBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["LocaleProductFeatureItemsByRootUniqueWhere"]: {
	root?: ModelTypes["ProductFeatureUniqueWhere"] | undefined
};
	["LocaleOrderPagesByRootUniqueWhere"]: {
	root?: ModelTypes["OrderPageUniqueWhere"] | undefined
};
	["LocaleOrderPagesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleOrderPagesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageLocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GenericPageLocaleEdge"]>
};
	["GenericPageLocaleEdge"]: {
		node: ModelTypes["GenericPageLocale"]
};
	["Content"]: {
		_meta?: ModelTypes["ContentMeta"] | undefined,
	id: ModelTypes["UUID"],
	blocks: Array<ModelTypes["ContentBlock"]>,
	blocksByReferences?: ModelTypes["ContentBlock"] | undefined,
	paginateBlocks: ModelTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	blocks?: ModelTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
		_meta?: ModelTypes["ContentBlockMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	json: string,
	content?: ModelTypes["Content"] | undefined,
	references: Array<ModelTypes["ContentReference"]>,
	referencesByLink?: ModelTypes["ContentReference"] | undefined,
	referencesByLinkTwo?: ModelTypes["ContentReference"] | undefined,
	referencesByButtonLink?: ModelTypes["ContentReference"] | undefined,
	referencesByButtonLinkTwo?: ModelTypes["ContentReference"] | undefined,
	referencesByFeatureList?: ModelTypes["ContentReference"] | undefined,
	referencesByProjection?: ModelTypes["ContentReference"] | undefined,
	referencesByContentImageWithFeatures?: ModelTypes["ContentReference"] | undefined,
	referencesByAsideContentMedia?: ModelTypes["ContentReference"] | undefined,
	referencesByImageList?: ModelTypes["ContentReference"] | undefined,
	referencesByProductCategories?: ModelTypes["ContentReference"] | undefined,
	referencesByFeatureSection?: ModelTypes["ContentReference"] | undefined,
	paginateReferences: ModelTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	json?: ModelTypes["FieldMeta"] | undefined,
	content?: ModelTypes["FieldMeta"] | undefined,
	references?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
		_meta?: ModelTypes["ContentReferenceMeta"] | undefined,
	id: ModelTypes["UUID"],
	type: ModelTypes["ContentReferenceType"],
	block?: ModelTypes["ContentBlock"] | undefined,
	primaryText?: string | undefined,
	link?: ModelTypes["Link"] | undefined,
	linkTwo?: ModelTypes["Link"] | undefined,
	buttonLink?: ModelTypes["ButtonLink"] | undefined,
	buttonLinkTwo?: ModelTypes["ButtonLink"] | undefined,
	featureList?: ModelTypes["FeatureList"] | undefined,
	projection?: ModelTypes["Projection"] | undefined,
	contentImageWithFeatures?: ModelTypes["ContentImageWithFeatures"] | undefined,
	asideContentMedia?: ModelTypes["AsideContentMedia"] | undefined,
	imageList?: ModelTypes["ImageList"] | undefined,
	productCategories?: ModelTypes["ProductCategoryList"] | undefined,
	image?: ModelTypes["Image"] | undefined,
	secondaryText?: string | undefined,
	callToActionTypes: ModelTypes["CallToActionType"],
	secondaryImage?: ModelTypes["Image"] | undefined,
	featureSection?: ModelTypes["FeatureSection"] | undefined,
	terciaryImage?: ModelTypes["Image"] | undefined,
	video?: ModelTypes["Video"] | undefined,
	isImageWithWhiteShadow: boolean,
	secondaryVideo?: ModelTypes["Video"] | undefined
};
	["ContentReferenceMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	block?: ModelTypes["FieldMeta"] | undefined,
	primaryText?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	linkTwo?: ModelTypes["FieldMeta"] | undefined,
	buttonLink?: ModelTypes["FieldMeta"] | undefined,
	buttonLinkTwo?: ModelTypes["FieldMeta"] | undefined,
	featureList?: ModelTypes["FieldMeta"] | undefined,
	projection?: ModelTypes["FieldMeta"] | undefined,
	contentImageWithFeatures?: ModelTypes["FieldMeta"] | undefined,
	asideContentMedia?: ModelTypes["FieldMeta"] | undefined,
	imageList?: ModelTypes["FieldMeta"] | undefined,
	productCategories?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	secondaryText?: ModelTypes["FieldMeta"] | undefined,
	callToActionTypes?: ModelTypes["FieldMeta"] | undefined,
	secondaryImage?: ModelTypes["FieldMeta"] | undefined,
	featureSection?: ModelTypes["FieldMeta"] | undefined,
	terciaryImage?: ModelTypes["FieldMeta"] | undefined,
	video?: ModelTypes["FieldMeta"] | undefined,
	isImageWithWhiteShadow?: ModelTypes["FieldMeta"] | undefined,
	secondaryVideo?: ModelTypes["FieldMeta"] | undefined
};
	["ButtonLink"]: {
		_meta?: ModelTypes["ButtonLinkMeta"] | undefined,
	id: ModelTypes["UUID"],
	visualType: ModelTypes["ButtonLinkVisualType"],
	link?: ModelTypes["Link"] | undefined,
	fillType: ModelTypes["ButtonLinkFillType"],
	widthType: ModelTypes["ButtonLinkWidthType"]
};
	["ButtonLinkMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	visualType?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	fillType?: ModelTypes["FieldMeta"] | undefined,
	widthType?: ModelTypes["FieldMeta"] | undefined
};
	["FeatureList"]: {
		_meta?: ModelTypes["FeatureListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["FeatureItem"]>,
	paginateItems: ModelTypes["FeatureItemConnection"]
};
	["FeatureListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["FeatureItem"]: {
		_meta?: ModelTypes["FeatureItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["FeatureList"] | undefined,
	feature?: ModelTypes["Feature"] | undefined
};
	["FeatureItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	feature?: ModelTypes["FieldMeta"] | undefined
};
	["Feature"]: {
		_meta?: ModelTypes["FeatureMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	icon?: ModelTypes["Image"] | undefined,
	text?: string | undefined
};
	["FeatureMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	icon?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined
};
	["FeatureItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["FeatureListOrderBy"] | undefined,
	feature?: ModelTypes["FeatureOrderBy"] | undefined
};
	["FeatureListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["FeatureOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	icon?: ModelTypes["ImageOrderBy"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined
};
	["FeatureItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureItemEdge"]>
};
	["FeatureItemEdge"]: {
		node: ModelTypes["FeatureItem"]
};
	["Projection"]: {
		_meta?: ModelTypes["ProjectionMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["ProjectionItem"]>,
	paginateItems: ModelTypes["ProjectionItemConnection"]
};
	["ProjectionMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["ProjectionItem"]: {
		_meta?: ModelTypes["ProjectionItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["Projection"] | undefined,
	item?: ModelTypes["ProjectionContent"] | undefined
};
	["ProjectionItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	item?: ModelTypes["FieldMeta"] | undefined
};
	["ProjectionContent"]: {
		_meta?: ModelTypes["ProjectionContentMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	text?: string | undefined,
	image?: ModelTypes["Image"] | undefined
};
	["ProjectionContentMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined
};
	["ProjectionItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["ProjectionOrderBy"] | undefined,
	item?: ModelTypes["ProjectionContentOrderBy"] | undefined
};
	["ProjectionOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["ProjectionContentOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined
};
	["ProjectionItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProjectionItemEdge"]>
};
	["ProjectionItemEdge"]: {
		node: ModelTypes["ProjectionItem"]
};
	["ContentImageWithFeatures"]: {
		_meta?: ModelTypes["ContentImageWithFeaturesMeta"] | undefined,
	id: ModelTypes["UUID"],
	subtitle?: string | undefined,
	title?: string | undefined,
	text?: string | undefined,
	image?: ModelTypes["Image"] | undefined,
	features?: ModelTypes["FeatureList"] | undefined
};
	["ContentImageWithFeaturesMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	subtitle?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	text?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	features?: ModelTypes["FieldMeta"] | undefined
};
	["AsideContentMedia"]: {
		_meta?: ModelTypes["AsideContentMediaMeta"] | undefined,
	id: ModelTypes["UUID"],
	subtitle?: string | undefined,
	title?: string | undefined,
	media?: ModelTypes["Image"] | undefined,
	link?: ModelTypes["Link"] | undefined,
	tableSpecifiation: Array<ModelTypes["AsideContentMediaSpecification"]>,
	paginateTableSpecifiation: ModelTypes["AsideContentMediaSpecificationConnection"]
};
	["AsideContentMediaMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	subtitle?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	media?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined,
	tableSpecifiation?: ModelTypes["FieldMeta"] | undefined
};
	["AsideContentMediaSpecification"]: {
		_meta?: ModelTypes["AsideContentMediaSpecificationMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	label?: string | undefined,
	value?: string | undefined,
	specification?: ModelTypes["AsideContentMedia"] | undefined
};
	["AsideContentMediaSpecificationMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	label?: ModelTypes["FieldMeta"] | undefined,
	value?: ModelTypes["FieldMeta"] | undefined,
	specification?: ModelTypes["FieldMeta"] | undefined
};
	["AsideContentMediaSpecificationOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	label?: ModelTypes["OrderDirection"] | undefined,
	value?: ModelTypes["OrderDirection"] | undefined,
	specification?: ModelTypes["AsideContentMediaOrderBy"] | undefined
};
	["AsideContentMediaOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	subtitle?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	media?: ModelTypes["ImageOrderBy"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["AsideContentMediaSpecificationConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["AsideContentMediaSpecificationEdge"]>
};
	["AsideContentMediaSpecificationEdge"]: {
		node: ModelTypes["AsideContentMediaSpecification"]
};
	["ImageList"]: {
		_meta?: ModelTypes["ImageListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["ImageListItem"]>,
	paginateItems: ModelTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
		_meta?: ModelTypes["ImageListItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	description?: string | undefined,
	list?: ModelTypes["ImageList"] | undefined,
	image?: ModelTypes["Image"] | undefined,
	imageMobile?: ModelTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	imageMobile?: ModelTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["ImageListOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	imageMobile?: ModelTypes["ImageOrderBy"] | undefined
};
	["ImageListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["ImageListItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
		node: ModelTypes["ImageListItem"]
};
	["ProductCategoryList"]: {
		_meta?: ModelTypes["ProductCategoryListMeta"] | undefined,
	id: ModelTypes["UUID"],
	items: Array<ModelTypes["ProductCategoryItem"]>,
	paginateItems: ModelTypes["ProductCategoryItemConnection"]
};
	["ProductCategoryListMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	items?: ModelTypes["FieldMeta"] | undefined
};
	["ProductCategoryItem"]: {
		_meta?: ModelTypes["ProductCategoryItemMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["ProductCategoryList"] | undefined,
	item?: ModelTypes["ProductCategory"] | undefined
};
	["ProductCategoryItemMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	item?: ModelTypes["FieldMeta"] | undefined
};
	["ProductCategoryItemOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["ProductCategoryListOrderBy"] | undefined,
	item?: ModelTypes["ProductCategoryOrderBy"] | undefined
};
	["ProductCategoryListOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined
};
	["ProductCategoryItemConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProductCategoryItemEdge"]>
};
	["ProductCategoryItemEdge"]: {
		node: ModelTypes["ProductCategoryItem"]
};
	["FeatureSection"]: {
		_meta?: ModelTypes["FeatureSectionMeta"] | undefined,
	id: ModelTypes["UUID"],
	temperature?: ModelTypes["FeatureSectionTemperature"] | undefined,
	heat?: ModelTypes["FeatureSectionHeat"] | undefined,
	pump?: ModelTypes["FeatureSectionPump"] | undefined,
	tube?: ModelTypes["FeatureSectionTube"] | undefined,
	antimer?: ModelTypes["FeatureSectionAntimer"] | undefined
};
	["FeatureSectionMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	temperature?: ModelTypes["FieldMeta"] | undefined,
	heat?: ModelTypes["FieldMeta"] | undefined,
	pump?: ModelTypes["FieldMeta"] | undefined,
	tube?: ModelTypes["FieldMeta"] | undefined,
	antimer?: ModelTypes["FieldMeta"] | undefined
};
	["FeatureSectionTemperature"]: {
		_meta?: ModelTypes["FeatureSectionTemperatureMeta"] | undefined,
	id: ModelTypes["UUID"],
	label?: string | undefined,
	description?: string | undefined,
	number?: number | undefined
};
	["FeatureSectionTemperatureMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	label?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	number?: ModelTypes["FieldMeta"] | undefined
};
	["FeatureSectionHeat"]: {
		_meta?: ModelTypes["FeatureSectionHeatMeta"] | undefined,
	id: ModelTypes["UUID"],
	title1?: string | undefined,
	titleHighlighted1?: string | undefined,
	titleHighlighted2?: string | undefined,
	description?: string | undefined,
	image?: ModelTypes["Image"] | undefined
};
	["FeatureSectionHeatMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title1?: ModelTypes["FieldMeta"] | undefined,
	titleHighlighted1?: ModelTypes["FieldMeta"] | undefined,
	titleHighlighted2?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined
};
	["FeatureSectionPump"]: {
		_meta?: ModelTypes["FeatureSectionPumpMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	sideTitle?: string | undefined,
	description?: string | undefined
};
	["FeatureSectionPumpMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	sideTitle?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined
};
	["FeatureSectionTube"]: {
		_meta?: ModelTypes["FeatureSectionTubeMeta"] | undefined,
	id: ModelTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	image?: ModelTypes["Image"] | undefined,
	secondaryImage?: ModelTypes["Image"] | undefined
};
	["FeatureSectionTubeMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	title?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined,
	secondaryImage?: ModelTypes["FieldMeta"] | undefined
};
	["FeatureSectionAntimer"]: {
		_meta?: ModelTypes["FeatureSectionAntimerMeta"] | undefined,
	id: ModelTypes["UUID"],
	label1?: string | undefined,
	label2?: string | undefined,
	description?: string | undefined,
	number?: number | undefined,
	image?: ModelTypes["Image"] | undefined
};
	["FeatureSectionAntimerMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	label1?: ModelTypes["FieldMeta"] | undefined,
	label2?: ModelTypes["FieldMeta"] | undefined,
	description?: ModelTypes["FieldMeta"] | undefined,
	number?: ModelTypes["FieldMeta"] | undefined,
	image?: ModelTypes["FieldMeta"] | undefined
};
	["Video"]: {
		_meta?: ModelTypes["VideoMeta"] | undefined,
	id: ModelTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: ModelTypes["Image"] | undefined,
	fileName?: string | undefined
};
	["VideoMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	src?: ModelTypes["FieldMeta"] | undefined,
	width?: ModelTypes["FieldMeta"] | undefined,
	height?: ModelTypes["FieldMeta"] | undefined,
	size?: ModelTypes["FieldMeta"] | undefined,
	type?: ModelTypes["FieldMeta"] | undefined,
	duration?: ModelTypes["FieldMeta"] | undefined,
	poster?: ModelTypes["FieldMeta"] | undefined,
	fileName?: ModelTypes["FieldMeta"] | undefined
};
	["ContentReferenceOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	block?: ModelTypes["ContentBlockOrderBy"] | undefined,
	primaryText?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined,
	linkTwo?: ModelTypes["LinkOrderBy"] | undefined,
	buttonLink?: ModelTypes["ButtonLinkOrderBy"] | undefined,
	buttonLinkTwo?: ModelTypes["ButtonLinkOrderBy"] | undefined,
	featureList?: ModelTypes["FeatureListOrderBy"] | undefined,
	projection?: ModelTypes["ProjectionOrderBy"] | undefined,
	contentImageWithFeatures?: ModelTypes["ContentImageWithFeaturesOrderBy"] | undefined,
	asideContentMedia?: ModelTypes["AsideContentMediaOrderBy"] | undefined,
	imageList?: ModelTypes["ImageListOrderBy"] | undefined,
	productCategories?: ModelTypes["ProductCategoryListOrderBy"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	secondaryText?: ModelTypes["OrderDirection"] | undefined,
	callToActionTypes?: ModelTypes["OrderDirection"] | undefined,
	secondaryImage?: ModelTypes["ImageOrderBy"] | undefined,
	featureSection?: ModelTypes["FeatureSectionOrderBy"] | undefined,
	terciaryImage?: ModelTypes["ImageOrderBy"] | undefined,
	video?: ModelTypes["VideoOrderBy"] | undefined,
	isImageWithWhiteShadow?: ModelTypes["OrderDirection"] | undefined,
	secondaryVideo?: ModelTypes["VideoOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	json?: ModelTypes["OrderDirection"] | undefined,
	content?: ModelTypes["ContentOrderBy"] | undefined
};
	["ButtonLinkOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	visualType?: ModelTypes["OrderDirection"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined,
	fillType?: ModelTypes["OrderDirection"] | undefined,
	widthType?: ModelTypes["OrderDirection"] | undefined
};
	["ContentImageWithFeaturesOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	subtitle?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	text?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	features?: ModelTypes["FeatureListOrderBy"] | undefined
};
	["FeatureSectionOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	temperature?: ModelTypes["FeatureSectionTemperatureOrderBy"] | undefined,
	heat?: ModelTypes["FeatureSectionHeatOrderBy"] | undefined,
	pump?: ModelTypes["FeatureSectionPumpOrderBy"] | undefined,
	tube?: ModelTypes["FeatureSectionTubeOrderBy"] | undefined,
	antimer?: ModelTypes["FeatureSectionAntimerOrderBy"] | undefined
};
	["FeatureSectionTemperatureOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	label?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	number?: ModelTypes["OrderDirection"] | undefined
};
	["FeatureSectionHeatOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title1?: ModelTypes["OrderDirection"] | undefined,
	titleHighlighted1?: ModelTypes["OrderDirection"] | undefined,
	titleHighlighted2?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined
};
	["FeatureSectionPumpOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	sideTitle?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined
};
	["FeatureSectionTubeOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	title?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined,
	secondaryImage?: ModelTypes["ImageOrderBy"] | undefined
};
	["FeatureSectionAntimerOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	label1?: ModelTypes["OrderDirection"] | undefined,
	label2?: ModelTypes["OrderDirection"] | undefined,
	description?: ModelTypes["OrderDirection"] | undefined,
	number?: ModelTypes["OrderDirection"] | undefined,
	image?: ModelTypes["ImageOrderBy"] | undefined
};
	["VideoOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	src?: ModelTypes["OrderDirection"] | undefined,
	width?: ModelTypes["OrderDirection"] | undefined,
	height?: ModelTypes["OrderDirection"] | undefined,
	size?: ModelTypes["OrderDirection"] | undefined,
	type?: ModelTypes["OrderDirection"] | undefined,
	duration?: ModelTypes["OrderDirection"] | undefined,
	poster?: ModelTypes["ImageOrderBy"] | undefined,
	fileName?: ModelTypes["OrderDirection"] | undefined
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByLinkTwoUniqueWhere"]: {
	linkTwo?: ModelTypes["LinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByButtonLinkUniqueWhere"]: {
	buttonLink?: ModelTypes["ButtonLinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByButtonLinkTwoUniqueWhere"]: {
	buttonLinkTwo?: ModelTypes["ButtonLinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByFeatureListUniqueWhere"]: {
	featureList?: ModelTypes["FeatureListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByProjectionUniqueWhere"]: {
	projection?: ModelTypes["ProjectionUniqueWhere"] | undefined
};
	["ContentBlockReferencesByContentImageWithFeaturesUniqueWhere"]: {
	contentImageWithFeatures?: ModelTypes["ContentImageWithFeaturesUniqueWhere"] | undefined
};
	["ContentBlockReferencesByAsideContentMediaUniqueWhere"]: {
	asideContentMedia?: ModelTypes["AsideContentMediaUniqueWhere"] | undefined
};
	["ContentBlockReferencesByImageListUniqueWhere"]: {
	imageList?: ModelTypes["ImageListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByProductCategoriesUniqueWhere"]: {
	productCategories?: ModelTypes["ProductCategoryListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByFeatureSectionUniqueWhere"]: {
	featureSection?: ModelTypes["FeatureSectionUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
		node: ModelTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
	references?: ModelTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
		node: ModelTypes["ContentBlock"]
};
	["GenericPageSideNavigation"]: {
		_meta?: ModelTypes["GenericPageSideNavigationMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	list?: ModelTypes["GenericPageLocale"] | undefined,
	link?: ModelTypes["Link"] | undefined
};
	["GenericPageSideNavigationMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	list?: ModelTypes["FieldMeta"] | undefined,
	link?: ModelTypes["FieldMeta"] | undefined
};
	["GenericPageSideNavigationOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	list?: ModelTypes["GenericPageLocaleOrderBy"] | undefined,
	link?: ModelTypes["LinkOrderBy"] | undefined
};
	["GenericPageSideNavigationConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GenericPageSideNavigationEdge"]>
};
	["GenericPageSideNavigationEdge"]: {
		node: ModelTypes["GenericPageSideNavigation"]
};
	["GenericPageLocalesByLocaleUniqueWhere"]: {
	locale?: ModelTypes["LocaleUniqueWhere"] | undefined
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
	seo?: ModelTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
	link?: ModelTypes["LinkableUniqueWhere"] | undefined
};
	["GenericPageLocalesByContentUniqueWhere"]: {
	content?: ModelTypes["ContentUniqueWhere"] | undefined
};
	["GenericPageLocalesBySideNavigationItemsUniqueWhere"]: {
	sideNavigationItems?: ModelTypes["GenericPageSideNavigationUniqueWhere"] | undefined
};
	["GenericPageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GenericPageEdge"]>
};
	["GenericPageEdge"]: {
		node: ModelTypes["GenericPage"]
};
	["LinkableConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
		node: ModelTypes["Linkable"]
};
	["SeoConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["SeoEdge"]>
};
	["SeoEdge"]: {
		node: ModelTypes["Seo"]
};
	["LocaleConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
		node: ModelTypes["Locale"]
};
	["LinkConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkEdge"]>
};
	["LinkEdge"]: {
		node: ModelTypes["Link"]
};
	["HeaderConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["HeaderEdge"]>
};
	["HeaderEdge"]: {
		node: ModelTypes["Header"]
};
	["ContentConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentEdge"]>
};
	["ContentEdge"]: {
		node: ModelTypes["Content"]
};
	["VideoUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["VideoConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["VideoEdge"]>
};
	["VideoEdge"]: {
		node: ModelTypes["Video"]
};
	["ButtonLinkConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ButtonLinkEdge"]>
};
	["ButtonLinkEdge"]: {
		node: ModelTypes["ButtonLink"]
};
	["FeatureUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	icon?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["FeatureConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureEdge"]>
};
	["FeatureEdge"]: {
		node: ModelTypes["Feature"]
};
	["FeatureListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureListEdge"]>
};
	["FeatureListEdge"]: {
		node: ModelTypes["FeatureList"]
};
	["LinkListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
		node: ModelTypes["LinkList"]
};
	["FooterConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FooterEdge"]>
};
	["FooterEdge"]: {
		node: ModelTypes["Footer"]
};
	["ProjectionConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProjectionEdge"]>
};
	["ProjectionEdge"]: {
		node: ModelTypes["Projection"]
};
	["ProjectionContentUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	image?: ModelTypes["ImageUniqueWhere"] | undefined
};
	["ProjectionContentConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProjectionContentEdge"]>
};
	["ProjectionContentEdge"]: {
		node: ModelTypes["ProjectionContent"]
};
	["ContentImageWithFeaturesConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ContentImageWithFeaturesEdge"]>
};
	["ContentImageWithFeaturesEdge"]: {
		node: ModelTypes["ContentImageWithFeatures"]
};
	["AsideContentMediaConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["AsideContentMediaEdge"]>
};
	["AsideContentMediaEdge"]: {
		node: ModelTypes["AsideContentMedia"]
};
	["FileUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FileConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FileEdge"]>
};
	["FileEdge"]: {
		node: ModelTypes["File"]
};
	["ImageListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
		node: ModelTypes["ImageList"]
};
	["ProductCategoryConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProductCategoryEdge"]>
};
	["ProductCategoryEdge"]: {
		node: ModelTypes["ProductCategory"]
};
	["ProductCategoryListConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["ProductCategoryListEdge"]>
};
	["ProductCategoryListEdge"]: {
		node: ModelTypes["ProductCategoryList"]
};
	["FeatureSectionConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureSectionEdge"]>
};
	["FeatureSectionEdge"]: {
		node: ModelTypes["FeatureSection"]
};
	["FeatureSectionHeatConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureSectionHeatEdge"]>
};
	["FeatureSectionHeatEdge"]: {
		node: ModelTypes["FeatureSectionHeat"]
};
	["FeatureSectionPumpConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureSectionPumpEdge"]>
};
	["FeatureSectionPumpEdge"]: {
		node: ModelTypes["FeatureSectionPump"]
};
	["FeatureSectionTemperatureConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureSectionTemperatureEdge"]>
};
	["FeatureSectionTemperatureEdge"]: {
		node: ModelTypes["FeatureSectionTemperature"]
};
	["FeatureSectionTubeConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureSectionTubeEdge"]>
};
	["FeatureSectionTubeEdge"]: {
		node: ModelTypes["FeatureSectionTube"]
};
	["FeatureSectionAntimerConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FeatureSectionAntimerEdge"]>
};
	["FeatureSectionAntimerEdge"]: {
		node: ModelTypes["FeatureSectionAntimer"]
};
	["RedirectConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
		node: ModelTypes["Redirect"]
};
	["GeneralConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["GeneralEdge"]>
};
	["GeneralEdge"]: {
		node: ModelTypes["General"]
};
	["FilledOrderForm"]: {
		_meta?: ModelTypes["FilledOrderFormMeta"] | undefined,
	id: ModelTypes["UUID"],
	name: string,
	email: string,
	deliveryAddress: string,
	note?: string | undefined,
	productFeatures: Array<ModelTypes["ProductFeature"]>,
	publishedAt: ModelTypes["DateTime"],
	product?: ModelTypes["Product"] | undefined,
	country?: ModelTypes["FilledOrderFormCountry"] | undefined,
	mobile: string,
	paginateProductFeatures: ModelTypes["ProductFeatureConnection"]
};
	["FilledOrderFormMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	email?: ModelTypes["FieldMeta"] | undefined,
	deliveryAddress?: ModelTypes["FieldMeta"] | undefined,
	note?: ModelTypes["FieldMeta"] | undefined,
	productFeatures?: ModelTypes["FieldMeta"] | undefined,
	publishedAt?: ModelTypes["FieldMeta"] | undefined,
	product?: ModelTypes["FieldMeta"] | undefined,
	country?: ModelTypes["FieldMeta"] | undefined,
	mobile?: ModelTypes["FieldMeta"] | undefined
};
	["DateTime"]:any;
	["FilledOrderFormCountry"]: {
		_meta?: ModelTypes["FilledOrderFormCountryMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	code: string,
	label: string
};
	["FilledOrderFormCountryMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	code?: ModelTypes["FieldMeta"] | undefined,
	label?: ModelTypes["FieldMeta"] | undefined
};
	["FilledOrderFormCountryWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	code?: ModelTypes["StringCondition"] | undefined,
	label?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["FilledOrderFormCountryWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FilledOrderFormCountryWhere"] | undefined> | undefined,
	not?: ModelTypes["FilledOrderFormCountryWhere"] | undefined
};
	["FilledOrderFormUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FilledOrderFormWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	email?: ModelTypes["StringCondition"] | undefined,
	deliveryAddress?: ModelTypes["StringCondition"] | undefined,
	note?: ModelTypes["StringCondition"] | undefined,
	productFeatures?: ModelTypes["ProductFeatureWhere"] | undefined,
	publishedAt?: ModelTypes["DateTimeCondition"] | undefined,
	product?: ModelTypes["ProductWhere"] | undefined,
	country?: ModelTypes["FilledOrderFormCountryWhere"] | undefined,
	mobile?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["FilledOrderFormWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FilledOrderFormWhere"] | undefined> | undefined,
	not?: ModelTypes["FilledOrderFormWhere"] | undefined
};
	["DateTimeCondition"]: {
	and?: Array<ModelTypes["DateTimeCondition"]> | undefined,
	or?: Array<ModelTypes["DateTimeCondition"]> | undefined,
	not?: ModelTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["DateTime"] | undefined,
	notEq?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined
};
	["FilledOrderFormOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	email?: ModelTypes["OrderDirection"] | undefined,
	deliveryAddress?: ModelTypes["OrderDirection"] | undefined,
	note?: ModelTypes["OrderDirection"] | undefined,
	publishedAt?: ModelTypes["OrderDirection"] | undefined,
	product?: ModelTypes["ProductOrderBy"] | undefined,
	country?: ModelTypes["FilledOrderFormCountryOrderBy"] | undefined,
	mobile?: ModelTypes["OrderDirection"] | undefined
};
	["FilledOrderFormCountryOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	code?: ModelTypes["OrderDirection"] | undefined,
	label?: ModelTypes["OrderDirection"] | undefined
};
	["FilledOrderFormConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FilledOrderFormEdge"]>
};
	["FilledOrderFormEdge"]: {
		node: ModelTypes["FilledOrderForm"]
};
	["_ValidationResult"]: {
		valid: boolean,
	errors: Array<ModelTypes["_ValidationError"]>
};
	["_ValidationError"]: {
		path: Array<ModelTypes["_PathFragment"]>,
	message: ModelTypes["_ValidationMessage"]
};
	["_PathFragment"]:ModelTypes["_FieldPathFragment"] | ModelTypes["_IndexPathFragment"];
	["_FieldPathFragment"]: {
		field: string
};
	["_IndexPathFragment"]: {
		index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
		text: string
};
	["FilledOrderFormCreateInput"]: {
	name?: string | undefined,
	email?: string | undefined,
	deliveryAddress?: string | undefined,
	note?: string | undefined,
	productFeatures?: Array<ModelTypes["FilledOrderFormCreateProductFeaturesEntityRelationInput"]> | undefined,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	product?: ModelTypes["FilledOrderFormCreateProductEntityRelationInput"] | undefined,
	country?: ModelTypes["FilledOrderFormCreateCountryEntityRelationInput"] | undefined,
	mobile?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["FilledOrderFormCreateProductFeaturesEntityRelationInput"]: {
	connect?: ModelTypes["ProductFeatureUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["FilledOrderFormCreateProductEntityRelationInput"]: {
	connect?: ModelTypes["ProductUniqueWhere"] | undefined
};
	["FilledOrderFormCreateCountryEntityRelationInput"]: {
	connect?: ModelTypes["FilledOrderFormCountryUniqueWhere"] | undefined
};
	["FilledOrderFormCountryUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	code?: string | undefined
};
	["FilledOrderFormUpdateInput"]: {
	name?: string | undefined,
	email?: string | undefined,
	deliveryAddress?: string | undefined,
	note?: string | undefined,
	productFeatures?: Array<ModelTypes["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]> | undefined,
	publishedAt?: ModelTypes["DateTime"] | undefined,
	product?: ModelTypes["FilledOrderFormUpdateProductEntityRelationInput"] | undefined,
	country?: ModelTypes["FilledOrderFormUpdateCountryEntityRelationInput"] | undefined,
	mobile?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]: {
	connect?: ModelTypes["ProductFeatureUniqueWhere"] | undefined,
	disconnect?: ModelTypes["ProductFeatureUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["FilledOrderFormUpdateProductEntityRelationInput"]: {
	connect?: ModelTypes["ProductUniqueWhere"] | undefined
};
	["FilledOrderFormUpdateCountryEntityRelationInput"]: {
	connect?: ModelTypes["FilledOrderFormCountryUniqueWhere"] | undefined
};
	["OrderPageConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["OrderPageEdge"]>
};
	["OrderPageEdge"]: {
		node: ModelTypes["OrderPage"]
};
	["FilledOrderFormEmailAddress"]: {
		_meta?: ModelTypes["FilledOrderFormEmailAddressMeta"] | undefined,
	id: ModelTypes["UUID"],
	order: number,
	email: string
};
	["FilledOrderFormEmailAddressMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	order?: ModelTypes["FieldMeta"] | undefined,
	email?: ModelTypes["FieldMeta"] | undefined
};
	["FilledOrderFormEmailAddressUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FilledOrderFormEmailAddressWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	order?: ModelTypes["IntCondition"] | undefined,
	email?: ModelTypes["StringCondition"] | undefined,
	and?: Array<ModelTypes["FilledOrderFormEmailAddressWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FilledOrderFormEmailAddressWhere"] | undefined> | undefined,
	not?: ModelTypes["FilledOrderFormEmailAddressWhere"] | undefined
};
	["FilledOrderFormEmailAddressOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	order?: ModelTypes["OrderDirection"] | undefined,
	email?: ModelTypes["OrderDirection"] | undefined
};
	["FilledOrderFormEmailAddressConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FilledOrderFormEmailAddressEdge"]>
};
	["FilledOrderFormEmailAddressEdge"]: {
		node: ModelTypes["FilledOrderFormEmailAddress"]
};
	["TranslationCatalogue"]: {
		_meta?: ModelTypes["TranslationCatalogueMeta"] | undefined,
	id: ModelTypes["UUID"],
	domain?: ModelTypes["TranslationDomain"] | undefined,
	identifier?: ModelTypes["TranslationCataloguesIdentifier"] | undefined,
	fallback?: ModelTypes["TranslationCatalogue"] | undefined,
	values: Array<ModelTypes["TranslationValue"]>,
	valuesByKey?: ModelTypes["TranslationValue"] | undefined,
	paginateValues: ModelTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	domain?: ModelTypes["FieldMeta"] | undefined,
	identifier?: ModelTypes["FieldMeta"] | undefined,
	fallback?: ModelTypes["FieldMeta"] | undefined,
	values?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
		_meta?: ModelTypes["TranslationDomainMeta"] | undefined,
	id: ModelTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<ModelTypes["TranslationCatalogue"]>,
	keys: Array<ModelTypes["TranslationKey"]>,
	cataloguesByIdentifier?: ModelTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: ModelTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: ModelTypes["TranslationKey"] | undefined,
	keysByValues?: ModelTypes["TranslationKey"] | undefined,
	paginateCatalogues: ModelTypes["TranslationCatalogueConnection"],
	paginateKeys: ModelTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	identifier?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	catalogues?: ModelTypes["FieldMeta"] | undefined,
	keys?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	domain?: ModelTypes["TranslationDomainWhere"] | undefined,
	identifier?: ModelTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	fallback?: ModelTypes["TranslationCatalogueWhere"] | undefined,
	values?: ModelTypes["TranslationValueWhere"] | undefined,
	and?: Array<ModelTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationCatalogueWhere"] | undefined
};
	["TranslationDomainWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	identifier?: ModelTypes["StringCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	catalogues?: ModelTypes["TranslationCatalogueWhere"] | undefined,
	keys?: ModelTypes["TranslationKeyWhere"] | undefined,
	and?: Array<ModelTypes["TranslationDomainWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationDomainWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationDomainWhere"] | undefined
};
	["TranslationKeyWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	identifier?: ModelTypes["StringCondition"] | undefined,
	contentType?: ModelTypes["TranslationKeyContentTypeCondition"] | undefined,
	note?: ModelTypes["StringCondition"] | undefined,
	domain?: ModelTypes["TranslationDomainWhere"] | undefined,
	values?: ModelTypes["TranslationValueWhere"] | undefined,
	and?: Array<ModelTypes["TranslationKeyWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationKeyWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationKeyWhere"] | undefined
};
	["TranslationKeyContentTypeCondition"]: {
	and?: Array<ModelTypes["TranslationKeyContentTypeCondition"]> | undefined,
	or?: Array<ModelTypes["TranslationKeyContentTypeCondition"]> | undefined,
	not?: ModelTypes["TranslationKeyContentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: ModelTypes["TranslationKeyContentType"] | undefined,
	notEq?: ModelTypes["TranslationKeyContentType"] | undefined,
	in?: Array<ModelTypes["TranslationKeyContentType"]> | undefined,
	notIn?: Array<ModelTypes["TranslationKeyContentType"]> | undefined,
	lt?: ModelTypes["TranslationKeyContentType"] | undefined,
	lte?: ModelTypes["TranslationKeyContentType"] | undefined,
	gt?: ModelTypes["TranslationKeyContentType"] | undefined,
	gte?: ModelTypes["TranslationKeyContentType"] | undefined
};
	["TranslationKeyContentType"]:TranslationKeyContentType;
	["TranslationValueWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	value?: ModelTypes["StringCondition"] | undefined,
	catalogue?: ModelTypes["TranslationCatalogueWhere"] | undefined,
	key?: ModelTypes["TranslationKeyWhere"] | undefined,
	and?: Array<ModelTypes["TranslationValueWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationValueWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationValueWhere"] | undefined
};
	["TranslationCataloguesIdentifierWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	name?: ModelTypes["StringCondition"] | undefined,
	code?: ModelTypes["StringCondition"] | undefined,
	catalogue?: ModelTypes["TranslationCatalogueWhere"] | undefined,
	and?: Array<ModelTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	not?: ModelTypes["TranslationCataloguesIdentifierWhere"] | undefined
};
	["TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	domain?: ModelTypes["TranslationDomainOrderBy"] | undefined,
	identifier?: ModelTypes["TranslationCataloguesIdentifierOrderBy"] | undefined,
	fallback?: ModelTypes["TranslationCatalogueOrderBy"] | undefined
};
	["TranslationDomainOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	identifier?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined
};
	["TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	name?: ModelTypes["OrderDirection"] | undefined,
	code?: ModelTypes["OrderDirection"] | undefined
};
	["TranslationKey"]: {
		_meta?: ModelTypes["TranslationKeyMeta"] | undefined,
	id: ModelTypes["UUID"],
	identifier: string,
	contentType: ModelTypes["TranslationKeyContentType"],
	note?: string | undefined,
	domain?: ModelTypes["TranslationDomain"] | undefined,
	values: Array<ModelTypes["TranslationValue"]>,
	valuesByCatalogue?: ModelTypes["TranslationValue"] | undefined,
	paginateValues: ModelTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	identifier?: ModelTypes["FieldMeta"] | undefined,
	contentType?: ModelTypes["FieldMeta"] | undefined,
	note?: ModelTypes["FieldMeta"] | undefined,
	domain?: ModelTypes["FieldMeta"] | undefined,
	values?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
		_meta?: ModelTypes["TranslationValueMeta"] | undefined,
	id: ModelTypes["UUID"],
	value: string,
	catalogue?: ModelTypes["TranslationCatalogue"] | undefined,
	key?: ModelTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	value?: ModelTypes["FieldMeta"] | undefined,
	catalogue?: ModelTypes["FieldMeta"] | undefined,
	key?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	value?: ModelTypes["OrderDirection"] | undefined,
	catalogue?: ModelTypes["TranslationCatalogueOrderBy"] | undefined,
	key?: ModelTypes["TranslationKeyOrderBy"] | undefined
};
	["TranslationKeyOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	identifier?: ModelTypes["OrderDirection"] | undefined,
	contentType?: ModelTypes["OrderDirection"] | undefined,
	note?: ModelTypes["OrderDirection"] | undefined,
	domain?: ModelTypes["TranslationDomainOrderBy"] | undefined
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ModelTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationCatalogueUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	domain?: ModelTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: ModelTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	identifier?: string | undefined,
	catalogues?: ModelTypes["TranslationCatalogueUniqueWhere"] | undefined,
	keys?: ModelTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationKeyUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	domain?: ModelTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: string | undefined,
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationValueUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	catalogue?: ModelTypes["TranslationCatalogueUniqueWhere"] | undefined,
	key?: ModelTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined,
	code?: string | undefined,
	catalogue?: ModelTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationValueConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
		node: ModelTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ModelTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
		node: ModelTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
		node: ModelTypes["TranslationKey"]
};
	["TranslationCataloguesIdentifier"]: {
		_meta?: ModelTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: ModelTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<ModelTypes["TranslationCatalogue"]>,
	catalogueByDomain?: ModelTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: ModelTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: ModelTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	name?: ModelTypes["FieldMeta"] | undefined,
	code?: ModelTypes["FieldMeta"] | undefined,
	catalogue?: ModelTypes["FieldMeta"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ModelTypes["TranslationDomainUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ModelTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ModelTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
		node: ModelTypes["TranslationCataloguesIdentifier"]
};
	["TranslationDomainConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
		node: ModelTypes["TranslationDomain"]
};
	["FilledOrderFormCountryConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FilledOrderFormCountryEdge"]>
};
	["FilledOrderFormCountryEdge"]: {
		node: ModelTypes["FilledOrderFormCountry"]
};
	["FilledProductWaitlistForm"]: {
		_meta?: ModelTypes["FilledProductWaitlistFormMeta"] | undefined,
	id: ModelTypes["UUID"],
	publishedAt: ModelTypes["DateTime"],
	email: string,
	product?: ModelTypes["Product"] | undefined
};
	["FilledProductWaitlistFormMeta"]: {
		id?: ModelTypes["FieldMeta"] | undefined,
	publishedAt?: ModelTypes["FieldMeta"] | undefined,
	email?: ModelTypes["FieldMeta"] | undefined,
	product?: ModelTypes["FieldMeta"] | undefined
};
	["FilledProductWaitlistFormUniqueWhere"]: {
	id?: ModelTypes["UUID"] | undefined
};
	["FilledProductWaitlistFormWhere"]: {
	id?: ModelTypes["UUIDCondition"] | undefined,
	publishedAt?: ModelTypes["DateTimeCondition"] | undefined,
	email?: ModelTypes["StringCondition"] | undefined,
	product?: ModelTypes["ProductWhere"] | undefined,
	and?: Array<ModelTypes["FilledProductWaitlistFormWhere"] | undefined> | undefined,
	or?: Array<ModelTypes["FilledProductWaitlistFormWhere"] | undefined> | undefined,
	not?: ModelTypes["FilledProductWaitlistFormWhere"] | undefined
};
	["FilledProductWaitlistFormOrderBy"]: {
	_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: ModelTypes["OrderDirection"] | undefined,
	publishedAt?: ModelTypes["OrderDirection"] | undefined,
	email?: ModelTypes["OrderDirection"] | undefined,
	product?: ModelTypes["ProductOrderBy"] | undefined
};
	["FilledProductWaitlistFormConnection"]: {
		pageInfo: ModelTypes["PageInfo"],
	edges: Array<ModelTypes["FilledProductWaitlistFormEdge"]>
};
	["FilledProductWaitlistFormEdge"]: {
		node: ModelTypes["FilledProductWaitlistForm"]
};
	["FilledProductWaitlistFormCreateInput"]: {
	publishedAt?: ModelTypes["DateTime"] | undefined,
	email?: string | undefined,
	product?: ModelTypes["FilledProductWaitlistFormCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["FilledProductWaitlistFormCreateProductEntityRelationInput"]: {
	connect?: ModelTypes["ProductUniqueWhere"] | undefined
};
	["FilledProductWaitlistFormUpdateInput"]: {
	publishedAt?: ModelTypes["DateTime"] | undefined,
	email?: string | undefined,
	product?: ModelTypes["FilledProductWaitlistFormUpdateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["FilledProductWaitlistFormUpdateProductEntityRelationInput"]: {
	connect?: ModelTypes["ProductUniqueWhere"] | undefined
};
	["QueryTransaction"]: {
		getImage?: ModelTypes["Image"] | undefined,
	listImage: Array<ModelTypes["Image"]>,
	paginateImage: ModelTypes["ImageConnection"],
	getGenericPage?: ModelTypes["GenericPage"] | undefined,
	listGenericPage: Array<ModelTypes["GenericPage"]>,
	paginateGenericPage: ModelTypes["GenericPageConnection"],
	getLinkable?: ModelTypes["Linkable"] | undefined,
	listLinkable: Array<ModelTypes["Linkable"]>,
	paginateLinkable: ModelTypes["LinkableConnection"],
	getSeo?: ModelTypes["Seo"] | undefined,
	listSeo: Array<ModelTypes["Seo"]>,
	paginateSeo: ModelTypes["SeoConnection"],
	getGenericPageLocale?: ModelTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<ModelTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: ModelTypes["GenericPageLocaleConnection"],
	getLocale?: ModelTypes["Locale"] | undefined,
	listLocale: Array<ModelTypes["Locale"]>,
	paginateLocale: ModelTypes["LocaleConnection"],
	getLink?: ModelTypes["Link"] | undefined,
	listLink: Array<ModelTypes["Link"]>,
	paginateLink: ModelTypes["LinkConnection"],
	getHeader?: ModelTypes["Header"] | undefined,
	listHeader: Array<ModelTypes["Header"]>,
	paginateHeader: ModelTypes["HeaderConnection"],
	getHeaderLocale?: ModelTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<ModelTypes["HeaderLocale"]>,
	paginateHeaderLocale: ModelTypes["HeaderLocaleConnection"],
	getContent?: ModelTypes["Content"] | undefined,
	listContent: Array<ModelTypes["Content"]>,
	paginateContent: ModelTypes["ContentConnection"],
	getContentBlock?: ModelTypes["ContentBlock"] | undefined,
	listContentBlock: Array<ModelTypes["ContentBlock"]>,
	paginateContentBlock: ModelTypes["ContentBlockConnection"],
	getContentReference?: ModelTypes["ContentReference"] | undefined,
	listContentReference: Array<ModelTypes["ContentReference"]>,
	paginateContentReference: ModelTypes["ContentReferenceConnection"],
	getVideo?: ModelTypes["Video"] | undefined,
	listVideo: Array<ModelTypes["Video"]>,
	paginateVideo: ModelTypes["VideoConnection"],
	getButtonLink?: ModelTypes["ButtonLink"] | undefined,
	listButtonLink: Array<ModelTypes["ButtonLink"]>,
	paginateButtonLink: ModelTypes["ButtonLinkConnection"],
	getFeature?: ModelTypes["Feature"] | undefined,
	listFeature: Array<ModelTypes["Feature"]>,
	paginateFeature: ModelTypes["FeatureConnection"],
	getFeatureItem?: ModelTypes["FeatureItem"] | undefined,
	listFeatureItem: Array<ModelTypes["FeatureItem"]>,
	paginateFeatureItem: ModelTypes["FeatureItemConnection"],
	getFeatureList?: ModelTypes["FeatureList"] | undefined,
	listFeatureList: Array<ModelTypes["FeatureList"]>,
	paginateFeatureList: ModelTypes["FeatureListConnection"],
	getLinkItem?: ModelTypes["LinkItem"] | undefined,
	listLinkItem: Array<ModelTypes["LinkItem"]>,
	paginateLinkItem: ModelTypes["LinkItemConnection"],
	getLinkList?: ModelTypes["LinkList"] | undefined,
	listLinkList: Array<ModelTypes["LinkList"]>,
	paginateLinkList: ModelTypes["LinkListConnection"],
	getFooter?: ModelTypes["Footer"] | undefined,
	listFooter: Array<ModelTypes["Footer"]>,
	paginateFooter: ModelTypes["FooterConnection"],
	getFooterGroupLinkList?: ModelTypes["FooterGroupLinkList"] | undefined,
	listFooterGroupLinkList: Array<ModelTypes["FooterGroupLinkList"]>,
	paginateFooterGroupLinkList: ModelTypes["FooterGroupLinkListConnection"],
	getFooterLocale?: ModelTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<ModelTypes["FooterLocale"]>,
	paginateFooterLocale: ModelTypes["FooterLocaleConnection"],
	getGenericPageSideNavigation?: ModelTypes["GenericPageSideNavigation"] | undefined,
	listGenericPageSideNavigation: Array<ModelTypes["GenericPageSideNavigation"]>,
	paginateGenericPageSideNavigation: ModelTypes["GenericPageSideNavigationConnection"],
	getProjection?: ModelTypes["Projection"] | undefined,
	listProjection: Array<ModelTypes["Projection"]>,
	paginateProjection: ModelTypes["ProjectionConnection"],
	getProjectionContent?: ModelTypes["ProjectionContent"] | undefined,
	listProjectionContent: Array<ModelTypes["ProjectionContent"]>,
	paginateProjectionContent: ModelTypes["ProjectionContentConnection"],
	getProjectionItem?: ModelTypes["ProjectionItem"] | undefined,
	listProjectionItem: Array<ModelTypes["ProjectionItem"]>,
	paginateProjectionItem: ModelTypes["ProjectionItemConnection"],
	getContentImageWithFeatures?: ModelTypes["ContentImageWithFeatures"] | undefined,
	listContentImageWithFeatures: Array<ModelTypes["ContentImageWithFeatures"]>,
	paginateContentImageWithFeatures: ModelTypes["ContentImageWithFeaturesConnection"],
	getAsideContentMedia?: ModelTypes["AsideContentMedia"] | undefined,
	listAsideContentMedia: Array<ModelTypes["AsideContentMedia"]>,
	paginateAsideContentMedia: ModelTypes["AsideContentMediaConnection"],
	getAsideContentMediaSpecification?: ModelTypes["AsideContentMediaSpecification"] | undefined,
	listAsideContentMediaSpecification: Array<ModelTypes["AsideContentMediaSpecification"]>,
	paginateAsideContentMediaSpecification: ModelTypes["AsideContentMediaSpecificationConnection"],
	getFile?: ModelTypes["File"] | undefined,
	listFile: Array<ModelTypes["File"]>,
	paginateFile: ModelTypes["FileConnection"],
	getImageList?: ModelTypes["ImageList"] | undefined,
	listImageList: Array<ModelTypes["ImageList"]>,
	paginateImageList: ModelTypes["ImageListConnection"],
	getImageListItem?: ModelTypes["ImageListItem"] | undefined,
	listImageListItem: Array<ModelTypes["ImageListItem"]>,
	paginateImageListItem: ModelTypes["ImageListItemConnection"],
	getProduct?: ModelTypes["Product"] | undefined,
	listProduct: Array<ModelTypes["Product"]>,
	paginateProduct: ModelTypes["ProductConnection"],
	getProductCategory?: ModelTypes["ProductCategory"] | undefined,
	listProductCategory: Array<ModelTypes["ProductCategory"]>,
	paginateProductCategory: ModelTypes["ProductCategoryConnection"],
	getProductCategoryLocale?: ModelTypes["ProductCategoryLocale"] | undefined,
	listProductCategoryLocale: Array<ModelTypes["ProductCategoryLocale"]>,
	paginateProductCategoryLocale: ModelTypes["ProductCategoryLocaleConnection"],
	getProductLocale?: ModelTypes["ProductLocale"] | undefined,
	listProductLocale: Array<ModelTypes["ProductLocale"]>,
	paginateProductLocale: ModelTypes["ProductLocaleConnection"],
	getProductCategoryItem?: ModelTypes["ProductCategoryItem"] | undefined,
	listProductCategoryItem: Array<ModelTypes["ProductCategoryItem"]>,
	paginateProductCategoryItem: ModelTypes["ProductCategoryItemConnection"],
	getProductCategoryList?: ModelTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<ModelTypes["ProductCategoryList"]>,
	paginateProductCategoryList: ModelTypes["ProductCategoryListConnection"],
	getFeatureSection?: ModelTypes["FeatureSection"] | undefined,
	listFeatureSection: Array<ModelTypes["FeatureSection"]>,
	paginateFeatureSection: ModelTypes["FeatureSectionConnection"],
	getFeatureSectionHeat?: ModelTypes["FeatureSectionHeat"] | undefined,
	listFeatureSectionHeat: Array<ModelTypes["FeatureSectionHeat"]>,
	paginateFeatureSectionHeat: ModelTypes["FeatureSectionHeatConnection"],
	getFeatureSectionPump?: ModelTypes["FeatureSectionPump"] | undefined,
	listFeatureSectionPump: Array<ModelTypes["FeatureSectionPump"]>,
	paginateFeatureSectionPump: ModelTypes["FeatureSectionPumpConnection"],
	getFeatureSectionTemperature?: ModelTypes["FeatureSectionTemperature"] | undefined,
	listFeatureSectionTemperature: Array<ModelTypes["FeatureSectionTemperature"]>,
	paginateFeatureSectionTemperature: ModelTypes["FeatureSectionTemperatureConnection"],
	getFeatureSectionTube?: ModelTypes["FeatureSectionTube"] | undefined,
	listFeatureSectionTube: Array<ModelTypes["FeatureSectionTube"]>,
	paginateFeatureSectionTube: ModelTypes["FeatureSectionTubeConnection"],
	getFeatureSectionAntimer?: ModelTypes["FeatureSectionAntimer"] | undefined,
	listFeatureSectionAntimer: Array<ModelTypes["FeatureSectionAntimer"]>,
	paginateFeatureSectionAntimer: ModelTypes["FeatureSectionAntimerConnection"],
	getRedirect?: ModelTypes["Redirect"] | undefined,
	listRedirect: Array<ModelTypes["Redirect"]>,
	paginateRedirect: ModelTypes["RedirectConnection"],
	getGeneral?: ModelTypes["General"] | undefined,
	listGeneral: Array<ModelTypes["General"]>,
	paginateGeneral: ModelTypes["GeneralConnection"],
	getGeneralLocale?: ModelTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<ModelTypes["GeneralLocale"]>,
	paginateGeneralLocale: ModelTypes["GeneralLocaleConnection"],
	getProductFeature?: ModelTypes["ProductFeature"] | undefined,
	listProductFeature: Array<ModelTypes["ProductFeature"]>,
	paginateProductFeature: ModelTypes["ProductFeatureConnection"],
	getProductFeatureLocale?: ModelTypes["ProductFeatureLocale"] | undefined,
	listProductFeatureLocale: Array<ModelTypes["ProductFeatureLocale"]>,
	paginateProductFeatureLocale: ModelTypes["ProductFeatureLocaleConnection"],
	getFilledOrderForm?: ModelTypes["FilledOrderForm"] | undefined,
	listFilledOrderForm: Array<ModelTypes["FilledOrderForm"]>,
	paginateFilledOrderForm: ModelTypes["FilledOrderFormConnection"],
	validateCreateFilledOrderForm: ModelTypes["_ValidationResult"],
	validateUpdateFilledOrderForm: ModelTypes["_ValidationResult"],
	getOrderPage?: ModelTypes["OrderPage"] | undefined,
	listOrderPage: Array<ModelTypes["OrderPage"]>,
	paginateOrderPage: ModelTypes["OrderPageConnection"],
	getOrderPageLocale?: ModelTypes["OrderPageLocale"] | undefined,
	listOrderPageLocale: Array<ModelTypes["OrderPageLocale"]>,
	paginateOrderPageLocale: ModelTypes["OrderPageLocaleConnection"],
	getFilledOrderFormEmailAddress?: ModelTypes["FilledOrderFormEmailAddress"] | undefined,
	listFilledOrderFormEmailAddress: Array<ModelTypes["FilledOrderFormEmailAddress"]>,
	paginateFilledOrderFormEmailAddress: ModelTypes["FilledOrderFormEmailAddressConnection"],
	getTranslationCatalogue?: ModelTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<ModelTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: ModelTypes["TranslationCatalogueConnection"],
	getTranslationCataloguesIdentifier?: ModelTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<ModelTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: ModelTypes["TranslationCataloguesIdentifierConnection"],
	getTranslationDomain?: ModelTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<ModelTypes["TranslationDomain"]>,
	paginateTranslationDomain: ModelTypes["TranslationDomainConnection"],
	getTranslationKey?: ModelTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<ModelTypes["TranslationKey"]>,
	paginateTranslationKey: ModelTypes["TranslationKeyConnection"],
	getTranslationValue?: ModelTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<ModelTypes["TranslationValue"]>,
	paginateTranslationValue: ModelTypes["TranslationValueConnection"],
	getFilledOrderFormCountry?: ModelTypes["FilledOrderFormCountry"] | undefined,
	listFilledOrderFormCountry: Array<ModelTypes["FilledOrderFormCountry"]>,
	paginateFilledOrderFormCountry: ModelTypes["FilledOrderFormCountryConnection"],
	getFilledProductWaitlistForm?: ModelTypes["FilledProductWaitlistForm"] | undefined,
	listFilledProductWaitlistForm: Array<ModelTypes["FilledProductWaitlistForm"]>,
	paginateFilledProductWaitlistForm: ModelTypes["FilledProductWaitlistFormConnection"],
	validateCreateFilledProductWaitlistForm: ModelTypes["_ValidationResult"],
	validateUpdateFilledProductWaitlistForm: ModelTypes["_ValidationResult"]
};
	["Info"]: {
		description?: string | undefined
};
	["Mutation"]: {
		createFilledOrderForm: ModelTypes["FilledOrderFormCreateResult"],
	updateFilledOrderForm: ModelTypes["FilledOrderFormUpdateResult"],
	upsertFilledOrderForm: ModelTypes["FilledOrderFormUpsertResult"],
	createFilledProductWaitlistForm: ModelTypes["FilledProductWaitlistFormCreateResult"],
	updateFilledProductWaitlistForm: ModelTypes["FilledProductWaitlistFormUpdateResult"],
	upsertFilledProductWaitlistForm: ModelTypes["FilledProductWaitlistFormUpsertResult"],
	transaction: ModelTypes["MutationTransaction"],
	query: ModelTypes["Query"]
};
	["FilledOrderFormCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["FilledOrderForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["MutationResult"]: ModelTypes["FilledOrderFormCreateResult"] | ModelTypes["FilledOrderFormUpdateResult"] | ModelTypes["FilledOrderFormUpsertResult"] | ModelTypes["FilledProductWaitlistFormCreateResult"] | ModelTypes["FilledProductWaitlistFormUpdateResult"] | ModelTypes["FilledProductWaitlistFormUpsertResult"];
	["_MutationError"]: {
		path: Array<ModelTypes["_PathFragment"]>,
	paths: Array<Array<ModelTypes["_PathFragment"]>>,
	type: ModelTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]:_MutationErrorType;
	["FilledOrderFormUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["FilledOrderForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["FilledOrderFormUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["FilledOrderForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["FilledProductWaitlistFormCreateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["FilledProductWaitlistForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["FilledProductWaitlistFormUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["FilledProductWaitlistForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["FilledProductWaitlistFormUpsertResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	node?: ModelTypes["FilledProductWaitlistForm"] | undefined,
	validation: ModelTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<ModelTypes["_MutationError"]>,
	validation: ModelTypes["_ValidationResult"],
	createFilledOrderForm: ModelTypes["FilledOrderFormCreateResult"],
	updateFilledOrderForm: ModelTypes["FilledOrderFormUpdateResult"],
	upsertFilledOrderForm: ModelTypes["FilledOrderFormUpsertResult"],
	createFilledProductWaitlistForm: ModelTypes["FilledProductWaitlistFormCreateResult"],
	updateFilledProductWaitlistForm: ModelTypes["FilledProductWaitlistFormUpdateResult"],
	upsertFilledProductWaitlistForm: ModelTypes["FilledProductWaitlistFormUpsertResult"],
	query?: ModelTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined
};
	["Json"]:any;
	["_Schema"]: {
		enums: Array<ModelTypes["_Enum"]>,
	entities: Array<ModelTypes["_Entity"]>
};
	["_Entity"]: {
		name: string,
	customPrimaryAllowed: boolean,
	fields: Array<ModelTypes["_Field"]>,
	unique: Array<ModelTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
		fields: Array<string>
};
	["_Field"]: ModelTypes["_Column"] | ModelTypes["_Relation"];
	["_Column"]: {
		name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: ModelTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<ModelTypes["_Rule"]>,
	validators: Array<ModelTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]:_OnDeleteBehaviour;
	["_RelationSide"]:_RelationSide;
	["_OrderByDirection"]:_OrderByDirection;
	["_OrderBy"]: {
		path: Array<string>,
	direction: ModelTypes["_OrderByDirection"]
};
	["_Relation"]: {
		name: string,
	type: string,
	side: ModelTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: ModelTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<ModelTypes["_OrderBy"]> | undefined,
	rules: Array<ModelTypes["_Rule"]>,
	validators: Array<ModelTypes["_Validator"]>
};
	["_Rule"]: {
		message?: ModelTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_Validator"]: {
		operation: string,
	arguments: Array<ModelTypes["_Argument"]>
};
	["_Argument"]:ModelTypes["_ValidatorArgument"] | ModelTypes["_PathArgument"] | ModelTypes["_LiteralArgument"];
	["_ValidatorArgument"]: {
		validator: number
};
	["_PathArgument"]: {
		path: Array<string>
};
	["_LiteralArgument"]: {
		value?: ModelTypes["Json"] | undefined
};
	["_RuleMessage"]: {
		text?: string | undefined
};
	["_Enum"]: {
		name: string,
	values: Array<string>
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getGenericPage?: GraphQLTypes["GenericPage"] | undefined,
	listGenericPage: Array<GraphQLTypes["GenericPage"]>,
	paginateGenericPage: GraphQLTypes["GenericPageConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getGenericPageLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<GraphQLTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: GraphQLTypes["GenericPageLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getHeader?: GraphQLTypes["Header"] | undefined,
	listHeader: Array<GraphQLTypes["Header"]>,
	paginateHeader: GraphQLTypes["HeaderConnection"],
	getHeaderLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<GraphQLTypes["HeaderLocale"]>,
	paginateHeaderLocale: GraphQLTypes["HeaderLocaleConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getButtonLink?: GraphQLTypes["ButtonLink"] | undefined,
	listButtonLink: Array<GraphQLTypes["ButtonLink"]>,
	paginateButtonLink: GraphQLTypes["ButtonLinkConnection"],
	getFeature?: GraphQLTypes["Feature"] | undefined,
	listFeature: Array<GraphQLTypes["Feature"]>,
	paginateFeature: GraphQLTypes["FeatureConnection"],
	getFeatureItem?: GraphQLTypes["FeatureItem"] | undefined,
	listFeatureItem: Array<GraphQLTypes["FeatureItem"]>,
	paginateFeatureItem: GraphQLTypes["FeatureItemConnection"],
	getFeatureList?: GraphQLTypes["FeatureList"] | undefined,
	listFeatureList: Array<GraphQLTypes["FeatureList"]>,
	paginateFeatureList: GraphQLTypes["FeatureListConnection"],
	getLinkItem?: GraphQLTypes["LinkItem"] | undefined,
	listLinkItem: Array<GraphQLTypes["LinkItem"]>,
	paginateLinkItem: GraphQLTypes["LinkItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getFooter?: GraphQLTypes["Footer"] | undefined,
	listFooter: Array<GraphQLTypes["Footer"]>,
	paginateFooter: GraphQLTypes["FooterConnection"],
	getFooterGroupLinkList?: GraphQLTypes["FooterGroupLinkList"] | undefined,
	listFooterGroupLinkList: Array<GraphQLTypes["FooterGroupLinkList"]>,
	paginateFooterGroupLinkList: GraphQLTypes["FooterGroupLinkListConnection"],
	getFooterLocale?: GraphQLTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<GraphQLTypes["FooterLocale"]>,
	paginateFooterLocale: GraphQLTypes["FooterLocaleConnection"],
	getGenericPageSideNavigation?: GraphQLTypes["GenericPageSideNavigation"] | undefined,
	listGenericPageSideNavigation: Array<GraphQLTypes["GenericPageSideNavigation"]>,
	paginateGenericPageSideNavigation: GraphQLTypes["GenericPageSideNavigationConnection"],
	getProjection?: GraphQLTypes["Projection"] | undefined,
	listProjection: Array<GraphQLTypes["Projection"]>,
	paginateProjection: GraphQLTypes["ProjectionConnection"],
	getProjectionContent?: GraphQLTypes["ProjectionContent"] | undefined,
	listProjectionContent: Array<GraphQLTypes["ProjectionContent"]>,
	paginateProjectionContent: GraphQLTypes["ProjectionContentConnection"],
	getProjectionItem?: GraphQLTypes["ProjectionItem"] | undefined,
	listProjectionItem: Array<GraphQLTypes["ProjectionItem"]>,
	paginateProjectionItem: GraphQLTypes["ProjectionItemConnection"],
	getContentImageWithFeatures?: GraphQLTypes["ContentImageWithFeatures"] | undefined,
	listContentImageWithFeatures: Array<GraphQLTypes["ContentImageWithFeatures"]>,
	paginateContentImageWithFeatures: GraphQLTypes["ContentImageWithFeaturesConnection"],
	getAsideContentMedia?: GraphQLTypes["AsideContentMedia"] | undefined,
	listAsideContentMedia: Array<GraphQLTypes["AsideContentMedia"]>,
	paginateAsideContentMedia: GraphQLTypes["AsideContentMediaConnection"],
	getAsideContentMediaSpecification?: GraphQLTypes["AsideContentMediaSpecification"] | undefined,
	listAsideContentMediaSpecification: Array<GraphQLTypes["AsideContentMediaSpecification"]>,
	paginateAsideContentMediaSpecification: GraphQLTypes["AsideContentMediaSpecificationConnection"],
	getFile?: GraphQLTypes["File"] | undefined,
	listFile: Array<GraphQLTypes["File"]>,
	paginateFile: GraphQLTypes["FileConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getProductCategoryLocale?: GraphQLTypes["ProductCategoryLocale"] | undefined,
	listProductCategoryLocale: Array<GraphQLTypes["ProductCategoryLocale"]>,
	paginateProductCategoryLocale: GraphQLTypes["ProductCategoryLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductCategoryItem?: GraphQLTypes["ProductCategoryItem"] | undefined,
	listProductCategoryItem: Array<GraphQLTypes["ProductCategoryItem"]>,
	paginateProductCategoryItem: GraphQLTypes["ProductCategoryItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getFeatureSection?: GraphQLTypes["FeatureSection"] | undefined,
	listFeatureSection: Array<GraphQLTypes["FeatureSection"]>,
	paginateFeatureSection: GraphQLTypes["FeatureSectionConnection"],
	getFeatureSectionHeat?: GraphQLTypes["FeatureSectionHeat"] | undefined,
	listFeatureSectionHeat: Array<GraphQLTypes["FeatureSectionHeat"]>,
	paginateFeatureSectionHeat: GraphQLTypes["FeatureSectionHeatConnection"],
	getFeatureSectionPump?: GraphQLTypes["FeatureSectionPump"] | undefined,
	listFeatureSectionPump: Array<GraphQLTypes["FeatureSectionPump"]>,
	paginateFeatureSectionPump: GraphQLTypes["FeatureSectionPumpConnection"],
	getFeatureSectionTemperature?: GraphQLTypes["FeatureSectionTemperature"] | undefined,
	listFeatureSectionTemperature: Array<GraphQLTypes["FeatureSectionTemperature"]>,
	paginateFeatureSectionTemperature: GraphQLTypes["FeatureSectionTemperatureConnection"],
	getFeatureSectionTube?: GraphQLTypes["FeatureSectionTube"] | undefined,
	listFeatureSectionTube: Array<GraphQLTypes["FeatureSectionTube"]>,
	paginateFeatureSectionTube: GraphQLTypes["FeatureSectionTubeConnection"],
	getFeatureSectionAntimer?: GraphQLTypes["FeatureSectionAntimer"] | undefined,
	listFeatureSectionAntimer: Array<GraphQLTypes["FeatureSectionAntimer"]>,
	paginateFeatureSectionAntimer: GraphQLTypes["FeatureSectionAntimerConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getGeneral?: GraphQLTypes["General"] | undefined,
	listGeneral: Array<GraphQLTypes["General"]>,
	paginateGeneral: GraphQLTypes["GeneralConnection"],
	getGeneralLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<GraphQLTypes["GeneralLocale"]>,
	paginateGeneralLocale: GraphQLTypes["GeneralLocaleConnection"],
	getProductFeature?: GraphQLTypes["ProductFeature"] | undefined,
	listProductFeature: Array<GraphQLTypes["ProductFeature"]>,
	paginateProductFeature: GraphQLTypes["ProductFeatureConnection"],
	getProductFeatureLocale?: GraphQLTypes["ProductFeatureLocale"] | undefined,
	listProductFeatureLocale: Array<GraphQLTypes["ProductFeatureLocale"]>,
	paginateProductFeatureLocale: GraphQLTypes["ProductFeatureLocaleConnection"],
	getFilledOrderForm?: GraphQLTypes["FilledOrderForm"] | undefined,
	listFilledOrderForm: Array<GraphQLTypes["FilledOrderForm"]>,
	paginateFilledOrderForm: GraphQLTypes["FilledOrderFormConnection"],
	validateCreateFilledOrderForm: GraphQLTypes["_ValidationResult"],
	validateUpdateFilledOrderForm: GraphQLTypes["_ValidationResult"],
	getOrderPage?: GraphQLTypes["OrderPage"] | undefined,
	listOrderPage: Array<GraphQLTypes["OrderPage"]>,
	paginateOrderPage: GraphQLTypes["OrderPageConnection"],
	getOrderPageLocale?: GraphQLTypes["OrderPageLocale"] | undefined,
	listOrderPageLocale: Array<GraphQLTypes["OrderPageLocale"]>,
	paginateOrderPageLocale: GraphQLTypes["OrderPageLocaleConnection"],
	getFilledOrderFormEmailAddress?: GraphQLTypes["FilledOrderFormEmailAddress"] | undefined,
	listFilledOrderFormEmailAddress: Array<GraphQLTypes["FilledOrderFormEmailAddress"]>,
	paginateFilledOrderFormEmailAddress: GraphQLTypes["FilledOrderFormEmailAddressConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getFilledOrderFormCountry?: GraphQLTypes["FilledOrderFormCountry"] | undefined,
	listFilledOrderFormCountry: Array<GraphQLTypes["FilledOrderFormCountry"]>,
	paginateFilledOrderFormCountry: GraphQLTypes["FilledOrderFormCountryConnection"],
	getFilledProductWaitlistForm?: GraphQLTypes["FilledProductWaitlistForm"] | undefined,
	listFilledProductWaitlistForm: Array<GraphQLTypes["FilledProductWaitlistForm"]>,
	paginateFilledProductWaitlistForm: GraphQLTypes["FilledProductWaitlistFormConnection"],
	validateCreateFilledProductWaitlistForm: GraphQLTypes["_ValidationResult"],
	validateUpdateFilledProductWaitlistForm: GraphQLTypes["_ValidationResult"],
	transaction?: GraphQLTypes["QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Info"] | undefined,
	schema?: GraphQLTypes["_Schema"] | undefined
};
	["Image"]: {
	__typename: "Image",
	_meta?: GraphQLTypes["ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined,
	fileName?: string | undefined
};
	["ImageMeta"]: {
	__typename: "ImageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	alt?: GraphQLTypes["FieldMeta"] | undefined,
	fileName?: GraphQLTypes["FieldMeta"] | undefined
};
	["FieldMeta"]: {
	__typename: "FieldMeta",
	readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["UUID"]: "scalar" & { name: "UUID" };
	["ImageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ImageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	alt?: GraphQLTypes["StringCondition"] | undefined,
	fileName?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageWhere"] | undefined
};
	["UUIDCondition"]: {
		and?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	or?: Array<GraphQLTypes["UUIDCondition"]> | undefined,
	not?: GraphQLTypes["UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["UUID"] | undefined,
	notEq?: GraphQLTypes["UUID"] | undefined,
	in?: Array<GraphQLTypes["UUID"]> | undefined,
	notIn?: Array<GraphQLTypes["UUID"]> | undefined,
	lt?: GraphQLTypes["UUID"] | undefined,
	lte?: GraphQLTypes["UUID"] | undefined,
	gt?: GraphQLTypes["UUID"] | undefined,
	gte?: GraphQLTypes["UUID"] | undefined
};
	["StringCondition"]: {
		and?: Array<GraphQLTypes["StringCondition"]> | undefined,
	or?: Array<GraphQLTypes["StringCondition"]> | undefined,
	not?: GraphQLTypes["StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["IntCondition"]: {
		and?: Array<GraphQLTypes["IntCondition"]> | undefined,
	or?: Array<GraphQLTypes["IntCondition"]> | undefined,
	not?: GraphQLTypes["IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["ImageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	alt?: GraphQLTypes["OrderDirection"] | undefined,
	fileName?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderDirection"]: OrderDirection;
	["ImageConnection"]: {
	__typename: "ImageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageEdge"]>
};
	["PageInfo"]: {
	__typename: "PageInfo",
	totalCount: number
};
	["ImageEdge"]: {
	__typename: "ImageEdge",
	node: GraphQLTypes["Image"]
};
	["GenericPage"]: {
	__typename: "GenericPage",
	_meta?: GraphQLTypes["GenericPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["GenericPageLocale"]>,
	isSideNavigation: boolean,
	localesByLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesByLink?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesByContent?: GraphQLTypes["GenericPageLocale"] | undefined,
	localesBySideNavigationItems?: GraphQLTypes["GenericPageLocale"] | undefined,
	paginateLocales: GraphQLTypes["GenericPageLocaleConnection"]
};
	["GenericPageMeta"]: {
	__typename: "GenericPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	isSideNavigation?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPageLocale"]: {
	__typename: "GenericPageLocale",
	_meta?: GraphQLTypes["GenericPageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	root?: GraphQLTypes["GenericPage"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	content?: GraphQLTypes["Content"] | undefined,
	sideNavigationItems: Array<GraphQLTypes["GenericPageSideNavigation"]>,
	paginateSideNavigationItems: GraphQLTypes["GenericPageSideNavigationConnection"]
};
	["GenericPageLocaleMeta"]: {
	__typename: "GenericPageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	sideNavigationItems?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	locales?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	isSideNavigation?: GraphQLTypes["BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["GenericPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GenericPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GenericPageWhere"] | undefined
};
	["GenericPageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["GenericPageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	sideNavigationItems?: GraphQLTypes["GenericPageSideNavigationWhere"] | undefined,
	and?: Array<GraphQLTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GenericPageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GenericPageLocaleWhere"] | undefined
};
	["LocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	genericPages?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	headers?: GraphQLTypes["HeaderLocaleWhere"] | undefined,
	footers?: GraphQLTypes["FooterLocaleWhere"] | undefined,
	productCategories?: GraphQLTypes["ProductCategoryLocaleWhere"] | undefined,
	products?: GraphQLTypes["ProductLocaleWhere"] | undefined,
	generals?: GraphQLTypes["GeneralLocaleWhere"] | undefined,
	currency?: GraphQLTypes["StringCondition"] | undefined,
	productFeatureItems?: GraphQLTypes["ProductFeatureLocaleWhere"] | undefined,
	orderPages?: GraphQLTypes["OrderPageLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LocaleWhere"] | undefined
};
	["HeaderLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["HeaderWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	specialLink?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["HeaderLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeaderLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeaderLocaleWhere"] | undefined
};
	["HeaderWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	locales?: GraphQLTypes["HeaderLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["HeaderWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["HeaderWhere"] | undefined> | undefined,
	not?: GraphQLTypes["HeaderWhere"] | undefined
};
	["OneCondition"]: {
		and?: Array<GraphQLTypes["OneCondition"]> | undefined,
	or?: Array<GraphQLTypes["OneCondition"]> | undefined,
	not?: GraphQLTypes["OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["One"] | undefined,
	notEq?: GraphQLTypes["One"] | undefined,
	in?: Array<GraphQLTypes["One"]> | undefined,
	notIn?: Array<GraphQLTypes["One"]> | undefined,
	lt?: GraphQLTypes["One"] | undefined,
	lte?: GraphQLTypes["One"] | undefined,
	gt?: GraphQLTypes["One"] | undefined,
	gte?: GraphQLTypes["One"] | undefined
};
	["One"]: One;
	["LinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["LinkTypeCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	externalLink?: GraphQLTypes["StringCondition"] | undefined,
	internalLink?: GraphQLTypes["LinkableWhere"] | undefined,
	scrollToTargetId?: GraphQLTypes["StringCondition"] | undefined,
	file?: GraphQLTypes["FileWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkWhere"] | undefined
};
	["LinkTypeCondition"]: {
		and?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["LinkTypeCondition"]> | undefined,
	not?: GraphQLTypes["LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["LinkType"] | undefined,
	notEq?: GraphQLTypes["LinkType"] | undefined,
	in?: Array<GraphQLTypes["LinkType"]> | undefined,
	notIn?: Array<GraphQLTypes["LinkType"]> | undefined,
	lt?: GraphQLTypes["LinkType"] | undefined,
	lte?: GraphQLTypes["LinkType"] | undefined,
	gt?: GraphQLTypes["LinkType"] | undefined,
	gte?: GraphQLTypes["LinkType"] | undefined
};
	["LinkType"]: LinkType;
	["LinkableWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectWhere"] | undefined,
	orderPage?: GraphQLTypes["OrderPageLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkableWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkableWhere"] | undefined
};
	["RedirectWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	target?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["RedirectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["RedirectWhere"] | undefined
};
	["OrderPageLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkableWhere"] | undefined,
	root?: GraphQLTypes["OrderPageWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	termsAndConditions?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["OrderPageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderPageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderPageLocaleWhere"] | undefined
};
	["OrderPageWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["OrderPageLocaleWhere"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["OrderPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["OrderPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["OrderPageWhere"] | undefined
};
	["SeoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	ogTitle?: GraphQLTypes["StringCondition"] | undefined,
	ogDescription?: GraphQLTypes["StringCondition"] | undefined,
	noIndex?: GraphQLTypes["BooleanCondition"] | undefined,
	noFollow?: GraphQLTypes["BooleanCondition"] | undefined,
	ogImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["SeoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["SeoWhere"] | undefined
};
	["BooleanCondition"]: {
		and?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	or?: Array<GraphQLTypes["BooleanCondition"]> | undefined,
	not?: GraphQLTypes["BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["FileWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	url?: GraphQLTypes["StringCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	fileName?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FileWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FileWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FileWhere"] | undefined
};
	["FooterLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	copyright?: GraphQLTypes["StringCondition"] | undefined,
	groupsLinkList?: GraphQLTypes["FooterGroupLinkListWhere"] | undefined,
	root?: GraphQLTypes["FooterWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	contact?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FooterLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FooterLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FooterLocaleWhere"] | undefined
};
	["FooterGroupLinkListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	footer?: GraphQLTypes["FooterLocaleWhere"] | undefined,
	linkList?: GraphQLTypes["LinkListWhere"] | undefined,
	isAddress?: GraphQLTypes["BooleanCondition"] | undefined,
	addressValue?: GraphQLTypes["StringCondition"] | undefined,
	addressLink?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["FooterGroupLinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FooterGroupLinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FooterGroupLinkListWhere"] | undefined
};
	["LinkListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["LinkItemWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkListWhere"] | undefined
};
	["LinkItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["LinkListWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["LinkItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["LinkItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["LinkItemWhere"] | undefined
};
	["FooterWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	logo?: GraphQLTypes["ImageWhere"] | undefined,
	locales?: GraphQLTypes["FooterLocaleWhere"] | undefined,
	logoCompany?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["FooterWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FooterWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FooterWhere"] | undefined
};
	["ProductCategoryLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryLocaleWhere"] | undefined
};
	["ProductCategoryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	products?: GraphQLTypes["ProductWhere"] | undefined,
	locales?: GraphQLTypes["ProductCategoryLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryWhere"] | undefined
};
	["ProductWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	miningPower?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	category?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	tileImage?: GraphQLTypes["ImageWhere"] | undefined,
	locales?: GraphQLTypes["ProductLocaleWhere"] | undefined,
	isForSale?: GraphQLTypes["BooleanCondition"] | undefined,
	features?: GraphQLTypes["ProductFeatureWhere"] | undefined,
	slug?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductWhere"] | undefined
};
	["ProductLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	root?: GraphQLTypes["ProductWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	price?: GraphQLTypes["IntCondition"] | undefined,
	buttonLabel?: GraphQLTypes["StringCondition"] | undefined,
	isCustomButtonLabel?: GraphQLTypes["BooleanCondition"] | undefined,
	discountedPrice?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductLocaleWhere"] | undefined
};
	["ProductFeatureWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	locales?: GraphQLTypes["ProductFeatureLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductFeatureWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductFeatureWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductFeatureWhere"] | undefined
};
	["ProductFeatureLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	price?: GraphQLTypes["IntCondition"] | undefined,
	root?: GraphQLTypes["ProductFeatureWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["ProductFeatureLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductFeatureLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductFeatureLocaleWhere"] | undefined
};
	["GeneralLocaleWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	root?: GraphQLTypes["GeneralWhere"] | undefined,
	locale?: GraphQLTypes["LocaleWhere"] | undefined,
	seo?: GraphQLTypes["SeoWhere"] | undefined,
	and?: Array<GraphQLTypes["GeneralLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GeneralLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GeneralLocaleWhere"] | undefined
};
	["GeneralWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	unique?: GraphQLTypes["OneCondition"] | undefined,
	locales?: GraphQLTypes["GeneralLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["GeneralWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GeneralWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GeneralWhere"] | undefined
};
	["ContentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	blocks?: GraphQLTypes["ContentBlockWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentWhere"] | undefined
};
	["ContentBlockWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	json?: GraphQLTypes["StringCondition"] | undefined,
	content?: GraphQLTypes["ContentWhere"] | undefined,
	references?: GraphQLTypes["ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentBlockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentBlockWhere"] | undefined
};
	["ContentReferenceWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	type?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	block?: GraphQLTypes["ContentBlockWhere"] | undefined,
	primaryText?: GraphQLTypes["StringCondition"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	linkTwo?: GraphQLTypes["LinkWhere"] | undefined,
	buttonLink?: GraphQLTypes["ButtonLinkWhere"] | undefined,
	buttonLinkTwo?: GraphQLTypes["ButtonLinkWhere"] | undefined,
	featureList?: GraphQLTypes["FeatureListWhere"] | undefined,
	projection?: GraphQLTypes["ProjectionWhere"] | undefined,
	contentImageWithFeatures?: GraphQLTypes["ContentImageWithFeaturesWhere"] | undefined,
	asideContentMedia?: GraphQLTypes["AsideContentMediaWhere"] | undefined,
	imageList?: GraphQLTypes["ImageListWhere"] | undefined,
	productCategories?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	secondaryText?: GraphQLTypes["StringCondition"] | undefined,
	callToActionTypes?: GraphQLTypes["CallToActionTypeCondition"] | undefined,
	secondaryImage?: GraphQLTypes["ImageWhere"] | undefined,
	featureSection?: GraphQLTypes["FeatureSectionWhere"] | undefined,
	terciaryImage?: GraphQLTypes["ImageWhere"] | undefined,
	video?: GraphQLTypes["VideoWhere"] | undefined,
	isImageWithWhiteShadow?: GraphQLTypes["BooleanCondition"] | undefined,
	secondaryVideo?: GraphQLTypes["VideoWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentReferenceWhere"] | undefined
};
	["ContentReferenceTypeCondition"]: {
		and?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ContentReferenceTypeCondition"]> | undefined,
	not?: GraphQLTypes["ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ContentReferenceType"] | undefined,
	notEq?: GraphQLTypes["ContentReferenceType"] | undefined,
	in?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	notIn?: Array<GraphQLTypes["ContentReferenceType"]> | undefined,
	lt?: GraphQLTypes["ContentReferenceType"] | undefined,
	lte?: GraphQLTypes["ContentReferenceType"] | undefined,
	gt?: GraphQLTypes["ContentReferenceType"] | undefined,
	gte?: GraphQLTypes["ContentReferenceType"] | undefined
};
	["ContentReferenceType"]: ContentReferenceType;
	["ButtonLinkWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	visualType?: GraphQLTypes["ButtonLinkVisualTypeCondition"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	fillType?: GraphQLTypes["ButtonLinkFillTypeCondition"] | undefined,
	widthType?: GraphQLTypes["ButtonLinkWidthTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["ButtonLinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ButtonLinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ButtonLinkWhere"] | undefined
};
	["ButtonLinkVisualTypeCondition"]: {
		and?: Array<GraphQLTypes["ButtonLinkVisualTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ButtonLinkVisualTypeCondition"]> | undefined,
	not?: GraphQLTypes["ButtonLinkVisualTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ButtonLinkVisualType"] | undefined,
	notEq?: GraphQLTypes["ButtonLinkVisualType"] | undefined,
	in?: Array<GraphQLTypes["ButtonLinkVisualType"]> | undefined,
	notIn?: Array<GraphQLTypes["ButtonLinkVisualType"]> | undefined,
	lt?: GraphQLTypes["ButtonLinkVisualType"] | undefined,
	lte?: GraphQLTypes["ButtonLinkVisualType"] | undefined,
	gt?: GraphQLTypes["ButtonLinkVisualType"] | undefined,
	gte?: GraphQLTypes["ButtonLinkVisualType"] | undefined
};
	["ButtonLinkVisualType"]: ButtonLinkVisualType;
	["ButtonLinkFillTypeCondition"]: {
		and?: Array<GraphQLTypes["ButtonLinkFillTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ButtonLinkFillTypeCondition"]> | undefined,
	not?: GraphQLTypes["ButtonLinkFillTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ButtonLinkFillType"] | undefined,
	notEq?: GraphQLTypes["ButtonLinkFillType"] | undefined,
	in?: Array<GraphQLTypes["ButtonLinkFillType"]> | undefined,
	notIn?: Array<GraphQLTypes["ButtonLinkFillType"]> | undefined,
	lt?: GraphQLTypes["ButtonLinkFillType"] | undefined,
	lte?: GraphQLTypes["ButtonLinkFillType"] | undefined,
	gt?: GraphQLTypes["ButtonLinkFillType"] | undefined,
	gte?: GraphQLTypes["ButtonLinkFillType"] | undefined
};
	["ButtonLinkFillType"]: ButtonLinkFillType;
	["ButtonLinkWidthTypeCondition"]: {
		and?: Array<GraphQLTypes["ButtonLinkWidthTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["ButtonLinkWidthTypeCondition"]> | undefined,
	not?: GraphQLTypes["ButtonLinkWidthTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["ButtonLinkWidthType"] | undefined,
	notEq?: GraphQLTypes["ButtonLinkWidthType"] | undefined,
	in?: Array<GraphQLTypes["ButtonLinkWidthType"]> | undefined,
	notIn?: Array<GraphQLTypes["ButtonLinkWidthType"]> | undefined,
	lt?: GraphQLTypes["ButtonLinkWidthType"] | undefined,
	lte?: GraphQLTypes["ButtonLinkWidthType"] | undefined,
	gt?: GraphQLTypes["ButtonLinkWidthType"] | undefined,
	gte?: GraphQLTypes["ButtonLinkWidthType"] | undefined
};
	["ButtonLinkWidthType"]: ButtonLinkWidthType;
	["FeatureListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["FeatureItemWhere"] | undefined,
	and?: Array<GraphQLTypes["FeatureListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureListWhere"] | undefined
};
	["FeatureItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["FeatureListWhere"] | undefined,
	feature?: GraphQLTypes["FeatureWhere"] | undefined,
	and?: Array<GraphQLTypes["FeatureItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureItemWhere"] | undefined
};
	["FeatureWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	icon?: GraphQLTypes["ImageWhere"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FeatureWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureWhere"] | undefined
};
	["ProjectionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProjectionItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ProjectionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProjectionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProjectionWhere"] | undefined
};
	["ProjectionItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProjectionWhere"] | undefined,
	item?: GraphQLTypes["ProjectionContentWhere"] | undefined,
	and?: Array<GraphQLTypes["ProjectionItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProjectionItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProjectionItemWhere"] | undefined
};
	["ProjectionContentWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ProjectionContentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProjectionContentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProjectionContentWhere"] | undefined
};
	["ContentImageWithFeaturesWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	subtitle?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	text?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	features?: GraphQLTypes["FeatureListWhere"] | undefined,
	and?: Array<GraphQLTypes["ContentImageWithFeaturesWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ContentImageWithFeaturesWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ContentImageWithFeaturesWhere"] | undefined
};
	["AsideContentMediaWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	subtitle?: GraphQLTypes["StringCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	media?: GraphQLTypes["ImageWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	tableSpecifiation?: GraphQLTypes["AsideContentMediaSpecificationWhere"] | undefined,
	and?: Array<GraphQLTypes["AsideContentMediaWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AsideContentMediaWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AsideContentMediaWhere"] | undefined
};
	["AsideContentMediaSpecificationWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	value?: GraphQLTypes["StringCondition"] | undefined,
	specification?: GraphQLTypes["AsideContentMediaWhere"] | undefined,
	and?: Array<GraphQLTypes["AsideContentMediaSpecificationWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["AsideContentMediaSpecificationWhere"] | undefined> | undefined,
	not?: GraphQLTypes["AsideContentMediaSpecificationWhere"] | undefined
};
	["ImageListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ImageListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListWhere"] | undefined
};
	["ImageListItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	list?: GraphQLTypes["ImageListWhere"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	imageMobile?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ImageListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ImageListItemWhere"] | undefined
};
	["ProductCategoryListWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	items?: GraphQLTypes["ProductCategoryItemWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryListWhere"] | undefined
};
	["ProductCategoryItemWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["ProductCategoryListWhere"] | undefined,
	item?: GraphQLTypes["ProductCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["ProductCategoryItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["ProductCategoryItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["ProductCategoryItemWhere"] | undefined
};
	["CallToActionTypeCondition"]: {
		and?: Array<GraphQLTypes["CallToActionTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["CallToActionTypeCondition"]> | undefined,
	not?: GraphQLTypes["CallToActionTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["CallToActionType"] | undefined,
	notEq?: GraphQLTypes["CallToActionType"] | undefined,
	in?: Array<GraphQLTypes["CallToActionType"]> | undefined,
	notIn?: Array<GraphQLTypes["CallToActionType"]> | undefined,
	lt?: GraphQLTypes["CallToActionType"] | undefined,
	lte?: GraphQLTypes["CallToActionType"] | undefined,
	gt?: GraphQLTypes["CallToActionType"] | undefined,
	gte?: GraphQLTypes["CallToActionType"] | undefined
};
	["CallToActionType"]: CallToActionType;
	["FeatureSectionWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	temperature?: GraphQLTypes["FeatureSectionTemperatureWhere"] | undefined,
	heat?: GraphQLTypes["FeatureSectionHeatWhere"] | undefined,
	pump?: GraphQLTypes["FeatureSectionPumpWhere"] | undefined,
	tube?: GraphQLTypes["FeatureSectionTubeWhere"] | undefined,
	antimer?: GraphQLTypes["FeatureSectionAntimerWhere"] | undefined,
	and?: Array<GraphQLTypes["FeatureSectionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureSectionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureSectionWhere"] | undefined
};
	["FeatureSectionTemperatureWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	number?: GraphQLTypes["IntCondition"] | undefined,
	and?: Array<GraphQLTypes["FeatureSectionTemperatureWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureSectionTemperatureWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureSectionTemperatureWhere"] | undefined
};
	["FeatureSectionHeatWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title1?: GraphQLTypes["StringCondition"] | undefined,
	titleHighlighted1?: GraphQLTypes["StringCondition"] | undefined,
	titleHighlighted2?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["FeatureSectionHeatWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureSectionHeatWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureSectionHeatWhere"] | undefined
};
	["FeatureSectionPumpWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	sideTitle?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FeatureSectionPumpWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureSectionPumpWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureSectionPumpWhere"] | undefined
};
	["FeatureSectionTubeWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	title?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	secondaryImage?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["FeatureSectionTubeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureSectionTubeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureSectionTubeWhere"] | undefined
};
	["FeatureSectionAntimerWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	label1?: GraphQLTypes["StringCondition"] | undefined,
	label2?: GraphQLTypes["StringCondition"] | undefined,
	description?: GraphQLTypes["StringCondition"] | undefined,
	number?: GraphQLTypes["IntCondition"] | undefined,
	image?: GraphQLTypes["ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["FeatureSectionAntimerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FeatureSectionAntimerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FeatureSectionAntimerWhere"] | undefined
};
	["VideoWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	src?: GraphQLTypes["StringCondition"] | undefined,
	width?: GraphQLTypes["IntCondition"] | undefined,
	height?: GraphQLTypes["IntCondition"] | undefined,
	size?: GraphQLTypes["IntCondition"] | undefined,
	type?: GraphQLTypes["StringCondition"] | undefined,
	duration?: GraphQLTypes["FloatCondition"] | undefined,
	poster?: GraphQLTypes["ImageWhere"] | undefined,
	fileName?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["VideoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["VideoWhere"] | undefined
};
	["FloatCondition"]: {
		and?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	or?: Array<GraphQLTypes["FloatCondition"]> | undefined,
	not?: GraphQLTypes["FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["GenericPageSideNavigationWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	list?: GraphQLTypes["GenericPageLocaleWhere"] | undefined,
	link?: GraphQLTypes["LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["GenericPageSideNavigationWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["GenericPageSideNavigationWhere"] | undefined> | undefined,
	not?: GraphQLTypes["GenericPageSideNavigationWhere"] | undefined
};
	["Locale"]: {
	__typename: "Locale",
	_meta?: GraphQLTypes["LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	label?: string | undefined,
	genericPages: Array<GraphQLTypes["GenericPageLocale"]>,
	headers: Array<GraphQLTypes["HeaderLocale"]>,
	footers: Array<GraphQLTypes["FooterLocale"]>,
	productCategories: Array<GraphQLTypes["ProductCategoryLocale"]>,
	products: Array<GraphQLTypes["ProductLocale"]>,
	generals: Array<GraphQLTypes["GeneralLocale"]>,
	currency?: string | undefined,
	productFeatureItems: Array<GraphQLTypes["ProductFeatureLocale"]>,
	orderPages: Array<GraphQLTypes["OrderPageLocale"]>,
	genericPagesByRoot?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesBySeo?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesByLink?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesByContent?: GraphQLTypes["GenericPageLocale"] | undefined,
	genericPagesBySideNavigationItems?: GraphQLTypes["GenericPageLocale"] | undefined,
	headersByRoot?: GraphQLTypes["HeaderLocale"] | undefined,
	footersByRoot?: GraphQLTypes["FooterLocale"] | undefined,
	footersByGroupsLinkList?: GraphQLTypes["FooterLocale"] | undefined,
	productCategoriesByRoot?: GraphQLTypes["ProductCategoryLocale"] | undefined,
	productsByRoot?: GraphQLTypes["ProductLocale"] | undefined,
	generalsByRoot?: GraphQLTypes["GeneralLocale"] | undefined,
	generalsBySeo?: GraphQLTypes["GeneralLocale"] | undefined,
	productFeatureItemsByRoot?: GraphQLTypes["ProductFeatureLocale"] | undefined,
	orderPagesByRoot?: GraphQLTypes["OrderPageLocale"] | undefined,
	orderPagesByLink?: GraphQLTypes["OrderPageLocale"] | undefined,
	orderPagesBySeo?: GraphQLTypes["OrderPageLocale"] | undefined,
	paginateGenericPages: GraphQLTypes["GenericPageLocaleConnection"],
	paginateHeaders: GraphQLTypes["HeaderLocaleConnection"],
	paginateFooters: GraphQLTypes["FooterLocaleConnection"],
	paginateProductCategories: GraphQLTypes["ProductCategoryLocaleConnection"],
	paginateProducts: GraphQLTypes["ProductLocaleConnection"],
	paginateGenerals: GraphQLTypes["GeneralLocaleConnection"],
	paginateProductFeatureItems: GraphQLTypes["ProductFeatureLocaleConnection"],
	paginateOrderPages: GraphQLTypes["OrderPageLocaleConnection"]
};
	["LocaleMeta"]: {
	__typename: "LocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	genericPages?: GraphQLTypes["FieldMeta"] | undefined,
	headers?: GraphQLTypes["FieldMeta"] | undefined,
	footers?: GraphQLTypes["FieldMeta"] | undefined,
	productCategories?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	generals?: GraphQLTypes["FieldMeta"] | undefined,
	currency?: GraphQLTypes["FieldMeta"] | undefined,
	productFeatureItems?: GraphQLTypes["FieldMeta"] | undefined,
	orderPages?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["GenericPageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["GenericPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	isSideNavigation?: GraphQLTypes["OrderDirection"] | undefined
};
	["LocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	currency?: GraphQLTypes["OrderDirection"] | undefined
};
	["SeoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	ogTitle?: GraphQLTypes["OrderDirection"] | undefined,
	ogDescription?: GraphQLTypes["OrderDirection"] | undefined,
	noIndex?: GraphQLTypes["OrderDirection"] | undefined,
	noFollow?: GraphQLTypes["OrderDirection"] | undefined,
	ogImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["LinkableOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleOrderBy"] | undefined,
	redirect?: GraphQLTypes["RedirectOrderBy"] | undefined,
	orderPage?: GraphQLTypes["OrderPageLocaleOrderBy"] | undefined
};
	["RedirectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	target?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	externalLink?: GraphQLTypes["OrderDirection"] | undefined,
	internalLink?: GraphQLTypes["LinkableOrderBy"] | undefined,
	scrollToTargetId?: GraphQLTypes["OrderDirection"] | undefined,
	file?: GraphQLTypes["FileOrderBy"] | undefined
};
	["FileOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	url?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	fileName?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderPageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkableOrderBy"] | undefined,
	root?: GraphQLTypes["OrderPageOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined,
	termsAndConditions?: GraphQLTypes["OrderDirection"] | undefined
};
	["OrderPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ContentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["HeaderLocale"]: {
	__typename: "HeaderLocale",
	_meta?: GraphQLTypes["HeaderLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["Header"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	specialLink?: GraphQLTypes["Link"] | undefined
};
	["HeaderLocaleMeta"]: {
	__typename: "HeaderLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	specialLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["Header"]: {
	__typename: "Header",
	_meta?: GraphQLTypes["HeaderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	logo?: GraphQLTypes["Image"] | undefined,
	locales: Array<GraphQLTypes["HeaderLocale"]>,
	localesByLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	paginateLocales: GraphQLTypes["HeaderLocaleConnection"]
};
	["HeaderMeta"]: {
	__typename: "HeaderMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["HeaderLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["HeaderOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	specialLink?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["HeaderOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["HeaderLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["LocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	genericPages?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined,
	headers?: GraphQLTypes["HeaderLocaleUniqueWhere"] | undefined,
	footers?: GraphQLTypes["FooterLocaleUniqueWhere"] | undefined,
	productCategories?: GraphQLTypes["ProductCategoryLocaleUniqueWhere"] | undefined,
	products?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined,
	generals?: GraphQLTypes["GeneralLocaleUniqueWhere"] | undefined,
	productFeatureItems?: GraphQLTypes["ProductFeatureLocaleUniqueWhere"] | undefined,
	orderPages?: GraphQLTypes["OrderPageLocaleUniqueWhere"] | undefined
};
	["GenericPageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["GenericPageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	content?: GraphQLTypes["ContentUniqueWhere"] | undefined,
	sideNavigationItems?: GraphQLTypes["GenericPageSideNavigationUniqueWhere"] | undefined
};
	["GenericPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined
};
	["SeoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	ogImage?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["LinkableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	genericPage?: GraphQLTypes["GenericPageLocaleUniqueWhere"] | undefined,
	redirect?: GraphQLTypes["RedirectUniqueWhere"] | undefined,
	orderPage?: GraphQLTypes["OrderPageLocaleUniqueWhere"] | undefined
};
	["RedirectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	target?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["OrderPageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["OrderPageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	link?: GraphQLTypes["LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["OrderPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["OrderPageLocaleUniqueWhere"] | undefined
};
	["ContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blocks?: GraphQLTypes["ContentBlockUniqueWhere"] | undefined
};
	["ContentBlockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentReferenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	linkTwo?: GraphQLTypes["LinkUniqueWhere"] | undefined,
	buttonLink?: GraphQLTypes["ButtonLinkUniqueWhere"] | undefined,
	buttonLinkTwo?: GraphQLTypes["ButtonLinkUniqueWhere"] | undefined,
	featureList?: GraphQLTypes["FeatureListUniqueWhere"] | undefined,
	projection?: GraphQLTypes["ProjectionUniqueWhere"] | undefined,
	contentImageWithFeatures?: GraphQLTypes["ContentImageWithFeaturesUniqueWhere"] | undefined,
	asideContentMedia?: GraphQLTypes["AsideContentMediaUniqueWhere"] | undefined,
	imageList?: GraphQLTypes["ImageListUniqueWhere"] | undefined,
	productCategories?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined,
	featureSection?: GraphQLTypes["FeatureSectionUniqueWhere"] | undefined
};
	["ButtonLinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["FeatureListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["FeatureItemUniqueWhere"] | undefined
};
	["FeatureItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProjectionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProjectionItemUniqueWhere"] | undefined
};
	["ProjectionItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ContentImageWithFeaturesUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	features?: GraphQLTypes["FeatureListUniqueWhere"] | undefined
};
	["AsideContentMediaUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	tableSpecifiation?: GraphQLTypes["AsideContentMediaSpecificationUniqueWhere"] | undefined
};
	["AsideContentMediaSpecificationUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ImageListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ImageListItemUniqueWhere"] | undefined
};
	["ImageListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["ProductCategoryListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["ProductCategoryItemUniqueWhere"] | undefined
};
	["ProductCategoryItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FeatureSectionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	temperature?: GraphQLTypes["FeatureSectionTemperatureUniqueWhere"] | undefined,
	heat?: GraphQLTypes["FeatureSectionHeatUniqueWhere"] | undefined,
	pump?: GraphQLTypes["FeatureSectionPumpUniqueWhere"] | undefined,
	tube?: GraphQLTypes["FeatureSectionTubeUniqueWhere"] | undefined,
	antimer?: GraphQLTypes["FeatureSectionAntimerUniqueWhere"] | undefined
};
	["FeatureSectionTemperatureUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FeatureSectionHeatUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FeatureSectionPumpUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FeatureSectionTubeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FeatureSectionAntimerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["GenericPageSideNavigationUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["HeaderLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["HeaderUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["HeaderUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["HeaderLocaleUniqueWhere"] | undefined
};
	["FooterLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["FooterUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	groupsLinkList?: GraphQLTypes["FooterGroupLinkListUniqueWhere"] | undefined
};
	["FooterUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["FooterLocaleUniqueWhere"] | undefined
};
	["FooterGroupLinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	linkList?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["LinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["LinkItemUniqueWhere"] | undefined
};
	["LinkItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ProductCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	locales?: GraphQLTypes["ProductCategoryLocaleUniqueWhere"] | undefined
};
	["ProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined,
	locales?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined
};
	["ProductLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["GeneralLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["GeneralUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined,
	seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GeneralUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["One"] | undefined,
	locales?: GraphQLTypes["GeneralLocaleUniqueWhere"] | undefined
};
	["ProductFeatureLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["ProductFeatureUniqueWhere"] | undefined,
	locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductFeatureUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["ProductFeatureLocaleUniqueWhere"] | undefined
};
	["HeaderLocaleConnection"]: {
	__typename: "HeaderLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeaderLocaleEdge"]>
};
	["HeaderLocaleEdge"]: {
	__typename: "HeaderLocaleEdge",
	node: GraphQLTypes["HeaderLocale"]
};
	["Link"]: {
	__typename: "Link",
	_meta?: GraphQLTypes["LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Linkable"] | undefined,
	scrollToTargetId?: string | undefined,
	file?: GraphQLTypes["File"] | undefined
};
	["LinkMeta"]: {
	__typename: "LinkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["FieldMeta"] | undefined,
	scrollToTargetId?: GraphQLTypes["FieldMeta"] | undefined,
	file?: GraphQLTypes["FieldMeta"] | undefined
};
	["Linkable"]: {
	__typename: "Linkable",
	_meta?: GraphQLTypes["LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	genericPage?: GraphQLTypes["GenericPageLocale"] | undefined,
	redirect?: GraphQLTypes["Redirect"] | undefined,
	orderPage?: GraphQLTypes["OrderPageLocale"] | undefined
};
	["LinkableMeta"]: {
	__typename: "LinkableMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	genericPage?: GraphQLTypes["FieldMeta"] | undefined,
	redirect?: GraphQLTypes["FieldMeta"] | undefined,
	orderPage?: GraphQLTypes["FieldMeta"] | undefined
};
	["Redirect"]: {
	__typename: "Redirect",
	_meta?: GraphQLTypes["RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Linkable"] | undefined,
	target?: GraphQLTypes["Link"] | undefined
};
	["RedirectMeta"]: {
	__typename: "RedirectMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	target?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPageLocale"]: {
	__typename: "OrderPageLocale",
	_meta?: GraphQLTypes["OrderPageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Linkable"] | undefined,
	root?: GraphQLTypes["OrderPage"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined,
	termsAndConditions?: string | undefined
};
	["OrderPageLocaleMeta"]: {
	__typename: "OrderPageLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined,
	termsAndConditions?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPage"]: {
	__typename: "OrderPage",
	_meta?: GraphQLTypes["OrderPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["OrderPageLocale"]>,
	logo?: GraphQLTypes["Image"] | undefined,
	localesByLocale?: GraphQLTypes["OrderPageLocale"] | undefined,
	localesByLink?: GraphQLTypes["OrderPageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["OrderPageLocale"] | undefined,
	paginateLocales: GraphQLTypes["OrderPageLocaleConnection"]
};
	["OrderPageMeta"]: {
	__typename: "OrderPageMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined
};
	["OrderPageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["OrderPageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["OrderPageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["OrderPageLocaleConnection"]: {
	__typename: "OrderPageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPageLocaleEdge"]>
};
	["OrderPageLocaleEdge"]: {
	__typename: "OrderPageLocaleEdge",
	node: GraphQLTypes["OrderPageLocale"]
};
	["Seo"]: {
	__typename: "Seo",
	_meta?: GraphQLTypes["SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	noIndex?: boolean | undefined,
	noFollow?: boolean | undefined,
	ogImage?: GraphQLTypes["Image"] | undefined
};
	["SeoMeta"]: {
	__typename: "SeoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["FieldMeta"] | undefined,
	noIndex?: GraphQLTypes["FieldMeta"] | undefined,
	noFollow?: GraphQLTypes["FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["File"]: {
	__typename: "File",
	_meta?: GraphQLTypes["FileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	size?: number | undefined,
	type?: string | undefined,
	label?: string | undefined,
	fileName?: string | undefined
};
	["FileMeta"]: {
	__typename: "FileMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	url?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	fileName?: GraphQLTypes["FieldMeta"] | undefined
};
	["FooterLocale"]: {
	__typename: "FooterLocale",
	_meta?: GraphQLTypes["FooterLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	copyright?: string | undefined,
	groupsLinkList: Array<GraphQLTypes["FooterGroupLinkList"]>,
	root?: GraphQLTypes["Footer"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	contact?: string | undefined,
	groupsLinkListByLinkList?: GraphQLTypes["FooterGroupLinkList"] | undefined,
	paginateGroupsLinkList: GraphQLTypes["FooterGroupLinkListConnection"]
};
	["FooterLocaleMeta"]: {
	__typename: "FooterLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	copyright?: GraphQLTypes["FieldMeta"] | undefined,
	groupsLinkList?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	contact?: GraphQLTypes["FieldMeta"] | undefined
};
	["FooterGroupLinkList"]: {
	__typename: "FooterGroupLinkList",
	_meta?: GraphQLTypes["FooterGroupLinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	footer?: GraphQLTypes["FooterLocale"] | undefined,
	linkList?: GraphQLTypes["LinkList"] | undefined,
	isAddress: boolean,
	addressValue?: string | undefined,
	addressLink?: GraphQLTypes["Link"] | undefined
};
	["FooterGroupLinkListMeta"]: {
	__typename: "FooterGroupLinkListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	footer?: GraphQLTypes["FieldMeta"] | undefined,
	linkList?: GraphQLTypes["FieldMeta"] | undefined,
	isAddress?: GraphQLTypes["FieldMeta"] | undefined,
	addressValue?: GraphQLTypes["FieldMeta"] | undefined,
	addressLink?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkList"]: {
	__typename: "LinkList",
	_meta?: GraphQLTypes["LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["LinkItem"]>,
	itemsByLink?: GraphQLTypes["LinkItem"] | undefined,
	paginateItems: GraphQLTypes["LinkItemConnection"]
};
	["LinkListMeta"]: {
	__typename: "LinkListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkItem"]: {
	__typename: "LinkItem",
	_meta?: GraphQLTypes["LinkItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["LinkList"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["LinkItemMeta"]: {
	__typename: "LinkItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["LinkItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["LinkListOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["LinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["LinkListItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["LinkItemConnection"]: {
	__typename: "LinkItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkItemEdge"]>
};
	["LinkItemEdge"]: {
	__typename: "LinkItemEdge",
	node: GraphQLTypes["LinkItem"]
};
	["FooterGroupLinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	footer?: GraphQLTypes["FooterLocaleOrderBy"] | undefined,
	linkList?: GraphQLTypes["LinkListOrderBy"] | undefined,
	isAddress?: GraphQLTypes["OrderDirection"] | undefined,
	addressValue?: GraphQLTypes["OrderDirection"] | undefined,
	addressLink?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["FooterLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	copyright?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["FooterOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	contact?: GraphQLTypes["OrderDirection"] | undefined
};
	["FooterOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined,
	logo?: GraphQLTypes["ImageOrderBy"] | undefined,
	logoCompany?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["Footer"]: {
	__typename: "Footer",
	_meta?: GraphQLTypes["FooterMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	logo?: GraphQLTypes["Image"] | undefined,
	locales: Array<GraphQLTypes["FooterLocale"]>,
	logoCompany?: GraphQLTypes["Image"] | undefined,
	localesByLocale?: GraphQLTypes["FooterLocale"] | undefined,
	localesByGroupsLinkList?: GraphQLTypes["FooterLocale"] | undefined,
	paginateLocales: GraphQLTypes["FooterLocaleConnection"]
};
	["FooterMeta"]: {
	__typename: "FooterMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	logo?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	logoCompany?: GraphQLTypes["FieldMeta"] | undefined
};
	["FooterLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["FooterLocalesByGroupsLinkListUniqueWhere"]: {
		groupsLinkList?: GraphQLTypes["FooterGroupLinkListUniqueWhere"] | undefined
};
	["FooterLocaleConnection"]: {
	__typename: "FooterLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterLocaleEdge"]>
};
	["FooterLocaleEdge"]: {
	__typename: "FooterLocaleEdge",
	node: GraphQLTypes["FooterLocale"]
};
	["FooterLocaleGroupsLinkListByLinkListUniqueWhere"]: {
		linkList?: GraphQLTypes["LinkListUniqueWhere"] | undefined
};
	["FooterGroupLinkListConnection"]: {
	__typename: "FooterGroupLinkListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterGroupLinkListEdge"]>
};
	["FooterGroupLinkListEdge"]: {
	__typename: "FooterGroupLinkListEdge",
	node: GraphQLTypes["FooterGroupLinkList"]
};
	["ProductCategoryLocale"]: {
	__typename: "ProductCategoryLocale",
	_meta?: GraphQLTypes["ProductCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["ProductCategory"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined
};
	["ProductCategoryLocaleMeta"]: {
	__typename: "ProductCategoryLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategory"]: {
	__typename: "ProductCategory",
	_meta?: GraphQLTypes["ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	products: Array<GraphQLTypes["Product"]>,
	locales: Array<GraphQLTypes["ProductCategoryLocale"]>,
	productsBySlug?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	localesByLocale?: GraphQLTypes["ProductCategoryLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateLocales: GraphQLTypes["ProductCategoryLocaleConnection"]
};
	["ProductCategoryMeta"]: {
	__typename: "ProductCategoryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	products?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["Product"]: {
	__typename: "Product",
	_meta?: GraphQLTypes["ProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	miningPower?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	category?: GraphQLTypes["ProductCategory"] | undefined,
	tileImage?: GraphQLTypes["Image"] | undefined,
	locales: Array<GraphQLTypes["ProductLocale"]>,
	isForSale: boolean,
	features: Array<GraphQLTypes["ProductFeature"]>,
	slug?: string | undefined,
	localesByLocale?: GraphQLTypes["ProductLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductLocaleConnection"],
	paginateFeatures: GraphQLTypes["ProductFeatureConnection"]
};
	["ProductMeta"]: {
	__typename: "ProductMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	miningPower?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	category?: GraphQLTypes["FieldMeta"] | undefined,
	tileImage?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined,
	isForSale?: GraphQLTypes["FieldMeta"] | undefined,
	features?: GraphQLTypes["FieldMeta"] | undefined,
	slug?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductLocale"]: {
	__typename: "ProductLocale",
	_meta?: GraphQLTypes["ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	price: number,
	buttonLabel?: string | undefined,
	isCustomButtonLabel: boolean,
	discountedPrice: number
};
	["ProductLocaleMeta"]: {
	__typename: "ProductLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	price?: GraphQLTypes["FieldMeta"] | undefined,
	buttonLabel?: GraphQLTypes["FieldMeta"] | undefined,
	isCustomButtonLabel?: GraphQLTypes["FieldMeta"] | undefined,
	discountedPrice?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	price?: GraphQLTypes["OrderDirection"] | undefined,
	buttonLabel?: GraphQLTypes["OrderDirection"] | undefined,
	isCustomButtonLabel?: GraphQLTypes["OrderDirection"] | undefined,
	discountedPrice?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	miningPower?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	category?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	tileImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	isForSale?: GraphQLTypes["OrderDirection"] | undefined,
	slug?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductFeature"]: {
	__typename: "ProductFeature",
	_meta?: GraphQLTypes["ProductFeatureMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["ProductFeatureLocale"]>,
	localesByLocale?: GraphQLTypes["ProductFeatureLocale"] | undefined,
	paginateLocales: GraphQLTypes["ProductFeatureLocaleConnection"]
};
	["ProductFeatureMeta"]: {
	__typename: "ProductFeatureMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductFeatureLocale"]: {
	__typename: "ProductFeatureLocale",
	_meta?: GraphQLTypes["ProductFeatureLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	price: number,
	root?: GraphQLTypes["ProductFeature"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	title: string
};
	["ProductFeatureLocaleMeta"]: {
	__typename: "ProductFeatureLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	price?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductFeatureLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	price?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductFeatureOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductFeatureOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductFeatureLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductFeatureLocaleConnection"]: {
	__typename: "ProductFeatureLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductFeatureLocaleEdge"]>
};
	["ProductFeatureLocaleEdge"]: {
	__typename: "ProductFeatureLocaleEdge",
	node: GraphQLTypes["ProductFeatureLocale"]
};
	["ProductLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductLocaleConnection"]: {
	__typename: "ProductLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductLocaleEdge"]>
};
	["ProductLocaleEdge"]: {
	__typename: "ProductLocaleEdge",
	node: GraphQLTypes["ProductLocale"]
};
	["ProductFeatureConnection"]: {
	__typename: "ProductFeatureConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductFeatureEdge"]>
};
	["ProductFeatureEdge"]: {
	__typename: "ProductFeatureEdge",
	node: GraphQLTypes["ProductFeature"]
};
	["ProductCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["ProductCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined
};
	["ProductCategoryProductsBySlugUniqueWhere"]: {
		slug?: string | undefined
};
	["ProductCategoryProductsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["ProductLocaleUniqueWhere"] | undefined
};
	["ProductCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["ProductConnection"]: {
	__typename: "ProductConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
	__typename: "ProductEdge",
	node: GraphQLTypes["Product"]
};
	["ProductCategoryLocaleConnection"]: {
	__typename: "ProductCategoryLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryLocaleEdge"]>
};
	["ProductCategoryLocaleEdge"]: {
	__typename: "ProductCategoryLocaleEdge",
	node: GraphQLTypes["ProductCategoryLocale"]
};
	["GeneralLocale"]: {
	__typename: "GeneralLocale",
	_meta?: GraphQLTypes["GeneralLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["General"] | undefined,
	locale?: GraphQLTypes["Locale"] | undefined,
	seo?: GraphQLTypes["Seo"] | undefined
};
	["GeneralLocaleMeta"]: {
	__typename: "GeneralLocaleMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	root?: GraphQLTypes["FieldMeta"] | undefined,
	locale?: GraphQLTypes["FieldMeta"] | undefined,
	seo?: GraphQLTypes["FieldMeta"] | undefined
};
	["General"]: {
	__typename: "General",
	_meta?: GraphQLTypes["GeneralMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["One"],
	locales: Array<GraphQLTypes["GeneralLocale"]>,
	localesByLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	localesBySeo?: GraphQLTypes["GeneralLocale"] | undefined,
	paginateLocales: GraphQLTypes["GeneralLocaleConnection"]
};
	["GeneralMeta"]: {
	__typename: "GeneralMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	unique?: GraphQLTypes["FieldMeta"] | undefined,
	locales?: GraphQLTypes["FieldMeta"] | undefined
};
	["GeneralLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	root?: GraphQLTypes["GeneralOrderBy"] | undefined,
	locale?: GraphQLTypes["LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["SeoOrderBy"] | undefined
};
	["GeneralOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	unique?: GraphQLTypes["OrderDirection"] | undefined
};
	["GeneralLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["GeneralLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GeneralLocaleConnection"]: {
	__typename: "GeneralLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GeneralLocaleEdge"]>
};
	["GeneralLocaleEdge"]: {
	__typename: "GeneralLocaleEdge",
	node: GraphQLTypes["GeneralLocale"]
};
	["LocaleGenericPagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["GenericPageUniqueWhere"] | undefined
};
	["LocaleGenericPagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleGenericPagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleGenericPagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["LocaleGenericPagesBySideNavigationItemsUniqueWhere"]: {
		sideNavigationItems?: GraphQLTypes["GenericPageSideNavigationUniqueWhere"] | undefined
};
	["LocaleHeadersByRootUniqueWhere"]: {
		root?: GraphQLTypes["HeaderUniqueWhere"] | undefined
};
	["LocaleFootersByRootUniqueWhere"]: {
		root?: GraphQLTypes["FooterUniqueWhere"] | undefined
};
	["LocaleFootersByGroupsLinkListUniqueWhere"]: {
		groupsLinkList?: GraphQLTypes["FooterGroupLinkListUniqueWhere"] | undefined
};
	["LocaleProductCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductCategoryUniqueWhere"] | undefined
};
	["LocaleProductsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["LocaleGeneralsByRootUniqueWhere"]: {
		root?: GraphQLTypes["GeneralUniqueWhere"] | undefined
};
	["LocaleGeneralsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["LocaleProductFeatureItemsByRootUniqueWhere"]: {
		root?: GraphQLTypes["ProductFeatureUniqueWhere"] | undefined
};
	["LocaleOrderPagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["OrderPageUniqueWhere"] | undefined
};
	["LocaleOrderPagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["LocaleOrderPagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageLocaleConnection"]: {
	__typename: "GenericPageLocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GenericPageLocaleEdge"]>
};
	["GenericPageLocaleEdge"]: {
	__typename: "GenericPageLocaleEdge",
	node: GraphQLTypes["GenericPageLocale"]
};
	["Content"]: {
	__typename: "Content",
	_meta?: GraphQLTypes["ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["ContentBlockConnection"]
};
	["ContentMeta"]: {
	__typename: "ContentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	blocks?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentBlock"]: {
	__typename: "ContentBlock",
	_meta?: GraphQLTypes["ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Content"] | undefined,
	references: Array<GraphQLTypes["ContentReference"]>,
	referencesByLink?: GraphQLTypes["ContentReference"] | undefined,
	referencesByLinkTwo?: GraphQLTypes["ContentReference"] | undefined,
	referencesByButtonLink?: GraphQLTypes["ContentReference"] | undefined,
	referencesByButtonLinkTwo?: GraphQLTypes["ContentReference"] | undefined,
	referencesByFeatureList?: GraphQLTypes["ContentReference"] | undefined,
	referencesByProjection?: GraphQLTypes["ContentReference"] | undefined,
	referencesByContentImageWithFeatures?: GraphQLTypes["ContentReference"] | undefined,
	referencesByAsideContentMedia?: GraphQLTypes["ContentReference"] | undefined,
	referencesByImageList?: GraphQLTypes["ContentReference"] | undefined,
	referencesByProductCategories?: GraphQLTypes["ContentReference"] | undefined,
	referencesByFeatureSection?: GraphQLTypes["ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["ContentReferenceConnection"]
};
	["ContentBlockMeta"]: {
	__typename: "ContentBlockMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	json?: GraphQLTypes["FieldMeta"] | undefined,
	content?: GraphQLTypes["FieldMeta"] | undefined,
	references?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReference"]: {
	__typename: "ContentReference",
	_meta?: GraphQLTypes["ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["ContentReferenceType"],
	block?: GraphQLTypes["ContentBlock"] | undefined,
	primaryText?: string | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	linkTwo?: GraphQLTypes["Link"] | undefined,
	buttonLink?: GraphQLTypes["ButtonLink"] | undefined,
	buttonLinkTwo?: GraphQLTypes["ButtonLink"] | undefined,
	featureList?: GraphQLTypes["FeatureList"] | undefined,
	projection?: GraphQLTypes["Projection"] | undefined,
	contentImageWithFeatures?: GraphQLTypes["ContentImageWithFeatures"] | undefined,
	asideContentMedia?: GraphQLTypes["AsideContentMedia"] | undefined,
	imageList?: GraphQLTypes["ImageList"] | undefined,
	productCategories?: GraphQLTypes["ProductCategoryList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	secondaryText?: string | undefined,
	callToActionTypes: GraphQLTypes["CallToActionType"],
	secondaryImage?: GraphQLTypes["Image"] | undefined,
	featureSection?: GraphQLTypes["FeatureSection"] | undefined,
	terciaryImage?: GraphQLTypes["Image"] | undefined,
	video?: GraphQLTypes["Video"] | undefined,
	isImageWithWhiteShadow: boolean,
	secondaryVideo?: GraphQLTypes["Video"] | undefined
};
	["ContentReferenceMeta"]: {
	__typename: "ContentReferenceMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	block?: GraphQLTypes["FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	linkTwo?: GraphQLTypes["FieldMeta"] | undefined,
	buttonLink?: GraphQLTypes["FieldMeta"] | undefined,
	buttonLinkTwo?: GraphQLTypes["FieldMeta"] | undefined,
	featureList?: GraphQLTypes["FieldMeta"] | undefined,
	projection?: GraphQLTypes["FieldMeta"] | undefined,
	contentImageWithFeatures?: GraphQLTypes["FieldMeta"] | undefined,
	asideContentMedia?: GraphQLTypes["FieldMeta"] | undefined,
	imageList?: GraphQLTypes["FieldMeta"] | undefined,
	productCategories?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["FieldMeta"] | undefined,
	callToActionTypes?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryImage?: GraphQLTypes["FieldMeta"] | undefined,
	featureSection?: GraphQLTypes["FieldMeta"] | undefined,
	terciaryImage?: GraphQLTypes["FieldMeta"] | undefined,
	video?: GraphQLTypes["FieldMeta"] | undefined,
	isImageWithWhiteShadow?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryVideo?: GraphQLTypes["FieldMeta"] | undefined
};
	["ButtonLink"]: {
	__typename: "ButtonLink",
	_meta?: GraphQLTypes["ButtonLinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	visualType: GraphQLTypes["ButtonLinkVisualType"],
	link?: GraphQLTypes["Link"] | undefined,
	fillType: GraphQLTypes["ButtonLinkFillType"],
	widthType: GraphQLTypes["ButtonLinkWidthType"]
};
	["ButtonLinkMeta"]: {
	__typename: "ButtonLinkMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	visualType?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	fillType?: GraphQLTypes["FieldMeta"] | undefined,
	widthType?: GraphQLTypes["FieldMeta"] | undefined
};
	["FeatureList"]: {
	__typename: "FeatureList",
	_meta?: GraphQLTypes["FeatureListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["FeatureItem"]>,
	paginateItems: GraphQLTypes["FeatureItemConnection"]
};
	["FeatureListMeta"]: {
	__typename: "FeatureListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["FeatureItem"]: {
	__typename: "FeatureItem",
	_meta?: GraphQLTypes["FeatureItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["FeatureList"] | undefined,
	feature?: GraphQLTypes["Feature"] | undefined
};
	["FeatureItemMeta"]: {
	__typename: "FeatureItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	feature?: GraphQLTypes["FieldMeta"] | undefined
};
	["Feature"]: {
	__typename: "Feature",
	_meta?: GraphQLTypes["FeatureMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	icon?: GraphQLTypes["Image"] | undefined,
	text?: string | undefined
};
	["FeatureMeta"]: {
	__typename: "FeatureMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	icon?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined
};
	["FeatureItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["FeatureListOrderBy"] | undefined,
	feature?: GraphQLTypes["FeatureOrderBy"] | undefined
};
	["FeatureListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["FeatureOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	icon?: GraphQLTypes["ImageOrderBy"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined
};
	["FeatureItemConnection"]: {
	__typename: "FeatureItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureItemEdge"]>
};
	["FeatureItemEdge"]: {
	__typename: "FeatureItemEdge",
	node: GraphQLTypes["FeatureItem"]
};
	["Projection"]: {
	__typename: "Projection",
	_meta?: GraphQLTypes["ProjectionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProjectionItem"]>,
	paginateItems: GraphQLTypes["ProjectionItemConnection"]
};
	["ProjectionMeta"]: {
	__typename: "ProjectionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProjectionItem"]: {
	__typename: "ProjectionItem",
	_meta?: GraphQLTypes["ProjectionItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Projection"] | undefined,
	item?: GraphQLTypes["ProjectionContent"] | undefined
};
	["ProjectionItemMeta"]: {
	__typename: "ProjectionItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProjectionContent"]: {
	__typename: "ProjectionContent",
	_meta?: GraphQLTypes["ProjectionContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	text?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["ProjectionContentMeta"]: {
	__typename: "ProjectionContentMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProjectionItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProjectionOrderBy"] | undefined,
	item?: GraphQLTypes["ProjectionContentOrderBy"] | undefined
};
	["ProjectionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProjectionContentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ProjectionItemConnection"]: {
	__typename: "ProjectionItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProjectionItemEdge"]>
};
	["ProjectionItemEdge"]: {
	__typename: "ProjectionItemEdge",
	node: GraphQLTypes["ProjectionItem"]
};
	["ContentImageWithFeatures"]: {
	__typename: "ContentImageWithFeatures",
	_meta?: GraphQLTypes["ContentImageWithFeaturesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	subtitle?: string | undefined,
	title?: string | undefined,
	text?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	features?: GraphQLTypes["FeatureList"] | undefined
};
	["ContentImageWithFeaturesMeta"]: {
	__typename: "ContentImageWithFeaturesMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	subtitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	text?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	features?: GraphQLTypes["FieldMeta"] | undefined
};
	["AsideContentMedia"]: {
	__typename: "AsideContentMedia",
	_meta?: GraphQLTypes["AsideContentMediaMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	subtitle?: string | undefined,
	title?: string | undefined,
	media?: GraphQLTypes["Image"] | undefined,
	link?: GraphQLTypes["Link"] | undefined,
	tableSpecifiation: Array<GraphQLTypes["AsideContentMediaSpecification"]>,
	paginateTableSpecifiation: GraphQLTypes["AsideContentMediaSpecificationConnection"]
};
	["AsideContentMediaMeta"]: {
	__typename: "AsideContentMediaMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	subtitle?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	media?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined,
	tableSpecifiation?: GraphQLTypes["FieldMeta"] | undefined
};
	["AsideContentMediaSpecification"]: {
	__typename: "AsideContentMediaSpecification",
	_meta?: GraphQLTypes["AsideContentMediaSpecificationMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	label?: string | undefined,
	value?: string | undefined,
	specification?: GraphQLTypes["AsideContentMedia"] | undefined
};
	["AsideContentMediaSpecificationMeta"]: {
	__typename: "AsideContentMediaSpecificationMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	specification?: GraphQLTypes["FieldMeta"] | undefined
};
	["AsideContentMediaSpecificationOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	value?: GraphQLTypes["OrderDirection"] | undefined,
	specification?: GraphQLTypes["AsideContentMediaOrderBy"] | undefined
};
	["AsideContentMediaOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	subtitle?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	media?: GraphQLTypes["ImageOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["AsideContentMediaSpecificationConnection"]: {
	__typename: "AsideContentMediaSpecificationConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AsideContentMediaSpecificationEdge"]>
};
	["AsideContentMediaSpecificationEdge"]: {
	__typename: "AsideContentMediaSpecificationEdge",
	node: GraphQLTypes["AsideContentMediaSpecification"]
};
	["ImageList"]: {
	__typename: "ImageList",
	_meta?: GraphQLTypes["ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ImageListItem"]>,
	paginateItems: GraphQLTypes["ImageListItemConnection"]
};
	["ImageListMeta"]: {
	__typename: "ImageListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItem"]: {
	__typename: "ImageListItem",
	_meta?: GraphQLTypes["ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	description?: string | undefined,
	list?: GraphQLTypes["ImageList"] | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	imageMobile?: GraphQLTypes["Image"] | undefined
};
	["ImageListItemMeta"]: {
	__typename: "ImageListItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	imageMobile?: GraphQLTypes["FieldMeta"] | undefined
};
	["ImageListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ImageListOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	imageMobile?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["ImageListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ImageListItemConnection"]: {
	__typename: "ImageListItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListItemEdge"]>
};
	["ImageListItemEdge"]: {
	__typename: "ImageListItemEdge",
	node: GraphQLTypes["ImageListItem"]
};
	["ProductCategoryList"]: {
	__typename: "ProductCategoryList",
	_meta?: GraphQLTypes["ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["ProductCategoryItem"]>,
	paginateItems: GraphQLTypes["ProductCategoryItemConnection"]
};
	["ProductCategoryListMeta"]: {
	__typename: "ProductCategoryListMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	items?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryItem"]: {
	__typename: "ProductCategoryItem",
	_meta?: GraphQLTypes["ProductCategoryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["ProductCategoryList"] | undefined,
	item?: GraphQLTypes["ProductCategory"] | undefined
};
	["ProductCategoryItemMeta"]: {
	__typename: "ProductCategoryItemMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	item?: GraphQLTypes["FieldMeta"] | undefined
};
	["ProductCategoryItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	item?: GraphQLTypes["ProductCategoryOrderBy"] | undefined
};
	["ProductCategoryListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined
};
	["ProductCategoryItemConnection"]: {
	__typename: "ProductCategoryItemConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryItemEdge"]>
};
	["ProductCategoryItemEdge"]: {
	__typename: "ProductCategoryItemEdge",
	node: GraphQLTypes["ProductCategoryItem"]
};
	["FeatureSection"]: {
	__typename: "FeatureSection",
	_meta?: GraphQLTypes["FeatureSectionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	temperature?: GraphQLTypes["FeatureSectionTemperature"] | undefined,
	heat?: GraphQLTypes["FeatureSectionHeat"] | undefined,
	pump?: GraphQLTypes["FeatureSectionPump"] | undefined,
	tube?: GraphQLTypes["FeatureSectionTube"] | undefined,
	antimer?: GraphQLTypes["FeatureSectionAntimer"] | undefined
};
	["FeatureSectionMeta"]: {
	__typename: "FeatureSectionMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	temperature?: GraphQLTypes["FieldMeta"] | undefined,
	heat?: GraphQLTypes["FieldMeta"] | undefined,
	pump?: GraphQLTypes["FieldMeta"] | undefined,
	tube?: GraphQLTypes["FieldMeta"] | undefined,
	antimer?: GraphQLTypes["FieldMeta"] | undefined
};
	["FeatureSectionTemperature"]: {
	__typename: "FeatureSectionTemperature",
	_meta?: GraphQLTypes["FeatureSectionTemperatureMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	label?: string | undefined,
	description?: string | undefined,
	number?: number | undefined
};
	["FeatureSectionTemperatureMeta"]: {
	__typename: "FeatureSectionTemperatureMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	number?: GraphQLTypes["FieldMeta"] | undefined
};
	["FeatureSectionHeat"]: {
	__typename: "FeatureSectionHeat",
	_meta?: GraphQLTypes["FeatureSectionHeatMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title1?: string | undefined,
	titleHighlighted1?: string | undefined,
	titleHighlighted2?: string | undefined,
	description?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["FeatureSectionHeatMeta"]: {
	__typename: "FeatureSectionHeatMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title1?: GraphQLTypes["FieldMeta"] | undefined,
	titleHighlighted1?: GraphQLTypes["FieldMeta"] | undefined,
	titleHighlighted2?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["FeatureSectionPump"]: {
	__typename: "FeatureSectionPump",
	_meta?: GraphQLTypes["FeatureSectionPumpMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	sideTitle?: string | undefined,
	description?: string | undefined
};
	["FeatureSectionPumpMeta"]: {
	__typename: "FeatureSectionPumpMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	sideTitle?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined
};
	["FeatureSectionTube"]: {
	__typename: "FeatureSectionTube",
	_meta?: GraphQLTypes["FeatureSectionTubeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	image?: GraphQLTypes["Image"] | undefined,
	secondaryImage?: GraphQLTypes["Image"] | undefined
};
	["FeatureSectionTubeMeta"]: {
	__typename: "FeatureSectionTubeMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	title?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined,
	secondaryImage?: GraphQLTypes["FieldMeta"] | undefined
};
	["FeatureSectionAntimer"]: {
	__typename: "FeatureSectionAntimer",
	_meta?: GraphQLTypes["FeatureSectionAntimerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	label1?: string | undefined,
	label2?: string | undefined,
	description?: string | undefined,
	number?: number | undefined,
	image?: GraphQLTypes["Image"] | undefined
};
	["FeatureSectionAntimerMeta"]: {
	__typename: "FeatureSectionAntimerMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	label1?: GraphQLTypes["FieldMeta"] | undefined,
	label2?: GraphQLTypes["FieldMeta"] | undefined,
	description?: GraphQLTypes["FieldMeta"] | undefined,
	number?: GraphQLTypes["FieldMeta"] | undefined,
	image?: GraphQLTypes["FieldMeta"] | undefined
};
	["Video"]: {
	__typename: "Video",
	_meta?: GraphQLTypes["VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Image"] | undefined,
	fileName?: string | undefined
};
	["VideoMeta"]: {
	__typename: "VideoMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	src?: GraphQLTypes["FieldMeta"] | undefined,
	width?: GraphQLTypes["FieldMeta"] | undefined,
	height?: GraphQLTypes["FieldMeta"] | undefined,
	size?: GraphQLTypes["FieldMeta"] | undefined,
	type?: GraphQLTypes["FieldMeta"] | undefined,
	duration?: GraphQLTypes["FieldMeta"] | undefined,
	poster?: GraphQLTypes["FieldMeta"] | undefined,
	fileName?: GraphQLTypes["FieldMeta"] | undefined
};
	["ContentReferenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	block?: GraphQLTypes["ContentBlockOrderBy"] | undefined,
	primaryText?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	linkTwo?: GraphQLTypes["LinkOrderBy"] | undefined,
	buttonLink?: GraphQLTypes["ButtonLinkOrderBy"] | undefined,
	buttonLinkTwo?: GraphQLTypes["ButtonLinkOrderBy"] | undefined,
	featureList?: GraphQLTypes["FeatureListOrderBy"] | undefined,
	projection?: GraphQLTypes["ProjectionOrderBy"] | undefined,
	contentImageWithFeatures?: GraphQLTypes["ContentImageWithFeaturesOrderBy"] | undefined,
	asideContentMedia?: GraphQLTypes["AsideContentMediaOrderBy"] | undefined,
	imageList?: GraphQLTypes["ImageListOrderBy"] | undefined,
	productCategories?: GraphQLTypes["ProductCategoryListOrderBy"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	secondaryText?: GraphQLTypes["OrderDirection"] | undefined,
	callToActionTypes?: GraphQLTypes["OrderDirection"] | undefined,
	secondaryImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	featureSection?: GraphQLTypes["FeatureSectionOrderBy"] | undefined,
	terciaryImage?: GraphQLTypes["ImageOrderBy"] | undefined,
	video?: GraphQLTypes["VideoOrderBy"] | undefined,
	isImageWithWhiteShadow?: GraphQLTypes["OrderDirection"] | undefined,
	secondaryVideo?: GraphQLTypes["VideoOrderBy"] | undefined
};
	["ContentBlockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	json?: GraphQLTypes["OrderDirection"] | undefined,
	content?: GraphQLTypes["ContentOrderBy"] | undefined
};
	["ButtonLinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	visualType?: GraphQLTypes["OrderDirection"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined,
	fillType?: GraphQLTypes["OrderDirection"] | undefined,
	widthType?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentImageWithFeaturesOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	subtitle?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	text?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	features?: GraphQLTypes["FeatureListOrderBy"] | undefined
};
	["FeatureSectionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	temperature?: GraphQLTypes["FeatureSectionTemperatureOrderBy"] | undefined,
	heat?: GraphQLTypes["FeatureSectionHeatOrderBy"] | undefined,
	pump?: GraphQLTypes["FeatureSectionPumpOrderBy"] | undefined,
	tube?: GraphQLTypes["FeatureSectionTubeOrderBy"] | undefined,
	antimer?: GraphQLTypes["FeatureSectionAntimerOrderBy"] | undefined
};
	["FeatureSectionTemperatureOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	number?: GraphQLTypes["OrderDirection"] | undefined
};
	["FeatureSectionHeatOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title1?: GraphQLTypes["OrderDirection"] | undefined,
	titleHighlighted1?: GraphQLTypes["OrderDirection"] | undefined,
	titleHighlighted2?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["FeatureSectionPumpOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	sideTitle?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined
};
	["FeatureSectionTubeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	title?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined,
	secondaryImage?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["FeatureSectionAntimerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	label1?: GraphQLTypes["OrderDirection"] | undefined,
	label2?: GraphQLTypes["OrderDirection"] | undefined,
	description?: GraphQLTypes["OrderDirection"] | undefined,
	number?: GraphQLTypes["OrderDirection"] | undefined,
	image?: GraphQLTypes["ImageOrderBy"] | undefined
};
	["VideoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	src?: GraphQLTypes["OrderDirection"] | undefined,
	width?: GraphQLTypes["OrderDirection"] | undefined,
	height?: GraphQLTypes["OrderDirection"] | undefined,
	size?: GraphQLTypes["OrderDirection"] | undefined,
	type?: GraphQLTypes["OrderDirection"] | undefined,
	duration?: GraphQLTypes["OrderDirection"] | undefined,
	poster?: GraphQLTypes["ImageOrderBy"] | undefined,
	fileName?: GraphQLTypes["OrderDirection"] | undefined
};
	["ContentBlockReferencesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByLinkTwoUniqueWhere"]: {
		linkTwo?: GraphQLTypes["LinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByButtonLinkUniqueWhere"]: {
		buttonLink?: GraphQLTypes["ButtonLinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByButtonLinkTwoUniqueWhere"]: {
		buttonLinkTwo?: GraphQLTypes["ButtonLinkUniqueWhere"] | undefined
};
	["ContentBlockReferencesByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["FeatureListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByProjectionUniqueWhere"]: {
		projection?: GraphQLTypes["ProjectionUniqueWhere"] | undefined
};
	["ContentBlockReferencesByContentImageWithFeaturesUniqueWhere"]: {
		contentImageWithFeatures?: GraphQLTypes["ContentImageWithFeaturesUniqueWhere"] | undefined
};
	["ContentBlockReferencesByAsideContentMediaUniqueWhere"]: {
		asideContentMedia?: GraphQLTypes["AsideContentMediaUniqueWhere"] | undefined
};
	["ContentBlockReferencesByImageListUniqueWhere"]: {
		imageList?: GraphQLTypes["ImageListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByProductCategoriesUniqueWhere"]: {
		productCategories?: GraphQLTypes["ProductCategoryListUniqueWhere"] | undefined
};
	["ContentBlockReferencesByFeatureSectionUniqueWhere"]: {
		featureSection?: GraphQLTypes["FeatureSectionUniqueWhere"] | undefined
};
	["ContentReferenceConnection"]: {
	__typename: "ContentReferenceConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentReferenceEdge"]>
};
	["ContentReferenceEdge"]: {
	__typename: "ContentReferenceEdge",
	node: GraphQLTypes["ContentReference"]
};
	["ContentBlocksByReferencesUniqueWhere"]: {
		references?: GraphQLTypes["ContentReferenceUniqueWhere"] | undefined
};
	["ContentBlockConnection"]: {
	__typename: "ContentBlockConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentBlockEdge"]>
};
	["ContentBlockEdge"]: {
	__typename: "ContentBlockEdge",
	node: GraphQLTypes["ContentBlock"]
};
	["GenericPageSideNavigation"]: {
	__typename: "GenericPageSideNavigation",
	_meta?: GraphQLTypes["GenericPageSideNavigationMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["GenericPageLocale"] | undefined,
	link?: GraphQLTypes["Link"] | undefined
};
	["GenericPageSideNavigationMeta"]: {
	__typename: "GenericPageSideNavigationMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	list?: GraphQLTypes["FieldMeta"] | undefined,
	link?: GraphQLTypes["FieldMeta"] | undefined
};
	["GenericPageSideNavigationOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	list?: GraphQLTypes["GenericPageLocaleOrderBy"] | undefined,
	link?: GraphQLTypes["LinkOrderBy"] | undefined
};
	["GenericPageSideNavigationConnection"]: {
	__typename: "GenericPageSideNavigationConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GenericPageSideNavigationEdge"]>
};
	["GenericPageSideNavigationEdge"]: {
	__typename: "GenericPageSideNavigationEdge",
	node: GraphQLTypes["GenericPageSideNavigation"]
};
	["GenericPageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["LocaleUniqueWhere"] | undefined
};
	["GenericPageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["SeoUniqueWhere"] | undefined
};
	["GenericPageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["LinkableUniqueWhere"] | undefined
};
	["GenericPageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["ContentUniqueWhere"] | undefined
};
	["GenericPageLocalesBySideNavigationItemsUniqueWhere"]: {
		sideNavigationItems?: GraphQLTypes["GenericPageSideNavigationUniqueWhere"] | undefined
};
	["GenericPageConnection"]: {
	__typename: "GenericPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GenericPageEdge"]>
};
	["GenericPageEdge"]: {
	__typename: "GenericPageEdge",
	node: GraphQLTypes["GenericPage"]
};
	["LinkableConnection"]: {
	__typename: "LinkableConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkableEdge"]>
};
	["LinkableEdge"]: {
	__typename: "LinkableEdge",
	node: GraphQLTypes["Linkable"]
};
	["SeoConnection"]: {
	__typename: "SeoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["SeoEdge"]>
};
	["SeoEdge"]: {
	__typename: "SeoEdge",
	node: GraphQLTypes["Seo"]
};
	["LocaleConnection"]: {
	__typename: "LocaleConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LocaleEdge"]>
};
	["LocaleEdge"]: {
	__typename: "LocaleEdge",
	node: GraphQLTypes["Locale"]
};
	["LinkConnection"]: {
	__typename: "LinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkEdge"]>
};
	["LinkEdge"]: {
	__typename: "LinkEdge",
	node: GraphQLTypes["Link"]
};
	["HeaderConnection"]: {
	__typename: "HeaderConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["HeaderEdge"]>
};
	["HeaderEdge"]: {
	__typename: "HeaderEdge",
	node: GraphQLTypes["Header"]
};
	["ContentConnection"]: {
	__typename: "ContentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentEdge"]>
};
	["ContentEdge"]: {
	__typename: "ContentEdge",
	node: GraphQLTypes["Content"]
};
	["VideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["VideoConnection"]: {
	__typename: "VideoConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["VideoEdge"]>
};
	["VideoEdge"]: {
	__typename: "VideoEdge",
	node: GraphQLTypes["Video"]
};
	["ButtonLinkConnection"]: {
	__typename: "ButtonLinkConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ButtonLinkEdge"]>
};
	["ButtonLinkEdge"]: {
	__typename: "ButtonLinkEdge",
	node: GraphQLTypes["ButtonLink"]
};
	["FeatureUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	icon?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["FeatureConnection"]: {
	__typename: "FeatureConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureEdge"]>
};
	["FeatureEdge"]: {
	__typename: "FeatureEdge",
	node: GraphQLTypes["Feature"]
};
	["FeatureListConnection"]: {
	__typename: "FeatureListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureListEdge"]>
};
	["FeatureListEdge"]: {
	__typename: "FeatureListEdge",
	node: GraphQLTypes["FeatureList"]
};
	["LinkListConnection"]: {
	__typename: "LinkListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["LinkListEdge"]>
};
	["LinkListEdge"]: {
	__typename: "LinkListEdge",
	node: GraphQLTypes["LinkList"]
};
	["FooterConnection"]: {
	__typename: "FooterConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FooterEdge"]>
};
	["FooterEdge"]: {
	__typename: "FooterEdge",
	node: GraphQLTypes["Footer"]
};
	["ProjectionConnection"]: {
	__typename: "ProjectionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProjectionEdge"]>
};
	["ProjectionEdge"]: {
	__typename: "ProjectionEdge",
	node: GraphQLTypes["Projection"]
};
	["ProjectionContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["ImageUniqueWhere"] | undefined
};
	["ProjectionContentConnection"]: {
	__typename: "ProjectionContentConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProjectionContentEdge"]>
};
	["ProjectionContentEdge"]: {
	__typename: "ProjectionContentEdge",
	node: GraphQLTypes["ProjectionContent"]
};
	["ContentImageWithFeaturesConnection"]: {
	__typename: "ContentImageWithFeaturesConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ContentImageWithFeaturesEdge"]>
};
	["ContentImageWithFeaturesEdge"]: {
	__typename: "ContentImageWithFeaturesEdge",
	node: GraphQLTypes["ContentImageWithFeatures"]
};
	["AsideContentMediaConnection"]: {
	__typename: "AsideContentMediaConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["AsideContentMediaEdge"]>
};
	["AsideContentMediaEdge"]: {
	__typename: "AsideContentMediaEdge",
	node: GraphQLTypes["AsideContentMedia"]
};
	["FileUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FileConnection"]: {
	__typename: "FileConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FileEdge"]>
};
	["FileEdge"]: {
	__typename: "FileEdge",
	node: GraphQLTypes["File"]
};
	["ImageListConnection"]: {
	__typename: "ImageListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ImageListEdge"]>
};
	["ImageListEdge"]: {
	__typename: "ImageListEdge",
	node: GraphQLTypes["ImageList"]
};
	["ProductCategoryConnection"]: {
	__typename: "ProductCategoryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryEdge"]>
};
	["ProductCategoryEdge"]: {
	__typename: "ProductCategoryEdge",
	node: GraphQLTypes["ProductCategory"]
};
	["ProductCategoryListConnection"]: {
	__typename: "ProductCategoryListConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["ProductCategoryListEdge"]>
};
	["ProductCategoryListEdge"]: {
	__typename: "ProductCategoryListEdge",
	node: GraphQLTypes["ProductCategoryList"]
};
	["FeatureSectionConnection"]: {
	__typename: "FeatureSectionConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureSectionEdge"]>
};
	["FeatureSectionEdge"]: {
	__typename: "FeatureSectionEdge",
	node: GraphQLTypes["FeatureSection"]
};
	["FeatureSectionHeatConnection"]: {
	__typename: "FeatureSectionHeatConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureSectionHeatEdge"]>
};
	["FeatureSectionHeatEdge"]: {
	__typename: "FeatureSectionHeatEdge",
	node: GraphQLTypes["FeatureSectionHeat"]
};
	["FeatureSectionPumpConnection"]: {
	__typename: "FeatureSectionPumpConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureSectionPumpEdge"]>
};
	["FeatureSectionPumpEdge"]: {
	__typename: "FeatureSectionPumpEdge",
	node: GraphQLTypes["FeatureSectionPump"]
};
	["FeatureSectionTemperatureConnection"]: {
	__typename: "FeatureSectionTemperatureConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureSectionTemperatureEdge"]>
};
	["FeatureSectionTemperatureEdge"]: {
	__typename: "FeatureSectionTemperatureEdge",
	node: GraphQLTypes["FeatureSectionTemperature"]
};
	["FeatureSectionTubeConnection"]: {
	__typename: "FeatureSectionTubeConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureSectionTubeEdge"]>
};
	["FeatureSectionTubeEdge"]: {
	__typename: "FeatureSectionTubeEdge",
	node: GraphQLTypes["FeatureSectionTube"]
};
	["FeatureSectionAntimerConnection"]: {
	__typename: "FeatureSectionAntimerConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FeatureSectionAntimerEdge"]>
};
	["FeatureSectionAntimerEdge"]: {
	__typename: "FeatureSectionAntimerEdge",
	node: GraphQLTypes["FeatureSectionAntimer"]
};
	["RedirectConnection"]: {
	__typename: "RedirectConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["RedirectEdge"]>
};
	["RedirectEdge"]: {
	__typename: "RedirectEdge",
	node: GraphQLTypes["Redirect"]
};
	["GeneralConnection"]: {
	__typename: "GeneralConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["GeneralEdge"]>
};
	["GeneralEdge"]: {
	__typename: "GeneralEdge",
	node: GraphQLTypes["General"]
};
	["FilledOrderForm"]: {
	__typename: "FilledOrderForm",
	_meta?: GraphQLTypes["FilledOrderFormMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	email: string,
	deliveryAddress: string,
	note?: string | undefined,
	productFeatures: Array<GraphQLTypes["ProductFeature"]>,
	publishedAt: GraphQLTypes["DateTime"],
	product?: GraphQLTypes["Product"] | undefined,
	country?: GraphQLTypes["FilledOrderFormCountry"] | undefined,
	mobile: string,
	paginateProductFeatures: GraphQLTypes["ProductFeatureConnection"]
};
	["FilledOrderFormMeta"]: {
	__typename: "FilledOrderFormMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	deliveryAddress?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	productFeatures?: GraphQLTypes["FieldMeta"] | undefined,
	publishedAt?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined,
	country?: GraphQLTypes["FieldMeta"] | undefined,
	mobile?: GraphQLTypes["FieldMeta"] | undefined
};
	["DateTime"]: "scalar" & { name: "DateTime" };
	["FilledOrderFormCountry"]: {
	__typename: "FilledOrderFormCountry",
	_meta?: GraphQLTypes["FilledOrderFormCountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	code: string,
	label: string
};
	["FilledOrderFormCountryMeta"]: {
	__typename: "FilledOrderFormCountryMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	label?: GraphQLTypes["FieldMeta"] | undefined
};
	["FilledOrderFormCountryWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	label?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FilledOrderFormCountryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FilledOrderFormCountryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FilledOrderFormCountryWhere"] | undefined
};
	["FilledOrderFormUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FilledOrderFormWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	deliveryAddress?: GraphQLTypes["StringCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	productFeatures?: GraphQLTypes["ProductFeatureWhere"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	country?: GraphQLTypes["FilledOrderFormCountryWhere"] | undefined,
	mobile?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FilledOrderFormWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FilledOrderFormWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FilledOrderFormWhere"] | undefined
};
	["DateTimeCondition"]: {
		and?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	or?: Array<GraphQLTypes["DateTimeCondition"]> | undefined,
	not?: GraphQLTypes["DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DateTime"] | undefined,
	notEq?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined
};
	["FilledOrderFormOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	deliveryAddress?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	publishedAt?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined,
	country?: GraphQLTypes["FilledOrderFormCountryOrderBy"] | undefined,
	mobile?: GraphQLTypes["OrderDirection"] | undefined
};
	["FilledOrderFormCountryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined,
	label?: GraphQLTypes["OrderDirection"] | undefined
};
	["FilledOrderFormConnection"]: {
	__typename: "FilledOrderFormConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FilledOrderFormEdge"]>
};
	["FilledOrderFormEdge"]: {
	__typename: "FilledOrderFormEdge",
	node: GraphQLTypes["FilledOrderForm"]
};
	["_ValidationResult"]: {
	__typename: "_ValidationResult",
	valid: boolean,
	errors: Array<GraphQLTypes["_ValidationError"]>
};
	["_ValidationError"]: {
	__typename: "_ValidationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	message: GraphQLTypes["_ValidationMessage"]
};
	["_PathFragment"]:{
        	__typename:"_FieldPathFragment" | "_IndexPathFragment"
        	['...on _FieldPathFragment']: '__union' & GraphQLTypes["_FieldPathFragment"];
	['...on _IndexPathFragment']: '__union' & GraphQLTypes["_IndexPathFragment"];
};
	["_FieldPathFragment"]: {
	__typename: "_FieldPathFragment",
	field: string
};
	["_IndexPathFragment"]: {
	__typename: "_IndexPathFragment",
	index: number,
	alias?: string | undefined
};
	["_ValidationMessage"]: {
	__typename: "_ValidationMessage",
	text: string
};
	["FilledOrderFormCreateInput"]: {
		name?: string | undefined,
	email?: string | undefined,
	deliveryAddress?: string | undefined,
	note?: string | undefined,
	productFeatures?: Array<GraphQLTypes["FilledOrderFormCreateProductFeaturesEntityRelationInput"]> | undefined,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	product?: GraphQLTypes["FilledOrderFormCreateProductEntityRelationInput"] | undefined,
	country?: GraphQLTypes["FilledOrderFormCreateCountryEntityRelationInput"] | undefined,
	mobile?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["FilledOrderFormCreateProductFeaturesEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductFeatureUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["FilledOrderFormCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["FilledOrderFormCreateCountryEntityRelationInput"]: {
		connect?: GraphQLTypes["FilledOrderFormCountryUniqueWhere"] | undefined
};
	["FilledOrderFormCountryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["FilledOrderFormUpdateInput"]: {
		name?: string | undefined,
	email?: string | undefined,
	deliveryAddress?: string | undefined,
	note?: string | undefined,
	productFeatures?: Array<GraphQLTypes["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]> | undefined,
	publishedAt?: GraphQLTypes["DateTime"] | undefined,
	product?: GraphQLTypes["FilledOrderFormUpdateProductEntityRelationInput"] | undefined,
	country?: GraphQLTypes["FilledOrderFormUpdateCountryEntityRelationInput"] | undefined,
	mobile?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductFeatureUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["ProductFeatureUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["FilledOrderFormUpdateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["FilledOrderFormUpdateCountryEntityRelationInput"]: {
		connect?: GraphQLTypes["FilledOrderFormCountryUniqueWhere"] | undefined
};
	["OrderPageConnection"]: {
	__typename: "OrderPageConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["OrderPageEdge"]>
};
	["OrderPageEdge"]: {
	__typename: "OrderPageEdge",
	node: GraphQLTypes["OrderPage"]
};
	["FilledOrderFormEmailAddress"]: {
	__typename: "FilledOrderFormEmailAddress",
	_meta?: GraphQLTypes["FilledOrderFormEmailAddressMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	email: string
};
	["FilledOrderFormEmailAddressMeta"]: {
	__typename: "FilledOrderFormEmailAddressMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	order?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined
};
	["FilledOrderFormEmailAddressUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FilledOrderFormEmailAddressWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	order?: GraphQLTypes["IntCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	and?: Array<GraphQLTypes["FilledOrderFormEmailAddressWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FilledOrderFormEmailAddressWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FilledOrderFormEmailAddressWhere"] | undefined
};
	["FilledOrderFormEmailAddressOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	order?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined
};
	["FilledOrderFormEmailAddressConnection"]: {
	__typename: "FilledOrderFormEmailAddressConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FilledOrderFormEmailAddressEdge"]>
};
	["FilledOrderFormEmailAddressEdge"]: {
	__typename: "FilledOrderFormEmailAddressEdge",
	node: GraphQLTypes["FilledOrderFormEmailAddress"]
};
	["TranslationCatalogue"]: {
	__typename: "TranslationCatalogue",
	_meta?: GraphQLTypes["TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByKey?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationCatalogueMeta"]: {
	__typename: "TranslationCatalogueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	fallback?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationDomain"]: {
	__typename: "TranslationDomain",
	_meta?: GraphQLTypes["TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["TranslationKeyConnection"]
};
	["TranslationDomainMeta"]: {
	__typename: "TranslationDomainMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["FieldMeta"] | undefined,
	keys?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCatalogueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCatalogueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCatalogueWhere"] | undefined
};
	["TranslationDomainWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationDomainWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationDomainWhere"] | undefined
};
	["TranslationKeyWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["StringCondition"] | undefined,
	contentType?: GraphQLTypes["TranslationKeyContentTypeCondition"] | undefined,
	note?: GraphQLTypes["StringCondition"] | undefined,
	domain?: GraphQLTypes["TranslationDomainWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationKeyWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationKeyWhere"] | undefined
};
	["TranslationKeyContentTypeCondition"]: {
		and?: Array<GraphQLTypes["TranslationKeyContentTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["TranslationKeyContentTypeCondition"]> | undefined,
	not?: GraphQLTypes["TranslationKeyContentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["TranslationKeyContentType"] | undefined,
	notEq?: GraphQLTypes["TranslationKeyContentType"] | undefined,
	in?: Array<GraphQLTypes["TranslationKeyContentType"]> | undefined,
	notIn?: Array<GraphQLTypes["TranslationKeyContentType"]> | undefined,
	lt?: GraphQLTypes["TranslationKeyContentType"] | undefined,
	lte?: GraphQLTypes["TranslationKeyContentType"] | undefined,
	gt?: GraphQLTypes["TranslationKeyContentType"] | undefined,
	gte?: GraphQLTypes["TranslationKeyContentType"] | undefined
};
	["TranslationKeyContentType"]: TranslationKeyContentType;
	["TranslationValueWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	value?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationValueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationValueWhere"] | undefined
};
	["TranslationCataloguesIdentifierWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	name?: GraphQLTypes["StringCondition"] | undefined,
	code?: GraphQLTypes["StringCondition"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueWhere"] | undefined,
	and?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["TranslationCataloguesIdentifierWhere"] | undefined
};
	["TranslationCatalogueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierOrderBy"] | undefined,
	fallback?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined
};
	["TranslationDomainOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined
};
	["TranslationCataloguesIdentifierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	name?: GraphQLTypes["OrderDirection"] | undefined,
	code?: GraphQLTypes["OrderDirection"] | undefined
};
	["TranslationKey"]: {
	__typename: "TranslationKey",
	_meta?: GraphQLTypes["TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["TranslationKeyContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["TranslationValueConnection"]
};
	["TranslationKeyMeta"]: {
	__typename: "TranslationKeyMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	identifier?: GraphQLTypes["FieldMeta"] | undefined,
	contentType?: GraphQLTypes["FieldMeta"] | undefined,
	note?: GraphQLTypes["FieldMeta"] | undefined,
	domain?: GraphQLTypes["FieldMeta"] | undefined,
	values?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValue"]: {
	__typename: "TranslationValue",
	_meta?: GraphQLTypes["TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["TranslationKey"] | undefined
};
	["TranslationValueMeta"]: {
	__typename: "TranslationValueMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	value?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined,
	key?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationValueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	value?: GraphQLTypes["OrderDirection"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueOrderBy"] | undefined,
	key?: GraphQLTypes["TranslationKeyOrderBy"] | undefined
};
	["TranslationKeyOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	identifier?: GraphQLTypes["OrderDirection"] | undefined,
	contentType?: GraphQLTypes["OrderDirection"] | undefined,
	note?: GraphQLTypes["OrderDirection"] | undefined,
	domain?: GraphQLTypes["TranslationDomainOrderBy"] | undefined
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
		catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationCatalogueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	catalogues?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	keys?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationKeyUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined,
	identifier?: string | undefined,
	values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationValueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined,
	key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	catalogue?: GraphQLTypes["TranslationCatalogueUniqueWhere"] | undefined
};
	["TranslationValueConnection"]: {
	__typename: "TranslationValueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationValueEdge"]>
};
	["TranslationValueEdge"]: {
	__typename: "TranslationValueEdge",
	node: GraphQLTypes["TranslationValue"]
};
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["TranslationCataloguesIdentifierUniqueWhere"] | undefined
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
		identifier?: string | undefined
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueConnection"]: {
	__typename: "TranslationCatalogueConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCatalogueEdge"]>
};
	["TranslationCatalogueEdge"]: {
	__typename: "TranslationCatalogueEdge",
	node: GraphQLTypes["TranslationCatalogue"]
};
	["TranslationKeyConnection"]: {
	__typename: "TranslationKeyConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationKeyEdge"]>
};
	["TranslationKeyEdge"]: {
	__typename: "TranslationKeyEdge",
	node: GraphQLTypes["TranslationKey"]
};
	["TranslationCataloguesIdentifier"]: {
	__typename: "TranslationCataloguesIdentifier",
	_meta?: GraphQLTypes["TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["TranslationCatalogueConnection"]
};
	["TranslationCataloguesIdentifierMeta"]: {
	__typename: "TranslationCataloguesIdentifierMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	name?: GraphQLTypes["FieldMeta"] | undefined,
	code?: GraphQLTypes["FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["FieldMeta"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
		domain?: GraphQLTypes["TranslationDomainUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
		values?: GraphQLTypes["TranslationValueUniqueWhere"] | undefined
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
		key?: GraphQLTypes["TranslationKeyUniqueWhere"] | undefined
};
	["TranslationCataloguesIdentifierConnection"]: {
	__typename: "TranslationCataloguesIdentifierConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationCataloguesIdentifierEdge"]>
};
	["TranslationCataloguesIdentifierEdge"]: {
	__typename: "TranslationCataloguesIdentifierEdge",
	node: GraphQLTypes["TranslationCataloguesIdentifier"]
};
	["TranslationDomainConnection"]: {
	__typename: "TranslationDomainConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["TranslationDomainEdge"]>
};
	["TranslationDomainEdge"]: {
	__typename: "TranslationDomainEdge",
	node: GraphQLTypes["TranslationDomain"]
};
	["FilledOrderFormCountryConnection"]: {
	__typename: "FilledOrderFormCountryConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FilledOrderFormCountryEdge"]>
};
	["FilledOrderFormCountryEdge"]: {
	__typename: "FilledOrderFormCountryEdge",
	node: GraphQLTypes["FilledOrderFormCountry"]
};
	["FilledProductWaitlistForm"]: {
	__typename: "FilledProductWaitlistForm",
	_meta?: GraphQLTypes["FilledProductWaitlistFormMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	publishedAt: GraphQLTypes["DateTime"],
	email: string,
	product?: GraphQLTypes["Product"] | undefined
};
	["FilledProductWaitlistFormMeta"]: {
	__typename: "FilledProductWaitlistFormMeta",
	id?: GraphQLTypes["FieldMeta"] | undefined,
	publishedAt?: GraphQLTypes["FieldMeta"] | undefined,
	email?: GraphQLTypes["FieldMeta"] | undefined,
	product?: GraphQLTypes["FieldMeta"] | undefined
};
	["FilledProductWaitlistFormUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["FilledProductWaitlistFormWhere"]: {
		id?: GraphQLTypes["UUIDCondition"] | undefined,
	publishedAt?: GraphQLTypes["DateTimeCondition"] | undefined,
	email?: GraphQLTypes["StringCondition"] | undefined,
	product?: GraphQLTypes["ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["FilledProductWaitlistFormWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["FilledProductWaitlistFormWhere"] | undefined> | undefined,
	not?: GraphQLTypes["FilledProductWaitlistFormWhere"] | undefined
};
	["FilledProductWaitlistFormOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["OrderDirection"] | undefined,
	publishedAt?: GraphQLTypes["OrderDirection"] | undefined,
	email?: GraphQLTypes["OrderDirection"] | undefined,
	product?: GraphQLTypes["ProductOrderBy"] | undefined
};
	["FilledProductWaitlistFormConnection"]: {
	__typename: "FilledProductWaitlistFormConnection",
	pageInfo: GraphQLTypes["PageInfo"],
	edges: Array<GraphQLTypes["FilledProductWaitlistFormEdge"]>
};
	["FilledProductWaitlistFormEdge"]: {
	__typename: "FilledProductWaitlistFormEdge",
	node: GraphQLTypes["FilledProductWaitlistForm"]
};
	["FilledProductWaitlistFormCreateInput"]: {
		publishedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	product?: GraphQLTypes["FilledProductWaitlistFormCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["FilledProductWaitlistFormCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["FilledProductWaitlistFormUpdateInput"]: {
		publishedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	product?: GraphQLTypes["FilledProductWaitlistFormUpdateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["FilledProductWaitlistFormUpdateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["ProductUniqueWhere"] | undefined
};
	["QueryTransaction"]: {
	__typename: "QueryTransaction",
	getImage?: GraphQLTypes["Image"] | undefined,
	listImage: Array<GraphQLTypes["Image"]>,
	paginateImage: GraphQLTypes["ImageConnection"],
	getGenericPage?: GraphQLTypes["GenericPage"] | undefined,
	listGenericPage: Array<GraphQLTypes["GenericPage"]>,
	paginateGenericPage: GraphQLTypes["GenericPageConnection"],
	getLinkable?: GraphQLTypes["Linkable"] | undefined,
	listLinkable: Array<GraphQLTypes["Linkable"]>,
	paginateLinkable: GraphQLTypes["LinkableConnection"],
	getSeo?: GraphQLTypes["Seo"] | undefined,
	listSeo: Array<GraphQLTypes["Seo"]>,
	paginateSeo: GraphQLTypes["SeoConnection"],
	getGenericPageLocale?: GraphQLTypes["GenericPageLocale"] | undefined,
	listGenericPageLocale: Array<GraphQLTypes["GenericPageLocale"]>,
	paginateGenericPageLocale: GraphQLTypes["GenericPageLocaleConnection"],
	getLocale?: GraphQLTypes["Locale"] | undefined,
	listLocale: Array<GraphQLTypes["Locale"]>,
	paginateLocale: GraphQLTypes["LocaleConnection"],
	getLink?: GraphQLTypes["Link"] | undefined,
	listLink: Array<GraphQLTypes["Link"]>,
	paginateLink: GraphQLTypes["LinkConnection"],
	getHeader?: GraphQLTypes["Header"] | undefined,
	listHeader: Array<GraphQLTypes["Header"]>,
	paginateHeader: GraphQLTypes["HeaderConnection"],
	getHeaderLocale?: GraphQLTypes["HeaderLocale"] | undefined,
	listHeaderLocale: Array<GraphQLTypes["HeaderLocale"]>,
	paginateHeaderLocale: GraphQLTypes["HeaderLocaleConnection"],
	getContent?: GraphQLTypes["Content"] | undefined,
	listContent: Array<GraphQLTypes["Content"]>,
	paginateContent: GraphQLTypes["ContentConnection"],
	getContentBlock?: GraphQLTypes["ContentBlock"] | undefined,
	listContentBlock: Array<GraphQLTypes["ContentBlock"]>,
	paginateContentBlock: GraphQLTypes["ContentBlockConnection"],
	getContentReference?: GraphQLTypes["ContentReference"] | undefined,
	listContentReference: Array<GraphQLTypes["ContentReference"]>,
	paginateContentReference: GraphQLTypes["ContentReferenceConnection"],
	getVideo?: GraphQLTypes["Video"] | undefined,
	listVideo: Array<GraphQLTypes["Video"]>,
	paginateVideo: GraphQLTypes["VideoConnection"],
	getButtonLink?: GraphQLTypes["ButtonLink"] | undefined,
	listButtonLink: Array<GraphQLTypes["ButtonLink"]>,
	paginateButtonLink: GraphQLTypes["ButtonLinkConnection"],
	getFeature?: GraphQLTypes["Feature"] | undefined,
	listFeature: Array<GraphQLTypes["Feature"]>,
	paginateFeature: GraphQLTypes["FeatureConnection"],
	getFeatureItem?: GraphQLTypes["FeatureItem"] | undefined,
	listFeatureItem: Array<GraphQLTypes["FeatureItem"]>,
	paginateFeatureItem: GraphQLTypes["FeatureItemConnection"],
	getFeatureList?: GraphQLTypes["FeatureList"] | undefined,
	listFeatureList: Array<GraphQLTypes["FeatureList"]>,
	paginateFeatureList: GraphQLTypes["FeatureListConnection"],
	getLinkItem?: GraphQLTypes["LinkItem"] | undefined,
	listLinkItem: Array<GraphQLTypes["LinkItem"]>,
	paginateLinkItem: GraphQLTypes["LinkItemConnection"],
	getLinkList?: GraphQLTypes["LinkList"] | undefined,
	listLinkList: Array<GraphQLTypes["LinkList"]>,
	paginateLinkList: GraphQLTypes["LinkListConnection"],
	getFooter?: GraphQLTypes["Footer"] | undefined,
	listFooter: Array<GraphQLTypes["Footer"]>,
	paginateFooter: GraphQLTypes["FooterConnection"],
	getFooterGroupLinkList?: GraphQLTypes["FooterGroupLinkList"] | undefined,
	listFooterGroupLinkList: Array<GraphQLTypes["FooterGroupLinkList"]>,
	paginateFooterGroupLinkList: GraphQLTypes["FooterGroupLinkListConnection"],
	getFooterLocale?: GraphQLTypes["FooterLocale"] | undefined,
	listFooterLocale: Array<GraphQLTypes["FooterLocale"]>,
	paginateFooterLocale: GraphQLTypes["FooterLocaleConnection"],
	getGenericPageSideNavigation?: GraphQLTypes["GenericPageSideNavigation"] | undefined,
	listGenericPageSideNavigation: Array<GraphQLTypes["GenericPageSideNavigation"]>,
	paginateGenericPageSideNavigation: GraphQLTypes["GenericPageSideNavigationConnection"],
	getProjection?: GraphQLTypes["Projection"] | undefined,
	listProjection: Array<GraphQLTypes["Projection"]>,
	paginateProjection: GraphQLTypes["ProjectionConnection"],
	getProjectionContent?: GraphQLTypes["ProjectionContent"] | undefined,
	listProjectionContent: Array<GraphQLTypes["ProjectionContent"]>,
	paginateProjectionContent: GraphQLTypes["ProjectionContentConnection"],
	getProjectionItem?: GraphQLTypes["ProjectionItem"] | undefined,
	listProjectionItem: Array<GraphQLTypes["ProjectionItem"]>,
	paginateProjectionItem: GraphQLTypes["ProjectionItemConnection"],
	getContentImageWithFeatures?: GraphQLTypes["ContentImageWithFeatures"] | undefined,
	listContentImageWithFeatures: Array<GraphQLTypes["ContentImageWithFeatures"]>,
	paginateContentImageWithFeatures: GraphQLTypes["ContentImageWithFeaturesConnection"],
	getAsideContentMedia?: GraphQLTypes["AsideContentMedia"] | undefined,
	listAsideContentMedia: Array<GraphQLTypes["AsideContentMedia"]>,
	paginateAsideContentMedia: GraphQLTypes["AsideContentMediaConnection"],
	getAsideContentMediaSpecification?: GraphQLTypes["AsideContentMediaSpecification"] | undefined,
	listAsideContentMediaSpecification: Array<GraphQLTypes["AsideContentMediaSpecification"]>,
	paginateAsideContentMediaSpecification: GraphQLTypes["AsideContentMediaSpecificationConnection"],
	getFile?: GraphQLTypes["File"] | undefined,
	listFile: Array<GraphQLTypes["File"]>,
	paginateFile: GraphQLTypes["FileConnection"],
	getImageList?: GraphQLTypes["ImageList"] | undefined,
	listImageList: Array<GraphQLTypes["ImageList"]>,
	paginateImageList: GraphQLTypes["ImageListConnection"],
	getImageListItem?: GraphQLTypes["ImageListItem"] | undefined,
	listImageListItem: Array<GraphQLTypes["ImageListItem"]>,
	paginateImageListItem: GraphQLTypes["ImageListItemConnection"],
	getProduct?: GraphQLTypes["Product"] | undefined,
	listProduct: Array<GraphQLTypes["Product"]>,
	paginateProduct: GraphQLTypes["ProductConnection"],
	getProductCategory?: GraphQLTypes["ProductCategory"] | undefined,
	listProductCategory: Array<GraphQLTypes["ProductCategory"]>,
	paginateProductCategory: GraphQLTypes["ProductCategoryConnection"],
	getProductCategoryLocale?: GraphQLTypes["ProductCategoryLocale"] | undefined,
	listProductCategoryLocale: Array<GraphQLTypes["ProductCategoryLocale"]>,
	paginateProductCategoryLocale: GraphQLTypes["ProductCategoryLocaleConnection"],
	getProductLocale?: GraphQLTypes["ProductLocale"] | undefined,
	listProductLocale: Array<GraphQLTypes["ProductLocale"]>,
	paginateProductLocale: GraphQLTypes["ProductLocaleConnection"],
	getProductCategoryItem?: GraphQLTypes["ProductCategoryItem"] | undefined,
	listProductCategoryItem: Array<GraphQLTypes["ProductCategoryItem"]>,
	paginateProductCategoryItem: GraphQLTypes["ProductCategoryItemConnection"],
	getProductCategoryList?: GraphQLTypes["ProductCategoryList"] | undefined,
	listProductCategoryList: Array<GraphQLTypes["ProductCategoryList"]>,
	paginateProductCategoryList: GraphQLTypes["ProductCategoryListConnection"],
	getFeatureSection?: GraphQLTypes["FeatureSection"] | undefined,
	listFeatureSection: Array<GraphQLTypes["FeatureSection"]>,
	paginateFeatureSection: GraphQLTypes["FeatureSectionConnection"],
	getFeatureSectionHeat?: GraphQLTypes["FeatureSectionHeat"] | undefined,
	listFeatureSectionHeat: Array<GraphQLTypes["FeatureSectionHeat"]>,
	paginateFeatureSectionHeat: GraphQLTypes["FeatureSectionHeatConnection"],
	getFeatureSectionPump?: GraphQLTypes["FeatureSectionPump"] | undefined,
	listFeatureSectionPump: Array<GraphQLTypes["FeatureSectionPump"]>,
	paginateFeatureSectionPump: GraphQLTypes["FeatureSectionPumpConnection"],
	getFeatureSectionTemperature?: GraphQLTypes["FeatureSectionTemperature"] | undefined,
	listFeatureSectionTemperature: Array<GraphQLTypes["FeatureSectionTemperature"]>,
	paginateFeatureSectionTemperature: GraphQLTypes["FeatureSectionTemperatureConnection"],
	getFeatureSectionTube?: GraphQLTypes["FeatureSectionTube"] | undefined,
	listFeatureSectionTube: Array<GraphQLTypes["FeatureSectionTube"]>,
	paginateFeatureSectionTube: GraphQLTypes["FeatureSectionTubeConnection"],
	getFeatureSectionAntimer?: GraphQLTypes["FeatureSectionAntimer"] | undefined,
	listFeatureSectionAntimer: Array<GraphQLTypes["FeatureSectionAntimer"]>,
	paginateFeatureSectionAntimer: GraphQLTypes["FeatureSectionAntimerConnection"],
	getRedirect?: GraphQLTypes["Redirect"] | undefined,
	listRedirect: Array<GraphQLTypes["Redirect"]>,
	paginateRedirect: GraphQLTypes["RedirectConnection"],
	getGeneral?: GraphQLTypes["General"] | undefined,
	listGeneral: Array<GraphQLTypes["General"]>,
	paginateGeneral: GraphQLTypes["GeneralConnection"],
	getGeneralLocale?: GraphQLTypes["GeneralLocale"] | undefined,
	listGeneralLocale: Array<GraphQLTypes["GeneralLocale"]>,
	paginateGeneralLocale: GraphQLTypes["GeneralLocaleConnection"],
	getProductFeature?: GraphQLTypes["ProductFeature"] | undefined,
	listProductFeature: Array<GraphQLTypes["ProductFeature"]>,
	paginateProductFeature: GraphQLTypes["ProductFeatureConnection"],
	getProductFeatureLocale?: GraphQLTypes["ProductFeatureLocale"] | undefined,
	listProductFeatureLocale: Array<GraphQLTypes["ProductFeatureLocale"]>,
	paginateProductFeatureLocale: GraphQLTypes["ProductFeatureLocaleConnection"],
	getFilledOrderForm?: GraphQLTypes["FilledOrderForm"] | undefined,
	listFilledOrderForm: Array<GraphQLTypes["FilledOrderForm"]>,
	paginateFilledOrderForm: GraphQLTypes["FilledOrderFormConnection"],
	validateCreateFilledOrderForm: GraphQLTypes["_ValidationResult"],
	validateUpdateFilledOrderForm: GraphQLTypes["_ValidationResult"],
	getOrderPage?: GraphQLTypes["OrderPage"] | undefined,
	listOrderPage: Array<GraphQLTypes["OrderPage"]>,
	paginateOrderPage: GraphQLTypes["OrderPageConnection"],
	getOrderPageLocale?: GraphQLTypes["OrderPageLocale"] | undefined,
	listOrderPageLocale: Array<GraphQLTypes["OrderPageLocale"]>,
	paginateOrderPageLocale: GraphQLTypes["OrderPageLocaleConnection"],
	getFilledOrderFormEmailAddress?: GraphQLTypes["FilledOrderFormEmailAddress"] | undefined,
	listFilledOrderFormEmailAddress: Array<GraphQLTypes["FilledOrderFormEmailAddress"]>,
	paginateFilledOrderFormEmailAddress: GraphQLTypes["FilledOrderFormEmailAddressConnection"],
	getTranslationCatalogue?: GraphQLTypes["TranslationCatalogue"] | undefined,
	listTranslationCatalogue: Array<GraphQLTypes["TranslationCatalogue"]>,
	paginateTranslationCatalogue: GraphQLTypes["TranslationCatalogueConnection"],
	getTranslationCataloguesIdentifier?: GraphQLTypes["TranslationCataloguesIdentifier"] | undefined,
	listTranslationCataloguesIdentifier: Array<GraphQLTypes["TranslationCataloguesIdentifier"]>,
	paginateTranslationCataloguesIdentifier: GraphQLTypes["TranslationCataloguesIdentifierConnection"],
	getTranslationDomain?: GraphQLTypes["TranslationDomain"] | undefined,
	listTranslationDomain: Array<GraphQLTypes["TranslationDomain"]>,
	paginateTranslationDomain: GraphQLTypes["TranslationDomainConnection"],
	getTranslationKey?: GraphQLTypes["TranslationKey"] | undefined,
	listTranslationKey: Array<GraphQLTypes["TranslationKey"]>,
	paginateTranslationKey: GraphQLTypes["TranslationKeyConnection"],
	getTranslationValue?: GraphQLTypes["TranslationValue"] | undefined,
	listTranslationValue: Array<GraphQLTypes["TranslationValue"]>,
	paginateTranslationValue: GraphQLTypes["TranslationValueConnection"],
	getFilledOrderFormCountry?: GraphQLTypes["FilledOrderFormCountry"] | undefined,
	listFilledOrderFormCountry: Array<GraphQLTypes["FilledOrderFormCountry"]>,
	paginateFilledOrderFormCountry: GraphQLTypes["FilledOrderFormCountryConnection"],
	getFilledProductWaitlistForm?: GraphQLTypes["FilledProductWaitlistForm"] | undefined,
	listFilledProductWaitlistForm: Array<GraphQLTypes["FilledProductWaitlistForm"]>,
	paginateFilledProductWaitlistForm: GraphQLTypes["FilledProductWaitlistFormConnection"],
	validateCreateFilledProductWaitlistForm: GraphQLTypes["_ValidationResult"],
	validateUpdateFilledProductWaitlistForm: GraphQLTypes["_ValidationResult"]
};
	["Info"]: {
	__typename: "Info",
	description?: string | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	createFilledOrderForm: GraphQLTypes["FilledOrderFormCreateResult"],
	updateFilledOrderForm: GraphQLTypes["FilledOrderFormUpdateResult"],
	upsertFilledOrderForm: GraphQLTypes["FilledOrderFormUpsertResult"],
	createFilledProductWaitlistForm: GraphQLTypes["FilledProductWaitlistFormCreateResult"],
	updateFilledProductWaitlistForm: GraphQLTypes["FilledProductWaitlistFormUpdateResult"],
	upsertFilledProductWaitlistForm: GraphQLTypes["FilledProductWaitlistFormUpsertResult"],
	transaction: GraphQLTypes["MutationTransaction"],
	query: GraphQLTypes["Query"]
};
	["FilledOrderFormCreateResult"]: {
	__typename: "FilledOrderFormCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["FilledOrderForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationResult"]: {
	__typename:"FilledOrderFormCreateResult" | "FilledOrderFormUpdateResult" | "FilledOrderFormUpsertResult" | "FilledProductWaitlistFormCreateResult" | "FilledProductWaitlistFormUpdateResult" | "FilledProductWaitlistFormUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>
	['...on FilledOrderFormCreateResult']: '__union' & GraphQLTypes["FilledOrderFormCreateResult"];
	['...on FilledOrderFormUpdateResult']: '__union' & GraphQLTypes["FilledOrderFormUpdateResult"];
	['...on FilledOrderFormUpsertResult']: '__union' & GraphQLTypes["FilledOrderFormUpsertResult"];
	['...on FilledProductWaitlistFormCreateResult']: '__union' & GraphQLTypes["FilledProductWaitlistFormCreateResult"];
	['...on FilledProductWaitlistFormUpdateResult']: '__union' & GraphQLTypes["FilledProductWaitlistFormUpdateResult"];
	['...on FilledProductWaitlistFormUpsertResult']: '__union' & GraphQLTypes["FilledProductWaitlistFormUpsertResult"];
};
	["_MutationError"]: {
	__typename: "_MutationError",
	path: Array<GraphQLTypes["_PathFragment"]>,
	paths: Array<Array<GraphQLTypes["_PathFragment"]>>,
	type: GraphQLTypes["_MutationErrorType"],
	message?: string | undefined
};
	["_MutationErrorType"]: _MutationErrorType;
	["FilledOrderFormUpdateResult"]: {
	__typename: "FilledOrderFormUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["FilledOrderForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["FilledOrderFormUpsertResult"]: {
	__typename: "FilledOrderFormUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["FilledOrderForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["FilledProductWaitlistFormCreateResult"]: {
	__typename: "FilledProductWaitlistFormCreateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["FilledProductWaitlistForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["FilledProductWaitlistFormUpdateResult"]: {
	__typename: "FilledProductWaitlistFormUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["FilledProductWaitlistForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["FilledProductWaitlistFormUpsertResult"]: {
	__typename: "FilledProductWaitlistFormUpsertResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	node?: GraphQLTypes["FilledProductWaitlistForm"] | undefined,
	validation: GraphQLTypes["_ValidationResult"]
};
	["MutationTransaction"]: {
	__typename: "MutationTransaction",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["_MutationError"]>,
	validation: GraphQLTypes["_ValidationResult"],
	createFilledOrderForm: GraphQLTypes["FilledOrderFormCreateResult"],
	updateFilledOrderForm: GraphQLTypes["FilledOrderFormUpdateResult"],
	upsertFilledOrderForm: GraphQLTypes["FilledOrderFormUpsertResult"],
	createFilledProductWaitlistForm: GraphQLTypes["FilledProductWaitlistFormCreateResult"],
	updateFilledProductWaitlistForm: GraphQLTypes["FilledProductWaitlistFormUpdateResult"],
	upsertFilledProductWaitlistForm: GraphQLTypes["FilledProductWaitlistFormUpsertResult"],
	query?: GraphQLTypes["Query"] | undefined
};
	["MutationTransactionOptions"]: {
		deferForeignKeyConstraints?: boolean | undefined
};
	["Json"]: "scalar" & { name: "Json" };
	["_Schema"]: {
	__typename: "_Schema",
	enums: Array<GraphQLTypes["_Enum"]>,
	entities: Array<GraphQLTypes["_Entity"]>
};
	["_Entity"]: {
	__typename: "_Entity",
	name: string,
	customPrimaryAllowed: boolean,
	fields: Array<GraphQLTypes["_Field"]>,
	unique: Array<GraphQLTypes["_UniqueConstraint"]>
};
	["_UniqueConstraint"]: {
	__typename: "_UniqueConstraint",
	fields: Array<string>
};
	["_Field"]: {
	__typename:"_Column" | "_Relation",
	name: string,
	type: string,
	nullable?: boolean | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
	['...on _Column']: '__union' & GraphQLTypes["_Column"];
	['...on _Relation']: '__union' & GraphQLTypes["_Relation"];
};
	["_Column"]: {
	__typename: "_Column",
	name: string,
	type: string,
	enumName?: string | undefined,
	defaultValue?: GraphQLTypes["Json"] | undefined,
	nullable: boolean,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_OnDeleteBehaviour"]: _OnDeleteBehaviour;
	["_RelationSide"]: _RelationSide;
	["_OrderByDirection"]: _OrderByDirection;
	["_OrderBy"]: {
	__typename: "_OrderBy",
	path: Array<string>,
	direction: GraphQLTypes["_OrderByDirection"]
};
	["_Relation"]: {
	__typename: "_Relation",
	name: string,
	type: string,
	side: GraphQLTypes["_RelationSide"],
	targetEntity: string,
	ownedBy?: string | undefined,
	inversedBy?: string | undefined,
	nullable?: boolean | undefined,
	onDelete?: GraphQLTypes["_OnDeleteBehaviour"] | undefined,
	orphanRemoval?: boolean | undefined,
	orderBy?: Array<GraphQLTypes["_OrderBy"]> | undefined,
	rules: Array<GraphQLTypes["_Rule"]>,
	validators: Array<GraphQLTypes["_Validator"]>
};
	["_Rule"]: {
	__typename: "_Rule",
	message?: GraphQLTypes["_RuleMessage"] | undefined,
	validator: number
};
	["_Validator"]: {
	__typename: "_Validator",
	operation: string,
	arguments: Array<GraphQLTypes["_Argument"]>
};
	["_Argument"]:{
        	__typename:"_ValidatorArgument" | "_PathArgument" | "_LiteralArgument"
        	['...on _ValidatorArgument']: '__union' & GraphQLTypes["_ValidatorArgument"];
	['...on _PathArgument']: '__union' & GraphQLTypes["_PathArgument"];
	['...on _LiteralArgument']: '__union' & GraphQLTypes["_LiteralArgument"];
};
	["_ValidatorArgument"]: {
	__typename: "_ValidatorArgument",
	validator: number
};
	["_PathArgument"]: {
	__typename: "_PathArgument",
	path: Array<string>
};
	["_LiteralArgument"]: {
	__typename: "_LiteralArgument",
	value?: GraphQLTypes["Json"] | undefined
};
	["_RuleMessage"]: {
	__typename: "_RuleMessage",
	text?: string | undefined
};
	["_Enum"]: {
	__typename: "_Enum",
	name: string,
	values: Array<string>
}
    }
export const enum OrderDirection {
	asc = "asc",
	desc = "desc",
	ascNullsFirst = "ascNullsFirst",
	descNullsLast = "descNullsLast"
}
export const enum One {
	One = "One"
}
export const enum LinkType {
	internal = "internal",
	external = "external",
	file = "file",
	scrollToTarget = "scrollToTarget"
}
export const enum ContentReferenceType {
	asideContentMedia = "asideContentMedia",
	button = "button",
	callToAction = "callToAction",
	contentImageWithFeatures = "contentImageWithFeatures",
	features = "features",
	featureSection = "featureSection",
	image = "image",
	imageTiles = "imageTiles",
	productTabsTiles = "productTabsTiles",
	projection = "projection"
}
export const enum ButtonLinkVisualType {
	default = "default",
	outline = "outline"
}
export const enum ButtonLinkFillType {
	transparent = "transparent",
	light = "light"
}
export const enum ButtonLinkWidthType {
	normal = "normal",
	medium = "medium",
	wide = "wide"
}
export const enum CallToActionType {
	default = "default",
	option1 = "option1"
}
export const enum TranslationKeyContentType {
	textPlain = "textPlain",
	textHtml = "textHtml"
}
export const enum _MutationErrorType {
	NotNullConstraintViolation = "NotNullConstraintViolation",
	UniqueConstraintViolation = "UniqueConstraintViolation",
	ForeignKeyConstraintViolation = "ForeignKeyConstraintViolation",
	NotFoundOrDenied = "NotFoundOrDenied",
	NonUniqueWhereInput = "NonUniqueWhereInput",
	InvalidDataInput = "InvalidDataInput",
	SqlError = "SqlError"
}
export const enum _OnDeleteBehaviour {
	restrict = "restrict",
	cascade = "cascade",
	setNull = "setNull"
}
export const enum _RelationSide {
	owning = "owning",
	inverse = "inverse"
}
export const enum _OrderByDirection {
	asc = "asc",
	desc = "desc"
}

type ZEUS_VARIABLES = {
	["UUID"]: ValueTypes["UUID"];
	["ImageUniqueWhere"]: ValueTypes["ImageUniqueWhere"];
	["ImageWhere"]: ValueTypes["ImageWhere"];
	["UUIDCondition"]: ValueTypes["UUIDCondition"];
	["StringCondition"]: ValueTypes["StringCondition"];
	["IntCondition"]: ValueTypes["IntCondition"];
	["ImageOrderBy"]: ValueTypes["ImageOrderBy"];
	["OrderDirection"]: ValueTypes["OrderDirection"];
	["GenericPageWhere"]: ValueTypes["GenericPageWhere"];
	["GenericPageLocaleWhere"]: ValueTypes["GenericPageLocaleWhere"];
	["LocaleWhere"]: ValueTypes["LocaleWhere"];
	["HeaderLocaleWhere"]: ValueTypes["HeaderLocaleWhere"];
	["HeaderWhere"]: ValueTypes["HeaderWhere"];
	["OneCondition"]: ValueTypes["OneCondition"];
	["One"]: ValueTypes["One"];
	["LinkWhere"]: ValueTypes["LinkWhere"];
	["LinkTypeCondition"]: ValueTypes["LinkTypeCondition"];
	["LinkType"]: ValueTypes["LinkType"];
	["LinkableWhere"]: ValueTypes["LinkableWhere"];
	["RedirectWhere"]: ValueTypes["RedirectWhere"];
	["OrderPageLocaleWhere"]: ValueTypes["OrderPageLocaleWhere"];
	["OrderPageWhere"]: ValueTypes["OrderPageWhere"];
	["SeoWhere"]: ValueTypes["SeoWhere"];
	["BooleanCondition"]: ValueTypes["BooleanCondition"];
	["FileWhere"]: ValueTypes["FileWhere"];
	["FooterLocaleWhere"]: ValueTypes["FooterLocaleWhere"];
	["FooterGroupLinkListWhere"]: ValueTypes["FooterGroupLinkListWhere"];
	["LinkListWhere"]: ValueTypes["LinkListWhere"];
	["LinkItemWhere"]: ValueTypes["LinkItemWhere"];
	["FooterWhere"]: ValueTypes["FooterWhere"];
	["ProductCategoryLocaleWhere"]: ValueTypes["ProductCategoryLocaleWhere"];
	["ProductCategoryWhere"]: ValueTypes["ProductCategoryWhere"];
	["ProductWhere"]: ValueTypes["ProductWhere"];
	["ProductLocaleWhere"]: ValueTypes["ProductLocaleWhere"];
	["ProductFeatureWhere"]: ValueTypes["ProductFeatureWhere"];
	["ProductFeatureLocaleWhere"]: ValueTypes["ProductFeatureLocaleWhere"];
	["GeneralLocaleWhere"]: ValueTypes["GeneralLocaleWhere"];
	["GeneralWhere"]: ValueTypes["GeneralWhere"];
	["ContentWhere"]: ValueTypes["ContentWhere"];
	["ContentBlockWhere"]: ValueTypes["ContentBlockWhere"];
	["ContentReferenceWhere"]: ValueTypes["ContentReferenceWhere"];
	["ContentReferenceTypeCondition"]: ValueTypes["ContentReferenceTypeCondition"];
	["ContentReferenceType"]: ValueTypes["ContentReferenceType"];
	["ButtonLinkWhere"]: ValueTypes["ButtonLinkWhere"];
	["ButtonLinkVisualTypeCondition"]: ValueTypes["ButtonLinkVisualTypeCondition"];
	["ButtonLinkVisualType"]: ValueTypes["ButtonLinkVisualType"];
	["ButtonLinkFillTypeCondition"]: ValueTypes["ButtonLinkFillTypeCondition"];
	["ButtonLinkFillType"]: ValueTypes["ButtonLinkFillType"];
	["ButtonLinkWidthTypeCondition"]: ValueTypes["ButtonLinkWidthTypeCondition"];
	["ButtonLinkWidthType"]: ValueTypes["ButtonLinkWidthType"];
	["FeatureListWhere"]: ValueTypes["FeatureListWhere"];
	["FeatureItemWhere"]: ValueTypes["FeatureItemWhere"];
	["FeatureWhere"]: ValueTypes["FeatureWhere"];
	["ProjectionWhere"]: ValueTypes["ProjectionWhere"];
	["ProjectionItemWhere"]: ValueTypes["ProjectionItemWhere"];
	["ProjectionContentWhere"]: ValueTypes["ProjectionContentWhere"];
	["ContentImageWithFeaturesWhere"]: ValueTypes["ContentImageWithFeaturesWhere"];
	["AsideContentMediaWhere"]: ValueTypes["AsideContentMediaWhere"];
	["AsideContentMediaSpecificationWhere"]: ValueTypes["AsideContentMediaSpecificationWhere"];
	["ImageListWhere"]: ValueTypes["ImageListWhere"];
	["ImageListItemWhere"]: ValueTypes["ImageListItemWhere"];
	["ProductCategoryListWhere"]: ValueTypes["ProductCategoryListWhere"];
	["ProductCategoryItemWhere"]: ValueTypes["ProductCategoryItemWhere"];
	["CallToActionTypeCondition"]: ValueTypes["CallToActionTypeCondition"];
	["CallToActionType"]: ValueTypes["CallToActionType"];
	["FeatureSectionWhere"]: ValueTypes["FeatureSectionWhere"];
	["FeatureSectionTemperatureWhere"]: ValueTypes["FeatureSectionTemperatureWhere"];
	["FeatureSectionHeatWhere"]: ValueTypes["FeatureSectionHeatWhere"];
	["FeatureSectionPumpWhere"]: ValueTypes["FeatureSectionPumpWhere"];
	["FeatureSectionTubeWhere"]: ValueTypes["FeatureSectionTubeWhere"];
	["FeatureSectionAntimerWhere"]: ValueTypes["FeatureSectionAntimerWhere"];
	["VideoWhere"]: ValueTypes["VideoWhere"];
	["FloatCondition"]: ValueTypes["FloatCondition"];
	["GenericPageSideNavigationWhere"]: ValueTypes["GenericPageSideNavigationWhere"];
	["GenericPageLocaleOrderBy"]: ValueTypes["GenericPageLocaleOrderBy"];
	["GenericPageOrderBy"]: ValueTypes["GenericPageOrderBy"];
	["LocaleOrderBy"]: ValueTypes["LocaleOrderBy"];
	["SeoOrderBy"]: ValueTypes["SeoOrderBy"];
	["LinkableOrderBy"]: ValueTypes["LinkableOrderBy"];
	["RedirectOrderBy"]: ValueTypes["RedirectOrderBy"];
	["LinkOrderBy"]: ValueTypes["LinkOrderBy"];
	["FileOrderBy"]: ValueTypes["FileOrderBy"];
	["OrderPageLocaleOrderBy"]: ValueTypes["OrderPageLocaleOrderBy"];
	["OrderPageOrderBy"]: ValueTypes["OrderPageOrderBy"];
	["ContentOrderBy"]: ValueTypes["ContentOrderBy"];
	["HeaderLocaleOrderBy"]: ValueTypes["HeaderLocaleOrderBy"];
	["HeaderOrderBy"]: ValueTypes["HeaderOrderBy"];
	["HeaderLocalesByLocaleUniqueWhere"]: ValueTypes["HeaderLocalesByLocaleUniqueWhere"];
	["LocaleUniqueWhere"]: ValueTypes["LocaleUniqueWhere"];
	["GenericPageLocaleUniqueWhere"]: ValueTypes["GenericPageLocaleUniqueWhere"];
	["GenericPageUniqueWhere"]: ValueTypes["GenericPageUniqueWhere"];
	["SeoUniqueWhere"]: ValueTypes["SeoUniqueWhere"];
	["LinkableUniqueWhere"]: ValueTypes["LinkableUniqueWhere"];
	["RedirectUniqueWhere"]: ValueTypes["RedirectUniqueWhere"];
	["LinkUniqueWhere"]: ValueTypes["LinkUniqueWhere"];
	["OrderPageLocaleUniqueWhere"]: ValueTypes["OrderPageLocaleUniqueWhere"];
	["OrderPageUniqueWhere"]: ValueTypes["OrderPageUniqueWhere"];
	["ContentUniqueWhere"]: ValueTypes["ContentUniqueWhere"];
	["ContentBlockUniqueWhere"]: ValueTypes["ContentBlockUniqueWhere"];
	["ContentReferenceUniqueWhere"]: ValueTypes["ContentReferenceUniqueWhere"];
	["ButtonLinkUniqueWhere"]: ValueTypes["ButtonLinkUniqueWhere"];
	["FeatureListUniqueWhere"]: ValueTypes["FeatureListUniqueWhere"];
	["FeatureItemUniqueWhere"]: ValueTypes["FeatureItemUniqueWhere"];
	["ProjectionUniqueWhere"]: ValueTypes["ProjectionUniqueWhere"];
	["ProjectionItemUniqueWhere"]: ValueTypes["ProjectionItemUniqueWhere"];
	["ContentImageWithFeaturesUniqueWhere"]: ValueTypes["ContentImageWithFeaturesUniqueWhere"];
	["AsideContentMediaUniqueWhere"]: ValueTypes["AsideContentMediaUniqueWhere"];
	["AsideContentMediaSpecificationUniqueWhere"]: ValueTypes["AsideContentMediaSpecificationUniqueWhere"];
	["ImageListUniqueWhere"]: ValueTypes["ImageListUniqueWhere"];
	["ImageListItemUniqueWhere"]: ValueTypes["ImageListItemUniqueWhere"];
	["ProductCategoryListUniqueWhere"]: ValueTypes["ProductCategoryListUniqueWhere"];
	["ProductCategoryItemUniqueWhere"]: ValueTypes["ProductCategoryItemUniqueWhere"];
	["FeatureSectionUniqueWhere"]: ValueTypes["FeatureSectionUniqueWhere"];
	["FeatureSectionTemperatureUniqueWhere"]: ValueTypes["FeatureSectionTemperatureUniqueWhere"];
	["FeatureSectionHeatUniqueWhere"]: ValueTypes["FeatureSectionHeatUniqueWhere"];
	["FeatureSectionPumpUniqueWhere"]: ValueTypes["FeatureSectionPumpUniqueWhere"];
	["FeatureSectionTubeUniqueWhere"]: ValueTypes["FeatureSectionTubeUniqueWhere"];
	["FeatureSectionAntimerUniqueWhere"]: ValueTypes["FeatureSectionAntimerUniqueWhere"];
	["GenericPageSideNavigationUniqueWhere"]: ValueTypes["GenericPageSideNavigationUniqueWhere"];
	["HeaderLocaleUniqueWhere"]: ValueTypes["HeaderLocaleUniqueWhere"];
	["HeaderUniqueWhere"]: ValueTypes["HeaderUniqueWhere"];
	["FooterLocaleUniqueWhere"]: ValueTypes["FooterLocaleUniqueWhere"];
	["FooterUniqueWhere"]: ValueTypes["FooterUniqueWhere"];
	["FooterGroupLinkListUniqueWhere"]: ValueTypes["FooterGroupLinkListUniqueWhere"];
	["LinkListUniqueWhere"]: ValueTypes["LinkListUniqueWhere"];
	["LinkItemUniqueWhere"]: ValueTypes["LinkItemUniqueWhere"];
	["ProductCategoryLocaleUniqueWhere"]: ValueTypes["ProductCategoryLocaleUniqueWhere"];
	["ProductCategoryUniqueWhere"]: ValueTypes["ProductCategoryUniqueWhere"];
	["ProductUniqueWhere"]: ValueTypes["ProductUniqueWhere"];
	["ProductLocaleUniqueWhere"]: ValueTypes["ProductLocaleUniqueWhere"];
	["GeneralLocaleUniqueWhere"]: ValueTypes["GeneralLocaleUniqueWhere"];
	["GeneralUniqueWhere"]: ValueTypes["GeneralUniqueWhere"];
	["ProductFeatureLocaleUniqueWhere"]: ValueTypes["ProductFeatureLocaleUniqueWhere"];
	["ProductFeatureUniqueWhere"]: ValueTypes["ProductFeatureUniqueWhere"];
	["OrderPageLocalesByLocaleUniqueWhere"]: ValueTypes["OrderPageLocalesByLocaleUniqueWhere"];
	["OrderPageLocalesByLinkUniqueWhere"]: ValueTypes["OrderPageLocalesByLinkUniqueWhere"];
	["OrderPageLocalesBySeoUniqueWhere"]: ValueTypes["OrderPageLocalesBySeoUniqueWhere"];
	["LinkItemOrderBy"]: ValueTypes["LinkItemOrderBy"];
	["LinkListOrderBy"]: ValueTypes["LinkListOrderBy"];
	["LinkListItemsByLinkUniqueWhere"]: ValueTypes["LinkListItemsByLinkUniqueWhere"];
	["FooterGroupLinkListOrderBy"]: ValueTypes["FooterGroupLinkListOrderBy"];
	["FooterLocaleOrderBy"]: ValueTypes["FooterLocaleOrderBy"];
	["FooterOrderBy"]: ValueTypes["FooterOrderBy"];
	["FooterLocalesByLocaleUniqueWhere"]: ValueTypes["FooterLocalesByLocaleUniqueWhere"];
	["FooterLocalesByGroupsLinkListUniqueWhere"]: ValueTypes["FooterLocalesByGroupsLinkListUniqueWhere"];
	["FooterLocaleGroupsLinkListByLinkListUniqueWhere"]: ValueTypes["FooterLocaleGroupsLinkListByLinkListUniqueWhere"];
	["ProductLocaleOrderBy"]: ValueTypes["ProductLocaleOrderBy"];
	["ProductOrderBy"]: ValueTypes["ProductOrderBy"];
	["ProductCategoryOrderBy"]: ValueTypes["ProductCategoryOrderBy"];
	["ProductFeatureLocaleOrderBy"]: ValueTypes["ProductFeatureLocaleOrderBy"];
	["ProductFeatureOrderBy"]: ValueTypes["ProductFeatureOrderBy"];
	["ProductFeatureLocalesByLocaleUniqueWhere"]: ValueTypes["ProductFeatureLocalesByLocaleUniqueWhere"];
	["ProductLocalesByLocaleUniqueWhere"]: ValueTypes["ProductLocalesByLocaleUniqueWhere"];
	["ProductCategoryLocaleOrderBy"]: ValueTypes["ProductCategoryLocaleOrderBy"];
	["ProductCategoryProductsBySlugUniqueWhere"]: ValueTypes["ProductCategoryProductsBySlugUniqueWhere"];
	["ProductCategoryProductsByLocalesUniqueWhere"]: ValueTypes["ProductCategoryProductsByLocalesUniqueWhere"];
	["ProductCategoryLocalesByLocaleUniqueWhere"]: ValueTypes["ProductCategoryLocalesByLocaleUniqueWhere"];
	["GeneralLocaleOrderBy"]: ValueTypes["GeneralLocaleOrderBy"];
	["GeneralOrderBy"]: ValueTypes["GeneralOrderBy"];
	["GeneralLocalesByLocaleUniqueWhere"]: ValueTypes["GeneralLocalesByLocaleUniqueWhere"];
	["GeneralLocalesBySeoUniqueWhere"]: ValueTypes["GeneralLocalesBySeoUniqueWhere"];
	["LocaleGenericPagesByRootUniqueWhere"]: ValueTypes["LocaleGenericPagesByRootUniqueWhere"];
	["LocaleGenericPagesBySeoUniqueWhere"]: ValueTypes["LocaleGenericPagesBySeoUniqueWhere"];
	["LocaleGenericPagesByLinkUniqueWhere"]: ValueTypes["LocaleGenericPagesByLinkUniqueWhere"];
	["LocaleGenericPagesByContentUniqueWhere"]: ValueTypes["LocaleGenericPagesByContentUniqueWhere"];
	["LocaleGenericPagesBySideNavigationItemsUniqueWhere"]: ValueTypes["LocaleGenericPagesBySideNavigationItemsUniqueWhere"];
	["LocaleHeadersByRootUniqueWhere"]: ValueTypes["LocaleHeadersByRootUniqueWhere"];
	["LocaleFootersByRootUniqueWhere"]: ValueTypes["LocaleFootersByRootUniqueWhere"];
	["LocaleFootersByGroupsLinkListUniqueWhere"]: ValueTypes["LocaleFootersByGroupsLinkListUniqueWhere"];
	["LocaleProductCategoriesByRootUniqueWhere"]: ValueTypes["LocaleProductCategoriesByRootUniqueWhere"];
	["LocaleProductsByRootUniqueWhere"]: ValueTypes["LocaleProductsByRootUniqueWhere"];
	["LocaleGeneralsByRootUniqueWhere"]: ValueTypes["LocaleGeneralsByRootUniqueWhere"];
	["LocaleGeneralsBySeoUniqueWhere"]: ValueTypes["LocaleGeneralsBySeoUniqueWhere"];
	["LocaleProductFeatureItemsByRootUniqueWhere"]: ValueTypes["LocaleProductFeatureItemsByRootUniqueWhere"];
	["LocaleOrderPagesByRootUniqueWhere"]: ValueTypes["LocaleOrderPagesByRootUniqueWhere"];
	["LocaleOrderPagesByLinkUniqueWhere"]: ValueTypes["LocaleOrderPagesByLinkUniqueWhere"];
	["LocaleOrderPagesBySeoUniqueWhere"]: ValueTypes["LocaleOrderPagesBySeoUniqueWhere"];
	["FeatureItemOrderBy"]: ValueTypes["FeatureItemOrderBy"];
	["FeatureListOrderBy"]: ValueTypes["FeatureListOrderBy"];
	["FeatureOrderBy"]: ValueTypes["FeatureOrderBy"];
	["ProjectionItemOrderBy"]: ValueTypes["ProjectionItemOrderBy"];
	["ProjectionOrderBy"]: ValueTypes["ProjectionOrderBy"];
	["ProjectionContentOrderBy"]: ValueTypes["ProjectionContentOrderBy"];
	["AsideContentMediaSpecificationOrderBy"]: ValueTypes["AsideContentMediaSpecificationOrderBy"];
	["AsideContentMediaOrderBy"]: ValueTypes["AsideContentMediaOrderBy"];
	["ImageListItemOrderBy"]: ValueTypes["ImageListItemOrderBy"];
	["ImageListOrderBy"]: ValueTypes["ImageListOrderBy"];
	["ProductCategoryItemOrderBy"]: ValueTypes["ProductCategoryItemOrderBy"];
	["ProductCategoryListOrderBy"]: ValueTypes["ProductCategoryListOrderBy"];
	["ContentReferenceOrderBy"]: ValueTypes["ContentReferenceOrderBy"];
	["ContentBlockOrderBy"]: ValueTypes["ContentBlockOrderBy"];
	["ButtonLinkOrderBy"]: ValueTypes["ButtonLinkOrderBy"];
	["ContentImageWithFeaturesOrderBy"]: ValueTypes["ContentImageWithFeaturesOrderBy"];
	["FeatureSectionOrderBy"]: ValueTypes["FeatureSectionOrderBy"];
	["FeatureSectionTemperatureOrderBy"]: ValueTypes["FeatureSectionTemperatureOrderBy"];
	["FeatureSectionHeatOrderBy"]: ValueTypes["FeatureSectionHeatOrderBy"];
	["FeatureSectionPumpOrderBy"]: ValueTypes["FeatureSectionPumpOrderBy"];
	["FeatureSectionTubeOrderBy"]: ValueTypes["FeatureSectionTubeOrderBy"];
	["FeatureSectionAntimerOrderBy"]: ValueTypes["FeatureSectionAntimerOrderBy"];
	["VideoOrderBy"]: ValueTypes["VideoOrderBy"];
	["ContentBlockReferencesByLinkUniqueWhere"]: ValueTypes["ContentBlockReferencesByLinkUniqueWhere"];
	["ContentBlockReferencesByLinkTwoUniqueWhere"]: ValueTypes["ContentBlockReferencesByLinkTwoUniqueWhere"];
	["ContentBlockReferencesByButtonLinkUniqueWhere"]: ValueTypes["ContentBlockReferencesByButtonLinkUniqueWhere"];
	["ContentBlockReferencesByButtonLinkTwoUniqueWhere"]: ValueTypes["ContentBlockReferencesByButtonLinkTwoUniqueWhere"];
	["ContentBlockReferencesByFeatureListUniqueWhere"]: ValueTypes["ContentBlockReferencesByFeatureListUniqueWhere"];
	["ContentBlockReferencesByProjectionUniqueWhere"]: ValueTypes["ContentBlockReferencesByProjectionUniqueWhere"];
	["ContentBlockReferencesByContentImageWithFeaturesUniqueWhere"]: ValueTypes["ContentBlockReferencesByContentImageWithFeaturesUniqueWhere"];
	["ContentBlockReferencesByAsideContentMediaUniqueWhere"]: ValueTypes["ContentBlockReferencesByAsideContentMediaUniqueWhere"];
	["ContentBlockReferencesByImageListUniqueWhere"]: ValueTypes["ContentBlockReferencesByImageListUniqueWhere"];
	["ContentBlockReferencesByProductCategoriesUniqueWhere"]: ValueTypes["ContentBlockReferencesByProductCategoriesUniqueWhere"];
	["ContentBlockReferencesByFeatureSectionUniqueWhere"]: ValueTypes["ContentBlockReferencesByFeatureSectionUniqueWhere"];
	["ContentBlocksByReferencesUniqueWhere"]: ValueTypes["ContentBlocksByReferencesUniqueWhere"];
	["GenericPageSideNavigationOrderBy"]: ValueTypes["GenericPageSideNavigationOrderBy"];
	["GenericPageLocalesByLocaleUniqueWhere"]: ValueTypes["GenericPageLocalesByLocaleUniqueWhere"];
	["GenericPageLocalesBySeoUniqueWhere"]: ValueTypes["GenericPageLocalesBySeoUniqueWhere"];
	["GenericPageLocalesByLinkUniqueWhere"]: ValueTypes["GenericPageLocalesByLinkUniqueWhere"];
	["GenericPageLocalesByContentUniqueWhere"]: ValueTypes["GenericPageLocalesByContentUniqueWhere"];
	["GenericPageLocalesBySideNavigationItemsUniqueWhere"]: ValueTypes["GenericPageLocalesBySideNavigationItemsUniqueWhere"];
	["VideoUniqueWhere"]: ValueTypes["VideoUniqueWhere"];
	["FeatureUniqueWhere"]: ValueTypes["FeatureUniqueWhere"];
	["ProjectionContentUniqueWhere"]: ValueTypes["ProjectionContentUniqueWhere"];
	["FileUniqueWhere"]: ValueTypes["FileUniqueWhere"];
	["DateTime"]: ValueTypes["DateTime"];
	["FilledOrderFormCountryWhere"]: ValueTypes["FilledOrderFormCountryWhere"];
	["FilledOrderFormUniqueWhere"]: ValueTypes["FilledOrderFormUniqueWhere"];
	["FilledOrderFormWhere"]: ValueTypes["FilledOrderFormWhere"];
	["DateTimeCondition"]: ValueTypes["DateTimeCondition"];
	["FilledOrderFormOrderBy"]: ValueTypes["FilledOrderFormOrderBy"];
	["FilledOrderFormCountryOrderBy"]: ValueTypes["FilledOrderFormCountryOrderBy"];
	["FilledOrderFormCreateInput"]: ValueTypes["FilledOrderFormCreateInput"];
	["FilledOrderFormCreateProductFeaturesEntityRelationInput"]: ValueTypes["FilledOrderFormCreateProductFeaturesEntityRelationInput"];
	["FilledOrderFormCreateProductEntityRelationInput"]: ValueTypes["FilledOrderFormCreateProductEntityRelationInput"];
	["FilledOrderFormCreateCountryEntityRelationInput"]: ValueTypes["FilledOrderFormCreateCountryEntityRelationInput"];
	["FilledOrderFormCountryUniqueWhere"]: ValueTypes["FilledOrderFormCountryUniqueWhere"];
	["FilledOrderFormUpdateInput"]: ValueTypes["FilledOrderFormUpdateInput"];
	["FilledOrderFormUpdateProductFeaturesEntityRelationInput"]: ValueTypes["FilledOrderFormUpdateProductFeaturesEntityRelationInput"];
	["FilledOrderFormUpdateProductEntityRelationInput"]: ValueTypes["FilledOrderFormUpdateProductEntityRelationInput"];
	["FilledOrderFormUpdateCountryEntityRelationInput"]: ValueTypes["FilledOrderFormUpdateCountryEntityRelationInput"];
	["FilledOrderFormEmailAddressUniqueWhere"]: ValueTypes["FilledOrderFormEmailAddressUniqueWhere"];
	["FilledOrderFormEmailAddressWhere"]: ValueTypes["FilledOrderFormEmailAddressWhere"];
	["FilledOrderFormEmailAddressOrderBy"]: ValueTypes["FilledOrderFormEmailAddressOrderBy"];
	["TranslationCatalogueWhere"]: ValueTypes["TranslationCatalogueWhere"];
	["TranslationDomainWhere"]: ValueTypes["TranslationDomainWhere"];
	["TranslationKeyWhere"]: ValueTypes["TranslationKeyWhere"];
	["TranslationKeyContentTypeCondition"]: ValueTypes["TranslationKeyContentTypeCondition"];
	["TranslationKeyContentType"]: ValueTypes["TranslationKeyContentType"];
	["TranslationValueWhere"]: ValueTypes["TranslationValueWhere"];
	["TranslationCataloguesIdentifierWhere"]: ValueTypes["TranslationCataloguesIdentifierWhere"];
	["TranslationCatalogueOrderBy"]: ValueTypes["TranslationCatalogueOrderBy"];
	["TranslationDomainOrderBy"]: ValueTypes["TranslationDomainOrderBy"];
	["TranslationCataloguesIdentifierOrderBy"]: ValueTypes["TranslationCataloguesIdentifierOrderBy"];
	["TranslationValueOrderBy"]: ValueTypes["TranslationValueOrderBy"];
	["TranslationKeyOrderBy"]: ValueTypes["TranslationKeyOrderBy"];
	["TranslationKeyValuesByCatalogueUniqueWhere"]: ValueTypes["TranslationKeyValuesByCatalogueUniqueWhere"];
	["TranslationCatalogueUniqueWhere"]: ValueTypes["TranslationCatalogueUniqueWhere"];
	["TranslationDomainUniqueWhere"]: ValueTypes["TranslationDomainUniqueWhere"];
	["TranslationKeyUniqueWhere"]: ValueTypes["TranslationKeyUniqueWhere"];
	["TranslationValueUniqueWhere"]: ValueTypes["TranslationValueUniqueWhere"];
	["TranslationCataloguesIdentifierUniqueWhere"]: ValueTypes["TranslationCataloguesIdentifierUniqueWhere"];
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: ValueTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"];
	["TranslationDomainCataloguesByValuesUniqueWhere"]: ValueTypes["TranslationDomainCataloguesByValuesUniqueWhere"];
	["TranslationDomainKeysByIdentifierUniqueWhere"]: ValueTypes["TranslationDomainKeysByIdentifierUniqueWhere"];
	["TranslationDomainKeysByValuesUniqueWhere"]: ValueTypes["TranslationDomainKeysByValuesUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: ValueTypes["TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"];
	["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: ValueTypes["TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"];
	["TranslationCatalogueValuesByKeyUniqueWhere"]: ValueTypes["TranslationCatalogueValuesByKeyUniqueWhere"];
	["FilledProductWaitlistFormUniqueWhere"]: ValueTypes["FilledProductWaitlistFormUniqueWhere"];
	["FilledProductWaitlistFormWhere"]: ValueTypes["FilledProductWaitlistFormWhere"];
	["FilledProductWaitlistFormOrderBy"]: ValueTypes["FilledProductWaitlistFormOrderBy"];
	["FilledProductWaitlistFormCreateInput"]: ValueTypes["FilledProductWaitlistFormCreateInput"];
	["FilledProductWaitlistFormCreateProductEntityRelationInput"]: ValueTypes["FilledProductWaitlistFormCreateProductEntityRelationInput"];
	["FilledProductWaitlistFormUpdateInput"]: ValueTypes["FilledProductWaitlistFormUpdateInput"];
	["FilledProductWaitlistFormUpdateProductEntityRelationInput"]: ValueTypes["FilledProductWaitlistFormUpdateProductEntityRelationInput"];
	["_MutationErrorType"]: ValueTypes["_MutationErrorType"];
	["MutationTransactionOptions"]: ValueTypes["MutationTransactionOptions"];
	["Json"]: ValueTypes["Json"];
	["_OnDeleteBehaviour"]: ValueTypes["_OnDeleteBehaviour"];
	["_RelationSide"]: ValueTypes["_RelationSide"];
	["_OrderByDirection"]: ValueTypes["_OrderByDirection"];
}