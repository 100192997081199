import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { AsideContentMediaResult } from '../data/AsideContentMediaFragment'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import styles from './AsideContentMedia.module.sass'

export type AsideContentMediaProps = AsideContentMediaResult

export const AsideContentMedia: FunctionComponent<AsideContentMediaProps> = ({
	title,
	subtitle,
	tableSpecifiation,
	link,
	media,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<div className={styles.content}>
					{subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
					{title && <h2 className={styles.title}>{title}</h2>}
					{tableSpecifiation && (
						<SpecificationWithLink deviceType="desktop" tableSpecifiation={tableSpecifiation} link={link} />
					)}
				</div>
				<div className={styles.media}>
					{media && (
						<Image
							className={styles.mediaIn}
							src={media.url}
							width={media.width}
							height={media.height}
							alt={media.alt ?? media.fileName ?? ''}
						/>
					)}
				</div>
				{tableSpecifiation && <SpecificationWithLink tableSpecifiation={tableSpecifiation} link={link} />}
			</div>
		</div>
	)
}

type SpecificationWithLinkProps = {
	tableSpecifiation: AsideContentMediaResult['tableSpecifiation']
	link: AsideContentMediaResult['link']
	deviceType?: 'mobile' | 'desktop'
}

const SpecificationWithLink: FunctionComponent<SpecificationWithLinkProps> = ({
	tableSpecifiation,
	link,
	deviceType = 'mobile',
}) => {
	return (
		<div className={clsx(styles.tableWrapper, styles[`is_${deviceType}`])}>
			{tableSpecifiation && (
				<table className={styles.tableSpecifiation}>
					<tbody>
						{tableSpecifiation.map(row => (
							<tr key={row.id} className={styles.tableSpecifiationRow}>
								<td className={clsx(styles.tableSpecifiationCell, styles.is_label)}>{row.label}</td>
								<td className={clsx(styles.tableSpecifiationCell, styles.is_value)}>{row.value}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
			{link && (
				<Link className={styles.link} href={contemberLinkToHref(link)}>
					{link.title}
				</Link>
			)}
		</div>
	)
}
