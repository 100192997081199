import clsx from 'clsx'
import { FunctionComponent, InputHTMLAttributes, TextareaHTMLAttributes } from 'react'
import styles from './Input.module.sass'

export type InputProps = Pick<
	InputHTMLAttributes<HTMLInputElement>,
	'name' | 'type' | 'required' | 'onChange' | 'onFocus' | 'onBlur' | 'autoComplete'
>

export const Input: FunctionComponent<InputProps> = ({
	name,
	type,
	required,
	onChange,
	onFocus,
	onBlur,
	autoComplete,
}) => {
	return (
		<input
			className={styles.wrapper}
			name={name}
			type={type}
			onChange={onChange}
			required={required}
			autoComplete={autoComplete}
			onFocus={onFocus}
			onBlur={onBlur}
		/>
	)
}

export type TextAreaProps = Pick<
	TextareaHTMLAttributes<HTMLTextAreaElement>,
	'name' | 'required' | 'autoComplete' | 'onChange' | 'onFocus' | 'onBlur'
>

export const TextArea: FunctionComponent<TextAreaProps> = ({
	name,
	required,
	onChange,
	onFocus,
	onBlur,
	autoComplete,
}) => {
	return (
		<textarea
			className={clsx(styles.wrapper, styles.is_textarea)}
			name={name}
			required={required}
			autoComplete={autoComplete}
			onChange={onChange}
			onFocus={onFocus}
			onBlur={onBlur}
		/>
	)
}
