import { FlashMessageProvider } from '../components/contexts/FlashMessageContext'
import { OrderPageLinkProvider } from '../components/contexts/OrderPageLinkContext'
import { SwitcherLocalesContextProvider } from '../components/contexts/SwitcherLocalesContext'
import { TranslationsContextProvider } from '../components/contexts/TranslationsContextProvider'
import { GenericPage } from '../components/GenericPage'
import { Layout } from '../components/Layout'
import { OrderPage } from '../components/OrderPage'
import { Seo } from '../components/Seo'
import { FooterLocaleFragment } from '../data/FooterLocaleFragment'
import { GeneralLocaleFragment } from '../data/GeneralLocaleFragment'
import { GenericPageDetailLocaleFragment } from '../data/GenericPageDetailLocaleFragment'
import { HeaderLocaleFragment } from '../data/HeaderLocaleFragment'
import { LinkFragment } from '../data/LinkFragment'
import { OrderPageDetailLocaleFragment } from '../data/OrderPageDetailLocaleFragment'
import { OrderProductFragment } from '../data/OrderProductFragment'
import { TranslationDomainWithCatalougesByIdentifierFragment } from '../data/TranslationDomainWithCatalougesByIdentifierFragment'
import { One } from '../generated/contember'
import { contember } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { scalars } from '../utilities/createScalarResolver'
import { getLinkableUrlFromContext } from '../utilities/getLinkableUrlFromContext'
import { handleGetStaticPaths, handleGetStaticProps, InferDataLoaderProps } from '../utilities/handlers'

export type CommonPageProps = InferDataLoaderProps<typeof getStaticProps>
export const programUrlKey = 'program'

export default function CommonPage({
	header,
	footer,
	page,
	currentLocale,
	pageLocales,
	orderPage,
	translations,
	seo,
}: CommonPageProps) {
	// @TODO: add HEAD - favicon

	const isOrderPage = Boolean(page.orderPage)
	return (
		<>
			<Seo {...seo} />
			<SwitcherLocalesContextProvider pageLocales={pageLocales} currentLocale={currentLocale}>
				<TranslationsContextProvider data={translations}>
					<OrderPageLinkProvider {...orderPage}>
						<FlashMessageProvider
							type="neutral"
							message=""
							handleFlashMessage={() => {}}
							handleVisibility={() => {}}
							isVisible={false}
						>
							<Layout header={header} footer={footer} isHeaderVisible={!isOrderPage} isFooterVisible={!isOrderPage}>
								{page.genericPage ? (
									<GenericPage {...page.genericPage} />
								) : page.orderPage ? (
									<OrderPage {...page.orderPage} />
								) : null}
								{/* @TODO: 404 */}
							</Layout>
						</FlashMessageProvider>
					</OrderPageLinkProvider>
				</TranslationsContextProvider>
			</SwitcherLocalesContextProvider>
		</>
	)
}

export const getStaticPaths = handleGetStaticPaths(async context => {
	const { listLinkable } = await contember('query', { scalars: scalars })({
		listLinkable: [
			{
				filter: {
					redirect: { id: { isNull: true } },
				},
			},
			{
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map(linkable => {
			const urlParts = linkable.url.split('/').filter(string => string !== '')

			let locale = context.defaultLocale

			if (context.locales?.includes(urlParts[0])) {
				locale = urlParts.shift()
			}

			return {
				locale,
				params: {
					page: urlParts,
				},
			}
		}),
		fallback: 'blocking',
	}
})

export const getStaticProps = handleGetStaticProps(async context => {
	const url = getLinkableUrlFromContext(context)

	const locale = context.locale ?? context.defaultLocale ?? 'en'

	const dataFromContember = await contember('query', { scalars: scalars })({
		getLinkable: [
			{
				by: { url },
			},
			{
				url: true,
				genericPage: [{}, GenericPageDetailLocaleFragment(locale)],
				orderPage: [{}, OrderPageDetailLocaleFragment()],
				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
		getHeader: [
			{
				by: {
					unique: One.One,
				},
			},
			HeaderLocaleFragment(locale),
		],
		getFooter: [
			{
				by: {
					unique: One.One,
				},
			},
			FooterLocaleFragment(locale),
		],
		getGeneral: [
			{
				by: {
					unique: One.One,
				},
			},
			GeneralLocaleFragment(locale),
		],
		getTranslationDomain: [
			{
				by: {
					identifier: 'app',
				},
			},
			TranslationDomainWithCatalougesByIdentifierFragment(locale),
		],
		getOrderPage: [
			{
				by: {
					unique: One.One,
				},
			},
			{
				localesByLocale: [
					{
						by: {
							locale: {
								code: locale,
							},
						},
					},
					{
						link: [
							{},
							{
								url: true,
							},
						],
					},
				],
			},
		],
	})

	const redirectUrl = (() => {
		const target = dataFromContember.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
		}
	}

	const canonicalUrl = (() => {
		const url = dataFromContember.getLinkable?.url
		if (!url) {
			return null
		}
		return (process.env.NEXT_PUBLIC_WEB_URL ?? '') + url
	})()

	const genericPage = dataFromContember.getLinkable?.genericPage ?? null

	const orderPage = await (async () => {
		const initial = dataFromContember.getLinkable?.orderPage ?? null

		if (!initial) {
			return null
		}

		const { listProduct, listFilledOrderFormCountry } = await contember('query', { scalars: scalars })({
			listProduct: [
				{
					filter: {
						isForSale: { eq: true },
					},
				},
				OrderProductFragment(locale),
			],
			listFilledOrderFormCountry: [
				{},
				{
					id: true,
					label: true,
					code: true,
				},
			],
		})

		return {
			...initial,
			products: listProduct,
			countries: listFilledOrderFormCountry,
		}
	})()

	const page = {
		genericPage,
		orderPage,
	}

	const translations = dataFromContember.getTranslationDomain

	const currentPage = Object.values(page).find(page => page !== null)

	if (!currentPage) {
		return {
			notFound: true,
		}
	}

	const currentLocale = currentPage.root?.locales?.find(item => item.locale?.code === locale)

	const formatter = new Intl.NumberFormat('en-US', {
		maximumFractionDigits: 0,
	})

	const currency = 'USD'

	const bitcoinPrice = await (async () => {
		try {
			const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice.json')
			const data = await response.json()
			const usd = formatter.format(data?.bpi?.USD?.rate_float ?? 0)
			const eur = formatter.format(data?.bpi?.EUR?.rate_float ?? 0)

			return { usd, eur }
		} catch (error) {
			console.error(error)
			return { usd: '0', eur: '0' }
		}
	})()

	const footer = await (async () => {
		const initial = dataFromContember.getFooter

		if (!initial) {
			return null
		}

		return {
			...initial,
			bitcoinPrice,
		}
	})()

	return {
		props: {
			header: dataFromContember.getHeader,
			footer,
			page,
			pageLocales: currentPage.root?.locales ?? [],
			currentLocale: currentLocale,
			translations,
			orderPage: dataFromContember.getOrderPage,
			seo: {
				canonicalUrl,
				seo: {
					...(dataFromContember.getGeneral?.localesByLocale?.seo ?? {}),
					...Object.fromEntries(Object.entries(currentPage.seo ?? {}).filter(([_, value]) => Boolean(value))),
				},
			},
		},
		revalidate: 60,
	}
})
