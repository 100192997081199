import clsx from 'clsx'
import Image from 'next/image'
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntersection } from 'react-use'
import { ProjectionResult } from '../data/ProjectionFragment'
import { ProjectionItemResult } from '../data/ProjectionItemFragment'
import { BreakableContent } from './BreakableContent'
import styles from './Projection.module.sass'
import { ProjectionMobile } from './ProjectionMobile'

export type ProjectionProps = ProjectionResult

export const Projection: FunctionComponent<ProjectionProps> = ({ items }) => {
	const [visibleAnchors, setVisibleAnchors] = useState<boolean[]>([])

	useEffect(() => {
		setVisibleAnchors(items.map(() => false))
	}, [items])

	const isActiveItemIndex = useMemo(() => {
		const reverseIndex = [...visibleAnchors].reverse().findIndex(isVisible => isVisible)
		if (reverseIndex === -1) {
			return 0
		}
		return visibleAnchors.length - reverseIndex - 1
	}, [visibleAnchors])

	const onVisiblityChange = useCallback((visibility: boolean, index: number) => {
		setVisibleAnchors(previous => {
			const next = [...previous]
			next[index] = visibility
			return next
		})
	}, [])

	return (
		<div className={styles.wrapper}>
			<div className={styles.is_mobile}>
				<ProjectionMobile items={items} />
			</div>
			<div className={clsx(styles.columns, styles.is_desktop)}>
				<div className={styles.column}>
					{items.map((item, index) => item.item && <ProjectionContent key={item.id} index={index} item={item.item} />)}
				</div>
				<div
					className={clsx(styles.column, styles.is_items)}
					style={{
						'--Projection-items-count': items.length,
					}}
				>
					<div className={styles.items}>
						{items.map(
							(item, index) =>
								item.item && (
									<ProjectionItem key={item.id} index={index} item={item.item} isActiveItem={isActiveItemIndex} />
								),
						)}
					</div>
					{items.map((item, index) => (
						<Anchor key={item.id} index={index} onVisiblityChange={onVisiblityChange} />
					))}
				</div>
			</div>
		</div>
	)
}

type ProjectionContentProps = {
	index: number
	item: NonNullable<ProjectionItemResult['item']>
}

const ProjectionContent: FunctionComponent<ProjectionContentProps> = ({ index, item }) => {
	const contentRef = useRef<HTMLDivElement>(null)
	const [progress, setProgress] = useState(0)

	const handleScroll = useCallback(() => {
		const viewableContentArea = window.innerHeight * 0.5
		if (contentRef.current) {
			const { y: contentY, height: contentHeight } = contentRef.current.getBoundingClientRect()

			const contentCenter = contentY + contentHeight / 2
			const viewportCenter = window.innerHeight / 2

			setProgress(
				Math.min(
					2 * (1 - Math.min(Math.abs(viewportCenter - contentCenter), viewableContentArea) / viewableContentArea),
					1,
				),
			)
		}
	}, [])

	useEffect(() => {
		document.addEventListener('scroll', handleScroll)

		return () => document.removeEventListener('scroll', handleScroll)
	}, [handleScroll])

	return (
		<div
			className={styles.content}
			style={{
				'--Projection-content-index': index,
				'--Projection-content-progress': `${progress}`,
			}}
			ref={contentRef}
		>
			<h3 className={styles.title}>{item.title}</h3>
			{item.text && (
				<span className={styles.text}>
					<BreakableContent text={item.text} isParagraph />
				</span>
			)}
		</div>
	)
}

type ProjectionItemProps = {
	index: number
	item: NonNullable<ProjectionItemResult['item']>
	isActiveItem: number
}

const ProjectionItem: FunctionComponent<ProjectionItemProps> = ({ index, item, isActiveItem }) => {
	return (
		<div className={clsx(styles.item, isActiveItem === index && styles.is_active)}>
			<div className={styles.image}>
				{item.image && (
					<Image
						className={styles.imageIn}
						src={item.image.url}
						width={item.image.width}
						height={item.image.height}
						alt={item.image.alt ?? item.image.fileName ?? ''}
					/>
				)}
			</div>
		</div>
	)
}

type AnchorProps = {
	index: number
	onVisiblityChange: (isVisible: boolean, index: number) => void
}

const Anchor: FunctionComponent<AnchorProps> = ({ index, onVisiblityChange }) => {
	const anchorRef = useRef<HTMLDivElement>(null)
	const intersection = useIntersection(anchorRef, {
		root: null,
		rootMargin: '0px',
		threshold: 0,
	})

	useEffect(() => {
		onVisiblityChange(intersection?.isIntersecting ?? false, index)
	}, [index, intersection?.isIntersecting, onVisiblityChange])

	return (
		<div
			ref={anchorRef}
			className={styles.anchor}
			style={{
				'--Projection-anchor-index': index,
			}}
		/>
	)
}
