import React, { useContext, useMemo } from 'react'
import { v4 } from 'uuid'
import { CommonPageProps } from '../../pages/[[...page]]'

export type SwitcherLocalesProps = {
	pageLocales: CommonPageProps['pageLocales']
	currentLocale: CommonPageProps['currentLocale']
}

const defaultSwitcherLocaleValues = {
	id: v4(),
	locale: {
		code: 'en',
		label: 'English',
		currency: '€',
	},
	link: {
		url: '#',
	},
}

const defaultSwitcherLocalesValues: SwitcherLocalesProps = {
	pageLocales: [defaultSwitcherLocaleValues],
	currentLocale: defaultSwitcherLocaleValues,
}

export const switcherLocalesContext = React.createContext<SwitcherLocalesProps>(defaultSwitcherLocalesValues)

export const SwitcherLocalesContextProvider: React.FunctionComponent<
	SwitcherLocalesProps & { children: React.ReactNode }
> = ({ children, pageLocales, currentLocale }) => {
	const value = useMemo(
		() => ({
			pageLocales,
			currentLocale,
		}),
		[currentLocale, pageLocales],
	)

	return <switcherLocalesContext.Provider value={value}>{children}</switcherLocalesContext.Provider>
}

export const useLocales = () => {
	const { currentLocale, pageLocales } = useContext(switcherLocalesContext)
	return { currentLocale, pageLocales }
}
