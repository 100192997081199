import clsx from 'clsx'
import { FunctionComponent, useCallback } from 'react'
import { OrderProductResult } from '../data/OrderProductFragment'
import { useFormatPriceAndCurrency } from '../utilities/useFormatPriceAndCurrency'
import { BreakableContent } from './BreakableContent'
import { useFeature, useOrderPrice } from './contexts/OrderFormContext'
import { useTranslate } from './contexts/TranslationsContextProvider'
import styles from './OrderProduct.module.sass'
import { OrderProductFeatures } from './OrderProductFeatures'
import { OrderSubmit } from './OrderSubmit'

export type OrderProductProps = OrderProductResult

export const OrderProduct: FunctionComponent<OrderProductProps> = ({ localesByLocale, features }) => {
	const translations = useTranslate()
	const totalPrice = useOrderPrice()
	const { featureIds } = useFeature()
	const strikethroughPrice = useFormatPriceAndCurrency(
		localesByLocale?.price && localesByLocale?.price > 0 ? localesByLocale?.price.toString() : '',
	)

	const isDiscountedPrice = localesByLocale?.discountedPrice && localesByLocale.discountedPrice > 0 ? true : false

	const isStrikethroughPriceVisibleInTotalSummary = useCallback(() => {
		return featureIds.length === 0 ? true : false
	}, [featureIds.length])
	return (
		<div className={styles.wrapper}>
			{localesByLocale?.description && (
				<div className={styles.description}>
					<BreakableContent text={localesByLocale.description} />
				</div>
			)}
			{features.length > 0 && (
				<div className={styles.features}>
					<OrderProductFeatures features={features} />
				</div>
			)}
			<div className={styles.summary}>
				<div className={styles.summaryTitle}>{translations('orderForm.summary.title')}</div>
				{/* The label/tag which displayed the text "-% Sale valid only during this weekend". Doesn't matter anymore, but the styles might be usable for something else. */}
				{/* {isDiscountedPrice && (
					<div className={styles.summaryDiscountLabel}>{translations('productTabsTiles.forSale.discountedLabel')}</div>
				)} */}
				<h2 className={styles.summaryTotal}>
					{useFormatPriceAndCurrency(totalPrice.toString())}
					{isDiscountedPrice && isStrikethroughPriceVisibleInTotalSummary() ? (
						<span className={clsx(styles.strikethrough, styles.is_small)}>{strikethroughPrice}</span>
					) : null}
				</h2>
				<table className={styles.summaryTable}>
					<tbody>
						<tr className={styles.summaryRow}>
							<SummaryItemChildren
								title={localesByLocale?.name}
								price={
									isDiscountedPrice
										? localesByLocale?.discountedPrice.toString() ?? ''
										: localesByLocale?.price.toString() ?? ''
								}
								strikethroughPrice={isDiscountedPrice ? strikethroughPrice : undefined}
							/>
						</tr>
						{/* @TODO: reservation commission */}
						{features.map(feature => {
							if (featureIds.find(featureId => featureId === feature.id)) {
								return (
									<tr key={feature.id} className={styles.summaryRow}>
										<SummaryItemChildren
											title={feature.localesByLocale?.title}
											price={feature.localesByLocale?.price.toString() ?? '0'}
										/>
									</tr>
								)
							}
						})}
					</tbody>
				</table>
				<div className={styles.actions}>
					<OrderSubmit />
				</div>
			</div>
		</div>
	)
}

type SummaryItemChildren = {
	title?: string
	price: string
	strikethroughPrice?: string
}

const SummaryItemChildren: FunctionComponent<SummaryItemChildren> = ({ title, price, strikethroughPrice }) => {
	return (
		<>
			<td className={clsx(styles.summaryCell, styles.is_price)}>
				{useFormatPriceAndCurrency(price ? price.toString() : '0')}
				{title && !strikethroughPrice && ':'}
			</td>
			{title && (
				<td className={styles.summaryCell}>
					{strikethroughPrice && (
						<span className={clsx(styles.strikethrough, styles.rightGap)}>{strikethroughPrice}</span>
					)}
					{title}
				</td>
			)}
		</>
	)
}
