import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { CommonPageProps } from '../pages/[[...page]]'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import { Container } from './Container'
import styles from './Header.module.sass'

export type HeaderProps = Pick<CommonPageProps, 'header'>

export const Header: FunctionComponent<HeaderProps> = ({ header }) => {
	return (
		<div className={styles.wrapper}>
			<Container size="wide">
				<div className={styles.main}>
					<div className={styles.logo}>
						{header?.localesByLocale?.root?.logo && (
							<Link title="logo" href="/" className={styles.logoLink}>
								<Image
									src={header.localesByLocale.root.logo.url}
									width={header.localesByLocale.root.logo.width}
									height={header.localesByLocale.root.logo.height}
									alt={header.localesByLocale.root.logo.alt ?? ''}
								/>
							</Link>
						)}
					</div>
					<div className={styles.column}>
						{/* @TODO: navigation */}
						{header?.localesByLocale?.specialLink && (
							<Button type="link" href={contemberLinkToHref(header.localesByLocale.specialLink)} fillType="light">
								{header.localesByLocale.specialLink.title}
							</Button>
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}
